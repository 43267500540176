import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import GlobalDataContext from "./../../../GlobalDataContext";

import "./../../scss/Basket/index.scss";
import Betslip from "./Betslip";
import BetList from "./BetList";
import cn from "clsx";
import api from "../../utils/api";
import {
  TICKET_COUNT_FOR_LOADING
} from "../../utils/constants";

const Basket = () => {
  const { state, dispatch } = useContext(GlobalDataContext);
  const { t } = useTranslation();

  const tabsTitles = ["betslip", "mybets"];
  const [activeTabTitle, setActiveTabTitle] = useState(tabsTitles[0]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    numberOfElements: TICKET_COUNT_FOR_LOADING,
    totalElements: 1000,
    number: 0,
    last: false,
    totalPages: 0

  });

  const fetchTicketHistory = async (size, page, resetState) => {

    setIsHistoryLoading(true);
    try {
      const resJson = await api.getCurrentBasket({ size: size, page: page });
      if (resJson.errorMsg) return;
      dispatch({
        type: "SET_BET_HISTORY",
        payload: resetState ? resJson.content : [...state.betHistory, ...resJson.content]
      });
      setIsHistoryLoading(false);
      setPaginationData({
        ...paginationData,
        numberOfElements: resJson.numberOfElements,
        totalElements: resJson.totalElements,
        number: resJson.number,
        last: resJson.last,
        totalPages: resJson.totalPages
      });
    } catch (error) {
      setIsHistoryLoading(false);
    }
  };

  // for mobile devices show basket with properly active tab item
  useEffect(() => {
    if (!state.slideAsideBlockId && activeTabTitle === "mybets")
      setActiveTabTitle(tabsTitles[0]);
    if (
      !state.slideAsideBlockId ||
      state.slideAsideBlockId === "creditHistory" ||
      state.slideAsideBlockId === "headerSlideMenu" ||
      state.slideAsideBlockId === "withdrawHistory" ||
      state.slideAsideBlockId === "withdrawMoney"
    )
      return;
    const tabTitle =
      state.slideAsideBlockId === "betHistory" ? tabsTitles[1] : tabsTitles[0];

    setActiveTabTitle(tabTitle);
    if (state.slideAsideBlockId === "betHistory")
      fetchTicketHistory(TICKET_COUNT_FOR_LOADING, 0, true);
    // eslint-disable-next-line
  }, [state.slideAsideBlockId]);

  const changeActiveItem = event => {
    const tabId = event.currentTarget.getAttribute("data-tab-id");
    if (tabId === activeTabTitle) return;
    if (tabId === "mybets") {
      fetchTicketHistory(TICKET_COUNT_FOR_LOADING, 0, true);
    }
    setActiveTabTitle(tabId);
  };
  return (
    <section className="basket">
      <div className="tabs">
        <nav
          className={cn("tabs-titles", {
            disable: false
          })}
        >
          {tabsTitles.map((item, i) => {
            return (
              <div
                key={i}
                data-tab-id={item}
                onClick={changeActiveItem}
                className={cn(
                  "tabs-titles-item",
                  {
                    active: activeTabTitle === item
                  },
                  {
                    disable: false
                  }
                )}
              >
                {item === 'mybets' && t("myBets")}
                {item === 'betslip' && t("betSlip")}
              </div>
            );
          })}
        </nav>
        <div className="tabs-content">
          {(state.slideAsideBlockId === "betSlip" ||
            activeTabTitle === "betslip") && <Betslip />}
          {(state.slideAsideBlockId === "betHistory" ||
            activeTabTitle === "mybets") && (
            <BetList
              gameSettings={state.gameSettings}
              isHistoryLoading={isHistoryLoading}
              ticketHistory={state.betHistory}
              fetchTicketHistory={fetchTicketHistory}
              paginationData={paginationData}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Basket;
