import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import cx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalMainContent,
  ModalCloseBtn
} from "./../Modal/Modal";
import ActionButton from "../ActionButton";

import Calculator from "../Calculator/Calculator";
import ReactTooltip from "react-tooltip";
import { isMobile, getFloor } from "../../utils/helpers";
import { toastreact } from "../ToastMessage";
import { toastMessageTypes } from "../ToastMessage/constants";
import { inputsNames } from "../../utils/constants";

const LogoutInfoAndWithdrawModal = ({
  showModal,
  openModalEventListener,
  withdrawMoneyCode,
  isWebGameVersion,
  creditsBalance,
  withdrawMoneyEventListener,
  currency
}) => {
  const [showVirtualKeyboard, toggleVirtualKeyboard] = useState(false);
  const [withdrawMoneyValue, setWithdrawMoneyValue] = useState(creditsBalance);

  const { t } = useTranslation();

  const errorMessages = {
    [inputsNames.WITHDRAWMONEY]: t(
      "modalWindows.logoutWithdrawModal.typeAmount"
    )
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [withdrawMoneyValue]);

  // update value of withdraw money input
  useEffect(() => {
    if (creditsBalance > 0) setWithdrawMoneyValue(creditsBalance);
  }, [creditsBalance]);

  const onChangeEventListener = e => {
    const valueWithdrawMoney = e.currentTarget ? e.currentTarget.value : e;
    if (isMobile.any() && valueWithdrawMoney > creditsBalance) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: errorMessages[inputsNames.WITHDRAWMONEY].replace(
          "%d%",
          `${creditsBalance}`
        )
      });
    }
    // disable type non number in input but allow empty value ('')
    const reg = new RegExp("^\\d+$");
    let formatedValue = valueWithdrawMoney.replace(",", ".");
    if (formatedValue.length > 0) {
      setWithdrawMoneyValue(
        reg.test(formatedValue.replace(".", ""))
          ? formatedValue
          : withdrawMoneyValue
      );
    } else {
      setWithdrawMoneyValue(formatedValue);
    }
  };

  // withdraw money request
  const onWithdrawMoneyEventListener = event => {
    event.preventDefault();
    if (event.currentTarget.classList.contains("disable")) return;
    withdrawMoneyEventListener(withdrawMoneyValue);
  };
  const onCloseModal = e => {
    openModalEventListener(e);
    setWithdrawMoneyValue(creditsBalance);
    toggleVirtualKeyboard(false);
  };

  return (
    <Modal
      showModal={showModal}
      id={"withdrawMoney"}
      customClass={`withdraw-money`}
      closeModalEventListener={onCloseModal}
    >
      <ModalCloseBtn closeModalEventListener={onCloseModal} />

      <ModalHeader>
        <h3>
          {t("modalWindows.logoutWithdrawModal.withdrawFirst")}
          <p style={{ marginTop: "4px", fontSize: "1rem", fontWeight: "400" }}>
            {t("modalWindows.logoutWithdrawModal.availableAmount")}{" "}
            {getFloor(creditsBalance, 100)} {currency}
          </p>
        </h3>
      </ModalHeader>

      <ModalMainContent>
        {showVirtualKeyboard ? (
          <Calculator
            onAddAmountEventListener={onChangeEventListener}
            onResult={() => {
              toggleVirtualKeyboard(false);
            }}
            selectedValue={withdrawMoneyValue}
            isKeyboard={false}
          />
        ) : withdrawMoneyCode ? (
          <div className="centered-block align-center">
            <hr />
            {t("modalWindows.logoutWithdrawModal.showCode")}:
            <hr />
            <h2>
              <strong>{withdrawMoneyCode}</strong>
            </h2>
          </div>
        ) : (
          <div className="input-wrap centered-block align-center">
            <div className="flex-row flex-align-baseline align-left form-group ">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  onClick={e => {
                    if (isMobile.any()) toggleVirtualKeyboard(true);
                  }}
                  value={`${getFloor(withdrawMoneyValue, 100)}`}
                  onChange={onChangeEventListener}
                  type="text"
                  placeholder={t(
                    "modalWindows.logoutWithdrawModal.moneyAmountPlacehold"
                  )}
                  name="withdrawMoneyAmount"
                  id="withdrawMoneyAmountTerminal"
                  readOnly={isMobile.any() || !isWebGameVersion}
                  className={cx("with-placeholder", {
                    error: withdrawMoneyValue > creditsBalance,
                    success:
                      withdrawMoneyValue <= creditsBalance &&
                      withdrawMoneyValue > 0
                    // [`v-keyboard`]: !isWebGameVersion
                  })}
                />
                <label htmlFor="withdrawMoneyAmountTerminal">
                  {t("modalWindows.logoutWithdrawModal.moneyAmountPlacehold")}
                </label>
                <span className={"valid-icon"}></span>
                <span
                  className={cx(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        !isMobile.any() && withdrawMoneyValue > creditsBalance
                    }
                  )}
                >
                  {errorMessages[inputsNames.WITHDRAWMONEY].replace(
                    "%d%",
                    `${getFloor(creditsBalance, 100)}`
                  )}
                </span>
                {/*{!isWebGameVersion && (*/}
                {/*  <span*/}
                {/*    id={inputsNames.LOGIN}*/}
                {/*    className={"modal-keyboard-btn"}*/}
                {/*    onClick={() => {*/}
                {/*      toggleVirtualKeyboard(true);*/}
                {/*    }}*/}
                {/*  ></span>*/}
                {/*)}*/}
              </div>
            </div>

            <ActionButton
              tooltip={
                withdrawMoneyValue < 1 || withdrawMoneyValue > creditsBalance
                  ? t("modalWindows.logoutWithdrawModal.prefferedAmount")
                  : ""
              }
              onclick={onWithdrawMoneyEventListener}
              classesName={cx("btn btn-warning", {
                disable:
                  withdrawMoneyValue === 0 ||
                  withdrawMoneyValue > creditsBalance
              })}
              isWarning={true}
            >
              {withdrawMoneyValue < 1 || withdrawMoneyValue > creditsBalance
                ? t("modalWindows.logoutWithdrawModal.typeCorrect")
                : `${t("modalWindows.logoutWithdrawModal.generateCodeFor")} ${getFloor(
                    withdrawMoneyValue,
                    100
                  )} ${currency}`}
            </ActionButton>
          </div>
        )}
      </ModalMainContent>
    </Modal>
  );
};

export default LogoutInfoAndWithdrawModal;
