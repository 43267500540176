import React from "react";
import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalHeader,
  ModalMainContent,
  ModalCloseBtn
} from "./../Modal/Modal";
import { getFloor } from "./../../utils/helpers";

const JackpotWinnerModal = ({
  showModal,
  openModalEventListener,
  jackpotCode,
  jackpotName,
  jackpotCurrency,
  jackpotDate,
  jackpotAmount,
  jackpotWinnerEventListener
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      showModal={showModal}
      id={"jackpotWinner"}
      customClass={`withdraw-money`}
      closeModalEventListener={openModalEventListener}
    >
      <ModalCloseBtn closeModalEventListener={openModalEventListener} />

      <ModalHeader>
        <h2>{t("modalWindows.jackpotWinnModal.title").replace("JACKPOTNAME", jackpotName)}</h2>
      </ModalHeader>

      <ModalMainContent>
        <div className="centered-block align-center">
          <label className="flex-col-21">
            {t("modalWindows.jackpotWinnModal.wonAmount")} (
            <strong>
              {jackpotCurrency} {getFloor(jackpotAmount, 100)}
            </strong>
            )
          </label>
          <hr />
          {t("modalWindows.jackpotWinnModal.showWinCode")}:
          <h2>
            <strong>{jackpotCode}</strong>
          </h2>
          <button
            onClick={jackpotWinnerEventListener}
            className="btn btn-warning"
          >
            {t("OK")}
          </button>
        </div>
      </ModalMainContent>
    </Modal>
  );
};

export default JackpotWinnerModal;
