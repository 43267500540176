import React from "react";
import { useTranslation } from "react-i18next";

import "./../../scss/History/index.scss";
import { useGlobalDataContext } from "../../../GlobalDataContext";
import { getOverUnderValueForFirstWinNumber } from "../Bets/helpers";

const HistoryContent = ({ results }) => {
  const { t } = useTranslation();
  const { state } = useGlobalDataContext();
  return (
    <div className="event-history-content">
      <div className="event-container">
        <div className="event-first-three-item">
          <span className={`event-win-item-${results.first}`} />
          <span className="event-first-three-item-name">
            {t("history.first")}
          </span>
        </div>
        <div className="event-first-three-item">
          <span className={`event-win-item-${results.second}`} />
          <span className="event-first-three-item-name">
            {t("history.second")}
          </span>
        </div>
        <div className="event-first-three-item">
          <span className={`event-win-item-${results.third}`} />
          <span className="event-first-three-item-name">
            {t("history.third")}
          </span>
        </div>
      </div>
      <div className="event-container">
        <div className="event-item-1">
          <div className="event-sub-item">
            <span className="event-item-name">{t("history.win")}</span>
            <span className={`event-win-item-${results.first}`} />
            <span className="event-win-coefficient">{results.winner}</span>
          </div>
        </div>
        <div className="event-item-3">
          <span className="event-item-name">{t("history.inFirst3")}</span>
          <div className="event-sub-container">
            <div className="event-sub-item">
              <span className={`event-win-item-${results.first}`} />
              <span className="event-win-coefficient">
                {results.placeOddsFirst}
              </span>
            </div>
            <div className="event-sub-item">
              <span className={`event-win-item-${results.second}`} />
              <span className="event-win-coefficient">
                {results.placeOddsSecond}
              </span>
            </div>
            <div className="event-sub-item">
              <span className={`event-win-item-${results.third}`} />
              <span className="event-win-coefficient">
                {results.placeOddsThird}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="event-container">
        <div className="event-item-1">
          <span className="event-item-name">{t("history.exacta")}</span>
          <div className="event-duo-container">
            <div className="event-sub-item">
              <span className={`event-win-duo-item-${results.first}`} />
            </div>
            <div className="event-sub-item">
              <span className={`event-win-duo-item-${results.second}`} />
            </div>
          </div>
          <span className="event-win-coefficient">{results.exacta}</span>
        </div>
        <div className="event-item-1">
          <span className="event-item-name">{t("history.quinella")}</span>
          <div className="event-duo-container">
            <div className="event-sub-item">
              <span className={`event-win-duo-item-${results.second}`} />
            </div>
            <div className="event-sub-item">
              <span className={`event-win-duo-item-${results.first}`} />
            </div>
          </div>
          <span className="event-win-coefficient">{results.quinella}</span>
        </div>
        <div className="event-item-1">
          <span className="event-item-name">{t("history.trifecta")}</span>
          <div className="event-trio-container">
            <div className="event-sub-item">
              <span className={`event-win-trio-item-${results.first}`} />
            </div>
            <div className="event-sub-item">
              <span className={`event-win-trio-item-${results.second}`} />
            </div>
            <div className="event-sub-item">
              <span className={`event-win-trio-item-${results.third}`} />
            </div>
          </div>
          <span className="event-win-coefficient">{results.trifecta}</span>
        </div>
      </div>
      <div className="event-container">
        <div className="event-item-2">
          <span className="event-item-name">{t("history.evenodd")}</span>
          <span className="event-win-odds">
            {results.first % 2 === 0 ? t("history.even") : t("history.odd")}
          </span>
          <span className="event-win-coefficient">
            {results.first % 2 === 0 ? results.evenOdds : results.oddOdds}
          </span>
        </div>
        <div className="event-item-2">
          <span className="event-item-name">{t("history.overunder")}</span>
          <span className="event-win-odds">
            {getOverUnderValueForFirstWinNumber(
              state.raceType,
              results.first
            ) === "over"
              ? t("history.over")
              : t("history.under")}
          </span>
          <span className="event-win-coefficient">
            {getOverUnderValueForFirstWinNumber(
                state.raceType,
                results.first
            ) === "over"
                ? results.highOdds
                : results.lowerOdds}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HistoryContent;
