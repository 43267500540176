import React from "react";
import KeypadRow from "./KeypadRow";
import KeypadButton from "./KeypadButton";

const Keyboard = props => (
  <section className="keypad">
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>1</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>2</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>3</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>4</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>5</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>6</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>7</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>8</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>9</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>0</KeypadButton>
      <KeypadButton type="dark" onButtonPress={props.onButtonPress}>
        ←
      </KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>q</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>w</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>e</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>r</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>t</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>y</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>u</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>i</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>o</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>p</KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>a</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>s</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>d</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>f</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>g</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>h</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>j</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>k</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>l</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>@</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>.</KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton type="dark" onButtonPress={props.onButtonPress}>
        ↑
      </KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>z</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>x</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>c</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>v</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>b</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>n</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>m</KeypadButton>
      <KeypadButton type="dark" onButtonPress={props.onButtonPress}>
        .?!&
      </KeypadButton>
    </KeypadRow>

    <KeypadRow>
      <KeypadButton type="space" onButtonPress={props.onButtonPress}>
        space
      </KeypadButton>
    </KeypadRow>

    <KeypadRow>
      <KeypadButton type="large" onButtonPress={props.onButtonPress}>
        OK
      </KeypadButton>
    </KeypadRow>
  </section>
);

export default Keyboard;
