import React from "react";

import GameList from "./GameList";
import AdditionalInformation from "./AdditionalInformation";
import JackPotLogo from "./JackPotLogo";
import LoginMenu from "./LoginMenu";

import MobileHeader from "./MobileHeader/index";

import "./../../scss/Header/index.scss";

const Header = ({ onChangeGameEventListener }) => {
  return (
    <>
      <header className="flex-row header">
        <div className="flex-col-6 hide-for-mobile">
          <GameList onChangeGame={onChangeGameEventListener} />
        </div>
        <div className="flex-col-9 align-center">
          <div className={"hide-for-mobile fullheight"}>
            <AdditionalInformation />
            <JackPotLogo />
          </div>
          <MobileHeader onChangeGameEventListener={onChangeGameEventListener} />
        </div>
        <div className="flex-col-6 right-header-block">
          <LoginMenu />
        </div>
      </header>
    </>
  );
};

export default Header;
