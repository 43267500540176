import React, { useState, Fragment, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import Accordion from "../../../components/Accordion/Accordion";
import BasketContent from "./BasketContent";
import TicketInfo from "./TicketInfo";

import { Scrollbars } from "react-custom-scrollbars";
import { getObjectForAccordionsOpenState } from "../../../components/Accordion/helpers";
import DatePicker from "../../../components/DatePicker/DateRangePicker";
import DateFilterModal from "../../../components/ModalWindows/DateFilterModal";
import Select from "react-select";
import { getTicketStatesOptionsFromConst, ticketStates } from "../../../utils/constants";
import cx from "clsx";
import { getFloor } from "../../../utils/helpers";
import {useGlobalDataContext} from "../../../../GlobalDataContext";

function BasketList({ items, currency, shopAddress, showCancelTicketPopup }) {
  const { t } = useTranslation();
  const { state } = useGlobalDataContext();

  const [statusFilter, setStatusFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [periodStartDate, setPeriodStartDate] = useState(null);
  const [showModal, toggleModal] = useState(null);

  // set the height of bet list content after page render
  useEffect(() => {
    setAutoHeightMaxScrollbar(
      document.querySelector(".basket").offsetHeight * 0.895
    );
  }, []);

  const onGetBetHistoryEventListener = event => {
    const raceIndex = event.currentTarget.getAttribute("data-index");

    const openEventsCopy = { ...openEvents };
    openEventsCopy[raceIndex] = !openEventsCopy[raceIndex];
    setOpenEvents(openEventsCopy);
  };

  // state for saving open state of each accordion
  const [openEvents, setOpenEvents] = useState(
    getObjectForAccordionsOpenState(items)
  );

  const [autoHeightMaxScrollBar, setAutoHeightMaxScrollbar] = useState(1000);

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: "flex", height: "60%", width: "50%" }}>
      <div>{label}</div>
    </div>
  );

  const statusFilterCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "2.675rem",
      height: "2.675rem",
      boxShadow: state.isFocused ? null : null
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "2.675rem",
      padding: "0 0.675rem"
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0"
    }),
    indicatorSeparator: state => ({
      display: "none"
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "2.675rem"
    })
  };

  const onChangeDateFilterListener = value => {
    setPeriodStartDate(value);
    setDateFilter(
      `${new Date(value).getMonth() + 1}/${new Date(
        value
      ).getDate()}/${new Date(value).getFullYear()}`
    );
  };

  const clearDateFilter = () => {
    setPeriodStartDate(null);
    setDateFilter(null);
  };

  // open modal
  const openModalEventListener = event => {
    const modalHref = event.currentTarget.getAttribute("data-modal");
    // if (modalHref === "calculator" && window.innerWidth < 1040) return;
    if (modalHref) {
      toggleModal(modalHref);
    } else {
      toggleModal(null);
    }
  };
  const DateFilterInput = forwardRef(({ value, onClick }, _ref) => (
    <input
      placeholder={t('bets.searchByDate')}
      className="date-picker-input"
      onClick={onClick}
      value={value}
      type="text"
      readOnly
      ref={_ref}
    />
  ));

  const getMaxWinningPerTicket = bets => {
    if (!bets) return;
    let maxWinningPerTicket = 0;

    for (let bet of bets) {
      maxWinningPerTicket = maxWinningPerTicket + bet.stake * bet.odds;
    }
    return getFloor(maxWinningPerTicket, 100);
  };

  const getHeading = (gameName) => {
    let heading = t('bets.rb');
    if (gameName.indexOf('DOG 6') !== -1) heading = t('bets.dog6rb');
    if (gameName.indexOf('DOG 8') !== -1) heading = t('bets.dog8rb');
    if (gameName.indexOf('HORSE 6') !== -1) heading = t('bets.horse6rb');
    if (gameName.indexOf('HORSE 8') !== -1) heading = t('bets.horse8rb');
    return heading;
  };

  const getTicketStatesOptions = (ticketStateDefault) => {
    let ticketStateOption = [...ticketStateDefault];

    for (let ticketState of ticketStateOption) {
      ticketState.label = t(ticketState.label);
    }
    
    return ticketStateOption;
  };
  return (
    <Scrollbars autoHeight autoHeightMin={autoHeightMaxScrollBar}>
      <div className="bets-filter-block-list-item">
        <div className="status-filter">
          <Select
            formatOptionLabel={formatOptionLabel}
            placeholder={t('bets.searchByStatus')}
            isClearable={true}
            isSearchable={false}
            onChange={e => {
              setStatusFilter(e ? e.value : null);
            }}

            options={getTicketStatesOptions(getTicketStatesOptionsFromConst())}
            styles={statusFilterCustomStyles}
          />
        </div>
        <div className="date-filter">
          <DatePicker
            dateFormat="MM/dd/yyyy"
            selected={periodStartDate}
            onChange={onChangeDateFilterListener}
            customInput={
              <DateFilterInput
                value={periodStartDate}
                onClick={onChangeDateFilterListener}
              />
            }
          />
          <span
            onClick={clearDateFilter}
            className={cx("close-button", {
              active: periodStartDate !== null
            })}
          ></span>
        </div>
      </div>
      {items
        .filter(event => {
          if (statusFilter !== null) {
            if (statusFilter === "PENDING") return event.state === "NOVICE";
            return event.state === statusFilter;
          } else return event;
        })
        .filter(event => {
          if (dateFilter !== null && dateFilter !== "")
            return (
              `${new Date(event.issueDate).getMonth() + 1}/${new Date(
                event.issueDate
              ).getDate()}/${new Date(event.issueDate).getFullYear()}` ===
              dateFilter
            );
          else return event;
        })
        .map((event, i) => {
          return (
            <Fragment key={i}>
              <Accordion
                isOpen={openEvents[event.ticketId]}
                onToggle={onGetBetHistoryEventListener}
                heading={`${getHeading(event.gameName)}`}
                subheading={`${t("event")} ${event.gameNumber}`}
                secondaryHeading={`${new Date(
                  event.issueDate
                ).getDate()}/${new Date(event.issueDate).getMonth() +
                  1}/${new Date(event.issueDate).getFullYear()} ${new Date(
                  event.issueDate
                ).toLocaleTimeString("en-GB", {
                  timeZone: "Africa/Johannesburg"
                })}`}
                dataIndex={event.ticketId}
                success={event.state === ticketStates.WINNING}
                alarm={event.state === ticketStates.STORNO}
              />
              <div
                className={`collapse-css-transition ${event.state === ticketStates.WINNING ? 'success' : ''}`}
                race-number = {event.gameNumber}
                aria-hidden={openEvents[event.ticketId] ? "false" : "true"}
                style={{
                  overflow: "hidden",
                  height: openEvents[event.ticketId] ? "100%" : 0,
                  visibility: openEvents[event.ticketId] ? null : "hidden"
                }}
              >
                <div className="bet-content">
                  <TicketInfo
                    shopAddress={shopAddress}
                    ticketData={event}
                    maxWinningPerTicket={getMaxWinningPerTicket(
                      event.betInformation
                    )}
                    currency={currency}
                  />

                  <div className="bet-content-divider" />
                  <BasketContent
                    results={event.betInformation}
                    ticketData={event}
                  />
                  {event.state === ticketStates.NOVICE

                  && ( <div
                      className={ state.currentLifecycleState === "RACE"
                                  && event.gameNumber === state.eventsOdds[0].raceNumber ? "align-right ticketCancelBtn hide" : "align-right ticketCancelBtn"}
                      style={{marginTop: "10px", cursor:"pointer"}}
                      onClick={showCancelTicketPopup}
                      data-ticket-id={event.ticketNumber}
                    >
                      {t("cancelTicketPopup.cancelTicket")}
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}

      {/*modal window for calculator */}
      {showModal && (
        <DateFilterModal
          openModalEventListener={openModalEventListener}
          showModal={showModal === "dateFilterModal"}
          onSetDateFilterListener={onChangeDateFilterListener}
          selectedValue={periodStartDate}
        />
      )}
    </Scrollbars>
  );
}

export default BasketList;
