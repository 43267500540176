import React from "react";
import cx from "clsx";
import { useTranslation } from "react-i18next";

import { useGlobalDataContext } from "../../../GlobalDataContext";
import {isMobile} from './../../utils/helpers';

const GameList = ({ onChangeGame }) => {
  const { t } = useTranslation();
  const { state } = useGlobalDataContext();
  const games = state.rbi === true ? state.rbiGames : state.allGames

    const onChangeGameEvent = event => {
    const target = event.currentTarget;
    if (target.classList.contains("active")) return;
    const newGameIdentifier = target.getAttribute("data-identifier");

    if (!newGameIdentifier) return;
    onChangeGame(newGameIdentifier);
  };
  return (
    <>
      <ul
        className={cx("games-list row-list", {
          [`games-list-disable-click`]: state.eventHistoryLoading && !isMobile.any()
        })}
      >
        {games.map(game => {
          const raceTypeArr = game.split("_");
          return (
            <li
              key={game}
              data-identifier={game}
              onClick={onChangeGameEvent}
              className={cx(`games-list-item ${game}`, {
                active: game === state.raceType
              })}
            >
              <span className={"games-list-item-name"}>
                {t(raceTypeArr[0])} <span>{raceTypeArr[1]}</span>
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default GameList;
