//https://github.com/nkbt/react-collapse

import React from "react";
import Collapse from "react-collapse";
import cx from "clsx";
import styled, { keyframes } from "styled-components";
const Filler = styled.div`
  background: #68b2e3;
  height: 100%;
  opacity: 0.3;
  position: absolute;
  left: 0;
  border-radius: inherit;
  z-index: 1;
  width: ${props => props.progressBarWidth}%;
  animation: ${props => props.keyFrame} ${props => props.progressBarTimer}s
    linear;
`;
const AccordionHeader = ({
  heading,
  subheading,
  secondaryHeading,
  alarm,
  locked,
  delay,
  success,
  onToggle,
  isOpen,
  dataStatus,
  dataIndex,
  progressBarTimer,
  progressBarWidth
}) => {
  const filler = keyframes`
    from {
      width: ${progressBarWidth}%;
    }
  
    to {
      width: 0;
    }
  `;

  return (
    <button
      aria-expanded={isOpen}
      onClick={onToggle}
      className={cx(
        "btn accordion-toggle",
        {
          "accordion-toggle-alarm": alarm
        },
        {
          "accordion-toggle-disable": locked
        },
        {
          "accordion-toggle-delay": delay
        },
        {
          "accordion-toggle-success": success
        }
      )}
      data-status={dataStatus}
      data-index={dataIndex}
      disabled={locked}
    >
      {progressBarTimer > 0 && (
        <Filler
          progressBarTimer={progressBarTimer / 1000}
          keyFrame={filler}
          progressBarWidth={progressBarWidth}
        />
      )}
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 32"
        className="accordion-svg"
        style={{
          color: "#FFFFFF",
          marginRight: "1rem",
          width: "1.25rem",
          height: "1.25rem",
          transition: "transform transitionDuration cubic-bezier(0, 1, 0, 1)",
          transform: isOpen ? "rotate(0)" : "rotate(-.25turn)"
        }}
      >
        <path
          fill="currentColor"
          d="M29.602 8.002l-13.6 11.562-13.6-11.562-2.4 2.752 16 13.764 16-13.764z"
        />
      </svg>
      <div
        style={{
          // flex: 1,
          width: "100%"
        }}
      >
        <div
          style={{
            width: "50%",
            textAlign: "left",
            flexDirection: "column",
            display: "flex"
          }}
        >
          {heading && (
            <span
              style={{
                float: "left",
                lineHeight: 1.25,
                color: "#FFFFFF",
                // fontSize: "1.125rem",
                fontWeight: "700"
              }}
            >
              {heading}
            </span>
          )}

          {subheading && (
            <span
              style={{
                marginTop: 1,
                float: "left",
                lineHeight: 1.25,
                color: "#FFFFFF",
                // fontSize: "1rem",
                fontWeight: "500"
              }}
            >
              {subheading}
            </span>
          )}
        </div>
        {secondaryHeading && (
          <span
            style={{
              marginTop: -19,
              float: "right",
              lineHeight: 1.25,
              color: "#FFFFFF"
              // fontSize: "1rem"
            }}
          >
            {secondaryHeading}
          </span>
        )}
      </div>
    </button>
  );
};

const Accordion = ({
  children,
  isOpen,
  heading,
  subheading,
  secondaryHeading,
  alarm,
  locked,
  delay,
  success,
  onToggle,
  transitionDuration,
  dataStatus,
  dataIndex,
  progressBarTimer,
  progressBarWidth
}) => {
  return (
    <div
      className={cx("accordion", {
        "accordion-is-open": isOpen,
        "accordion-is-closed": !isOpen
      })}
      style={{
        position: "relative",
        transition: transitionDuration
      }}
    >
      <AccordionHeader
        {...{
          heading,
          subheading,
          secondaryHeading,
          alarm,
          locked,
          delay,
          success,
          transitionDuration,
          onToggle,
          isOpen,
          dataStatus,
          dataIndex,
          progressBarTimer,
          progressBarWidth
        }}
      />

      <Collapse
        isOpen={isOpen}
        style={{ transitionDuration }}
        aria-hidden={isOpen ? "false" : "true"}

      >
        <div
          style={{
            marginBottom: 10
          }}
        >
          {children}
          {/*{isOpen && <div data-testId="referenced-div" />}*/}
        </div>
      </Collapse>
      <div
        className="accordion-divider"
        style={{
          position: "absolute",
          bottom: 0,
          height: 1,
          width: "100%",
          background: "#7ba5bf",
          transition: "260ms",
          opacity: isOpen ? 0 : 1
        }}
      />
    </div>
  );
};

export default Accordion;
