import React, { useReducer, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n";

import appReducer, { initialState } from "./app/containers/App/reducer";

import WebSocketApp from "./app/containers/App/WebSocket";
import Routes from "./Routes";
import api from "./app/utils/api";
import {
  broadcastTypes,
  lifecycleStates,
  LOGOUT_URI,
  AUTOLOGOUT_TIMER,
} from "./app/utils/constants";

import GlobalDataContext from "./GlobalDataContext";

import { getCorrectXhrAPIdomain, isMobile } from "./app/utils/helpers";
import RotationWindow from "./app/components/Rotation/RotationWindow";

import {
  getItemFromStorage,
  setItemInStorage,
  removeItemFromStorage,
} from "./app/utils/LocalStorageService";
import SecondScreenVideo from "./app/containers/SecondScreenVideo/SecondScreenVideo";
// import { all } from "core-js/fn/promise";
// import GameSelectModal from "./app/components/ModalWindows/GameSelectModal";
const AppIndex = () => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  // eslint-disable-next-line
  const [webSocket, setNewWebSocket] = useState(null);
  const [isLandscape, setIsLandscape] = useState(false);
  const [rotateToTheRight, setRotateToTheRight] = useState(false);

  // for terminal version show add credits modal and start countdown timer for autologout user
  const showLogoutModalWindowTerminal = async ({
    nextRaceNumber,
    raceType,
  }) => {
    // detect if all events with placed bets were finished or not
    const isAllEventsFinished = (idsOfEventsPlacedBets_) => {
      let eventsIsFinished = true;
      // eslint-disable-next-line
      for (const [racetype, eventIdsByRaceTypeArr] of Object.entries(
        idsOfEventsPlacedBets_
      )) {
        eventsIsFinished = !(eventIdsByRaceTypeArr.length > 0);
      }
      return eventsIsFinished;
    };
    // get current race number(if count of race number start from 1 than need to detect properly previous race number)
    const getCurrentRaceNumber = (eventIdsByRaceTypeArr) => {
      let currentRaceNumber = nextRaceNumber - 1;
      if (currentRaceNumber === 0) {
        currentRaceNumber = Math.max(
          ...eventIdsByRaceTypeArr.map((eventId) => Number(eventId))
        );
      }
      return currentRaceNumber;
    };
    // do it only for terminal version
    if (state.isWebGameVersion) return;
    const workWithSessionStorage = true;
    const idsOfEventsPlacedBets = getItemFromStorage(
      "idsOfEventsPlacedBets",
      workWithSessionStorage
    );

    if (!idsOfEventsPlacedBets || !idsOfEventsPlacedBets[raceType]) return;
    const raceNumber = getCurrentRaceNumber(idsOfEventsPlacedBets[raceType]);
    // remove id of finished events from array with the events which placed bets
    const eventsIdsByRaceTypeFiltered = idsOfEventsPlacedBets[raceType].filter(
      (eventId) => Number(eventId) !== Number(raceNumber)
    );
    idsOfEventsPlacedBets[raceType] = eventsIdsByRaceTypeFiltered;
    setItemInStorage(
      "idsOfEventsPlacedBets",
      idsOfEventsPlacedBets,
      workWithSessionStorage
    );

    // if all events with placed bets were finished than actualize credits
    if (!isAllEventsFinished(idsOfEventsPlacedBets)) return;
    // if all events with placed bets were finished check the balance and show modal window for add credits
    // const betHistory = await api.getCurrentBasket();
    // if (betHistory.errorMsg) return;

    const updatedUserData = await api.getCurrentUserData();
    if (updatedUserData.errorMsg) return;

    // for terminal version show modal with add user credits if user credits equal zero after all bets were finished

    if (
      // eslint-disable-next-line
      updatedUserData.createWalletDTO.credits <= 0
    ) {
      // do not show add credits modal while game select modal is active

      dispatch({
        type: "OPEN_MODAL_WINDOW",
        payload: "addCreditsTerminalZeroBalance",
      });
      const logoutRedirectTimeout = setTimeout(() => {
        window.location.href = LOGOUT_URI;
      }, AUTOLOGOUT_TIMER);

      dispatch({
        type: "SET_LOGOUT_TIMEOUT_TERMINAL",
        payload: logoutRedirectTimeout,
      });

      removeItemFromStorage("idsOfEventsPlacedBets", workWithSessionStorage);
    }

    dispatch({
      type: "SET_CURRENT_USER_DATA",
      payload: updatedUserData,
    });
  };

  // refresh user data after each round of each race type finished
  const updateUserData = async ({ raceNumber, raceType }) => {
    let size = 20;
    let page = 0;
    try {
      const loadMoreTicketBtn = document.getElementById("loadMoreTicketId");
      if (loadMoreTicketBtn) {
        page = loadMoreTicketBtn.getAttribute("data-page-number")
          ? Number(loadMoreTicketBtn.getAttribute("data-page-number"))
          : 0;
        size = loadMoreTicketBtn.getAttribute("data-page-size")
          ? Number(loadMoreTicketBtn.getAttribute("data-page-size")) *
            (page + 1)
          : 20;
      }
    } catch (e) {
      console.log("error in ticket pagination");
    }

    const betHistory = await api.getCurrentBasket({ size: size, page: 0 });
    try {
      // update ticket history only for the current raceType
      if (
        document
          .querySelector(".games-list-item.active")
          .classList.contains(raceType)
      ) {
        dispatch({
          type: "SET_BET_HISTORY",
          payload: betHistory.errorMsg ? [] : betHistory.content,
        });
      }
    } catch (e) {
      console.log("error in ticket pagination");
    }

    // update balance for user
    const updatedUserData = await api.getCurrentUserData();
    if (updatedUserData.errorMsg) return;

    dispatch({
      type: "SET_CURRENT_USER_DATA",
      payload: updatedUserData,
    });
  };

  const setLifecycleData = (data) => {
    const broadcastType = data.webSocketData.broadcastType;

    if (broadcastType === broadcastTypes.LIFECYCLE_STATUS) {
      dispatch({ type: data.webSocketData.state, payload: data.webSocketData });
      // show toastmessage if user won the bet
      if (data.webSocketData.state === lifecycleStates.DELAY) {
        updateUserData({
          lifecycleState: data.webSocketData.state,
          raceNumber: data.webSocketData.raceNumber,
          raceType: data.webSocketData.raceType,
        });
      }

      // for each race type on Delay state check if user has not played bets and if all bets were played and his credits equals 0 show modal window for add credits
      if (data.webSocketData.state === lifecycleStates.DELAY)
        showLogoutModalWindowTerminal({
          lifecycleState: data.webSocketData.state,
          nextRaceNumber: data.webSocketData.nextRaceNumber,
          raceType: data.webSocketData.raceType,
        });

      if (broadcastType === broadcastTypes.JACKPOT_STATUS) {
        dispatch({
          type: "JACKPOT",
          payload: {
            raceType: data.webSocketData.raceType,
            jackpotState: data.webSocketData.jackpotState,
            superJackpotState: data.webSocketData.superJackpotState,
          },
        });
      }
    }
  };

  const MIN_HEIGHT_MOBILE = 500;
  const html = document.getElementsByTagName("html");
  const isLandscapeOrientation = (htmlOffsetHeight, orientation) => {
    return (
      (isMobile.iOS() &&
        (Number(orientation) === 90 || Number(orientation) === -90)) ||
      (isMobile.Android() &&
        window.innerHeight < window.innerWidth &&
        Number(htmlOffsetHeight) < MIN_HEIGHT_MOBILE)
    ); // if height of window browser is more than MIN_HEIGHT_MOBILE than allow operation by default
  };

  // check if device should be rotated to the right side
  const isRotateToTheRight = (htmlOffsetHeight, orientation) => {
    if (isMobile.iOS() && Number(orientation) === 90) return true;
    if (
      isMobile.Android() &&
      window.innerHeight < window.innerWidth &&
      Number(htmlOffsetHeight) < MIN_HEIGHT_MOBILE
    )
      return true;
  };

  // send request for fetching jackpot data(need to be executed after each round ending)
  // eslint-disable-next-line
  const getJackPotData = async () => {
    try {
      //send request for getting state of current session only if there is token exist in Local Storage
      const resJson = await api.getJackPotData();
      if (resJson.errorMsg) {
        console.log("Jackpot data fetching was failed!");
      } else {
        dispatch({
          type: "JACKPOT_DATA_RECIEVED",
          payload: resJson,
        });
      }
    } catch (error) {
      console.log("Jackpot data fetching was failed!");
    }
  };

  useEffect(() => {
    if (webSocket)
      setTimeout(() => {
        // getJackPotData();// if jackpot need activate just uncoment this line
      }, 500); // return 500 error without this delay
  }, [webSocket]);

  // refresh jackpot data on BET game's status
  useEffect(() => {
    if (state.currentLifecycleState === lifecycleStates.BET) {
      setTimeout(() => {
        // getJackPotData(); // if jackpot need activate just uncoment this line
      }, 2000); // return 500 error without this delay
    }
  }, [state.currentLifecycleState]);

  useEffect(() => {
    let allGames = state.allGames;

    if (allGames.length === 0 || !state.gameSettings.portalKey) return;

    // open websocket for geting lifecycle and jackpot data
    const originHostArr = `${getCorrectXhrAPIdomain(
      window.location.origin
    )}`.split(":");
    originHostArr.splice(0, 1, "wss");
    const webSocketOrigin = originHostArr.join(":");
    // eslint-disable-next-line
    for (const game of allGames) {
      setNewWebSocket(
        new WebSocketApp({
          // create new instance of WebSocketApp class
          socketUrl: `${webSocketOrigin}/api/lifecycle/${state.gameSettings.portalKey}/${game}`,
          onMessageRecievedCallback: setLifecycleData,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.allGames]);

  useEffect(() => {
    // detect if it is the iframe version

    if (i18n.language === "ru")
      document.body.classList.add("smaller-font-size");
    if (isMobile.any()) {
      setIsLandscape(
        isLandscapeOrientation(
          html[0].offsetHeight,
          isMobile.Android()
            ? window.screen.orientation.type
            : window.orientation
        )
      );
      setRotateToTheRight(
        isRotateToTheRight(
          html[0].offsetHeight,
          isMobile.Android()
            ? window.screen.orientation.type
            : window.orientation
        )
      );
    }
    // eslint-disable-next-line
  }, []);

  window.addEventListener("resize", function(e) {
    setTimeout(() => {
      (function() {
        const htmlHeight = document.getElementsByTagName("html")[0]
          .offsetHeight;
        setIsLandscape(
          isLandscapeOrientation(
            htmlHeight,
            isMobile.Android()
              ? window.screen.orientation.type
              : window.orientation
          )
        );
        setRotateToTheRight(
          isRotateToTheRight(
            htmlHeight,
            isMobile.Android()
              ? window.screen.orientation.type
              : window.orientation
          )
        );
      })();
    }, 150);
  });
  //mainly for ios
  if (isMobile.iOS()) {
    window.addEventListener("orientationchange", function(e) {});
  }

  return (
    <GlobalDataContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Routes />
      {!state.isWebGameVersion && <SecondScreenVideo />}
      {isMobile.any() && (
        <RotationWindow
          isLandscape={isLandscape}
          rotateToTheRight={rotateToTheRight}
        />
      )}
    </GlobalDataContext.Provider>
  );
};

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <AppIndex />
  </I18nextProvider>,
  document.getElementById("root")
);
