import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";

import {
  betTypesForBetsContainer,
  lifecycleStates
} from "./../../utils/constants";

import "./../../scss/Bets/index.scss";

import { useGlobalDataContext } from "../../../GlobalDataContext";
import { setVideoSize } from "../App/actions";

import ActionButton from "../../components/ActionButton";

import { getSumDataFromBetsObject } from "./../Basket/helpers";
import PreLoader from "../../components/PreLoader/PreLoader";
import { isMobile } from "../../utils/helpers";
import CurrentBetTable from "./CurrentBetTable";

import { getNewBetsArray, getRaceTypeForPlaceBet } from "./helpers";

const Bets = () => {
  const { state, dispatch } = useGlobalDataContext();
  const { t } = useTranslation();

  const [secondScreenIsOpen, setSecondScreenIsOpen] = useState(false);
  const [eventsForBeting, setEventsForBeting] = useState([]);
  const [raceType, setRaceType] = useState(null);
  const [lifecyclesConfig, setLifecyclesConfig] = useState(null);
  const [activeBetType, setActiveBetType] = useState(
    Object.keys(betTypesForBetsContainer)[0]
  );
  const [eventResult, setEventResult] = useState(null);
  const [videoSizeStatus, setVideoSizeStatus] = useState(
    state.video.videoSizeStatus
  );
  const [tabContainerHeight, setTabContainerHeight] = useState(
    state.video.videoSizeStatus.tabContainer.height
  );
  const [lifecycleData, setLifecycleData] = useState(null);
  const [videoComplete, setVideoComplete] = useState(false);
  const [gameSettings, setGameSettings] = useState(null);
  const [openEvents, setOpenEvents] = useState({});
  // eslint-disable-next-line
  const [autoHeightMaxScrollBar, setAutoHeightMaxScrollbar] = useState("30rem");
  const [betsObject, setBetsObject] = useState(null);
  const [betsCount, setBetsCount] = useState(0);

  // set the height of bets tab content after page render
  useEffect(() => {
    if (!document.querySelector(".basket")) return;
    setAutoHeightMaxScrollbar(
        document.querySelector(".video-container").offsetHeight
            ? document.querySelector(".basket").offsetHeight * 0.525
            : document.querySelector(".basket").offsetHeight -
            document.querySelector(".basket .tabs-titles").offsetHeight -
            document.querySelector(".show-betslip-button").offsetHeight -
            document.querySelector(".show-result-history-button").offsetHeight -
            document.querySelector(".show-betslip-button").offsetHeight -
            document.querySelector(".show-result-history-button").offsetHeight
    );
  }, []);

  useEffect(() => {
    if (state.eventsOdds.length) {
      setEventsForBeting(state.eventsOdds);
    }
  }, [state.eventsOdds]);

  useEffect(() => {
    setBetsObject(state.betsObject);
    setBetsCount(getSumDataFromBetsObject(state.betsObject).betsCount);

  }, [state.betsObject]);

  useEffect(() => {
    if (state.raceType) {
      setRaceType(state.raceType);
    }
  }, [state.raceType]);

  useEffect(() => {
    if (state.lifecyclesConfig) {
      setLifecyclesConfig(state.lifecyclesConfig);
    }
  }, [state.lifecyclesConfig]);

  useEffect(() => {
    if (state.activeBetType) {
      setActiveBetType(state.activeBetType);
    }
  }, [state.activeBetType]);

  useEffect(() => {

    if (
        state.lifecycleData && state.lifecycleData.result
    ) {
        setEventResult(state.lifecycleData.result);
      }

    if (state.lifecycleData) setLifecycleData(state.lifecycleData);
    // eslint-disable-next-line
  }, [state.lifecycleData]);

  useEffect(() => {
    setTabContainerHeight(state.video.videoSizeStatus.tabContainer.height);
    setVideoSizeStatus(state.video.videoSizeStatus);
    // eslint-disable-next-line
  }, [state.video.videoSizeStatus]);

  useEffect(() => {
    setVideoComplete(state.videoComplete);
  }, [state.videoComplete]);

  useEffect(() => {
    setGameSettings(state.gameSettings);
  }, [state.gameSettings]);

  // show betslip on mobile devices
  const onShowBetslipEventListener = event => {
    dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload: "betSlip"
    });
  };

  const changeContainerHeightEventListener = event => {

    setTimeout(() => {
      dispatch(
        setVideoSize({
          tabContainer: {
            height: document.querySelector(".basket").offsetHeight * 0.625
          },
          videoContainer: {
            height: document.querySelector(".basket").offsetHeight * 0.45,
            width: "60%"
          }
        })
      );
    }, 150);

    if (
      !state.isWebGameVersion &&
      state.secondScreenInstance.supportSecondScreen
    ) {
      state.secondScreenInstance.open();
      setSecondScreenIsOpen(!secondScreenIsOpen);
      if (secondScreenIsOpen) {
        setTimeout(() => {
          
          dispatch(
            setVideoSize({
              tabContainer: {
                height: isMobile.any()
                  ? window.innerHeight * 0.65
                  : window.innerHeight < 900
                  ? window.innerHeight * 0.37
                  : "29rem"
              },
              videoContainer: {
                height: "100%"
              }
            })
          );
          if (
            state.secondScreenInstance &&
            state.secondScreenInstance.supportSecondScreen
          )
            state.secondScreenInstance.close();
        }, 300);

        return;
      }

      // if open second screen than hide video container and show event list on full height
      setTimeout(() => {
    
        dispatch(
          setVideoSize({
            tabContainer: {
              height:
                document.querySelector(".basket-aside").offsetHeight -
                (document.querySelector(".tabs.odds-tables .tabs-titles")
                  .offsetHeight +
                  4)
            },
            videoContainer: {
              height: 0,
              width: "0"
            }
          })
        );
      }, 150);
    }
  };

  // change type of bet (change tab in bet component)
  const changeBetTypeEventListener = event => {
    const betType = event.currentTarget.getAttribute("data-tab-id");

    dispatch({
      type: "SET_ACTIVE_BET_TYPE",
      payload: betType
    });
  };

  // show result history on mobile devices
  const onShowEventHistoryEventListener = event => {
    dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload: "eventHistory"
    });
  };
  const tabsTitles = Object.keys(betTypesForBetsContainer);

  // save one bet in bets array
  const addBetsInBetSlip = event => {

    const dataForOneNewBet = {
      odds: event.currentTarget.getAttribute("data-perfecta-coords")
          ? getTrifectaOdd(
              event.currentTarget.getAttribute("data-perfecta-coords"),
              event.currentTarget.getAttribute("data-event-id"),
              eventsForBeting
          )
          : event.currentTarget.getAttribute("data-odd-amount"),
      tip: event.currentTarget.getAttribute("data-type-bet"),
      winNumber: event.currentTarget.getAttribute("data-win-number"),
      eventId: event.currentTarget.getAttribute("data-event-id"),
      raceTypeForRequest: getRaceTypeForPlaceBet(raceType),
      raceType: getRaceTypeForCurrentEvent(
          eventsForBeting,
          event.currentTarget.getAttribute("data-event-id")
      ),
      stake: gameSettings.minStake ? gameSettings.minStake : 1

      // add here necessary property which need for bet data object
    };

    dispatch({
      type: "ADD_BET_IN_CART",
      payload: getNewBetsArray(state.betsObject, dataForOneNewBet)
    });
  };

  const getTrifectaOdd = (perfectaCoords, currentEventId, eventsForBeting) => {
    const currentEventObject = eventsForBeting.filter(event => {
      return event.raceNumber === currentEventId;
    })[0];
    const perfectaCoordsArray = perfectaCoords.split(",");
    const oddForPerfecta =
        currentEventObject.combinedOdds[perfectaCoordsArray[0]][
            perfectaCoordsArray[1]
            ];
    return oddForPerfecta * currentEventObject.multiplier;
  };

  const getRaceTypeForCurrentEvent = (eventsForBeting_, eventId) => {
    const stringWithRaceType = eventsForBeting_.filter(event => {
      return event.raceNumber === eventId;
    })[0].raceType;

    return stringWithRaceType
        ? stringWithRaceType.split("_")[stringWithRaceType.split("_").length - 1]
        : "6";
  };

  // open accordions
  function openFirstAccordion(raceNumber) {
    const openEventsCopy = { ...openEvents };
    openEventsCopy[raceNumber] = true;
    setOpenEvents(openEventsCopy);
  }

  // function for toggle accordions
  const onGetEventHistoryEventListener = event => {
    const raceNumber = event.currentTarget.getAttribute("data-index");
    const raceStatus = event.currentTarget.getAttribute("data-status");
    // open video container on mobile devices
    if (raceStatus === lifecycleStates.RACE && isMobile.any()) {
     
      dispatch(
          setVideoSize({
            tabContainer: { height: 'auto' },
            videoContainer: { height: "100%" },
            startVideoOnMobile: true
          })
      );
    } else {
      const openEventsCopy = { ...openEvents };
      openEventsCopy[raceNumber] = !openEventsCopy[raceNumber];
      setOpenEvents(openEventsCopy);
    }
  };

  return (
    <section
      // className={cn("bets-types", {
      //   exit: videoSizeStatus && tabContainerHeight === 0
      // })}
        className="bets-types"
    >
      <PreLoader
        isLoading={
          eventsForBeting.length === 0 &&
          lifecycleData &&
          lifecycleData.state === lifecycleStates.BET
        }
        isLightPreLoader={true}
      />
      <div
        className={cn(
          "titles-fullscreen",
          {
            exit:
              state.secondScreenInstance &&
              !state.secondScreenInstance.supportSecondScreen &&
              videoSizeStatus &&
              tabContainerHeight > 0
          },
          { [`close-second-screen`]: secondScreenIsOpen },
          "hide-for-mobile"
        )}
        onClick={changeContainerHeightEventListener}
      />

      <div className="tabs odds-tables">
        <nav
          className={cn("tabs-titles", {
            disable: eventsForBeting.length === 0
          })}
        >
          {tabsTitles.map((item, i) => {
            return (
              <div
                key={i}
                data-tab-id={item}
                onClick={changeBetTypeEventListener}
                className={cn(
                  "tabs-titles-item",
                  {
                    active: activeBetType === item
                  },
                  {
                    disable: eventsForBeting.length === 0
                  }
                )}
              >
                {/*{item}*/}
                {t(`${item}`)}
              </div>
            );
          })}
        </nav>
        <div className="tabs-content">
          {eventsForBeting.length > 0 ? (
            <CurrentBetTable
              eventsForBeting={eventsForBeting}
              betType={activeBetType}
              raceType={raceType}
              eventResult={eventResult}
              lifecyclesConfig={lifecyclesConfig}
              videoComplete={videoComplete}
              videoSizeStatus={videoSizeStatus}
              gameSettings={gameSettings}
              openEvents={openEvents}
              // autoHeightMaxScrollBar={autoHeightMaxScrollBar}
              addBetsInBetSlip={addBetsInBetSlip}
              onGetEventHistoryEventListener={onGetEventHistoryEventListener}
              betsObject={betsObject}
              lifecycleData={lifecycleData}
              openFirstAccordion={openFirstAccordion}
            />
          ) : (
            <div style={{ height: "400px" }}></div>
          )}
        </div>
      </div>

      <ActionButton
        onclick={onShowEventHistoryEventListener}
        classesName={cn("show-result-history-button hide-for-desktop", {
          inactive: false
          // [`preloader-layer`]: state.lfcommon.eventsOdds.length === 0
        })}
        buttonBlinkDisable={true}
      >
        <span>{t("bets.resultHistory")}</span>
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 32 32"
          className="history-button-svg"
        >
          <path
            fill="currentColor"
            d="M29.602 8.002l-13.6 11.562-13.6-11.562-2.4 2.752 16 13.764 16-13.764z"
          />
        </svg>
        <div className="history-button-divider" />
      </ActionButton>
      <ActionButton
        onclick={onShowBetslipEventListener}
        classesName={cn("show-betslip-button hide-for-desktop", {
          inactive: false
          // [`preloader-layer`]: state.lfcommon.eventsOdds.length === 0
        })}
      >
        {t("bets.coupons")}{" "}
        <span>{`${betsCount}`}</span>
      </ActionButton>
    </section>
  );
};

export default Bets;
