import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const CreditsContent = ({ item, currency, transactionType, creditAmount, proceedCreditAmount, time, portalKey }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="bet-content-divider" />
      <div className="bet-content">
        <div className="flex-row bet-content-row">
          <div className="flex-col-11 font-light mb-5">
            {transactionType}
          </div>
          <div className="flex-col-10 align-right font-light">
            {proceedCreditAmount} {currency}
          </div>
        </div>

        <div className="flex-row bet-content-row">
          <div className="flex-col-11 font-light mb-5">{t('creditHistory.credits')}</div>
          <div className="flex-col-10 align-right font-light">
            {creditAmount} {currency}
          </div>
        </div>

        <div className="flex-row bet-content-row">
          <div className="flex-col-11 font-light mb-5">{t('creditHistory.cashierNumber')}</div>
          <div className="flex-col-10 align-right font-light">
            {portalKey}
          </div>
        </div>

        <div className="flex-row bet-content-row">
          <div className="flex-col-11 font-light mb-5">{t('creditHistory.time')}</div>
          <div className="flex-col-10 align-right font-light">
            {time}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreditsContent;
