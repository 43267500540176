import React, { useEffect, useState } from "react";
import { useGlobalDataContext } from "../../../GlobalDataContext";
import { isMobile } from "../../utils/helpers";

const JWPlayer = ({ videLink }) => {
  const { state, dispatch } = useGlobalDataContext();
  const [playerWidth, setPlayerWidth] = useState(null);
  // eslint-disable-next-line
  const playerId = "mohiogaming";
  // useEffect(() => {
  //   if (isMobile.iOS()) {
  //     load();
  //     // obtain reference to the video element
  //     const player = document.getElementById("ios-player-container");
  //
  //     console.log('this is IOS mobile player')
  //     // if the reference to video player has been obtained
  //     if (player) {
  //       // set the video attributes using javascript as per the
  //       // webkit Policy
  //       player.controls = false;
  //       player.playsInline = true;
  //       player.muted = true;
  //       player.setAttribute("muted", ""); // leave no stones unturned :)
  //       player.autoPlay = true;
  //
  //       player.addEventListener("suspend", () => {
  //         // suspend invoked
  //         // show play button
  //       });
  //
  //       player.addEventListener("play", () => {
  //         // video is played
  //         // remove play button UI
  //       });
  //       player.addEventListener('canplaythrough', function(e) {
  //         this.currentTime = (Number(new Date().getTime()) - state.timeStampVideo) / 1000;
  //       });
  //       // player.on("pause", e => {
  //       //   if (e.pauseReason !== 'interaction') {
  //       //     player.play();
  //       //   }
  //       // });
  //       player.addEventListener("ended", function(e) {
  //         dispatch({
  //           type: "SET_VIDEO_COMPLETE",
  //           payload: {
  //             raceType: state.raceType,
  //             videoComplete: true
  //           }
  //         });
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [state.videoName, state.currentLifecycleState, state.video.videoSizeStatus.startVideoOnMobile]);


  useEffect(() => {
    // if (isMobile.iOS()) return;
    if ((state.currentLifecycleState === 'RACE' && state.video.videoSizeStatus.startVideoOnMobile) || (state.currentLifecycleState === 'RACE' && !isMobile.any())) {
      console.log('load video for DESKTOP container')
      if (!isMobile.any()) setPlayerWidth(document.querySelector(".game-video-content").offsetHeight * 1.78);
      remove();
      load();

    } else {
      console.log('remove video container')
      remove();
    }
    // eslint-disable-next-line
  }, [state.currentLifecycleState, state.raceType, state.video.videoSizeStatus.startVideoOnMobile]);


  useEffect(() => {

    if (window.jwplayer) {
      window.jwplayer(playerId).setMute();
    }
    // eslint-disable-next-line
  }, [state.video.videoMute && !isMobile.iOS()]);

  // video complete event listener
  // const onVideoCompleteEventListener = event => {
  //   if (window.jwplayer) {
  //     dispatch({
  //       type: "SET_VIDEO_COMPLETE",
  //       payload: {
  //         raceType: state.raceType,
  //         videoComplete: true
  //       }
  //     });
  //     remove();
  //   }
  //
  // };

  const remove = () => {
    if (window.jwplayer) {
      window.jwplayer(playerId).remove();
    }
  };

  const load = () => {
    dispatch({
      type: "SET_VIDEO_COMPLETE",
      payload: {
        raceType: state.raceType,
        videoComplete: false
      }
    });

    if (window.jwplayer) {
      window.jwplayer.key = "SbLw/rTJkBYP133hol2o5FCBHkE8QOge8TIZmotvFmc=";
      // console.log("videLink.replace({VIDEO_NAME}, state.videoName)", videLink.replace("{VIDEO_NAME}", state.videoName))

      let config = {
        width: "100%",
        aspectratio: "16:9",
        autostart: "viewable",
        autoPause: {
          "viewability": false,
        },
        events: {
          onTime: function(e){
            if(!e.viewable){
              this.play();
            }
          }
        },
        start: 0,
        end: 240,
        seekRange: { start: 0, end: 240 },
        cast: {
          appid: "00000000"
        },
        controls: false,
        displaydescription: false,
        displaytitle: false,
        key: "SbLw/rTJkBYP133hol2o5FCBHkE8QOge8TIZmotvFmc=",
        mute: true,
        ph: 3,
        pid: "kGWxh33Q",
        playbackRateControls: false,
        // playlist: [
        //   {
        sources: [
          {
            file: videLink ? videLink.replace("{VIDEO_NAME}", state.videoName) : null
          }
        ],
        // }
        // ]
        preload: "auto",
        repeat: false,
        stagevideo: false,
        // autostart: "viewable",
        autoplay: true,
        // flashplayer: "./../../../app/jwplayer/jwplayer.flash.swf",
        // preload: "metadata",
        // stretching: "fill"
        // stretching: "uniform"

      };

      if (window.jwplayer) {
        window
            .jwplayer(playerId)
            .setup(Object.assign(config, config))
            .seek((Number(new Date().getTime()) - state.timeStampVideo) / 1000)
            .on("error", e => {
              console.log("Failed to load video", e);
            })
            .on("setupError", e => {
              console.log("Failed to load video player", e);
            });

        // window.jwplayer(playerId).onComplete(onVideoCompleteEventListener);
      }
    }
  };
  return (
      <div
          className="player-container background-video-fadein"
          style={{
            width: "100%",
            height: "auto !important",
            maxHeight: "auto !important",
            margin: "0 auto",
            maxWidth: playerWidth,
          }}
      >

        { <div id={playerId} style={{opacity: state.currentLifecycleState === 'RACE' ? 1 : 0}}></div>}
      </div>
  );
};

export default JWPlayer;
