import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import cx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalMainContent,
  ModalCloseBtn
} from "./../Modal/Modal";

import Calculator from "../Calculator/Calculator";
import ActionButton from "../ActionButton";
import ReactTooltip from "react-tooltip";
import { isMobile } from "../../utils/helpers";
import { inputsNames, VOUCHER_CODE_LENGTH } from "../../utils/constants";
import { toastreact } from "../ToastMessage";
import { toastMessageTypes } from "../ToastMessage/constants";
import { useGlobalDataContext } from "../../../GlobalDataContext";

const AddCreditsModal = ({
  showModal,
  openModalEventListener,
  activateVoucher,
  isWebGameVersion,
  applyVoucherError,
  setApplyVoucherError
}) => {
  const { state, dispatch } = useGlobalDataContext();
  const { t } = useTranslation();

  const [showVirtualKeyboard, toggleVirtualKeyboard] = useState(false);
  const [startValidate, setStartValidate] = useState(false);
  // voucher value in modal input
  const [voucherId, setVoucherId] = useState("");

  const errorMessages = {
    [inputsNames.VOUCHERCODE]: t("modalWindows.addCreditsModal.typeCorrectCode").replace('VOUCHER_CODE_LENGTH', VOUCHER_CODE_LENGTH)
  };

  // tooltip rebuild
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [voucherId]);

  // show virtual keyboard
  const onShowVirtualKeyboardEventListener = e => {
    setTimeout(() => {
      setStartValidate(true);
      dispatch({
        type: "SET_VIRTUAL_KEYBOARD_USE",
        payload: !state.useVirtualKeyboard
      });
    }, 1000);
    toggleVirtualKeyboard(true);
  };

  // make activate voucher request
  const activateVoucherEventListener = async event => {
    setStartValidate(false);
    event.preventDefault();
    if (event.currentTarget.classList.contains("disable")) return false;
    setVoucherId("");
    activateVoucher(voucherId);
  };

  const onChangeEventListener = e => {
    const voucherCode = e.currentTarget ? e.currentTarget.value : e;

    // show validation error msg for mobile use toastreact component
    if (isMobile.any() && voucherCode.length !== VOUCHER_CODE_LENGTH) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: errorMessages[inputsNames.VOUCHERCODE]
      });
    }
    setVoucherId(voucherCode);
  };

  const onCloseModal = e => {
    openModalEventListener(e);
    setStartValidate(false);
    setVoucherId("");
    toggleVirtualKeyboard(false);
    setApplyVoucherError(false);
  };

  return (
    <Modal
      showModal={showModal}
      id={"voucher"}
      customClass={`activate-voucher`}
      closeModalEventListener={onCloseModal}
    >
      <ModalCloseBtn closeModalEventListener={onCloseModal} />

      <ModalHeader>
        <h3>{t("modalWindows.addCreditsModal.title")}:</h3>
      </ModalHeader>

      <ModalMainContent>
        {showVirtualKeyboard ? (
          <div className={"keyboard"}>
            <Calculator
              onAddAmountEventListener={onChangeEventListener}
              onResult={() => {
                toggleVirtualKeyboard(false);
              }}
              selectedValue={voucherId}
              isKeyboard={true}
            />
          </div>
        ) : (
          <div className="input-wrap centered-block align-center">
            <div className="flex-row flex-align-baseline align-left form-group">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  value={voucherId}
                  onChange={onChangeEventListener}
                  onFocus={() => {
                    setApplyVoucherError(false);
                  }}
                  onClick={e => {
                    if (isMobile.any()) onShowVirtualKeyboardEventListener(e);
                  }}
                  type="text"
                  placeholder={t("modalWindows.addCreditsModal.voucherCodePlacehold").replace('VOUCHER_CODE_LENGTH', VOUCHER_CODE_LENGTH)}
                  name="voucher_code"
                  id="voucher_code"
                  readOnly={isMobile.any()}
                  className={cx("with-placeholder", {
                    error:
                      (voucherId.length !== VOUCHER_CODE_LENGTH &&
                        startValidate) ||
                      applyVoucherError,
                    success:
                      voucherId.length === VOUCHER_CODE_LENGTH && startValidate,
                    [`v-keyboard`]: !isWebGameVersion
                  })}
                />
                <label htmlFor="voucher_code">
                  {t("modalWindows.addCreditsModal.voucherCode")}
                </label>
                <span className={"valid-icon"}></span>
                <span
                  className={cx(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        (!isMobile.any() &&
                          voucherId.length !== VOUCHER_CODE_LENGTH &&
                          startValidate) ||
                        (!isMobile.any() && applyVoucherError)
                    }
                  )}
                >
                  {setApplyVoucherError &&
                    t("modalWindows.addCreditsModal.voucherAlreadyActive")}
                  {voucherId.length !== VOUCHER_CODE_LENGTH &&
                    errorMessages[inputsNames.VOUCHERCODE]}
                </span>
                {!isWebGameVersion && (
                  <span
                    className={"modal-keyboard-btn"}
                    onClick={onShowVirtualKeyboardEventListener}
                  ></span>
                )}
              </div>
            </div>

            <ActionButton
              tooltip={
                !voucherId
                  ? t("modalWindows.addCreditsModal.insertVoucherCode")
                  : ""
              }
              onclick={activateVoucherEventListener}
              classesName={cx("btn btn-warning", {
                disable:
                  voucherId.length !== VOUCHER_CODE_LENGTH || applyVoucherError
              })}
              isWarning={true}
            >
              {t("modalWindows.addCreditsModal.activateVoucher")}
            </ActionButton>
          </div>
        )}
      </ModalMainContent>
    </Modal>
  );
};

export default AddCreditsModal;
