import {
  betsTypeForBetRequest,
  betTypesForBetsContainer,
  identifiers,
  lifecycleStates
} from "./../../utils/constants";

export const getWinnerBets = nestedArray => {
  // get all values by diagonal line for winner column
  let winnerOdds = [];
  nestedArray.map((item, i) => {
    winnerOdds = [...winnerOdds, nestedArray[i][i]];
    return item;
  });
  return winnerOdds;
};

export const getNewBetsArray = (oldBetsArray, dataForOneNewBet) => {
  const oldBetsArrayCopy = { ...oldBetsArray };
  const eventId = dataForOneNewBet.eventId;
  const oneBetData = { ...makeBetDataByBetType[dataForOneNewBet.tip] }; // get the suitable data mockup for current bet type
  oneBetData.odds = Number(dataForOneNewBet.odds); // set the odd of bet
  oneBetData.win =
    Number(dataForOneNewBet.odds) * Number(dataForOneNewBet.stake); // win per bet with 1 stake amount
  oneBetData.raceType = dataForOneNewBet.raceTypeForRequest;
  oneBetData.stake = dataForOneNewBet.stake;

  // set data by type of bet
  if (dataForOneNewBet.tip === betsTypeForBetRequest.winBet) {
    const winTipWithWinNumber = dataForOneNewBet.tip.replace(
      "%win%",
      dataForOneNewBet.winNumber
    );

    oneBetData.tip = winTipWithWinNumber;
    oneBetData.first = Number(dataForOneNewBet.winNumber);
    oneBetData.startNumber = Number(dataForOneNewBet.winNumber);
  } else if (dataForOneNewBet.tip === betsTypeForBetRequest.inFirst3) {
    const inFirst3TipWithWinNumber = dataForOneNewBet.tip.replace(
      "%win%",
      dataForOneNewBet.winNumber
    );
    oneBetData.tip = inFirst3TipWithWinNumber;
    oneBetData.inFirstThree = Number(dataForOneNewBet.winNumber);
  } else if (dataForOneNewBet.tip === betsTypeForBetRequest.quinella) {
    const winNumberArr = dataForOneNewBet.winNumber.split("||");
    // set tip for quinella bet
    const quinellaTipWithWinNumber = dataForOneNewBet.tip.replace(
      "%win%",
      winNumberArr[0] + "," + winNumberArr[1]
    );
    oneBetData.tip = quinellaTipWithWinNumber;
    oneBetData.firstGuess = Number(winNumberArr[0]);
    oneBetData.secondGuess = Number(winNumberArr[1]);
  } else if (dataForOneNewBet.tip === betsTypeForBetRequest.perfecta) {
    const winNumberArr = dataForOneNewBet.winNumber.split("||");
    // set tip for perfecta bet
    const perfectaTipWithWinNumber = dataForOneNewBet.tip.replace(
      "%win%",
      winNumberArr[0] + "," + winNumberArr[1]
    );
    oneBetData.tip = perfectaTipWithWinNumber;
    oneBetData.first = Number(winNumberArr[0]);
    oneBetData.second = Number(winNumberArr[1]);
  } else if (dataForOneNewBet.tip === betsTypeForBetRequest.over) {
    const overTipWithWinNumber = dataForOneNewBet.tip.replace(
      "%win%",
      dataForOneNewBet.raceType === "8" ? "5,6,7,8" : "4,5,6"
    );
    oneBetData.tip = overTipWithWinNumber;
  } else if (dataForOneNewBet.tip === betsTypeForBetRequest.under) {
    const underTipWithWinNumber = dataForOneNewBet.tip.replace(
      "%win%",
      dataForOneNewBet.raceType === "8" ? "1,2,3,4" : "1,2,3"
    );
    oneBetData.tip = underTipWithWinNumber;
  } else if (dataForOneNewBet.tip === betsTypeForBetRequest.even) {
    const evenTipWithWinNumber = dataForOneNewBet.tip.replace(
      "%win%",
      dataForOneNewBet.raceType === "8" ? "2,4,6,8" : "2,4,6"
    );
    oneBetData.tip = evenTipWithWinNumber;
  } else if (dataForOneNewBet.tip === betsTypeForBetRequest.odd) {
    const oddTipWithWinNumber = dataForOneNewBet.tip.replace(
      "%win%",
      dataForOneNewBet.raceType === "8" ? "1,3,5,7" : "1,3,5"
    );
    oneBetData.tip = oddTipWithWinNumber;
  } else if (dataForOneNewBet.tip === betsTypeForBetRequest.trifecta) {
    // set tip for perfecta bet
    const winNumberArr = dataForOneNewBet.winNumber.split(",");
    oneBetData.tip = dataForOneNewBet.tip.replace(
      "%win%",
      dataForOneNewBet.winNumber
    );
    oneBetData.first = Number(winNumberArr[0]);
    oneBetData.second = Number(winNumberArr[1]);
    oneBetData.third = Number(winNumberArr[2]);
  }

  // if bets for current event already exists then add new bet's data in event bets object...
  if (oldBetsArrayCopy[eventId]) {
    const oldBetsByOneEvent = oldBetsArrayCopy[eventId];
    const oldBetsByOneEventCopy = { ...oldBetsByOneEvent };
    let newBetsByOneEvent = {};

    // remove bet from bets data if clicked on already selected cell
    // it is related for all bets except TRIFECTA
    if (
      dataForOneNewBet.tip !== betsTypeForBetRequest.trifecta &&
      oldBetsByOneEvent[dataForOneNewBet.tip] &&
      String(oldBetsByOneEvent[dataForOneNewBet.tip].odds) ===
        String(dataForOneNewBet.odds)
    ) {
      delete oldBetsByOneEventCopy[`${dataForOneNewBet.tip}`];
      newBetsByOneEvent = oldBetsByOneEventCopy;
    } else {
      // add new bet data in event
      newBetsByOneEvent = {
        ...oldBetsByOneEvent,
        [dataForOneNewBet.tip]: oneBetData
      };

      // it is related for 2 WAY BETS
      // remove over/under bet from bets data if selected
      if (
        dataForOneNewBet.tip === betsTypeForBetRequest.over &&
        oldBetsByOneEvent[betsTypeForBetRequest.under]
      )
        delete newBetsByOneEvent[betsTypeForBetRequest.under];
      if (
        dataForOneNewBet.tip === betsTypeForBetRequest.under &&
        oldBetsByOneEvent[betsTypeForBetRequest.over]
      )
        delete newBetsByOneEvent[betsTypeForBetRequest.over];

      // remove even/odd bet from bets data if selected
      if (
        dataForOneNewBet.tip === betsTypeForBetRequest.even &&
        oldBetsByOneEvent[betsTypeForBetRequest.odd]
      )
        delete newBetsByOneEvent[betsTypeForBetRequest.odd];
      if (
        dataForOneNewBet.tip === betsTypeForBetRequest.odd &&
        oldBetsByOneEvent[betsTypeForBetRequest.even]
      )
        delete newBetsByOneEvent[betsTypeForBetRequest.even];
    }

    // add new event bets in object
    return { ...oldBetsArrayCopy, [eventId]: newBetsByOneEvent };
  } else {
    // if not then add new event's bets object
    return {
      ...oldBetsArrayCopy,
      [eventId]: { [dataForOneNewBet.tip]: oneBetData }
    };
  }
};

const makeBetDataByBetType = {
  [betsTypeForBetRequest.winBet]: {
    tip: "WinBet (%win%)",
    odds: 0,
    win: 0,
    betSelection: "",
    stake: 1,
    first: 0,
    second: null,
    third: null,
    raceType: 1,
    [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.ExactBetRequest",
    exactBetType: 0,
    startNumber: 0
  },
  [betsTypeForBetRequest.inFirst3]: {
    tip: "In First 3 (%win%)",
    odds: 0,
    win: 0,
    betSelection: "",
    stake: 1,
    [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.PlaceBetRequest",
    inFirstThree: 0,
    raceType: 1
  },
  [betsTypeForBetRequest.quinella]: {
    tip: "Quinella (%win%)",
    odds: 0,
    win: 0,
    betSelection: "",
    stake: 1,
    firstGuess: 0,
    secondGuess: 0,
    [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.QuinellaBetRequest",
    raceType: 1
  },
  [betsTypeForBetRequest.perfecta]: {
    tip: "Perfecta (%win%)",
    odds: 0,
    win: 0,
    betSelection: "",
    stake: 1,
    first: 0,
    second: 0,
    third: null,
    raceType: 0,
    [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.ForecastBetRequest",
    forecastBetType: 0
  },
  [betsTypeForBetRequest.trifecta]: {
    tip: "Trifecta (%win%)",
    odds: 0,
    win: 0,
    betSelection: "",
    stake: 1,
    first: 0,
    second: 0,
    third: 0,
    raceType: 1,
    [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.ForecastBetRequest",
    forecastBetType: 1
  },
  [betsTypeForBetRequest.over]: {
    tip: "Over (%win%)",
    odds: 0,
    win: 0,
    betSelection: "",
    stake: 1,
    sideBetType: 2,
    raceType: 1,
    [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.SideBetRequest"
  },
  [betsTypeForBetRequest.under]: {
    tip: "Under (%win%)",
    odds: 0,
    win: 0,
    betSelection: "",
    stake: 1,
    sideBetType: 3,
    raceType: 1,
    [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.SideBetRequest"
  },
  [betsTypeForBetRequest.even]: {
    tip: "Even (%win%)",
    odds: 0,
    win: 0,
    betSelection: "",
    stake: 1,
    sideBetType: 1,
    raceType: 1,
    [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.SideBetRequest"
  },
  [betsTypeForBetRequest.odd]: {
    tip: "Odd (%win%)",
    odds: 0,
    win: 0,
    betSelection: "",
    stake: 1,
    sideBetType: 0,
    raceType: 1,
    [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.SideBetRequest"
  }
};

// @class property value for each tip
// const classesByTip = [
//   {
//     tip: "Odd (%win%)",
//     [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.SideBetRequest"
//   },
//   {
//     tip: "Even (%win%)",
//     [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.SideBetRequest"
//   },
//   {
//     tip: "Under (%win%)",
//     [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.SideBetRequest"
//   },
//   {
//     tip: "Over (%win%)",
//     [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.SideBetRequest"
//   },
//   {
//     tip: "Trifecta (%win%)",
//     [`@class`]:
//       "com.mohiogaming.cashier.rapi.racingbet.request.ForecastBetRequest"
//   },
//   {
//     tip: "Perfecta (%win%)",
//     [`@class`]:
//       "com.mohiogaming.cashier.rapi.racingbet.request.ForecastBetRequest"
//   },
//   {
//     tip: "Quinella (%win%)",
//     [`@class`]:
//       "com.mohiogaming.cashier.rapi.racingbet.request.QuinellaBetRequest"
//   },
//   {
//     tip: "In First 3 (%win%)",
//     [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.PlaceBetRequest"
//   },
//   {
//     tip: "WinBet (%win%)",
//     [`@class`]: "com.mohiogaming.cashier.rapi.racingbet.request.ExactBetRequest"
//   }
// ];

export const getRaceTypeForPlaceBet = currentRaceType => {
  return currentRaceType === identifiers.DOG_6
    ? 0
    : currentRaceType === identifiers.DOG_8
    ? 1
    : currentRaceType === identifiers.HORSE_6
    ? 2
    : currentRaceType === identifiers.HORSE_8
    ? 3
    : currentRaceType === identifiers.DOG_6_WEB
    ? 4
    : currentRaceType === identifiers.DOG_8_WEB
    ? 5
    : currentRaceType === identifiers.HORSE_6_WEB
    ? 6
    : currentRaceType === identifiers.HORSE_8_WEB
    ? 7
    : -1;
};

export const getOverUnderValueForFirstWinNumber = (
  currentRaceType,
  firstNumber
) => {
  const overTipWithWinNumbers =
    currentRaceType.indexOf("8") !== -1 ? "5,6,7,8" : "4,5,6";
  const underTipWithWinNumbers =
    currentRaceType.indexOf("8") !== -1 ? "1,2,3,4" : "1,2,3";
  if (overTipWithWinNumbers.indexOf(firstNumber) !== -1) return "over";
  if (underTipWithWinNumbers.indexOf(firstNumber) !== -1) return "under";
};

export const getDataForBetsTable = (
  events,
  betType,
  rowsForTriffecta,
  rowTitles
) => {
  let dataForBetsTables = {};
  // eslint-disable-next-line
  events.map(singleEvent => {
    if (singleEvent.state !== lifecycleStates.BET) return dataForBetsTables;
    dataForBetsTables[singleEvent.raceNumber] = {};
    if (betType === betTypesForBetsContainer.MAIN) {
      let mainBetsData = {};
      mainBetsData["inFirst3"] = singleEvent["placeOdds"];

      mainBetsData["winner"] = getWinnerBets(singleEvent["combinedOdds"]); // return odds for WINNER columns
      // create array of arrays for table MAIN odds render
      let matrixMainBets = [];
      mainBetsData["winner"].map((item, i) => {
        matrixMainBets[i] = [
          mainBetsData["winner"][i],
          mainBetsData["inFirst3"][i]
        ];
        return item;
      });
      dataForBetsTables[singleEvent.raceNumber][betType] = matrixMainBets;
    }
    //... here add another bets odds by others betType
    if (betType === betTypesForBetsContainer.EXACTA) {
      let exactaBetsData = {};
      exactaBetsData["exacta"] = singleEvent["combinedOdds"];
      dataForBetsTables[singleEvent.raceNumber][betType] = exactaBetsData;
    }
    if (betType === betTypesForBetsContainer.TRIFECTA) {
      // let triffectaBetsData = {};
      let matrixTriffectaBets = [
        ...rowsForTriffecta.map((item, index) => {
          return Array(rowTitles.length).fill(
            index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"
          );
        })
      ];
      dataForBetsTables[singleEvent.raceNumber][betType] = matrixTriffectaBets;
    }
    if (betType === betTypesForBetsContainer.QUINELLA) {
      let quinellaBetsData = {};
      quinellaBetsData["quinella"] = singleEvent["quinellaOdds"];
      dataForBetsTables[singleEvent.raceNumber][betType] = quinellaBetsData;
    }
    if (betType === betTypesForBetsContainer["2WAYBETS"]) {
      let twowayBetsData = {};
      twowayBetsData["odd"] = singleEvent["oddOdds"];
      twowayBetsData["even"] = singleEvent["evenOdds"];
      twowayBetsData["over"] = singleEvent["highOdds"];
      twowayBetsData["under"] = singleEvent["lowOdds"];
      dataForBetsTables[singleEvent.raceNumber][betType] = twowayBetsData;
    }
  });

  return dataForBetsTables;
};

export const getNewEventOdds = (eventsOdds, state) => {
  let newEventOdds = {};
  for (const [key, value] of Object.entries(eventsOdds[0])) {
    newEventOdds[key] = key === "state" ? state : value;
  }
  return [newEventOdds];
};

// get current bets for betslip of the game on which switching on
export const getActiveBetsObject = ({ betsObject, eventsOdds }) => {
  const betsObjectCopy = { ...betsObject };
  const betsObjectRaceIds = Object.keys(betsObjectCopy);
  const eventsOddsRaceIds = eventsOdds.map(bet => {
    return bet.raceNumber;
  });

  // https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
  const uniqueItemFromArrays = betsObjectRaceIds.filter(item => {
    return !eventsOddsRaceIds.includes(item);
  });
  for (const uniqueItem of uniqueItemFromArrays) {
    delete betsObjectCopy[uniqueItem]; // delete bets for event which are unavailable in events list
  }
  const eventsOddsRaceIdsAndLfStates = eventsOdds.map(bet => {
    return { raceNumber: bet.raceNumber, lfState: bet.state };
  });
  const sameItemsFromArrays = betsObjectRaceIds.filter(item => {
    return eventsOddsRaceIds.includes(item);
  });

  const expiredEvents = eventsOddsRaceIdsAndLfStates.filter(item => {
    return (
      item.lfState !== lifecycleStates.BET &&
      sameItemsFromArrays.includes(item.raceNumber)
    );
  });
  for (const sameItem of expiredEvents) {
    delete betsObjectCopy[sameItem.raceNumber]; // delete bets if them events have state different from BET
  }
  return {
    betsObject: betsObjectCopy,
    expiredRaceIds: [
      ...expiredEvents.map(item => {
        return item.raceNumber;
      }),
      ...uniqueItemFromArrays
    ]
  };
};

// sort future events odds by raceType
export const getSortedFutureEvents = futureEvents => {
  let sortedFutureEvents = {};
  for (const key of Object.keys(identifiers)) {
    sortedFutureEvents[key] = [];
  }
  for (const oneEvent of futureEvents) {
    oneEvent.state = lifecycleStates.BET;
    sortedFutureEvents[oneEvent.raceType].push(oneEvent);
  }
  return sortedFutureEvents;
};

// get actual events list with current event and future
export const getActualFutureEvents = ({ payload, futureEvents }) => {
  let indexCurrentEvent = -1;
  // eslint-disable-next-line
  futureEvents.filter((futureEvent, index) => {
    // if (futureEvent.raceNumber === payload.raceNumber)
    if (Number(futureEvent.raceNumber) === Number(payload.raceNumber))
      indexCurrentEvent = index;
  });
  // drop all past(finished) events
  const dropOldEvents = (futureEvents || []).filter((futureEvent, index) => {
    return (
      index > indexCurrentEvent &&
      Number(futureEvent.raceNumber) !== Number(payload.raceNumber)
    );
  });
  return dropOldEvents;
};

// columns or rows for all odds tables
export const getRowTitles = raceType => {
  return raceType.indexOf("8") !== -1
    ? [1, 2, 3, 4, 5, 6, 7, 8]
    : [1, 2, 3, 4, 5, 6];
};
