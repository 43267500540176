// https://github.com/fkhadra/react-toastify
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Flip } from "react-toastify";
import { autoCloseDelayForToastMsg, toastMessageTypes } from "./constants";

export const ToastReact = () => {
  return <ToastContainer transition={Flip} />;
};

export const toastreact = ({ type, title, customDelayForToastMsg }) => {
  const toastConfigure = {
    position: "bottom-left",
    autoClose:
      customDelayForToastMsg !== null
        ? customDelayForToastMsg
        : autoCloseDelayForToastMsg,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true
    // draggable: true
  };

  let toastInstance=null;

  if (type === toastMessageTypes.INFO) {
    toastInstance=toast.info(title, toastConfigure);
  } else if (type === toastMessageTypes.SUCCESS) {
    toastInstance=toast.success(title, toastConfigure);
  } else if (type === toastMessageTypes.WARN) {
    toastInstance=toast.warn(title, toastConfigure);
  } else if (type === toastMessageTypes.ERROR) {
    toastInstance=toast.error(title, toastConfigure);
  } else {
    toastInstance=toast(title, toastConfigure);
  }

  return toastInstance;
};
