import React from "react";
import KeypadRow from "./KeypadRow";
import KeypadButton from "./KeypadButton";

const KeyboardUppercase = props => (
  <section className="keypad">
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>1</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>2</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>3</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>4</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>5</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>6</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>7</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>8</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>9</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>0</KeypadButton>
      <KeypadButton type="dark" onButtonPress={props.onButtonPress}>
        ←
      </KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>Q</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>W</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>E</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>R</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>T</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>Y</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>U</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>I</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>O</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>P</KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>A</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>S</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>D</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>F</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>G</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>H</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>J</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>K</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>L</KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton type="dark" onButtonPress={props.onButtonPress}>
        ↓
      </KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>Z</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>X</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>C</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>V</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>B</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>N</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>M</KeypadButton>
      <KeypadButton type="dark" onButtonPress={props.onButtonPress}>
        .?!&
      </KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton type="space" onButtonPress={props.onButtonPress}>
        space
      </KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton type="large" onButtonPress={props.onButtonPress}>
        OK
      </KeypadButton>
    </KeypadRow>
  </section>
);

export default KeyboardUppercase;
