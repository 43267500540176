import React from "react";
import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalHeader,
  ModalCloseBtn,
  ModalMainContent
} from "./../Modal/Modal";
import Calculator from "../Calculator/Calculator";

const DateFilterModal = ({
  showModal,
  openModalEventListener,
  onSetDateFilterListener,
  selectedValue
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      showModal={showModal}
      id={"dateFilterModal"}
      closeModalEventListener={openModalEventListener}
    >
      <ModalCloseBtn closeModalEventListener={openModalEventListener} />

      <ModalHeader>
        <h3 className="modal-header">{t("modalWindows.dateFilterModal.tapDate")}</h3>
      </ModalHeader>
      <ModalMainContent>
        <Calculator
          onAddAmountEventListener={onSetDateFilterListener}
          onResult={openModalEventListener}
          selectedValue={selectedValue}
        />
      </ModalMainContent>
    </Modal>
  );
};

export default DateFilterModal;
