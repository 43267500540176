class WebSocketApp {
  constructor(props) {
    const { socketUrl, onErrorCallback, onMessageRecievedCallback } = props;
    this.onMessageRecievedCallback = onMessageRecievedCallback;
    this.onErrorCallback = onErrorCallback;
    this.socketUrl = socketUrl;
    this.reconectInterval = 3000;
    this.webSocketData = null;
    this.state = { CLOSED: 3 };
    this.closingState = {CORRECT: 1000}
    // this.connectInterval = null;
    // this.reconectTimeout = null;
    // this.onCloseInterval = null;
    // this.manualyClosedConnection = false;

    this.init();

  }

  // init new websocket connection
  init() {
    if (this.webSocketConnecion) {
      this.webSocketConnecion.removeAllListeners();
      this.webSocketConnecion = null;
    }

    try {
      this.webSocketConnecion = new WebSocket(this.socketUrl);
      // add events listeners for new websocket connection
      this.addEventListeners();
    } catch (e) {
      console.log("ERROR", e);
    }


  }

  addEventListeners() {
    // open websocket connection event listener
    this.webSocketConnecion.onopen = () => {
      console.log(`connected websocket ${this.socketUrl}`);
    };
    // recieve message via web socket connection
    this.webSocketConnecion.onmessage = evt => {
      try {
        const responseIsValid = this.isJson(
          evt.data.substring(evt.data.indexOf("|") + 1)
        );

        if (responseIsValid && this.onMessageRecievedCallback) {
          this.webSocketData = JSON.parse(
            evt.data.substring(evt.data.indexOf("|") + 1)
          );
          // listen to data sent from the websocket server
          this.onMessageRecievedCallback(this);
        }
      } catch (e) {
        console.log(`${this.socketUrl} websocket error in onmessage event `);
      }
    };
    // websocket onclose event listener
    this.webSocketConnecion.onclose = e => {

      const self = this;
      this.webSocketConnecion = null;
      // if (this.manualyClosedConnection) {
      if (e.code === this.closingState.CORRECT) {
        console.log(
          `Application closed the ${this.socketUrl} connetcion manualy`
        );
      } else {
        console.log(
          `Socket connection in onclose event listener ${
            this.socketUrl
          } is closed. Reconnect will be attempted in ${this.reconectInterval /
            1000} seconds.`,
          e
        );
        this.webSocketConnecion = null;
        self.reconectTimeout = setTimeout(() => {
          self.check();
        }, self.reconectInterval); //call check function after timeout

      }
    };

    // websocket onerror event listener
    this.webSocketConnecion.onerror = err => {
      try {
        console.error("Socket encountered error: ", err, "Closing socket");
        if (this.onErrorCallback) {
          this.onErrorCallback();
        }
      } catch (e) {
        console.log("catch error in OnError");
      }
    };
  }

  //check if websocket instance is closed, if so call `init` function.
  check() {

    if (
      !this.webSocketConnecion
    ) {
      this.init();
    }
  }

  // check if socket message contains suitable data
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  // close web socket connection
  close() {
    this.webSocketConnecion.close();
  }

  // send data through websocket connection
  webSocketSendData(data) {
    this.webSocketConnecion.send(data);
  }
}

export default WebSocketApp;
