/**
 * countdown timer for event start time
 * */

import React, { useEffect, useState } from "react";

const Countdown = ({
  timeInMs,
  manageCountdown = true,
  countdownName = ""
}) => {
  // by default always start countdown by this component manageCountdown = true
  const ONE_MINUTE_MILISECONDS = 60000;
  const ONE_SECOND_MILISECONDS = 1000;

  // get minutes from miliseconds
  const getMinutesCountdown = miliseconds => {
    return Number(String(miliseconds / ONE_MINUTE_MILISECONDS).split(".")[0]);
  };
  // get seconds from miliseconds
  const getSecondsCountdown = miliseconds => {
    return Number(
      String(
        (miliseconds % ONE_MINUTE_MILISECONDS) / ONE_SECOND_MILISECONDS
      ).split(".")[0]
    );
  };

  // format minutes for countdown
  const formatMinutes = minutes => {
    return minutes < 10 ? `0${minutes}` : minutes;
  };

  // format seconds for countdown
  const formatSeconds = seconds => {
    // const secondsWithotMilisecondsDot = seconds || seconds.split('.')[0];
    return seconds < 10 ? `0${seconds}` : seconds;
  };
  const [countdownId, setCountdownId] = useState(null);
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    if (timeInMs < 0) return;
    if (!manageCountdown) return;
    let timeOut = null;
    let msForInterval = timeInMs;

    if (countdownId !== null) {
      clearInterval(countdownId);
    }


    timeOut = setInterval(() => {
      msForInterval = msForInterval - 1000;
      setCountdown(
        `${formatMinutes(getMinutesCountdown(msForInterval))}:${formatSeconds(
          getSecondsCountdown(msForInterval)
        )}`
      );
      if (msForInterval <= 0) {
        clearInterval(countdownId);
        clearInterval(timeOut);
      }
    }, 1000);

    setCountdownId(timeOut);

    return () => {
      clearInterval(countdownId);
      clearInterval(timeOut);
    };
    // eslint-disable-next-line
  }, [timeInMs]);

  return (
    <div
      id={`${countdownName}Id`}
      className={`countdown-container ${countdownName ? countdownName : ""}`}
      data-timeinms={timeInMs}

    >
      {countdown}
    </div>
  );
};

export default Countdown;
