import React from "react";
import { Route, Router } from "react-router-dom";
import GameScreen from "./GameScreen";
import Callback from "./app/containers/Callback";
import history from "./history";
import Login from "./app/components/Authorization/Login";
import TestVideo from "./app/containers/TestVideo/TestVideo";


const Routes = () => (
  <Router history={history} component={GameScreen}>
    <Route exact path="/" render={props => <GameScreen {...props} />} />
    <Route
      path="/calback_url"
      render={props => {
        return <Callback {...props} />;
      }}
    />
    <Route
        path="/login"
        render={props => {
          return <Login {...props} />;
        }}
    />
    <Route
        path="/testVideo"
        render={props => {
          return <TestVideo {...props} />;
        }}
    />
  </Router>
);

export default Routes;
