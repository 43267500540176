// https://googlechrome.github.io/samples/presentation-api/
class SecondScreen {
  constructor(props) {
    const { secondScreenUrl } = props;
    this.secondScreenUrl = secondScreenUrl;
    this.presentationRequest = null;
    this.presentationConnection = null;
    this.supportSecondScreen = false;
    this.connection = null;
    this.availability = null;

    this.init();
  }

  // init new websocket connection
  init() {
    const self = this;
    this.presentationRequest = new PresentationRequest([
      this.secondScreenUrl
    ]);

    // Make this presentation the default one when using the "Cast" browser menu.
    navigator.presentation.defaultRequest = this.presentationRequest;

    this.presentationRequest.addEventListener("connectionavailable", function(
      event
    ) {
      console.log('presentation Connection', event.connection)
      self.presentationConnection = event.connection;
      self.presentationConnection.addEventListener("close", function() {
        console.log("> Connection closed.");
      });
      self.presentationConnection.addEventListener("terminate", function() {
        console.log("> Connection terminated.");
      });
      self.presentationConnection.addEventListener("message", function(event) {
        console.log("> " + event.data);
      });
      console.log('<<<self>>>>', self)
    });
  }

  open() {
    const self = this;
    this.presentationRequest
      .start()
      .then(connection => {
        self.connection = connection;
        console.log(
          "> Connected to " + connection.url + ", id: " + connection.id
        );
      })
      .catch(error => {
        console.log("> " + error.name + ": " + error.message);
      });
  }

  close() {
    console.log("Closing connection...");
    if (this.presentationConnection) this.presentationConnection.close();
  }

  terminate() {
    console.log("Terminating connection...");
    this.presentationConnection.terminate();
  }

  reconnect(presentationId) {
    this.presentationRequest
      .reconnect(presentationId)
      .then(connection => {
        console.log("Reconnected to " + connection.id);
      })
      .catch(error => {
        console.log(
          "Presentation.reconnect() error, " + error.name + ": " + error.message
        );
      });
  }

  // send data through websocket connection
  sendMessage(messageData) {
    // console.log('Sending "' + message + '"...');
    try {
      this.presentationConnection.send(JSON.stringify(messageData));
    } catch (e) {
      console.log('Error caused because stream is not open any more!')
      this.init();
    }
  }

  async getAvailability () {
    /* Availability monitoring */
    const self = this;
    await this.presentationRequest
      .getAvailability()
      .then(availability => {
        // console.log("Available presentation displays: " + availability.value);
        availability.addEventListener("change", function() {
          console.log(
            "> Available presentation displays: " + availability.value
          );
          self.supportSecondScreen = availability.value;
          self.availability = availability;
        });
      })
      .catch(error => {
        console.log(
          "Presentation availability not supported, " +
            error.name +
            ": " +
            error.message
        );
      });
  }
}

export default SecondScreen;
