import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cn from "clsx";

import { betsTypeForBetRequest } from "./../../../utils/constants";

const MainBetOddsTable = ({
  oddsData,
  columnTitles,
  rowTitles,
  onAddBetEventListener,
  eventId,
  betsForEvent,
  raceType
}) => {
  // state for saving active cell of each column
  const [bettedWinner, setBettedWinner] = useState(
    betsForEvent[betsTypeForBetRequest.winBet]
      ? `${betsForEvent[betsTypeForBetRequest.winBet].first - 1}||${0}`
      : ""
  ); // WinBet (%win%)
  const [bettedInFirst3, setBettedInFirst3] = useState(
    betsForEvent[betsTypeForBetRequest.inFirst3]
      ? `${betsForEvent[betsTypeForBetRequest.inFirst3].inFirstThree - 1}||${1}`
      : ""
  ); //  In First 3 (%win%)

  useEffect(() => {
    if (Object.keys(betsForEvent).length === 0) {
      setBettedWinner("");
      setBettedInFirst3("");
    }

    setBettedWinner(
      betsForEvent[betsTypeForBetRequest.winBet]
        ? `${betsForEvent[betsTypeForBetRequest.winBet].first - 1}||${0}`
        : ""
    );
    setBettedInFirst3(
      betsForEvent[betsTypeForBetRequest.inFirst3]
        ? `${betsForEvent[betsTypeForBetRequest.inFirst3].inFirstThree -
            1}||${1}`
        : ""
    );
  }, [betsForEvent]);

  // set the active cell in odds table after game changed
  useEffect(() => {
    if (betsForEvent[betsTypeForBetRequest.winBet]) {
      setBettedWinner(
        `${betsForEvent[betsTypeForBetRequest.winBet].first - 1}||${0}`
      );
    }
    if (betsForEvent[betsTypeForBetRequest.inFirst3]) {
      setBettedInFirst3(
        `${betsForEvent[betsTypeForBetRequest.inFirst3].inFirstThree - 1}||${1}`
      );
    }
    // eslint-disable-next-line
  }, [raceType]);

  // make bet (create bet in betslip container in basket)
  // in each column available to choose only one cell
  const onClickEventListener = event => {
    const typeBet = event.currentTarget.getAttribute("data-type-bet");

    const coordsCell = event.currentTarget.getAttribute("data-coords-cell");
    if (typeBet === betsTypeForBetRequest.winBet) {
      setBettedWinner(coordsCell === bettedWinner ? "" : coordsCell);
    }

    if (typeBet === betsTypeForBetRequest.inFirst3) {
      setBettedInFirst3(coordsCell === bettedInFirst3 ? "" : coordsCell);
    }

    // prepare all needed data for send bets to the server
    onAddBetEventListener(event);
  };

  return (
    <div className="flex-row accordion-table-wrap">
      <table className="flex-col-21 bordered-table light-table table main">
        <thead>
          <tr>
            {/*empty cell*/}
            <th className={`no-table-cell titleRow-class`}></th>

            {/*name type of bets*/}
            {columnTitles.map((title, j) => {
              return (
                <th className="no-table-cell" key={j}>
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rowTitles.map((titleRow, k) => {
            return (
              <tr key={k}>
                {/* cell of column with number*/}
                <td className="no-table-cell">
                  <span className={`event-win-item-${titleRow}`}></span>
                </td>
                {oddsData[k].map((cellOdd, j) => {
                  const typeBet =
                    j % 2 !== 0
                      ? betsTypeForBetRequest.inFirst3
                      : betsTypeForBetRequest.winBet;
                  // row of cells with odds
                  return (
                    <td
                      className={cn({
                        active:
                          (j % 2 !== 0 &&
                            bettedInFirst3.includes(`${k}||${j}`)) ||
                          (j % 2 === 0 && bettedWinner.includes(`${k}||${j}`))
                      })}
                      data-type-bet={`${typeBet}`}
                      data-coords-cell={`${k}||${j}`}
                      data-odd-amount={cellOdd}
                      data-win-number={k + 1}
                      data-event-id={eventId}
                      onClick={onClickEventListener.bind(this)}
                      key={j}
                    >
                      {cellOdd}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

MainBetOddsTable.propTypes = {
  oddsData: PropTypes.array
};

export default MainBetOddsTable;
