import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";

import ActiveGameLogo from "./ActiveGameLogo";
import { useGlobalDataContext } from "./../../../../GlobalDataContext";
import { getFloor } from "../../../utils/helpers";

const MobileHeader = ({ onChangeGameEventListener }) => {
  const { state, dispatch } = useGlobalDataContext();
  const { t } = useTranslation();
  const [jackpotShadow, makeJackpotShadow] = useState(false);
  const [superJackpotShadow, makeSuperJackpotShadow] = useState(false);

  const showSlideAsideBlockEventListener = event => {
    dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload:
        event.currentTarget.getAttribute("data-aside-block") ===
        state.slideAsideBlockId
          ? null
          : event.currentTarget.getAttribute("data-aside-block")
    });
  };

  const hideSlideAsideBlockEventListener = event => {
    dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload: null
    });
  };
  const makeJackpotAnimate = () => {
    makeJackpotShadow(true);
    const jackpotShadowTimeout = setTimeout(() => {
      makeJackpotShadow(false);
    }, 2000);
    const superJackpotShadowTimeout = setTimeout(() => {
      makeSuperJackpotAnimate();
    }, 2000);
    return () => {
      clearTimeout(jackpotShadowTimeout);
      clearTimeout(superJackpotShadowTimeout);
    };
  };

  const makeSuperJackpotAnimate = () => {
    makeSuperJackpotShadow(true);
    const superJackpotShadowTimeout = setTimeout(() => {
      makeSuperJackpotShadow(false);
    }, 2000);
    const jackpotShadowTimeout = setTimeout(() => {
      makeJackpotAnimate();
    }, 12000);
    return () => {
      clearTimeout(jackpotShadowTimeout);
      clearTimeout(superJackpotShadowTimeout);
    };
  };

  return (
    <div className="hide-for-desktop mobile-header">
      <div className="header-aside align-left">
        <ul className="login-menu login-menu-right row-list fl-cntr-start flex-align-start">
          {state.slideAsideBlockId && (
            <li
              className={cn("login-menu-item back-btn align-center", {
                show: !!state.slideAsideBlockId
              })}
              onClick={hideSlideAsideBlockEventListener}
            >
              <span></span>
            </li>
          )}

          <li
            className="login-menu-item menu align-center"
            onClick={showSlideAsideBlockEventListener}
            data-aside-block="headerSlideMenu"
          >
            <span></span>
          </li>
          <li
            className={cn("login-menu-item username align-center", {
              hidden: state.slideAsideBlockId || state.partnerWalletActive
            })}
          >
            <span></span>
            <div className="name-block ">
              <p className={"name hello"}>{t("header.loginMenu.hello")},</p>{" "}
              <p className={"name"}>
                {state.userData && state.userData.userName}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="header-center align-center">
        <ActiveGameLogo
          raceType={state.raceType}
          onChangeGameEventListener={onChangeGameEventListener}
        />
      </div>
      <div className="header-aside align-right right-menu">
        <ul className="login-menu row-list fl-cntr-end flex-align-end">
          <li
            onClick={showSlideAsideBlockEventListener}
            data-aside-block="creditHistory"
            className="login-menu-item credits align-center"
          >
            {t("header.loginMenu.credits")}
            {state.userData && state.userData.createWalletDTO.enabled && (
              <span>
                {getFloor(state.userData.createWalletDTO.credits, 100)}
                {state.gameSettings.currency}
              </span>
            )}
            <div className={`credits-text-flash-line mobile`} />
          </li>
          {state.isWebGameVersion && (
            <>
              <li
                onClick={showSlideAsideBlockEventListener}
                data-aside-block="betHistory"
                className="login-menu-item basket-btn align-center"
              >
                <span></span>
              </li>
            </>
          )}
        </ul>
      </div>

      {state.jackPot !== 0 && state.superJackPot !== 0 && (
        <div className="jackpot-block align-center">
          {state.jackPot !== 0 && (
            <span
              className={
                !jackpotShadow
                  ? `jackpot`
                  : `jackpot jackpot-mobile-text-shadow`
              }
            >
              {t("header.jackpot.jackpot")}: {getFloor(state.jackPot, 100)}{" "}
            </span>
          )}
          {state.superJackPot !== 0 && (
            <span
              className={
                !superJackpotShadow
                  ? `super-jackpot`
                  : `super-jackpot super-jackpot-mobile-text-shadow`
              }
            >
              {t("header.jackpot.superjackpot")}:{" "}
              {getFloor(state.superJackPot, 100)}
            </span>
          )}
        </div>
      )}

      {state.gameSettings &&
        state.gameSettings.addressHeader !== "" &&
        // eslint-disable-next-line
        state.jackPot == 0 &&
        // eslint-disable-next-line
        state.superJackPot == 0 && (
          <div className="address-block align-center">
            <span
              className={
                !jackpotShadow
                  ? `label-address`
                  : `label-address jackpot-mobile-text-shadow`
              }
            >
              {t("header.withdrawVoucherProvide")}:{" "}
            </span>
            <span
              className={
                !superJackpotShadow
                  ? `address`
                  : `address super-jackpot-mobile-text-shadow`
              }
            >
              {state.gameSettings && state.gameSettings.addressHeader
                ? state.gameSettings.addressHeader.split("-//-").join(" ")
                : ""}
            </span>
          </div>
        )}
    </div>
  );
};

export default MobileHeader;
