/**
 * component which set in global store value for countdown timer in ms
 * */

import React, { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

const VideoCountdown = ({ lftimestamp, betTime }) => {
  const [progressPercentage, setProgressPercentage] = useState(100);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    let timeoutid = null;
    if (timeoutId !== null) clearInterval(timeoutId);

    let progresBarPer = 100;
    timeoutid = setInterval(() => {

      progresBarPer = ((lftimestamp + betTime - new Date().getTime()) * 100) / betTime;
      setProgressPercentage(progresBarPer);

      if (progresBarPer < 0) clearInterval(timeoutid);
    }, 250);

    setTimeoutId(timeoutid);

    return () => {
      clearInterval(timeoutId);
      clearInterval(timeoutid);

    };

    // eslint-disable-next-line
  }, [lftimestamp]);

  return (
    <>
      <CircularProgressbar
        value={progressPercentage}
        counterClockwise
        background
        backgroundPadding={6}
        styles={buildStyles({
          strokeLinecap: "butt",
          backgroundColor: "#3e98c7",
          textColor: "#fff",
          pathColor: "#fff",
          trailColor: "transparent"
        })}
      />
    </>
  );
};

export default VideoCountdown;
