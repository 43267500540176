import { getTokenData, saveTokenData } from "./TokenServiceStorage";
import {
  redirectToAuthServer,
  getUrlForTokenRefresh,
} from "./../../utils/helpers";
import { statuses } from "../../utils/constants";

//https://habr.com/ru/post/456188/
// get new access token using refresh_token
function refreshToken(refreshToken) {
  return fetch(getUrlForTokenRefresh(window.location.origin, refreshToken), {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((res) => {
      if (res.status === statuses.statusOk) {
        return res;
      }

      redirectToAuthServer(statuses.statusForRedirect);
    })
    .then((res) => {
      return res.json();
    });
}

// proxy function for all API request which need authentification
export async function fetchWithAuth(url, options) {
  let tokenData = null; // объявляем локальную переменную tokenData

  if (getTokenData()) {
    // если в localStorage присутствует tokenData, то берем её
    tokenData = JSON.parse(getTokenData());
  } else {
    redirectToAuthServer(statuses.statusForRedirect); // если токен отсутствует, то перенаправляем пользователя на страницу авторизации
  }

  if (!options.headers) {
    // если в запросе отсутствует headers, то задаем их
    options.headers = {};
  }
  if (tokenData) {
    // redirect on authorization page if access_token expired
    if (Date.now() >= tokenData["expires_in"]) {
      // проверяем не истек ли срок жизни токена
      try {
        const newTokenData = await refreshToken(tokenData["refresh_token"]); // если истек, то обновляем токен с помощью refresh_token
        saveTokenData(newTokenData);
        tokenData = newTokenData;
      } catch (error) {
        // если тут что-то пошло не так, то перенаправляем пользователя на страницу авторизации
        redirectToAuthServer(statuses.statusForRedirect); // go to the login page
      }
    }

    if (tokenData["access_token"] === undefined) {
      options.headers.Authorization = `bearer ${tokenData.acess_token}`;
    } else {
      options.headers.Authorization = `bearer ${tokenData["access_token"]}`;
    }
    // добавляем токен в headers запроса
  }

  return fetch(url, options)
    .then((res) => {
      if (res.status === statuses.statusOkNoContent) {
        return { status: res.status };
      }
      if (res.status === statuses.statusForRedirect)
        redirectToAuthServer(res.status);
      if (res.ok) {
        if (res.url.indexOf("users/check?") !== -1) {
          return { status: statuses.statusOk, msg: "Already exists" };
        }

        if (res.url.indexOf("users/password") !== -1) {
          return res;
        }

        if (res.url.indexOf("users/disable") !== -1) {
          return res;
        }

        return res.json();
      }

      if (!res.ok) {
        let message = "Something went wrong with request.";
        if (res.url.indexOf("creditsCodes/apply") !== -1)
          message =
            "This voucher has already been activated. Please, use a different voucher.";
        // return error message text if change password request was failed
        if (res.url.indexOf("/auth/users/password") !== -1) {
          return res;
        }

        // catch 500 error
        if (
          res.status === statuses.internalError &&
          res.url.indexOf("/current") === -1
        )
          message =
            "Something went wrong with request. We are so sorry and already fixing that.";

        const error = {
          errorMsg: message,
          status: res.status,
        };

        return error;
      }
    })
    .catch((err) => {
      const error = {
        errorMsg: "Something went wrong with request.",
      };
      console.log("Error in " + url + " request");
      return error;
    });
}
