import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";

import { getFloor, isMobile } from "./../../../utils/helpers";
import InputTypeText from "./../../../components/InputTypeText";

import { getBetTipName, getBetWinNumbers } from "./../helpers";
import { stakeModes } from "./../../../utils/constants";
import VirtualKeyboard from "../../../components/ModalWindows/VirtualKeyboard";

const BetsList = ({
  maxStake,
  minStake,
  onChangeStakeEventListener,
  eventObj,
  eventId,
  onRemoveOneEventsBetsEventListener,
  onSetStakeAmountCalculatorListener,
  stakeMode,
  onBetStakeClick,
  currency,
  isLowCredits,
  isWebGameVersion
}) => {
  const [showModal, toggleModal] = useState(null);
  const { t } = useTranslation();

  const onChangeEventListener = e => {
    onChangeStakeEventListener(e);
  };

  // open modal
  const openModalEventListener = event => {
    const modalHref = event.currentTarget.getAttribute("data-modal");
    if (modalHref) {
      toggleModal(modalHref);
    } else {
      toggleModal(null);
    }
  };

  return (
    <ul className="column-list event-bets-block-list">
      {Object.keys(eventObj).map((betTip, j) => {
        const betObj = eventObj[betTip];
        return (
          <li className="event-bets-block-list-item" key={j}>
            <div className="bet-status">
              <span
                data-event-id={eventId}
                data-bet-type={betTip}
                onClick={onRemoveOneEventsBetsEventListener}
                className="close-button"
              ></span>
              <p className="bet-name">{t(getBetTipName(betObj.tip).replaceAll(" ", ''))}</p>{" "}
              <span className="win">{getBetWinNumbers(betObj.tip)}</span>{" "}
              <span className="odds">( {getFloor(betObj.odds, 10)} )</span>
            </div>

            <div
              data-event-id={eventId}
              data-bet-type={betTip}
              className={cn("bet-stake", {
                [`readonly-input`]:
                  stakeMode === stakeModes.STAKE_PER_BET_MODE ||
                  stakeMode === stakeModes.SPLIT_STAKE_MODE
              })}
            >
              <label htmlFor={`${eventId}+${j}`} className="bet-stake-label">
                {t("betslip.betstake")}, {currency}
              </label>
              <InputTypeText
                readOnly={isMobile.any()}
                id={`${eventId}+${j}`}
                onChangeEvent={onChangeEventListener}
                dataModal={isMobile.any() ? "virtualKeyboard" : null}
                dataEventId={eventId}
                dataBetType={betTip}
                onClickEvent={e => {
                  // if (isMobile.any()) openModalEventListener(e);
                  onBetStakeClick(e);
                }}
                value={getFloor(betObj.stake, 100)}
                classNames={cn(
                  `stake-amount-input ${
                    stakeMode === stakeModes.STAKE_PER_BET_MODE ||
                    stakeMode === stakeModes.SPLIT_STAKE_MODE
                      ? "readonly stake-amount"
                      : "stake-amount"
                  }`,
                  {
                    danger:
                      isLowCredits ||
                      (stakeMode === stakeModes.CUSTOM_STAKE_MODE &&
                        (betObj.stake > maxStake || betObj.stake < minStake)),
                    [`v-keyboard`]: !isWebGameVersion
                  }
                )}
              />
              {stakeMode !== stakeModes.STAKE_PER_BET_MODE &&
                stakeMode !== stakeModes.SPLIT_STAKE_MODE &&
                !isWebGameVersion && (
                  <span
                    className={"modal-betslip-keyboard-btn"}
                    onClick={(e)=>{
                      // if (isMobile.any()) openModalEventListener(e);
                      onBetStakeClick(e);
                    }}
                    data-modal="virtualKeyboard"
                    data-event-id={eventId}
                    data-bet-type={betTip}
                  ></span>
                )}
            </div>
            {/*modal window for calculator */}
            <VirtualKeyboard
              openModalEventListener={openModalEventListener}
              showModal={showModal === "virtualKeyboard"}
              onSetStakeAmountCalculatorListener={
                onSetStakeAmountCalculatorListener
              }
              selectedValue={betObj.stake}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default BetsList;
