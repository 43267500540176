export const xhrAPIdomain = {
  toString: function() {
    let hostname = window.location.origin;

    if (
      window.location.origin.indexOf("localhost") !== -1
    ) {
      hostname = "https://web.mohiogaming.com"; // prod
      // hostname = "https://webstage.mohiogaming.com";
    }
    // if (window.location.origin.indexOf("terminal.mohiogaming") !== -1) hostname = "https://web.mohiogaming.com";
    // if (window.location.origin.indexOf("terminalracingza") !== -1) hostname = 'racingza.mohiogaming.com';
    return hostname;
  }
};
