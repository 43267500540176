import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import cn from "clsx";
import { betsTypeForBetRequest } from "../../../utils/constants";

const QuinellaBetOddsTable = ({
  oddsData,
  columnTitles,
  rowTitles,
  onAddBetEventListener,
  eventId,
  betsForEvent,
  raceType,
  language
}) => {
  const [quinellaBet, setQuinellaBet] = useState(
    betsForEvent[betsTypeForBetRequest.quinella]
      ? `${betsForEvent[betsTypeForBetRequest.quinella].firstGuess -
          1}||${betsForEvent[betsTypeForBetRequest.quinella].secondGuess - 1}`
      : ""
  ); // state for saving active cell

  useEffect(() => {
    if (Object.keys(betsForEvent).length === 0) {
      setQuinellaBet("");
    }

    if (betsForEvent[betsTypeForBetRequest.quinella]) {
      setQuinellaBet(
        betsForEvent[betsTypeForBetRequest.quinella]
          ? `${betsForEvent[betsTypeForBetRequest.quinella].firstGuess -
              1}||${betsForEvent[betsTypeForBetRequest.quinella].secondGuess -
              1}`
          : ""
      );
    }
  }, [betsForEvent]);

  // set the active cell in odds table after game changed
  useEffect(() => {
    if (betsForEvent[betsTypeForBetRequest.quinella]) {
      setQuinellaBet(
        `${betsForEvent[betsTypeForBetRequest.quinella].firstGuess -
          1}||${betsForEvent[betsTypeForBetRequest.quinella].secondGuess - 1}`
      );
    }

    // eslint-disable-next-line
  }, [raceType]);

  // make bet (create bet in betslip container in basket)
  // in each column available to choose only one cell
  const onClickEventListener = event => {
    // const typeBet = event.currentTarget.getAttribute("data-type-bet");
    const coordsCell = event.currentTarget.getAttribute("data-coords-cell");
    setQuinellaBet(coordsCell === quinellaBet ? "" : coordsCell);
    // prepare all needed data for send bets to the server
    onAddBetEventListener(event);
  };
  return (
    <div className="flex-row accordion-table-wrap">
      <table className="flex-col-21 bordered-table light-table table quinella">
        <thead>
          <tr>
            {/*empty cell*/}
            <th
              className={`titleRow-class no-table-cell first-second-label ${language}`}
            ></th>
            {/*name type of bets*/}
            {columnTitles.map((title, j) => {
              return (
                <th className="no-table-cell" key={j}>
                  <span className={`event-win-item-${title}`}></span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rowTitles.map((titleRow, k) => {
            return (
              <tr key={k}>
                {/* cell of column with number*/}
                <td className="no-table-cell">
                  <span className={`event-win-item-${titleRow}`}></span>
                </td>
                {oddsData[k].map((cellOdd, j) => {
                  // row of cells with odds
                  return (
                    <td
                      className={cn(
                        {
                          active: quinellaBet.includes(`${k}||${j}`)
                        },
                        {
                          unavailable: k === j
                        }
                      )}
                      data-type-bet={betsTypeForBetRequest.quinella}
                      data-coords-cell={`${k}||${j}`}
                      data-odd-amount={cellOdd}
                      data-win-number={`${k + 1}||${j + 1}`}
                      data-event-id={eventId}
                      onClick={onClickEventListener.bind(this)}
                      key={j}
                    >
                      {cellOdd}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

QuinellaBetOddsTable.propTypes = {
  oddsData: PropTypes.array
};

export default QuinellaBetOddsTable;
