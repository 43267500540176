import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "clsx";

import { isMobile, getCorrectXhrAPIdomain } from "../../utils/helpers";
import {
  Modal,
  ModalMainContent,
  ModalHeader,
  ModalCloseBtn
} from "./../Modal/Modal";
import Calculator from "../Calculator/Calculator";
import ActionButton from "../ActionButton";

import { toastreact } from "../ToastMessage";
import { toastMessageTypes } from "../ToastMessage/constants";

const UserEmailModalWindow = ({
  showModal,
  onCloseModal,
  sendUserMailForPswRecoverySuccess
}) => {
  const [userEmail, setUserEmail] = useState("");
  const [isValidMail, setIsValidMail] = useState(null);
  const [showVirtualKeyboard, toggleVirtualKeyboard] = useState(false);
  const [emailCheckStatus, setEmailCheckStatus] = useState(null);
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);

  const { t } = useTranslation();

  const sendUserMailForPswRecovery = userMail => {
    setIsLoadingProcess(true);
    fetch(
      `${getCorrectXhrAPIdomain(
        window.location.origin
      )}/auth/users/check?email=${userMail}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(userMailSendStatus => {
        return { status: userMailSendStatus.status };
      })
      .then(resj => {
        if (resj.status && resj.status === 404) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: t("modalWindows.emailModal.emailErrorMsg")
          });
          setEmailCheckStatus(404);
          setIsLoadingProcess(false);
        }
        if (resj.status && resj.status === 200) {
          fetch(
            `${getCorrectXhrAPIdomain(
              window.location.origin
            )}/auth/password/forgot`,
            {
              method: "post",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                email: userMail
              })
            }
          ).then(sendMailRes => {
            if (sendMailRes.status === 200) {
              // setShowModal("userEmailStatusModalWindow");
              sendUserMailForPswRecoverySuccess();
              setIsLoadingProcess(false);
            }
            if (sendMailRes.status === 400) {
              toastreact({
                type: toastMessageTypes.ERROR,
                title: t("modalWindows.emailModal.allowedRecover")
              });
            }
          });
        }
      });
  };

  const validateMail = email => {
    // eslint-disable-next-line
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };
  const onChangeEventListenerVKeyboard = value => {
    const isValidEmail = validateMail(value);
    setIsValidMail(isValidEmail);
    setUserEmail(value);
    if (isValidEmail) setEmailCheckStatus(null);
    if (
      isMobile.any() &&
      (!isValidEmail || emailCheckStatus === 404) &&
      document.querySelectorAll(".Toastify>.Toastify__toast-container")
        .length === 0
    ) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title:
          emailCheckStatus === 404
            ? t("modalWindows.emailModal.emailErrorMsg")
            : t("modalWindows.emailModal.correctMail")
      });
    }
  };
  const onChangeEventListener = e => {
    const isValidEmail = validateMail(e.currentTarget.value);
    setIsValidMail(isValidEmail);
    setUserEmail(e.currentTarget.value);
    if (isValidEmail) setEmailCheckStatus(null);
    if (
      isMobile.any() &&
      (!isValidEmail || emailCheckStatus === 404) &&
      document.querySelectorAll(".Toastify>.Toastify__toast-container")
        .length === 0
    ) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title:
          emailCheckStatus === 404
            ? t("modalWindows.emailModal.emailErrorMsg")
            : t("modalWindows.emailModal.correctMail")
      });
    }
  };

  const onSubmitActionBtn = () => {
    sendUserMailForPswRecovery(userEmail);
  };

  // show virtual keyboard
  const onShowVirtualKeyboardEventListener = e => {
    // if (isMobile.any() || !isWebGameVersion)
    toggleVirtualKeyboard(true);
  };

  return (
    <Modal
      showModal={showModal === "userEmailModalWindow"}
      id={"userEmailModalWindow"}
    >
      <ModalCloseBtn closeModalEventListener={onCloseModal} />

      <ModalHeader>
        <h3>{t("modalWindows.emailModal.title")}:</h3>
      </ModalHeader>
      <ModalMainContent>
        {showVirtualKeyboard ? (
          <Calculator
            onAddAmountEventListener={onChangeEventListenerVKeyboard}
            onResult={() => {
              toggleVirtualKeyboard(false);
            }}
            isKeyboard={true}
            selectedValue={userEmail}
          />
        ) : (
          <div className="input-wrap centered-block align-center">
            <div className="flex-row flex-align-baseline align-left form-group">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  value={userEmail}
                  onChange={onChangeEventListener}
                  type="text"
                  placeholder={t("modalWindows.emailModal.enterMail")}
                  name="user_mail"
                  id="user_mail"
                  onClick={e => {
                    if (isMobile.any()) onShowVirtualKeyboardEventListener(e);
                  }}
                  readOnly={isMobile.any()}
                  className={cx({
                    error:
                      (!isValidMail && isValidMail !== null) ||
                      emailCheckStatus === 404,
                    success: isValidMail && emailCheckStatus !== 404,
                    [`with-placeholder`]: true
                  })}
                  // onClick={onShowVirtualKeyboardEventListener}
                />
                <label htmlFor="user_mail">
                  {t("modalWindows.emailModal.mail")}
                </label>
                <span className={"valid-icon"}></span>
                <span
                  className={cx(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        (!isMobile.any() && !isValidMail && userEmail) ||
                        (!isMobile.any() && emailCheckStatus === 404)
                    }
                  )}
                >
                  {emailCheckStatus === 404
                    ? t("modalWindows.emailModal.emailErrorMsg")
                    : t("modalWindows.emailModal.correctMail")}
                </span>
              </div>
            </div>

            <ActionButton
              onclick={onSubmitActionBtn}
              classesName={cx("btn btn-warning", {
                // disable: !isValidMail || emailCheckStatus === 404 || isLoadingProcess,
                // inactive: !isValidMail || emailCheckStatus === 404 || isLoadingProcess
                disable: isLoadingProcess,
                inactive: isLoadingProcess
              })}
              isWarning={true}
            >
              {t("modalWindows.emailModal.sendMail")}
            </ActionButton>
          </div>
        )}
      </ModalMainContent>
    </Modal>
  );
};

export default UserEmailModalWindow;
