import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";

import { Scrollbars } from "react-custom-scrollbars";

import api from "./../../../utils/api";
import BetsList from "./BetsList";
import { useGlobalDataContext } from "./../../../../GlobalDataContext";
import {
  stakeModes,
  incrementsForStake,
  lifecycleStates
} from "./../../../utils/constants";
import { getFloor, isMobile } from "./../../../utils/helpers";
import { toastreact } from "./../../../components/ToastMessage/index";
import { toastMessageTypes } from "./../../../components/ToastMessage/constants";
import ActionButton from "./../../../components/ActionButton";
import VirtualKeyboard from "./../../../components/ModalWindows/VirtualKeyboard";
import {
  getNewStakeAmountValueIncrement,
  getNewStakeAmountValue,
  checkIfCreditsValueIsLow,
  getNewBetsEventDelete,
  getNewBets,
  getBetsWithSameStake,
  getBetsWithOneNewStake,
  getSumDataFromBetsObject,
  getBetsWithSplitStake,
  getBetTipName,
  getMinStakeAmount,
  checkIfEmptyBetsObject
} from "./../helpers";

import PreLoader from "./../../../components/PreLoader/PreLoader";

import {
  getActiveBetsObject,
  getRaceTypeForPlaceBet
} from "../../Bets/helpers";

import {
  getItemFromStorage,
  setItemInStorage
} from "../../../utils/LocalStorageService";

const Betslip = () => {
  const { state, dispatch } = useGlobalDataContext();
  const { t } = useTranslation();

  const [autoHeightMaxScrollBar, setAutoHeightMaxScrollBar] = useState(500);
  const [stakeMode, setStakeMode] = useState(stakeModes.STAKE_PER_BET_MODE);
  const [stakeAmount, setStakeAmount] = useState(
    state.gameSettings.minStake ? state.gameSettings.minStake : `0`
  );
  const [disablePlaceBet, setDisablePlaceBet] = useState(false);
  const [betStakeEventId, setBetStakeEventId] = useState(null);
  const [betStakeType, setBetStakeType] = useState(null);
  const [maxWinning, setMaxWinning] = useState(0);
  const [totalStake, setTotalStake] = useState(0);
  const [betsCount, setBetsCount] = useState(null);
  const [isLowCredits, setIsLowCredits] = useState(false);
  const [stakeAmountIsToHeight, setStakeAmountIsToHeight] = useState(false);
  const [stakeAmountIsToLow, setStakeAmountIsToLow] = useState(false);
  const [placeBetsProcess, setPlaceBetsProcess] = useState(false);
  const [confirmBet, setConfirmBet] = useState(false);
  const [rememberConfirmBet, setRememberConfirmBet] = useState(false);

  const [maxWinnPerTicketDisable, setMaxWinnPerTicketDisable] = useState(false);

  const [maxWinPerTicket, setMaxWinPerTicket] = useState(0);

  const betsObjectByEvents = state.betsObject;
  const raceType = state.raceType;
  const [blockedResetBets, setBlockedResetBets] = useState(false);

  // set min stake amount after fetched this data from the server
  React.useEffect(() => {
    if (Object.keys(state.gameSettings).length <= 0) return;
    setStakeAmount(getMinStakeAmount(state.gameSettings.minStake));
    setMaxWinPerTicket(state.gameSettings.maxWinPerTicket);
  }, [state.gameSettings]);

  // refresh info for betslip footer after stake amount changed or betsObject canged(add or remove bet)
  React.useEffect(() => {
    // check if there is no bet in betsObject and if true set all state variable to default
    if (checkIfEmptyBetsObject(state.betsObject)) {
      setMaxWinnPerTicketDisable(false);
      setMaxWinning(0);
      setTotalStake(0);
      setStakeAmount(getMinStakeAmount(state.gameSettings.minStake));
      setBetsCount(0);
      setIsLowCredits(false);
      setStakeAmountIsToHeight(false);
      setStakeAmountIsToLow(false);
      return;
    }

    // set the correct bets object for split and stake per bet modes
    const betsObjectInReactUseEffect =
      stakeMode === stakeModes.STAKE_PER_BET_MODE
        ? getBetsWithSameStake(state.betsObject, Number(stakeAmount))
        : stakeMode === stakeModes.SPLIT_STAKE_MODE
        ? getBetsWithSplitStake(
            state.betsObject,
            Number(stakeAmount),
            // eslint-disable-next-line
            betsCount
          )
        : state.betsObject;

    const summaryData = getSumDataFromBetsObject(betsObjectInReactUseEffect);

    setTotalStake(
      stakeMode === stakeModes.SPLIT_STAKE_MODE
        ? Number(stakeAmount)
        : summaryData.tempTotalStake
    );
    setMaxWinning(summaryData.tempMaxWinning);
    // if at least one ticket has winning amount bigger than max winning per ticket than disable place bets
    setMaxWinnPerTicketDisable(
      summaryData.maxWinningPerTicket.filter(item => {
        return item > maxWinPerTicket || item > state.gameSettings.maxWinPerBet;
      }).length > 0
    );
    setBetsCount(summaryData.betsCount);
    // check if total stake amount bigger than user's credits
    const creditsValueIsLow = checkIfCreditsValueIsLow(
      summaryData.tempTotalStake,
      state.userData.createWalletDTO.credits
    );
    setIsLowCredits(creditsValueIsLow);

    // show notification msg if total stake amount for SPLIT_STAKE_MODE mode is bigger than max stake value
    // and if stake amount value is to low (less than min stake amount)
    if (stakeMode === stakeModes.SPLIT_STAKE_MODE) {
      setStakeAmountIsToHeight(
        betsCount
          ? Number(stakeAmount) / betsCount > state.gameSettings.maxStake
          : false
      );

      setStakeAmountIsToLow(
        Number(stakeAmount) / betsCount < state.gameSettings.minStake
      );
    }

    // show notification msg if stake amount for STAKE_PER_BET_MODE mode is bigger than max stake value
    if (stakeMode === stakeModes.STAKE_PER_BET_MODE)
      setStakeAmountIsToHeight(
        Number(stakeAmount) > state.gameSettings.maxStake
      );

    if (stakeMode === stakeModes.STAKE_PER_BET_MODE) {
      setStakeAmountIsToLow(false);
    }

    // eslint-disable-next-line
  }, [state.betsObject, stakeAmount]);

  // show error notification message after betslip state changed
  React.useEffect(() => {
    // do not show two or more notification at same time
    const toastifyContainerActiveLength = document.querySelectorAll(
      ".Toastify>.Toastify__toast-container"
    ).length;
    if (isLowCredits && totalStake > 0 && toastifyContainerActiveLength === 0) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("betslip.tooltip.stakeBiggerCreds")
      });
    }
    if (
      stakeAmountIsToHeight &&
      totalStake > 0 &&
      toastifyContainerActiveLength === 0
    ) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("betslip.tooltip.stakeBiggerMaxValue")
      });
    }
    if (
      stakeAmountIsToLow &&
      totalStake > 0 &&
      toastifyContainerActiveLength === 0
    ) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("betslip.tooltip.stakeLessMinValue")
      });
    }

    // eslint-disable-next-line
  }, [totalStake]);

  // set minimal stake for all bets in SPLIT_STAKE_MODE and set the whole stake amount
  React.useEffect(() => {
    if (stakeMode === stakeModes.SPLIT_STAKE_MODE) {
      if (stakeAmount / betsCount < state.gameSettings.minStake)
        setStakeAmount(state.gameSettings.minStake * betsCount);
    }

    // eslint-disable-next-line
  }, [betsCount, stakeMode]);

  // for Stake per bet mode set the same stake property value for just added bet
  React.useEffect(() => {
    // remove all data from betsObject
    if (betsCount === 0) {
      dispatch({
        type: "ADD_BET_IN_CART",
        payload: {}
      });
      return;
    }

    // refresh bets object for each betlist mode
    if (stakeMode === stakeModes.STAKE_PER_BET_MODE) {
      dispatch({
        type: "ADD_BET_IN_CART",
        payload: getBetsWithSameStake(state.betsObject, Number(stakeAmount))
      });
    }
    if (stakeMode === stakeModes.SPLIT_STAKE_MODE) {
      dispatch({
        type: "ADD_BET_IN_CART",
        payload: getBetsWithSplitStake(
          state.betsObject,
          Number(stakeAmount),
          betsCount
        )
      });
    }

    // eslint-disable-next-line
  }, [betsCount, stakeMode, stakeAmount, maxWinning]);

  React.useEffect(() => {
    if (
      maxWinnPerTicketDisable &&
      (maxWinning > state.gameSettings.maxWinPerTicket ||
        maxWinning > state.gameSettings.maxWinPerBet)
    )
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("betslip.tooltip.winExceedMaxWin")
      });
    // eslint-disable-next-line
  }, [maxWinnPerTicketDisable]);

  // set the heigh of basket's tab content after page was rendered
  React.useEffect(() => {
    if (!document.querySelector(".basket")) return false;
    setAutoHeightMaxScrollBar(
      document.querySelector(".basket").offsetHeight -
        (document.querySelector("footer.stake-operation-block").offsetHeight +
          document.querySelector(".basket .tabs-titles").offsetHeight +
          20)
    );
  }, [state.slideAsideBlockId]);

  // remove all bets of event with state RACE or BET_LOCKED
  React.useEffect(() => {
    if (state.currentLifecycleState === lifecycleStates.BET) return;

    const activeBetsObject = getActiveBetsObject({
      betsObject: state.betsObject,
      eventsOdds: state.eventsOdds
    });
    // show modal window with info about deleted bets by not available events
    if (activeBetsObject.expiredRaceIds.length > 0 && !blockedResetBets) {
      toggleModal("showInfoExpiredEvents");
    }

    // remove all bets for event with Race or Bet_locked state
    dispatch({
      type: "ADD_BET_IN_CART",
      payload: activeBetsObject.betsObject
    });
    // eslint-disable-next-line
  }, [state.eventsOdds]);

  // refresh user data after each round of each race type finished
  const updateUserData = async () => {
    setDisablePlaceBet(true);
    // const betHistory = await api.getCurrentBasket();
    // if (!betHistory.errorMsg) {
    const updatedUserData = await api.getCurrentUserData();
    if (!updatedUserData.errorMsg) {
      setDisablePlaceBet(false);
      dispatch({
        type: "SET_CURRENT_USER_DATA",
        payload: updatedUserData
      });
    }
    // }
  };

  // remove all bets by EVENT
  const onRemoveAllEventsBetsEventListener = event => {
    const eventId = event.currentTarget.getAttribute("data-event-id");
    toastreact({
      type: toastMessageTypes.WARN,
      title: t("betslip.tooltip.allBetsRemoved").replace("%eventId%", eventId)
    });
    dispatch({
      type: "ADD_BET_IN_CART",
      payload: getNewBetsEventDelete(state.betsObject, eventId)
    });
  };

  // remove bets by one
  const onRemoveOneEventsBetsEventListener = event => {
    const betType = event.currentTarget.getAttribute("data-bet-type");
    const eventId = event.currentTarget.getAttribute("data-event-id");

    toastreact({
      type: toastMessageTypes.WARN,
      title: `${t(getBetTipName(betType).replaceAll(" ", ""))} ${t(
        "betslip.tooltip.betOfEvent"
      )} ${eventId} ${t("betslip.tooltip.removeBetslip")} `
    });

    dispatch({
      type: "ADD_BET_IN_CART",
      payload: getNewBets(state.betsObject, eventId, betType)
    });
  };

  // change stake mode of betslip
  const onChangeStakeModeEventListener = event => {
    setStakeMode(event.currentTarget.getAttribute("data-stake-mode"));
  };

  // clear stake amount field
  const onClearBetsStakeEventListener = e => {
    setStakeAmount(
      getMinStakeAmount(
        stakeMode === stakeModes.SPLIT_STAKE_MODE
          ? state.gameSettings.minStake * betsCount
          : state.gameSettings.minStake
      )
    );
  };

  // stake amount calculator listener
  const onSetStakeAmountCalculatorListener = amount => {
    if (amount !== "") {
      setStakeAmountIsToHeight(amount > state.gameSettings.maxStake);
      if (stakeMode !== stakeModes.CUSTOM_STAKE_MODE)
        setStakeAmount(
          amount < state.gameSettings.minStake
            ? state.gameSettings.minStake
            : getNewStakeAmountValue(amount)
        );
      else {
        dispatch({
          type: "ADD_BET_IN_CART",
          payload: getBetsWithOneNewStake(
            state.betsObject,
            Number(amount),
            betStakeEventId,
            betStakeType
          )
        });
      }
    }
  };

  // set the stake amount increments stake list
  const onIncrementStakeAmountEventListener = e => {
    if (e.currentTarget.getAttribute("data-alias-stake") === "Max") {
      if (stakeMode === stakeModes.STAKE_PER_BET_MODE) {
        setStakeAmount(state.gameSettings.maxStake);
      }
      if (stakeMode === stakeModes.SPLIT_STAKE_MODE) {
        setStakeAmount(state.gameSettings.maxStake * betsCount);
      }
    } else {
      setStakeAmount(
        getNewStakeAmountValueIncrement(
          stakeAmount,
          e.currentTarget.getAttribute("data-alias-stake")
        )
      );
    }
  };

  // prepare data for post request(place bets request)
  const getBetsObjectForRequest = eventId => {
    return {
      amountOfGames: 1,
      betList: [...Object.values(state.betsObject[eventId])],
      raceType: getRaceTypeForPlaceBet(state.raceType)
    };
  };

  // place bet event listener by Place Bet button
  const onPlaceBetEventListener = async () => {
    if (state.currentLifecycleState === lifecycleStates.BET_LOCKED) return;
    setConfirmBet(false);

    setPlaceBetsProcess(true);
    let eventsId = Object.keys(state.betsObject).map(eventId => {
      return eventId;
    });

    let betsObjectForRequests = {};
    for (const eventId of eventsId) {
      betsObjectForRequests[eventId] = getBetsObjectForRequest(eventId);
    }
    let errors = [];
    let success = [];
    setBlockedResetBets(true)

    for (const eventId of eventsId) {
      try {
        // api request with all user's bets
        // eslint-disable-next-line
        const res = await api.placeBet(betsObjectForRequests[eventId], eventId);

        if (res.errorMsg) {
          errors.push(eventId);
        } else {
          success.push(eventId);
        }
      } catch (error) {
        console.log("error while bets placing process", error);
      }
    }

    setBlockedResetBets(false)

    if (errors.length > 0) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("betslip.tooltip.errorPlaceBet").replace(
          "%errors%",
          errors.join(", ")
        )
      });
    }

    if (success.length > 0) {
      toastreact({
        type: toastMessageTypes.SUCCESS,
        title:
          eventsId.length === 1
            ? t("betslip.tooltip.youPlacedEvent")
                .replace("%totalStake%", totalStake)
                .replace("%currency%", state.gameSettings.currency)
                .replace("%event%", success[0])
            : t("betslip.tooltip.youPlacedEvents")
                .replace("%totalStake%", totalStake)
                .replace("%currency%", state.gameSettings.currency)
                .replace("%events%", success.join(", "))
      });
      // save event's ids in session storage for terminal version
      if (!state.isWebGameVersion) {
        const workWithSessionStorage = true;
        const idsOfEventsPlacedBets = getItemFromStorage(
          "idsOfEventsPlacedBets",
          workWithSessionStorage
        )
          ? {
              ...getItemFromStorage(
                "idsOfEventsPlacedBets",
                workWithSessionStorage
              )
            }
          : { [state.raceType]: [] };

        if (
          idsOfEventsPlacedBets[state.raceType] &&
          idsOfEventsPlacedBets[state.raceType].length > 0
        ) {
          idsOfEventsPlacedBets[state.raceType] = [
            ...new Set([...idsOfEventsPlacedBets[state.raceType], ...success])
          ];
        } else {
          idsOfEventsPlacedBets[state.raceType] = success;
        }
        setItemInStorage(
          "idsOfEventsPlacedBets",
          idsOfEventsPlacedBets,
          workWithSessionStorage
        );
      }
    }

    // clear betslip from bets
    dispatch({
      type: "ADD_BET_IN_CART",
      payload: {}
    });

    updateUserData();

    setPlaceBetsProcess(false);
    setStakeAmount(getMinStakeAmount(state.gameSettings.minStake));
    setBetsCount(0);
    if (isMobile.any()) dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload: null
    });
  };

  // close confirm bets block
  const onCancelPlaceBetEventListener = () => {
    setConfirmBet(false);
  };

  // remember choise in session storage and do not show confirm place bets component
  const onChangeShowConfirmPlaceBetsEvent = e => {
    setItemInStorage("showConfirmPlaceBets", e.currentTarget.checked, true);
    setRememberConfirmBet(!rememberConfirmBet);
  };
  // open modal
  const toggleModal = modalName => {
    dispatch({
      type: "OPEN_MODAL_WINDOW",
      payload: modalName
    });
  };

  // open custom betslip modal
  const openCustomBetslipModalEventListener = event => {
    const modalHref = event.currentTarget.getAttribute("data-modal");
    const eventId = event.currentTarget.getAttribute("data-event-id");
    const betType = event.currentTarget.getAttribute("data-bet-type");
    if (stakeMode === stakeModes.CUSTOM_STAKE_MODE) {
      setBetStakeEventId(eventId);
      setBetStakeType(betType);
      toggleModal(modalHref);
    }
  };

  // set in stake amount input valid value if use pc keyboard not virtual keyboard
  const onChangeStakeAmountListener = e => {
    const inputValue = e.currentTarget.value;
    if (isMobile.any()) return;
    if (!inputValue) setStakeAmount("");
    const reg = new RegExp("^\\d+$");
    if (inputValue) {
      const formatedValue = inputValue.replace(",", ".");

      if (
        formatedValue.indexOf(".") !== -1 &&
        reg.test(formatedValue.replace(".", ""))
      ) {
        setStakeAmount(formatedValue);
      } else {
        setStakeAmount(reg.test(formatedValue) ? formatedValue : stakeAmount);
      }
    }
  };

  const onFocuseOutListener = e => {
    const value = e.currentTarget.value;
    if (
      value === 0 ||
      value < state.gameSettings.minStake ||
      value === "" ||
      value === " "
    )
      setStakeAmount(state.gameSettings.minStake);
  };

  // change stake value for one bet in CUSTOM_STAKE_MODE mode
  const onChangeStakeEventListener = e => {
    const target = e.currentTarget;
    const amount = target.value;

    // set property for notification errors messages
    setStakeAmountIsToLow(amount < state.gameSettings.minStake);
    setStakeAmountIsToHeight(amount > state.gameSettings.maxStake);

    // refresh betsObject for CUSTOM_STAKE_MODE mode
    dispatch({
      type: "ADD_BET_IN_CART",
      payload: getBetsWithOneNewStake(
        state.betsObject,
        Number(amount),
        betStakeEventId,
        betStakeType
      )
    });
  };

  return (
    <>
      {placeBetsProcess && (
        <PreLoader
          isLoading={true}
          isLoadingText={t("betslip.tooltip.placingBets")}
          isLightPreLoader={false}
          showLoadingText={true}
          showInParent={true}
        />
      )}
      <div className="basket-tab-content">
        <Scrollbars
          className="basket-scrollbar"
          autoHeight={true}
          autoHeightMax={autoHeightMaxScrollBar}
        >
          {Object.keys(betsObjectByEvents).length === 0 && (
            <div className="empty-bet-list align-center">
              {t("betslip.emptyMsg")}
            </div>
          )}
          {Object.keys(state.betsObject).map((eventId, index) => {
            const eventObj = betsObjectByEvents[eventId];

            return (
              Object.keys(eventObj).length > 0 && (
                <div key={index} className="event-bets-block">
                  <header className="event-bets-block-header">
                    <span
                      data-event-id={eventId}
                      onClick={onRemoveAllEventsBetsEventListener}
                      className="close-button-alt"
                    ></span>
                    <h2 className="top-title align-left">
                      {`${t("event")}  ${eventId}`}
                    </h2>
                    <div className="event-info">{t(raceType)}</div>
                  </header>
                  <main className="event-bets-block-main">
                    <BetsList
                      maxStake={state.gameSettings.maxStake}
                      minStake={state.gameSettings.minStake}
                      onChangeStakeEventListener={onChangeStakeEventListener}
                      isWebGameVersion={state.isWebGameVersion}
                      stakeMode={stakeMode}
                      eventObj={eventObj}
                      onRemoveOneEventsBetsEventListener={
                        onRemoveOneEventsBetsEventListener
                      }
                      onSetStakeAmountCalculatorListener={
                        onSetStakeAmountCalculatorListener
                      }
                      eventId={eventId}
                      onBetStakeClick={openCustomBetslipModalEventListener}
                      currency={state.gameSettings.currency}
                      isLowCredits={
                        stakeMode === stakeModes.CUSTOM_STAKE_MODE &&
                        isLowCredits
                      }
                    />
                  </main>
                </div>
              )
            );
          })}
        </Scrollbars>
        <footer className="stake-operation-block">
          <div className="align-center toggle-stake-mode">
            <span
              onClick={onChangeStakeModeEventListener}
              className={cn("stake-mode-indicator", {
                active: stakeMode === stakeModes.STAKE_PER_BET_MODE
              })}
              data-stake-mode={stakeModes.STAKE_PER_BET_MODE}
            >
              {t("betslip.perBet")}
            </span>
            <span
              onClick={onChangeStakeModeEventListener}
              className={cn("stake-mode-indicator", {
                active: stakeMode === stakeModes.SPLIT_STAKE_MODE
              })}
              data-stake-mode={stakeModes.SPLIT_STAKE_MODE}
            >
              {t("betslip.splitStake")}
            </span>
            <span
              onClick={onChangeStakeModeEventListener}
              className={cn("stake-mode-indicator", {
                active: stakeMode === stakeModes.CUSTOM_STAKE_MODE
              })}
              data-stake-mode={stakeModes.CUSTOM_STAKE_MODE}
            >
              {t("betslip.customStake")}
            </span>
          </div>

          <div className="stake-status">
            <div className="flex-row">
              <div
                className={cn("flex-col-11 font-bold mb-5", {
                  danger:
                    isLowCredits ||
                    stakeAmountIsToHeight ||
                    stakeAmountIsToLow ||
                    maxWinnPerTicketDisable
                })}
              >
                {t("betslip.totalStake")}{" "}
                {!!betsCount &&
                  stakeAmount &&
                  stakeMode === stakeModes.STAKE_PER_BET_MODE && (
                    <span className="font-light total-status">
                      ({`${betsCount}x${stakeAmount}`})
                    </span>
                  )}
                {!!betsCount && stakeMode === stakeModes.CUSTOM_STAKE_MODE && (
                  <span className="font-light total-status">
                    ({`${betsCount}`})
                  </span>
                )}
                {!!betsCount && stakeMode === stakeModes.SPLIT_STAKE_MODE && (
                  <span className="font-light total-status">
                    ({`${betsCount}x${getFloor(stakeAmount / betsCount, 100)}`})
                  </span>
                )}
              </div>
              <div
                className={cn(
                  "flex-col-10 align-right font-bold total-stake-amount",
                  {
                    danger:
                      isLowCredits ||
                      stakeAmountIsToHeight ||
                      stakeAmountIsToLow ||
                      maxWinnPerTicketDisable
                  }
                )}
              >
                {`${getFloor(totalStake, 100)} ${state.gameSettings.currency}`}
              </div>
            </div>
            <div className="flex-row">
              <div
                className={cn(`flex-col-11 font-light mb-5`, {
                  danger: maxWinnPerTicketDisable
                })}
              >
                {t("betslip.maxWinning")}
              </div>
              <div
                className={cn(
                  "flex-col-10 align-right font-light max-wining-state",
                  {
                    danger: maxWinnPerTicketDisable
                  }
                )}
              >
                {`${getFloor(maxWinning, 100)} ${state.gameSettings.currency}`}
              </div>
            </div>
            <div className="flex-row">
              <div
                className="flex-col-11 font-light mb-5 x-small-text hide-for-desktop"
                style={{ paddingRight: "10px", textAlign: "right" }}
              >
                {t("betslip.minStake")}:{" "}
                {`${state.gameSettings.minStake} ${state.gameSettings.currency}`}
              </div>
              <div
                className="flex-col-10 font-light x-small-text hide-for-desktop"
                style={{ paddingLeft: "10px" }}
              >
                {t("betslip.maxStake")}:{" "}
                {`${state.gameSettings.maxStake} ${state.gameSettings.currency}`}
              </div>

              <div className="flex-col-11 font-light mb-5 small-text hide-for-mobile">
                {t("betslip.minStake")}
              </div>
              <div className="flex-col-10 align-right font-light max-wining-state small-text hide-for-mobile">
                {`${state.gameSettings.minStake} ${state.gameSettings.currency}`}
              </div>
            </div>

            <div className="flex-row hide-for-mobile">
              <div className="flex-col-11 font-light small-text">
                {t("betslip.maxStake")}
              </div>
              <div className="flex-col-10 align-right font-light max-wining-state small-text">
                {`${state.gameSettings.maxStake} ${state.gameSettings.currency}`}
              </div>
            </div>
          </div>

          <div
            data-tip={
              betsCount === 0
                ? t("betslip.tooltip.selectBet")
                : isLowCredits
                ? t("betslip.tooltip.stakeBiggerCreds")
                : stakeAmountIsToHeight
                ? t("betslip.tooltip.stakeBiggerMaxValue")
                : stakeAmountIsToLow
                ? t("betslip.tooltip.stakeLessMinValue")
                : maxWinnPerTicketDisable
                ? t("betslip.tooltip.winExceedMaxWin")
                : ""
            }
            className={cn({
              disable:
                stakeMode === stakeModes.CUSTOM_STAKE_MODE ||
                betsCount === 0 ||
                betsCount === null ||
                disablePlaceBet
            })}
          >
            {(stakeMode === stakeModes.CUSTOM_STAKE_MODE ||
              betsCount === 0 ||
              betsCount === null ||
              disablePlaceBet) && <div className={"disable-block"}></div>}

            {/* stake amount  */}
            <div className={cn("stake-amount  flex-row")}>
              <div className="flex-col-10 font-bold">
                <span className="stake-amount-label">{t("betslip.stake")}</span>{" "}
              </div>
              <div className="flex-col-11 align-right font-bold">
                <div className="stake-amount-input">
                  <input
                    name="stake_amount"
                    id="stake_amount"
                    type="text"
                    value={`${stakeAmount}`}
                    onChange={onChangeStakeAmountListener}
                    onBlur={onFocuseOutListener}
                    data-modal={
                      isMobile.any() || !state.isWebGameVersion
                        ? "virtualKeyboard"
                        : null
                    }
                    onClick={e => {
                      if (isMobile.any()) toggleModal("virtualKeyboard");
                    }}
                    // https://hirejordansmith.com/how-to-disable-the-mobile-keyboard-from-displaying-input-field/
                    readOnly={isMobile.any()}
                    className={cn("stake-amount-input", {
                      danger:
                        (isLowCredits ||
                          stakeAmountIsToHeight ||
                          stakeAmountIsToLow) &&
                        (stakeMode === stakeModes.SPLIT_STAKE_MODE ||
                          stakeMode === stakeModes.STAKE_PER_BET_MODE),
                      [`v-keyboard`]: !state.isWebGameVersion
                    })}
                  />{" "}
                  <label
                    htmlFor="stake_amount"
                    className={cn("input-currency", {
                      danger:
                        (isLowCredits ||
                          stakeAmountIsToHeight ||
                          stakeAmountIsToLow) &&
                        (stakeMode === stakeModes.SPLIT_STAKE_MODE ||
                          stakeMode === stakeModes.STAKE_PER_BET_MODE)
                    })}
                  >
                    {state.gameSettings.currency}
                  </label>
                  {!state.isWebGameVersion && (
                    <span
                      className={"modal-keyboard-btn"}
                      onClick={() => {
                        toggleModal("virtualKeyboard");
                      }}
                      data-modal="virtualKeyboard"
                    ></span>
                  )}
                  <span
                    onClick={onClearBetsStakeEventListener}
                    className={cn("close-button-alt primary", {
                      "disable-button":
                        stakeMode === stakeModes.CUSTOM_STAKE_MODE ||
                        betsCount === 0
                    })}
                  ></span>
                </div>
              </div>
            </div>

            {/* stake amount alias */}
            <ul className={cn("row-list increments-list")}>
              {Object.keys(incrementsForStake).map((alias, i) => {
                return (
                  <li
                    onClick={onIncrementStakeAmountEventListener}
                    data-alias-stake={alias}
                    key={i}
                  >
                    { i === Object.keys(incrementsForStake).length - 1 ? t('max') : incrementsForStake[alias]}
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            className={cn("confirm-place-bets", {
              show: confirmBet
            })}
          >
            <h4 className="confirm-place-bets-text align-center">
              {t("betslip.confirmBet")}
            </h4>
            <div className="confirm-place-bets-btns align-center">
              <ActionButton onclick={onPlaceBetEventListener}>
                <span>{t("betslip.okPlaceBets")}</span>
              </ActionButton>
              <ActionButton onclick={onCancelPlaceBetEventListener}>
                <span>{t("betslip.cancel")}</span>
              </ActionButton>
            </div>
            <div className="confirm-place-bets-remember align-center">
              <input
                type={"checkbox"}
                // value={true}
                checked={rememberConfirmBet}
                name={"remember-confirm"}
                id={"remember_confirm"}
                onChange={onChangeShowConfirmPlaceBetsEvent}
              />
              <label htmlFor={"remember_confirm"}>
                {t("betslip.rememberChoice")}
              </label>
            </div>
          </div>

          {/* place bet button */}
          <ActionButton
            tooltip={
              betsCount === 0
                ? t("betslip.tooltip.selectBet")
                : isLowCredits
                ? t("betslip.tooltip.stakeBiggerCreds")
                : stakeAmountIsToHeight
                ? t("betslip.tooltip.stakeBiggerMaxValue")
                : stakeAmountIsToLow
                ? t("betslip.tooltip.stakeLessMinValue")
                : maxWinnPerTicketDisable
                ? t("betslip.tooltip.winExceedMaxWin")
                : ""
            }
            // onclick={
            //   (getItemFromStorage("showConfirmPlaceBets", true) === false  ||
            //   getItemFromStorage("showConfirmPlaceBets", true) === undefined) && !state.partnerWalletActive 
            //     ? () => {
            //         if (
            //           state.currentLifecycleState === lifecycleStates.BET_LOCKED 
            //         )
            //           return;
            //         setConfirmBet(true);
            //       }
            //     : onPlaceBetEventListener
            // }
            onclick={onPlaceBetEventListener}

            classesName={cn("place-bet-button", {
              inactive:
                betsCount === 0 ||
                isLowCredits ||
                stakeAmountIsToHeight ||
                stakeAmountIsToLow ||
                disablePlaceBet ||
                maxWinnPerTicketDisable ||
                state.currentLifecycleState === lifecycleStates.BET_LOCKED
            })}
            buttonBlinkDisable={
              betsCount === 0 ||
              isLowCredits ||
              stakeAmountIsToHeight ||
              maxWinnPerTicketDisable
            }
          >
            {state.currentLifecycleState === lifecycleStates.BET_LOCKED
              ? t("betslip.betLocked")
              : t("betslip.placeBet")}

            {state.currentLifecycleState ===
            lifecycleStates.BET_LOCKED ? null : (
              <span>
                (
                {`${getFloor(totalStake, 100)} ${state.gameSettings.currency} `}
                )
              </span>
            )}
          </ActionButton>
        </footer>

        {/*modal window for calculator */}
        <VirtualKeyboard
          openModalEventListener={() => toggleModal(null)}
          showModal={state.showModal === "virtualKeyboard"}
          onSetStakeAmountCalculatorListener={
            onSetStakeAmountCalculatorListener
          }
          selectedValue={stakeAmount}
        />
      </div>
    </>
  );
};

export default Betslip;
