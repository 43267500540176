import { inputsNames } from "./constants";

// returns true if value is valid
// eslint-disable-next-line
export default {
  [inputsNames.LOGIN]: value => {
    return value.length > 3;
  },
  [inputsNames.IDNUMBER]: value => {
    // disable non number
    const reg = new RegExp("^\\d+$");
    return value.length === 13 && reg.test(value);
  },
  [inputsNames.PINCODE]: value => {
    // eslint-disable-next-line
    const reg = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!~`@#$%^&*=?,_+:;{}\\-\\)\\(\\[\\]\\*])(?=.{8,})"
    );
    return reg.test(value);
  },
  [inputsNames.USERPASSWORD]: value => {
    // eslint-disable-next-line
    const reg = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!~`@#$%^&*=?,_+:;{}\\-\\)\\(\\[\\]\\*])(?=.{8,})"
    );
    return reg.test(value);
  },
  [inputsNames.EMAIL]: value => {
    // eslint-disable-next-line
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  },
  // [inputsNames.FULLNAME]: value => {
  //   return value.length > 3;
  // },
  // [inputsNames.PHONENUMBER]: value => {
  //   // return /^((?:\+27|27)|0)(=72|82|73|83|74|84|76)(\d{7})$/.test(value);
  //   return /^((?:\+27|27)|0)[6-8][0-9](\d{7})$/.test(value);
  // },
  // [inputsNames.PHYSICALADDRESS]: value => {
  //   return value.length > 3;
  // },
};
