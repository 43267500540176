import React, { useEffect, useState } from "react";

const InputTypeText = ({
  readOnly,
  onChangeEvent,
  id,
  value,
  classNames,
  onClickEvent,
  dataModal,
  dataEventId,
  dataBetType
}) => {
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  const onChangeEventListener = event => {
    const value = event.currentTarget.value;
    if (!/^\d*\.?\d*$/.test(value)) {
      setInputValue(inputValue);
      return inputValue;
    }
    setInputValue(value);
    onChangeEvent(event);
    return value;
  };
  return (
    <input
      readOnly={readOnly}
      id={id}
      onChange={onChangeEventListener}
      type="text"
      onFocus={onClickEvent}
      onClick={onClickEvent}
      data-modal={dataModal}
      data-event-id={dataEventId}
      data-bet-type={dataBetType}
      value={inputValue}
      className={classNames}
    />
  );
};

export default InputTypeText;
