import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import HistoryList from "./HistoryList";
import { useGlobalDataContext } from "../../../GlobalDataContext";
import api from "./../../utils/api";

import "./../../scss/History/index.scss";
import { lifecycleStates } from "../../utils/constants";
import { toastreact } from "../../components/ToastMessage";
import { toastMessageTypes } from "../../components/ToastMessage/constants";

const EventHistory = () => {
  const { state, dispatch } = useGlobalDataContext();
  const { t } = useTranslation();

  // eslint-disable-next-line
  const fetchAllHistory = async raceType => {
    if (!raceType) return;
    dispatch({
      type: "SET_EVENT_HISTORY_LOADING_FLAG",
      payload: true
    });
    try {
      const resJson = await api.getCurrentEventHistory(raceType);
      if (resJson.errorMsg) {
        toastreact({
          type: toastMessageTypes.ERROR,
          title: "Something went wrong with event's history fetching"
        });
      }
      dispatch({
        type: "SET_EVENT_HISTORY",
        payload: {
          raceType: raceType,
          eventHistory: resJson.errorMsg ? state.eventHistory : resJson
        }
      });
    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: "Something went wrong with event's history fetching"
      });
    }
  };

  const fetchCurrentHistory = async () => {
    if (!state.raceType) return;

    try {

      const resJson = await api.getCurrentEventHistory(state.raceType);
      if (resJson.errorMsg) {
        toastreact({
          type: toastMessageTypes.ERROR,
          title: "Something went wrong with event's history fetching"
        });

      }
      dispatch({
        type: "SET_EVENT_HISTORY",
        payload: {
          raceType: state.raceType,
          eventHistory: resJson.errorMsg ? state.eventHistory : resJson
        }
      });
    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: "Something went wrong with event's history fetching"
      });
      dispatch({
        type: "SET_EVENT_HISTORY_LOADING_FLAG",
        payload: true
      });
    }
  };
  //
  // const getAllGamesHistory = async () => {
  //   const allGames = state.allGames;
  //   return Promise.all(allGames.map(game => fetchAllHistory(game)));
  // };

  // useEffect(() => {
  //   if (state.allGames.length === 0) return;
    // getting event history data for all game types
    // getAllGamesHistory();
    // eslint-disable-next-line
  // }, [state.allGames]);

  // refresh event history on game changing
  useEffect(() => {
    fetchCurrentHistory();
    // eslint-disable-next-line
  }, [state.raceType]);

  // refresh event history if lifecycle state is DELAY
  // useEffect(() => {
  //   if (state.currentLifecycleState === lifecycleStates.DELAY)
  //     fetchCurrentHistory();
  //   // eslint-disable-next-line
  // }, [state.currentLifecycleState]);

  // refresh event history if lifecycle state is RACE and video is ended
  useEffect(() => {
    if (
      (state.currentLifecycleState === lifecycleStates.RACE || state.currentLifecycleState === lifecycleStates.DELAY) &&
      state.videoComplete
    ) {
      fetchCurrentHistory();
    }
    // eslint-disable-next-line
  }, [state.videoComplete]);

  return (
    <div className="event-history-container">
      <h2 className="top-title">{t("history.history")}</h2>

      <HistoryList
        eventHistory={state.eventHistory}
        eventHistoryLoading={state.eventHistoryLoading}
        lifecycleData={state.lifecycleData}
        raceType={state.raceType}
      />
    </div>
  );
};

export default EventHistory;
