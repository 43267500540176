import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import cn from "clsx";

import api from './../../../utils/api';
import { useGlobalDataContext } from "./../../../../GlobalDataContext";
import { toastreact } from "./../../../components/ToastMessage/index";
import { toastMessageTypes } from "./../../../components/ToastMessage/constants";

import ActionButton from "./../../../components/ActionButton";

import BasketList from "./BasketList";

import PreLoader from "../../../components/PreLoader/PreLoader";
import {lifecycleStates} from "../../../utils/constants";

const BetList = ({
  ticketHistory,
  gameSettings,
  isHistoryLoading,
  paginationData,
  fetchTicketHistory
}) => {
  const { t } = useTranslation();
  // const [state, dispatch] = useReducer(appReducer, initialState);
  // const { dispatch } = useGlobalDataContext();
  const { state, dispatch } = useGlobalDataContext();
  const [cancelTicket, setCancelTicket] = useState(false);
  let cancelTicketsInfo = document.querySelectorAll(`.ticketCancelBtn`);
  let ticketsInfo = document.querySelectorAll(`.bet-content`);

  const onFetchTicketEventListener = event => {
    if (event.currentTarget.classList.contains("disable")) return;
    fetchTicketHistory(
      paginationData.numberOfElements,
      paginationData.number + 1
    );
  };

  const onCancelTicketEventListener =  async (e) => {
    const res = await api.cancelTicket(cancelTicket);
    const currTicket = document.querySelector(`.accordion-toggle[data-index='${ticketHistory.find(x=>x.ticketNumber === cancelTicket).ticketId}']`);

    setCancelTicket(false)
    if (res.errorMsg) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("betslip.tooltip.cancelTicketError")
      });
      return
    }; 
    // update user's ballance  
    const updatedUserData = await api.getCurrentUserData();
    if (updatedUserData.errorMsg) return;
  
    dispatch({
      type: "SET_CURRENT_USER_DATA",
      payload: updatedUserData
    });
    
    toastreact({
      type: toastMessageTypes.SUCCESS,
      title: t("betslip.tooltip.cancelTicketSuccess")
    });
    currTicket.classList.add('accordion-toggle-alarm');

    for(let ticket of cancelTicketsInfo) {
      if (ticket.getAttribute('data-ticket-id') === cancelTicket) {
        ticket.style.display = 'none';
        return false;
      }
    }
  }
  useEffect(() => {
    if (state.currentLifecycleState === lifecycleStates.RACE || state.currentLifecycleState === lifecycleStates.BET_LOCKED) {
      ticketsInfo.forEach(x => {
        if(x.parentElement.getAttribute('race-number') === state.eventsOdds[0].raceNumber
            && x.getElementsByClassName('ticketCancelBtn')[0]) {
          x.getElementsByClassName('ticketCancelBtn')[0].innerHTML = '';
        }
        setCancelTicket(false)
      })
    }
    // eslint-disable-next-line
  }, [state.currentLifecycleState]);

  const showCancelTicketPopup = (e) => {
    setCancelTicket(e.currentTarget.getAttribute('data-ticket-id'));
  }

  return (
    <>
      <div>
        {ticketHistory.length > 0 && (
          <>
            <BasketList
              shopAddress={gameSettings.addressHeader}
              currency={gameSettings.currency}
              paginationData={paginationData}
              items={ticketHistory}
              showCancelTicketPopup={showCancelTicketPopup}
            />
            {paginationData.totalPages > 1 && (
              <section>
                <div
                  id="loadMoreTicketId"
                  data-page-size={paginationData.numberOfElements}
                  data-page-number={paginationData.number}
                  className={`action-button align-center ${
                    paginationData.last ? "disable" : ""
                  }`}
                  style={{
                    margin: "3px -4px 0",
                    backgroundColor: "#f8f8f8",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    position: "absolute"
                  }}
                  onClick={onFetchTicketEventListener}
                  disable={paginationData.last ? "disable" : ""}
                >
                  {t("betlist.loadMore")}
                  <div className="button-flash-line"></div>
                </div>
              </section>
            )}
          </>
        )}
        {!isHistoryLoading && ticketHistory.length === 0 && (
          <div
            className="empty-bet-list align-center"
            style={{ marginTop: "30px" }}
          >
            {t("betlist.emptyListMsg")}
          </div>
        )}
        {/*show preloader if there is no bet in bet history list*/}
        {isHistoryLoading && (
          <PreLoader
            isLoading={true}
            isLightPreLoader={true}
            showLoadingText={false}
          />
        )}
      </div>

      <div
        className={cn("confirm-place-bets", {
          show: cancelTicket
        })}
      >
        <h4 className="confirm-place-bets-text align-center">
          {t("cancelTicketPopup.msg")}
        </h4>
        <div className="confirm-place-bets-btns align-center">
          <ActionButton onclick={onCancelTicketEventListener}>
            <span>{t("cancelTicketPopup.yes")}</span>
          </ActionButton>
          <ActionButton onclick={()=>setCancelTicket(false)}>
            <span>{t("cancelTicketPopup.no")}</span>
          </ActionButton>
        </div>
      </div>
    </>
    
  );
};

export default BetList;
