import React, { useState } from "react";

import { useGlobalDataContext } from "../../../GlobalDataContext";
import { useTranslation } from "react-i18next";
// ALL modal windows
import AddCreditsModal from "./AddCreditsModal";
import WithdrawMoneyModal from "./WithdrawMoneyModal";
import ShowWithdrawMoneyCodeModal from "./ShowWithdrawMoneyCodeModal";
import GameSelectModal from "./GameSelectModal";
import UserCredsSetUpModal from "./UserCredsSetUpModal";
import UserPasswordUpdateModal from "./UserPasswordUpdateModal";
import UserAccountCloseModal from "./UserAccountCloseModal";
import DeleteBetsBetslipModal from "./DeleteBetsBetslipModal";
import AddCreditsTerminalZeroBalance from "./AddCreditsTerminalZeroBalance";
import IframeTokenDataErrorModal from "./IframeTokenDataErrorModal";
import JackpotWinnerModal from "./JackpotWinnerModal";

import ReactTooltipWrapper from "../Tooltip";
import api from "../../utils/api";
import { isPasswordExpired } from "./../../utils/helpers";
import { LOGOUT_URI, statuses } from "../../utils/constants";
import { toastreact } from "../ToastMessage";
import { toastMessageTypes } from "../ToastMessage/constants";
import { removeTokenData } from "../Authorization/TokenServiceStorage";
import {
  getItemFromStorage,
  removeItemFromStorage, setItemInStorage
} from "../../utils/LocalStorageService";
import { eraseCookie } from "../../utils/CookieService";
import LogoutInfoAndWithdrawModal from "./LogoutInfoAndWithdrawModal";
import InternalErrorMessageModal from "./InternalErrorMessageModal";
import { getActiveBetsObject } from "../../containers/Bets/helpers";
import {ChangeGame} from "../../mixins/ChangeGame";

const ModalWindows = ({
  toggleModal,
  setExpiredRaceIds,
  expiredRaceIds,
  fetchCurrentUserData,
  toggleLoading
}) => {
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalDataContext();

  const [isEmailExists, setIsEmailExists] = useState(false); // email is already exists error
  const [isUserNameExists, setIsUserNameExists] = useState(false); // email is already exists error before user data change submit

  const [applyVoucherError, setApplyVoucherError] = useState(false);

  // open modal window
  const openModalEventListener = event => {
    const modalHref = event.currentTarget.getAttribute("data-modal");
    if (modalHref) {
      toggleModal(modalHref);
    } else {
      setIsUserNameExists(false);
      setIsEmailExists(false);
      setApplyVoucherError(false);
      toggleModal(null);
    }
  };

  // activate voucher for add credits in user account (ActivateVoucherModal modal window)
  const activateVoucherEventListener = async (
    voucherId,
    clearAutologoutTimer
  ) => {
    // for terminal version after voucher activated clear timeout for autologout redirect
    if (clearAutologoutTimer) {
      clearTimeout(state.logoutRedirectTimeoutTerminal);
      dispatch({
        type: "SET_LOGOUT_TIMEOUT_TERMINAL",
        payload: null
      });
    }
    try {
      // eslint-disable-next-line
      const res = await api.activateVoucher({
        voucherId: voucherId,
        portalKey: state.gameSettings.portalKey
      });

      if (!res.errorMsg) {
        toggleModal(null);
        toastreact({
          type: toastMessageTypes.SUCCESS,
          title: t("modalWindows.addCreditsModal.voucherActivated")
        });
        fetchCurrentUserData();
      } else {
        setApplyVoucherError(true);
        toastreact({
          type: toastMessageTypes.ERROR,
          title: res.errorMsg.indexOf('Please, use a different voucher.') !== -1 ? t("modalWindows.addCreditsModal.voucherAlreadyActivatedError") : res.errorMsg
        });
      }
    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("modalWindows.addCreditsModal.smthWrongVaucher")
      });
    }
  };

  // withdraw money (WithdrawMoneyModal) modal window
  const withdrawMoneyEventListener = async withdrawMoneyValue => {
    try {
      const resJson = await api.generateWithdrawCode({
        amount: withdrawMoneyValue,
        portalKey: state.gameSettings.portalKey
      });

      if (!resJson.errorMsg) {
        dispatch({
          type: "SET_WITHDRAW_MONEY_CODE",
          payload: resJson.id.code
        });
        toggleModal("showWithdrawMoneyCode");
        fetchCurrentUserData();
      } else {
        toastreact({
          type: toastMessageTypes.ERROR,
          title: resJson.errorMsg
        });
      }
    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("smthWentWrongRequest")
      });
    }
  };

  // change game event listener(in gameSelectModal modal window)
  const onChangeGameEventListener = event => {
    const target = event.currentTarget;
    const newGameIdentifier = target.getAttribute("data-identifier");
    const allRaceTypeData = getItemFromStorage("allRaceTypeData", true);

    ChangeGame(
      newGameIdentifier,
      state,
      dispatch,
      allRaceTypeData,
      getActiveBetsObject,
      toggleModal,
      setExpiredRaceIds,
      setItemInStorage)

    toggleModal(null);
  };
  // save user creds in user creds(saveUserCreds) modal window
  const onSaveUserCreds = async creds => {
    try {
      // check if username is already exists and if yes than do not save user's data
      const isLoginExist = await api.checkExistsUserLogin(creds.userName);
      // check if email is already exists and if yes than do not save user's data
      const isEmailExist = await api.checkExistsUserMail(creds.email);
      if (
        isLoginExist.status === statuses.statusOk ||
        isEmailExist.status === statuses.statusOk
      ) {
        // username exists
        if (isLoginExist.status === statuses.statusOk) {
          setIsUserNameExists(true);
          toastreact({
            type: toastMessageTypes.ERROR,
            title: t("modalWindows.userCredsModal.userNameExists")
          });
        }
        // email exists
        if (isEmailExist.status === statuses.statusOk) {
          setIsEmailExists(true);
          toastreact({
            type: toastMessageTypes.ERROR,
            title: t("modalWindows.userCredsModal.emailExists")
          });
        }
        return;
      }

      // api request with all user's credentials
      // eslint-disable-next-line
      const res = await api.updateUserData(creds);

      if (!res.errorMsg) {
        removeTokenData();
        toastreact({
          type: toastMessageTypes.SUCCESS,
          title: t("modalWindows.userCredsModal.userDataSavedSuccess")
        });
        toggleLoading(true);
        toggleModal(null);
        fetchCurrentUserData();
      } else {
        if (res.status === statuses.clientError) {
          // setSaveCredsError(true);
          toastreact({
            type: toastMessageTypes.ERROR,
            title: res.errorMsg
          });
        }
      }
    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("modalWindows.userCredsModal.smthWrongDataSave")
      });
    }
  };
  // update user password in update user password modal window
  const onUpdateUserPassword = async creds => {
    try {
      // api request with all user's credentials
      // eslint-disable-next-line
      const res = await api.updateUserPassword(creds);
      if (res.status === statuses.statusOk) {
        toastreact({
          type: toastMessageTypes.SUCCESS,
          title: t("modalWindows.pswUpdateModal.passwordUpdateSuccess")
        });
        setTimeout(() => {
          removeTokenData();
        }, 1500);
        toggleLoading(true);
        toggleModal(null);
        fetchCurrentUserData();
      } else {
        if (res.status === statuses.clientError) {
          return res.text();
        }
      }
    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("modalWindows.pswUpdateModal.smthWrongPswUpdate")
      });
    }
  };
  // close user account in close user account modal window
  const onCloseUserAccount = async () => {
    try {
      // api request with user id
      // eslint-disable-next-line
      const res = await api.closeUserAccount(state.userData.id);
      if (res.status === statuses.statusOk) {
        toastreact({
          type: toastMessageTypes.SUCCESS,
          title: t("modalWindows.closeUserModal.closeAccSuccess")
        });
        toggleLoading(true);
        toggleModal(null);
        setTimeout(() => {
          removeItemFromStorage("idsPlacedEvents", true);
          removeTokenData();
          removeItemFromStorage("gameIdentifier");
          eraseCookie("JSESSIONID", "/", window.location.origin);
          window.location.href = LOGOUT_URI;
        }, 1000);
      } else {
        if (res.status === statuses.clientError) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: res.errorMsg
          });
        }
      }
    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: t("modalWindows.closeUserModal.smthWrongCloseAcc")
      });
    }
  };

  // logout user if he click on Logout btn in zerro credits modal window
  const logoutEventHandler = () => {
    removeItemFromStorage("idsPlacedEvents", true);
    removeTokenData();
    removeItemFromStorage("gameIdentifier");
    eraseCookie("JSESSIONID", "/", "terminalza.mohiogaming.com");
    setTimeout(() => {
      window.location.href = `${LOGOUT_URI}?origin=terminal`;
    }, 1000);
  };
  const closeWithdrawMoneyCodeModal = () => {
    toggleModal(null);
    dispatch({
      type: "SET_WITHDRAW_MONEY_CODE",
      payload: null
    });
  };

  // focuse on username input
  const onFocuseUsernameInput = () => {
    setIsUserNameExists(false);
  };
  return (
    <>
      {/*modal window for voucher activating*/}
      <AddCreditsModal
        showModal={state.showModal === "voucher"}
        openModalEventListener={openModalEventListener}
        activateVoucher={activateVoucherEventListener}
        isWebGameVersion={state.isWebGameVersion}
        applyVoucherError={applyVoucherError}
        setApplyVoucherError={setApplyVoucherError}
      />

      {/*modal window for add credits by user with zero balance of Terminal version*/}
      <AddCreditsTerminalZeroBalance
        showModal={state.showModal === "addCreditsTerminalZeroBalance"}
        openModalEventListener={openModalEventListener}
        activateVoucher={activateVoucherEventListener}
        isWebGameVersion={state.isWebGameVersion}
        applyVoucherError={applyVoucherError}
        setApplyVoucherError={setApplyVoucherError}
        logoutCountdown={state.logoutRedirectTimeoutTerminal}
        logoutEventHandler={logoutEventHandler}
      />
      {/*modal window for withdraw money request*/}
      <WithdrawMoneyModal
        showModal={state.showModal === "withdrawMoney"}
        openModalEventListener={openModalEventListener}
        withdrawMoneyEventListener={withdrawMoneyEventListener}
        withdrawMoneyCode={state.selfData.withdrawMoneyCode}
        isWebGameVersion={state.isWebGameVersion}
        creditsBalance={
          state.userData ? state.userData.createWalletDTO.credits : 0
        }
        currency={
          state.gameSettings.currency ? state.gameSettings.currency : ""
        }
        threshold={9999999999}
      />
      {/*modal window for logout and withdraw money request*/}
      <LogoutInfoAndWithdrawModal
        showModal={state.showModal === "logoutWithdrawMoney"}
        openModalEventListener={openModalEventListener}
        withdrawMoneyEventListener={withdrawMoneyEventListener}
        withdrawMoneyCode={state.selfData.withdrawMoneyCode}
        isWebGameVersion={state.isWebGameVersion}
        creditsBalance={
          state.userData ? state.userData.createWalletDTO.credits : 0
        }
        currency={
          state.gameSettings.currency ? state.gameSettings.currency : ""
        }
      />

      {/*modal window for withdraw money request*/}
      <ShowWithdrawMoneyCodeModal
        showModal={state.showModal === "showWithdrawMoneyCode"}
        openModalEventListener={openModalEventListener}
        withdrawMoneyCode={state.selfData.withdrawMoneyCode}
        closeWithdrawMoneyCodeModal={closeWithdrawMoneyCodeModal}
        isWebGameVersion={state.isWebGameVersion}
        cashierAddress={state.gameSettings.addressHeader}
        credits={state.userData ? state.userData.createWalletDTO.credits : 0}
      />

      {/*modal window for gameSelect */}
      <GameSelectModal
        allGames={state.rbi === true ? state.rbiGames : state.allGames}
        onChangeGameEventListener={onChangeGameEventListener}
        showModal={state.showModal === "gameSelectModal"}
      />

      {/*modal window for save user creds */}
      <UserCredsSetUpModal
        userData={state.userData}
        onSaveUserCreds={onSaveUserCreds}
        showModal={state.showModal === "saveUserCreds"}
        ReactTooltip={ReactTooltipWrapper}
        isWebGameVersion={state.isWebGameVersion}
        // saveCredsError={saveCredsError}
        onFocuseUsernameInput={onFocuseUsernameInput}
        isEmailExists={isEmailExists}
        isUserNameExists={isUserNameExists}
        onFocuseEmailInput={() => {
          setIsEmailExists(false);
        }}
      />

      {/*modal window for update user password */}
      <UserPasswordUpdateModal
        userData={state.userData}
        onUpdateUserPassword={onUpdateUserPassword}
        openModalEventListener={openModalEventListener}
        showModal={state.showModal === "updateUserPassword"}
        ReactTooltip={ReactTooltipWrapper}
        isWebGameVersion={state.isWebGameVersion}
        isPasswordExpired={
          state.isWebGameVersion && state.userData
            ? isPasswordExpired(state.userData.daysToPasswordExpiring)
            : {}
        }
      />

      {/*modal window for close user account*/}
      <UserAccountCloseModal
        showModal={state.showModal === "closeUserAccount"}
        openModalEventListener={openModalEventListener}
        onCloseUserAccount={onCloseUserAccount}
      />

      {/*modal window for jackpot winner */}
      <JackpotWinnerModal
        showModal={state.showModal === "jackpotWinner"}
        openModalEventListener={openModalEventListener}
        jackpotWinnerEventListener={() => toggleModal(null)}
        jackpotName={
          state.superJackpotStatus && state.superJackpotStatus.fallen
            ? "SUPER JACKPOT"
            : "JACKPOT"
        }
        jackpotCode={
          state.superJackpotStatus
            ? state.superJackpotStatus.jackpotCode
            : state.jackpotStatus.jackpotCode
        }
        jackpotCurrency={
          state.superJackpotStatus
            ? state.superJackpotStatus.jackpotCurrency
            : state.jackpotStatus.jackpotCurrency
        }
        jackpotDate={
          state.superJackpotStatus
            ? state.superJackpotStatus.timestamp
            : state.jackpotStatus.timestamp
        }
        jackpotAmount={
          state.superJackpotStatus
            ? state.superJackpotStatus.wonAmount
            : state.jackpotStatus.wonAmount
        }
      />

      {/*modal window for save user creds */}
      <DeleteBetsBetslipModal
        openModalEventListener={() => {
          setExpiredRaceIds([]);
          toggleModal(null);
        }}
        showModal={state.showModal === "showInfoExpiredEvents"}
        expiredRaceIds={expiredRaceIds}
      />
      {/* modal window with error message after request return 500 error */}
      <InternalErrorMessageModal
        showModal={state.showModal === "internalErrorModal"}
      />

      {/* if token data for iframe window got an error than show this modal */}
      <IframeTokenDataErrorModal
          showModal={state.showModal === "iframeTokenDataErrorModal"}
      />
    </>
  );
};

export default ModalWindows;
