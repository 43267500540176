import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import Accordion from "../../components/Accordion/Accordion";
import HistoryContent from "./HistoryContent";

import { Scrollbars } from "react-custom-scrollbars";
import { getObjectForAccordionsOpenState } from "../../components/Accordion/helpers";
import PreLoader from "../../components/PreLoader/PreLoader";
import { lifecycleStates } from "../../utils/constants";

function HistoryList({
  eventHistory,
  eventHistoryLoading,
  lifecycleData
}) {
  const {t} = useTranslation();
  // set the height of history content after page render
  useEffect(() => {
    setAutoHeightMaxScrollbar(
      document.querySelector(".basket")
        ? document.querySelector(".basket").offsetHeight * 0.9
        : 200
    );
  }, []);

  const onGetEventHistoryEventListener = event => {
    const raceIndex = event.currentTarget.getAttribute("data-index");

    const openEventsCopy = { ...openEvents };
    openEventsCopy[raceIndex] = !openEventsCopy[raceIndex];
    setOpenEvents(openEventsCopy);
  };

  // state for saving open state of each accordion
  const [openEvents, setOpenEvents] = useState(
    getObjectForAccordionsOpenState(eventHistory)
  );

  const [autoHeightMaxScrollBar, setAutoHeightMaxScrollbar] = useState(1000);

  return (
    <Scrollbars autoHeight autoHeightMin={autoHeightMaxScrollBar}>
      {eventHistoryLoading && (
        <PreLoader
          showLoadingText={false}
          isLoading={true}
          showInParent={true}
        />
      )}
      {eventHistory &&
        eventHistory
          .filter(event => {
            if (
              lifecycleData &&
              lifecycleData.state === lifecycleStates.RACE &&
              lifecycleData.result
            )
              return event.raceIndex !== lifecycleData.result.raceIndex;
            else return event;
          })
          .map((event, i) => {
            return (
              <Fragment key={i}>
                <Accordion
                  isOpen={openEvents[event.raceIndex]}
                  onToggle={onGetEventHistoryEventListener}
                  heading={`${t("event")} ${event.raceIndex}`}
                  secondaryHeading={event.timeStartRace}
                  dataIndex={event.raceIndex}
                />
                <div
                  className="collapse-css-transition"
                  aria-hidden={openEvents[event.raceIndex] ? "false" : "true"}
                  style={{
                    overflow: "hidden",
                    height: openEvents[event.raceIndex] ? 350 : 0,
                    visibility: openEvents[event.raceIndex] ? null : "hidden"
                  }}
                >
                  <HistoryContent results={event} />
                </div>
              </Fragment>
            );
          })}
      {/*{eventHistory.length === 0 && !eventHistoryLoading && (*/}
      {/*  <div className="empty-bet-list align-center">*/}
      {/*    History is loading...*/}
      {/*  </div>*/}
      {/*)}*/}
    </Scrollbars>
  );
}

export default HistoryList;
