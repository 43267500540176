/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from "immer";
import { betTypesForBetsContainer } from "./../../utils/constants";
import { DELAY, BET, BET_LOCKED, JACKPOT, RACE } from "./constantActions";
import { getActualFutureEvents } from "./../Bets/helpers";
import { isMobile } from "../../utils/helpers";
import {
  getItemFromStorage,
  setItemInStorage,
} from "../../utils/LocalStorageService";

// The initial state of the App
export const initialState = {
  partnerWalletActive: false,
  globalCountdownTimer: 0,
  eventHistoryLoading: false,
  logoutRedirectTimeoutTerminal: null,
  idsPlacedEvents: [],
  lifecyclesConfig: {},
  futureEvents: {},
  showModal: null,
  identifier: null,
  raceType: null,
  jackPot: 0,
  superJackPot: 0,
  isWebGameVersion: process.env.REACT_APP_TERMINAL_VERSION === "FALSE",
  isDemoGameVersion: false,
  lifecycleData: null, // data object returned from server
  currentLifecycleState: null,
  eventsOdds: [], // events with its odds
  jackpotStatus: {}, // jackpot data object returned from server
  superJackpotStatus: {}, // jackpot data object returned from server
  video: {
    videoSizeStatus: {
      tabContainer: {
        height: isMobile.any()
          ? window.innerHeight * 0.65
          : window.innerHeight < 900
          ? window.innerHeight * 0.37
          : "29rem",
      },
      videoContainer: { width: "100%", height: "100%" },
    },
    videoMute: false,
  },

  activeBetType: Object.keys(betTypesForBetsContainer)[0],
  slideAsideBlockId: null,
  eventHistory: [],
  betHistory: [],
  creditsHistory: [],
  withdrawHistory: [],
  timeStampVideo: 0,
  videoProgressLine: 0,
  videoName: null,
  videoComplete: false,
  videoUrl: "",
  betsObject: {}, // array of bets(this item use for betslip render in basket) key - bet type, value - object with bet data
  loginData: {
    isLoggedIn: false,
    refreshToken: null,
    accessToken: null,
    expiresIn: 0,
  },
  allGames: [],
  rbiGames: [],
  gameSettings: {},
  selfData: {
    state: null,
    walletAmount: 100,
    walletCurrency: "USD",
    withdrawMoneyCode: null,
  },
  userData: null,
  rbi: false,
  preActionLoader: false, // set true to show information preloader with text,
  useVirtualKeyboard: false, // set true to use virtual keyboard in inputs
};

const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case BET:
        const actualFutureEvents = getActualFutureEvents({
          futureEvents: [...state.futureEvents[action.payload.raceType]],
          payload: { ...action.payload },
        });
        const actualEventsBet = [{ ...action.payload }, ...actualFutureEvents];
        let allRaceTypeDataBet = getItemFromStorage("allRaceTypeData", true);
        if (allRaceTypeDataBet) {
          if (allRaceTypeDataBet[action.payload.raceType]) {
            allRaceTypeDataBet[action.payload.raceType].lifecycleData = {
              ...action.payload,
              timestamp: Date.now(),
            };
            allRaceTypeDataBet[
              action.payload.raceType
            ].eventsOdds = actualEventsBet;
            setItemInStorage("allRaceTypeData", allRaceTypeDataBet, true);
          } else {
            allRaceTypeDataBet[action.payload.raceType] = {};
            allRaceTypeDataBet[action.payload.raceType].lifecycleData = {
              ...action.payload,
              timestamp: Date.now(),
            };
            allRaceTypeDataBet[
              action.payload.raceType
            ].eventsOdds = actualEventsBet;
            setItemInStorage("allRaceTypeData", allRaceTypeDataBet, true);
          }
        } else {
          allRaceTypeDataBet = {};
          allRaceTypeDataBet[action.payload.raceType] = {};
          allRaceTypeDataBet[action.payload.raceType].lifecycleData = {
            ...action.payload,
            timestamp: Date.now(),
          };
          allRaceTypeDataBet[
            action.payload.raceType
          ].eventsOdds = actualEventsBet;
          setItemInStorage("allRaceTypeData", allRaceTypeDataBet, true);
        }

        if (action.payload.raceType === state.raceType) {
          draft.eventsOdds = actualEventsBet;
          draft.currentLifecycleState = action.payload.state;
          draft.lifecycleData = {
            ...action.payload,
            timestamp: Date.now(),
          };
          draft.videoComplete = false;
        }
        draft.futureEvents = {
          ...state.futureEvents,
          [action.payload.raceType]: actualFutureEvents,
        };

        break;
      case BET_LOCKED:
        const eventsOddsBetLocked = [
          { ...action.payload, raceNumber: action.payload.raceIndex },
          ...getActualFutureEvents({
            futureEvents: [...state.futureEvents[action.payload.raceType]],
            payload: {
              ...action.payload,
              raceNumber: action.payload.raceIndex,
            },
          }),
        ];

        if (action.payload.raceType === state.raceType) {
          draft.videoName = action.payload.videoName;
          draft.videoComplete = false;
          draft.currentLifecycleState = action.payload.state;
          draft.lifecycleData = {
            ...action.payload,
            timestamp: Date.now(),
          };
          draft.eventsOdds = eventsOddsBetLocked;
        }

        let allRaceTypeDataLocked = getItemFromStorage("allRaceTypeData", true);
        if (allRaceTypeDataLocked) {
          if (allRaceTypeDataLocked[action.payload.raceType]) {
            allRaceTypeDataLocked[action.payload.raceType].lifecycleData = {
              ...action.payload,
              timestamp: Date.now(),
            };
            allRaceTypeDataLocked[
              action.payload.raceType
            ].eventsOdds = eventsOddsBetLocked;
            allRaceTypeDataLocked[action.payload.raceType].videoName =
              action.payload.videoName;
            allRaceTypeDataLocked[
              action.payload.raceType
            ].videoComplete = false;
            setItemInStorage("allRaceTypeData", allRaceTypeDataLocked, true);
          } else {
            allRaceTypeDataLocked[action.payload.raceType] = {};
            allRaceTypeDataLocked[action.payload.raceType].lifecycleData = {
              ...action.payload,
              timestamp: Date.now(),
            };
            allRaceTypeDataLocked[
              action.payload.raceType
            ].eventsOdds = eventsOddsBetLocked;
            allRaceTypeDataLocked[action.payload.raceType].videoName =
              action.payload.videoName;
            allRaceTypeDataLocked[
              action.payload.raceType
            ].videoComplete = false;
            setItemInStorage("allRaceTypeData", allRaceTypeDataLocked, true);
          }
        } else {
          allRaceTypeDataLocked = {};
          allRaceTypeDataLocked[action.payload.raceType] = {};
          allRaceTypeDataLocked[action.payload.raceType].lifecycleData = {
            ...action.payload,
            timestamp: Date.now(),
          };
          allRaceTypeDataLocked[
            action.payload.raceType
          ].eventsOdds = eventsOddsBetLocked;
          allRaceTypeDataLocked[action.payload.raceType].videoName =
            action.payload.videoName;
          allRaceTypeDataLocked[action.payload.raceType].videoComplete = false;
          setItemInStorage("allRaceTypeData", allRaceTypeDataLocked, true);
        }

        break;
      case RACE:
        const eventsOddsRace = [
          { ...action.payload, raceNumber: action.payload.result.raceIndex },
          ...getActualFutureEvents({
            futureEvents: state.futureEvents[action.payload.raceType],
            payload: {
              ...action.payload,
              raceNumber: action.payload.result.raceIndex,
            },
          }),
        ];

        if (action.payload.raceType === state.raceType) {
          draft.currentLifecycleState = action.payload.state;
          draft.lifecycleData = {
            ...action.payload,
            timestamp: Date.now(),
          };
          draft.timeStampVideo = action.payload.timestamp;
          draft.videoProgressLine =
            (Number(Date.now()) - action.payload.timestamp) / 1000;
          draft.eventsOdds = eventsOddsRace;
          draft.videoName = action.payload.videoName;
        }

        let allRaceTypeDataRace = getItemFromStorage("allRaceTypeData", true);
        if (allRaceTypeDataRace) {
          if (allRaceTypeDataRace[action.payload.raceType]) {
            allRaceTypeDataRace[action.payload.raceType].lifecycleData = {
              ...action.payload,
              timestamp: Date.now(),
            };
            allRaceTypeDataRace[
              action.payload.raceType
            ].eventsOdds = eventsOddsRace;
            allRaceTypeDataRace[action.payload.raceType].timeStampVideo =
              action.payload.timestamp;
            allRaceTypeDataRace[action.payload.raceType].videoProgressLine =
              (Number(Date.now()) - action.payload.timestamp) / 1000;
            setItemInStorage("allRaceTypeData", allRaceTypeDataRace, true);
          } else {
            allRaceTypeDataRace[action.payload.raceType] = {};
            allRaceTypeDataRace[action.payload.raceType].lifecycleData = {
              ...action.payload,
              timestamp: Date.now(),
            };
            allRaceTypeDataRace[
              action.payload.raceType
            ].eventsOdds = eventsOddsRace;
            allRaceTypeDataRace[action.payload.raceType].timeStampVideo =
              action.payload.timestamp;
            allRaceTypeDataRace[action.payload.raceType].videoProgressLine =
              (Number(Date.now()) - action.payload.timestamp) / 1000;
            setItemInStorage("allRaceTypeData", allRaceTypeDataRace, true);
          }
        } else {
          allRaceTypeDataLocked = {};
          allRaceTypeDataLocked[action.payload.raceType] = {};
          allRaceTypeDataRace[action.payload.raceType].lifecycleData = {
            ...action.payload,
            timestamp: Date.now(),
          };
          allRaceTypeDataRace[
            action.payload.raceType
          ].eventsOdds = eventsOddsRace;
          allRaceTypeDataRace[action.payload.raceType].timeStampVideo =
            action.payload.timestamp;
          allRaceTypeDataRace[action.payload.raceType].videoProgressLine =
            (Number(Date.now()) - action.payload.timestamp) / 1000;
          setItemInStorage("allRaceTypeData", allRaceTypeDataLocked, true);
        }

        break;
      case DELAY:
        const eventsOddsDelay = [
          { ...action.payload, raceNumber: action.payload.result.raceIndex },
          ...getActualFutureEvents({
            futureEvents: state.futureEvents[action.payload.raceType],
            payload: {
              ...action.payload,
              raceNumber: action.payload.result.raceIndex,
            },
          }),
        ];

        if (action.payload.raceType === state.raceType) {
          draft.currentLifecycleState = action.payload.state;
          draft.lifecycleData = {
            ...action.payload,
            timestamp: Date.now(),
          };
          draft.videoName = null;
          draft.videoComplete = true;
          draft.timeStampVideo = 0;
          draft.videoProgressLine = 0;
          draft.eventsOdds = eventsOddsDelay;
        }

        let allRaceTypeDataDelay = getItemFromStorage("allRaceTypeData", true);
        if (allRaceTypeDataDelay) {
          if (allRaceTypeDataDelay[action.payload.raceType]) {
            allRaceTypeDataDelay[action.payload.raceType].lifecycleData = {
              ...action.payload,
              timestamp: Date.now(),
            };
            allRaceTypeDataDelay[
              action.payload.raceType
            ].eventsOdds = eventsOddsDelay;
            allRaceTypeDataDelay[action.payload.raceType].timeStampVideo = 0;
            allRaceTypeDataDelay[action.payload.raceType].videoProgressLine = 0;
            allRaceTypeDataDelay[action.payload.raceType].videoName = null;
            allRaceTypeDataDelay[action.payload.raceType].videoComplete = false;
            setItemInStorage("allRaceTypeData", allRaceTypeDataDelay, true);
          } else {
            allRaceTypeDataDelay[action.payload.raceType] = {};
            allRaceTypeDataDelay[action.payload.raceType].lifecycleData = {
              ...action.payload,
              timestamp: Date.now(),
            };
            allRaceTypeDataDelay[
              action.payload.raceType
            ].eventsOdds = eventsOddsDelay;
            allRaceTypeDataDelay[action.payload.raceType].timeStampVideo = 0;
            allRaceTypeDataDelay[action.payload.raceType].videoProgressLine = 0;
            allRaceTypeDataDelay[action.payload.raceType].videoName = null;
            allRaceTypeDataDelay[action.payload.raceType].videoComplete = false;
            setItemInStorage("allRaceTypeData", allRaceTypeDataDelay, true);
          }
        } else {
          allRaceTypeDataDelay = {};
          allRaceTypeDataDelay[action.payload.raceType] = {};
          allRaceTypeDataDelay[action.payload.raceType].lifecycleData = {
            ...action.payload,
            timestamp: Date.now(),
          };
          allRaceTypeDataDelay[
            action.payload.raceType
          ].eventsOdds = eventsOddsDelay;
          allRaceTypeDataDelay[action.payload.raceType].timeStampVideo = 0;
          allRaceTypeDataDelay[action.payload.raceType].videoProgressLine = 0;
          allRaceTypeDataDelay[action.payload.raceType].videoName = null;
          allRaceTypeDataDelay[action.payload.raceType].videoComplete = false;
          setItemInStorage("allRaceTypeData", allRaceTypeDataDelay, true);
        }

        break;
      case JACKPOT:
        if (action.payload.raceType === state.raceType) {
          draft.jackpotStatus = action.payload.jackpotState;
          draft.superJackpotStatus = action.payload.superJackpotState;
        }
        if (draft[action.payload.raceType]) {
          draft[action.payload.raceType].jackpotStatus =
            action.payload.jackpotState;
          draft[action.payload.raceType].superJackpotStatus =
            action.payload.superJackpotState;
        } else {
          draft[action.payload.raceType] = {};
          draft[action.payload.raceType].jackpotStatus =
            action.payload.jackpotState;
          draft[action.payload.raceType].superJackpotStatus =
            action.payload.superJackpotState;
        }

        break;

      /**
       * dispatch if need hide user interface elements for iframe solution and if user was created on the partner's side and use theirs wallet
       * */
      case "SET_PARENT_WALLET_USING":
        draft.partnerWalletActive = action.payload;
        break;
      /**
       * set demo game
       */
      case "SET_DEMO_GAME_VERSION":
        draft.isDemoGameVersion = action.payload;
        break;
      /**
       * set countdown timer for current race type for BET lifecycle state
       * */
      case "SET_COUNTDOWN_TIMER":
        draft.globalCountdownTimer = action.payload;
        break;
      /**
       * save array of future events
       * */
      case "SAVE_FUTURE_EVENTS":
        draft.futureEvents = action.payload;
        break;

      /**
       * save LIFECYCLE configuration
       * */
      case "SAVE_LIFECYCLE_CONFIG":
        draft.lifecyclesConfig = action.payload;
        break;
      /**
       * set modal window name and show it
       * */
      case "OPEN_MODAL_WINDOW":
        draft.showModal = action.payload;
        break;
      /**
       * set video data
       * */
      case "SET_VIDEO_DATA":
        draft.videoName = action.payload.videoName;
        draft.timeStampVideo = action.payload.timeStampVideo;
        draft.videoProgressLine = action.payload.videoProgressLine;
        break;

      /**
       * set video size
       * */
      case "SET_VIDEO_SIZE":
        draft.video.videoSizeStatus = action.payload;
        break;

      /**
       * set video mute
       * */
      case "SET_VIDEO_MUTE":
        draft.video.videoMute = action.payload;
        break;

      /**
       * set video complete
       * */
      case "SET_VIDEO_COMPLETE":
        if (action.payload.raceType === state.raceType) {
          draft.videoComplete = action.payload.videoComplete;
        }

        let allRaceTypeDataVideoComplete = getItemFromStorage(
          "allRaceTypeData",
          true
        );
        if (allRaceTypeDataVideoComplete) {
          if (allRaceTypeDataVideoComplete[action.payload.raceType]) {
            allRaceTypeDataVideoComplete[
              action.payload.raceType
            ].videoComplete = action.payload.videoComplete;
            setItemInStorage(
              "allRaceTypeData",
              allRaceTypeDataVideoComplete,
              true
            );
          } else {
            allRaceTypeDataVideoComplete[action.payload.raceType] = {};
            allRaceTypeDataVideoComplete[
              action.payload.raceType
            ].videoComplete = action.payload.videoComplete;
            setItemInStorage(
              "allRaceTypeData",
              allRaceTypeDataVideoComplete,
              true
            );
          }
        } else {
          allRaceTypeDataVideoComplete = {};
          allRaceTypeDataVideoComplete[action.payload.raceType] = {};
          allRaceTypeDataVideoComplete[action.payload.raceType].videoComplete =
            action.payload.videoComplete;
          setItemInStorage(
            "allRaceTypeData",
            allRaceTypeDataVideoComplete,
            true
          );
        }

        break;

      /**
       * set video progress line
       * */
      case "SET_VIDEO_PROGRESS":
        if (action.payload.raceType === state.raceType) {
          draft.videoProgressLine = action.payload.videoProgressLine;
        }

        let allRaceTypeDataVideoProgressLine = getItemFromStorage(
          "allRaceTypeData",
          true
        );
        if (allRaceTypeDataVideoProgressLine) {
          if (allRaceTypeDataVideoProgressLine[action.payload.raceType]) {
            allRaceTypeDataVideoProgressLine[
              action.payload.raceType
            ].videoProgressLine = action.payload.videoProgressLine;
            setItemInStorage(
              "allRaceTypeData",
              allRaceTypeDataVideoProgressLine,
              true
            );
          } else {
            allRaceTypeDataVideoProgressLine[action.payload.raceType] = {};
            allRaceTypeDataVideoProgressLine[
              action.payload.raceType
            ].videoProgressLine = action.payload.videoProgressLine;
            setItemInStorage(
              "allRaceTypeData",
              allRaceTypeDataVideoProgressLine,
              true
            );
          }
        } else {
          allRaceTypeDataVideoProgressLine = {};
          allRaceTypeDataVideoProgressLine[action.payload.raceType] = {};
          allRaceTypeDataVideoProgressLine[
            action.payload.raceType
          ].videoProgressLine = action.payload.videoProgressLine;
          setItemInStorage(
            "allRaceTypeData",
            allRaceTypeDataVideoProgressLine,
            true
          );
        }

        break;

      /**
       * set url for playing video
       * */
      case "SET_VIDEO_URL":
        draft.videoUrl = action.payload;
        break;

      /**
       * set new bets in betsArray
       * */
      case "ADD_BET_IN_CART":
        draft.betsObject = { ...action.payload };
        break;

      /**
       * set data for current bet basket
       * */
      case "SET_BET_HISTORY":
        draft.betHistory = [...action.payload];
        break;

      /**
       * set data for credits history
       * */
      case "SET_CREDITS_HISTORY":
        draft.creditsHistory = action.payload;
        break;

      /**
       * set data for withdraw history
       * */
      case "SET_WITHDRAW_HISTORY":
        draft.withdrawHistory = action.payload;
        break;

      /**
       * set all games settings
       * */
      case "SET_GAMES_SETTINGS":
        // eslint-disable-next-line
        let [[key, name]] = action.payload;
        const allGames = ["DOG_6", "DOG_8", "HORSE_6", "HORSE_8"];
        // const allGamesWeb = [
        //   "DOG_6_WEB",
        //   "DOG_8_WEB",
        //   "HORSE_6_WEB",
        //   "HORSE_8_WEB",
        // ];
        let gamesToFilter = name.split(" ");

        let intersection = gamesToFilter.filter((element) =>
          allGames.includes(element)
        );
        let arr = [];

        if (intersection.length < 1) {
          intersection = allGames;
        } else {
          intersection.forEach((game) => arr.push(game.toUpperCase() + "_WEB"));
          intersection = arr;
        }
        draft.rbiGames = intersection;
        break;

      case "SET_RBI":
        draft.rbi = action.payload;
        break;

      case "SET_APP_SETTINGS":
        draft.allGames = [
          ...action.payload.enabledGames.filter((game) => {
            return game.indexOf("WEB") !== -1;
            // return game;
          }),
        ];
        if (draft.allGames.length < 1)
          draft.allGames = [
            "DOG_6_WEB",
            "DOG_8_WEB",
            "HORSE_6_WEB",
            "HORSE_8_WEB",
          ];
        const gameSettings = { ...action.payload };

        if (!action.payload.currency) gameSettings.currency = "ZAR";

        if (state.isDemoGameVersion) gameSettings.currency = "USD";

        draft.gameSettings = gameSettings;
        break;

      /**
       * get jackpots data
       * **/
      case "JACKPOT_DATA_RECIEVED":
        draft.jackPot = action.payload.jackpotBalance;
        draft.superJackPot = action.payload.superJackpotBalance;
        break;
      /**
       * set new lifecycle state and relevant data for new lifecycle state
       * */
      case "CHANGE_LIFECYCLE_STATE":
        draft.currentLifecycleState = action.payload.state;
        draft.lifecycleData = action.payload;
        break;

      /**
       * set ID of aside slide block which are showing
       * */
      case "SLIDE_ASIDE_BLOCK":
        const newSlideAsideBlockId =
          action.payload === state.slideAsideBlockId ? null : action.payload;
        draft.slideAsideBlockId = newSlideAsideBlockId;
        break;

      /**
       * set active bet type in bet's tab
       * */
      case "SET_ACTIVE_BET_TYPE":
        draft.activeBetType = action.payload;
        break;

      /**
       * refresh all events with its ODDS
       * */
      case "SET_EVENTS_ODDS":
        draft.eventsOdds = action.payload.events;
        break;

      /**
       * set withdraw money code
       * */
      case "SET_WITHDRAW_MONEY_CODE":
        draft.selfData.withdrawMoneyCode = action.payload;
        break;
      /**
       * change game in game's list
       * */
      case "CHANGE_GAME":
        draft.eventsOdds = action.payload.eventsOdds;
        draft.raceType = action.payload.raceType;
        draft.lifecycleData = action.payload.lifecycleData;
        draft.currentLifecycleState = action.payload.currentLifecycleState;
        draft.videoName = action.payload.videoName;
        draft.timeStampVideo = action.payload.timeStampVideo;
        draft.videoProgressLine = action.payload.videoProgressLine;
        draft.eventHistory = action.payload.eventHistory;
        draft.betsObject = action.payload.betsObject;

        break;

      /**
       * set data for event history
       * */
      case "SET_EVENT_HISTORY":
        if (action.payload.raceType === state.raceType) {
          draft.eventHistory = action.payload.eventHistory;
        }

        let allRaceTypeDataHistory = getItemFromStorage(
          "allRaceTypeData",
          true
        );
        if (allRaceTypeDataHistory) {
          if (allRaceTypeDataHistory[action.payload.raceType]) {
            allRaceTypeDataHistory[action.payload.raceType].eventHistory =
              action.payload.eventHistory;
            setItemInStorage("allRaceTypeData", allRaceTypeDataHistory, true);
          } else {
            allRaceTypeDataHistory[action.payload.raceType] = {};
            allRaceTypeDataHistory[action.payload.raceType].eventHistory =
              action.payload.eventHistory;
            setItemInStorage("allRaceTypeData", allRaceTypeDataHistory, true);
          }
        } else {
          allRaceTypeDataHistory = {};
          allRaceTypeDataHistory[action.payload.raceType] = {};
          allRaceTypeDataHistory[action.payload.raceType].eventHistory =
            action.payload.eventHistory;
          setItemInStorage("allRaceTypeData", allRaceTypeDataHistory, true);
        }

        draft.eventHistoryLoading = false;
        break;

      /**
       * set event history loading In Progress flag
       * */
      case "SET_EVENT_HISTORY_LOADING_FLAG":
        draft.eventHistoryLoading = action.payload;
        break;

      /**
       * set current user data
       * */
      case "SET_CURRENT_USER_DATA":
        draft.userData = { ...action.payload };
        break;

      /**
       * set logout redirect timeout for terminal version if user has zero creds and all his bets lost
       * */
      case "SET_LOGOUT_TIMEOUT_TERMINAL":
        draft.logoutRedirectTimeoutTerminal = action.payload;
        break;

      /**
       * set virtual keyboard use
       * */
      case "SET_VIRTUAL_KEYBOARD_USE":
        draft.useVirtualKeyboard = action.payload;
        break;

      /**
       * set object for second screen instance(it object allows open video on another browser window)
       * */
      case "SET_SECOND_SCREEN_INSTANCE":
        draft.secondScreenInstance = action.payload;
        break;

      default:
        return state;
    }
  });

export default appReducer;
