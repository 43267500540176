export const ChangeGame = (
  newGameIdentifier,
  state,
  dispatch,
  allRaceTypeData,
  getActiveBetsObject,
  toggleModal,
  setExpiredRaceIds,
  setItemInStorage) => {

  dispatch({
    type: "SET_EVENT_HISTORY_LOADING_FLAG",
    payload: true
  });

  // save all bets from betslip

  const gameStorageData =
    allRaceTypeData && allRaceTypeData[newGameIdentifier]
      ? allRaceTypeData[newGameIdentifier]
      : {};

  const videoName = gameStorageData.videoName
    ? gameStorageData.videoName
    : null;

  const timeStampVideo = gameStorageData.timeStampVideo
    ? gameStorageData.timeStampVideo
    : 0;

  const videoProgressLine = gameStorageData.videoProgressLine
    ? gameStorageData.videoProgressLine
    : 0;

  const eventsOdds = gameStorageData.eventsOdds
    ? gameStorageData.eventsOdds
    : state.futureEvents[newGameIdentifier]
      ? state.futureEvents[newGameIdentifier]
      : [];

  const eventHistory = gameStorageData.eventHistory
    ? gameStorageData.eventHistory
    : [];

  const currentLifecycleState = gameStorageData.lifecycleData
    ? gameStorageData.lifecycleData.state
    : null;

  const lifecycleData = gameStorageData.lifecycleData
    ? gameStorageData.lifecycleData
    : null;

  const activeBetsObject = getActiveBetsObject({
    betsObject: gameStorageData.betsObject ? gameStorageData.betsObject : {},
    eventsOdds: eventsOdds
  });


  // show modal window with info about deleted bets by not available events
  if (activeBetsObject.expiredRaceIds.length > 0) {
    setExpiredRaceIds(activeBetsObject.expiredRaceIds);
    toggleModal("showInfoExpiredEvents");
  }

  const betsObject = activeBetsObject.betsObject;

  dispatch({
    type: "CHANGE_GAME",
    payload: {
      raceType: newGameIdentifier,
      lifecycleData: lifecycleData,
      currentLifecycleState: currentLifecycleState,
      videoName: videoName,
      timeStampVideo: timeStampVideo,
      videoProgressLine: videoProgressLine,
      eventsOdds: eventsOdds,
      eventHistory: eventHistory,
      betsObject: betsObject
    }
  });

  setItemInStorage("gameIdentifier", newGameIdentifier);
}
