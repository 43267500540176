import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreditsList from "./CreditsList";
import { useGlobalDataContext } from "./../../../GlobalDataContext";
import api from "./../../utils/api";

import "./../../scss/Credits/index.scss";
import { isMobile } from "../../utils/helpers";
import { toastreact } from "../../components/ToastMessage";
import { toastMessageTypes } from "../../components/ToastMessage/constants";

const CreditsHistory = ({ toggleModal }) => {
  const { state, dispatch } = useGlobalDataContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (state.slideAsideBlockId === "creditHistory") {
      getCreditsHistory();
    }
    // eslint-disable-next-line
  }, [state.slideAsideBlockId]);

  const getCreditsHistory = async () => {
    try {
      const creditsHistory = await api.getCurrentCreditsHistory(
        state.userData.id
      );
      if (creditsHistory.errorMsg) {
        toastreact({
          type: toastMessageTypes.ERROR,
          title: "Something went wrong with credits history fetching"
        });
      } else {
        dispatch({
          type: "SET_CREDITS_HISTORY",
          payload: creditsHistory
        });
      }
    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: "Something went wrong with credits history fetching"
      });
    }
  };

  // close credits history
  const hideSlideAsideBlockEventListener = () => {
    dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload: null
    });
  };

  return (
    <div className="credits-history-container">
      {isMobile.any() && (
        <span
          onClick={() => {
            hideSlideAsideBlockEventListener();
            toggleModal("voucher");
          }}
          className="modal-add-btn"
        >
          {t("header.loginMenu.addCredits")}
        </span>
      )}

      <h2 className="top-title">{t("creditHistory.creditHistory")}</h2>
      <span
        onClick={hideSlideAsideBlockEventListener}
        className="modal-close-btn"
      >
        {t("header.loginMenu.close")}
      </span>
      {Object.keys(state.creditsHistory).length && (
        <CreditsList
          currency={state.gameSettings.currency}
          creditsHistory={state.creditsHistory}
          portalKey={state.gameSettings.portalKey}
        />
      )}
    </div>
  );
};

export default CreditsHistory;
