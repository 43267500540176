import React from "react";

const ResultScreen = ({ result, hideTypedValue }) => {
  return <div className="result-screen">{
    hideTypedValue ? (
        <input type="password" onChange={()=>{}} value={result} />
    ) : result
  }</div>
};

export default ResultScreen;
