import React, { useEffect, useState } from "react";
import ResultScreen from "./ResultScreen";
import Keypad from "./Keypad";
import Keyboard from "./Keyboard";
import KeyboardUppercase from "./KeyboardUppercase";
import KeyboardSymbols from "./KeyboardSymbols";

const Calculator = ({
  onAddAmountEventListener,
  onResult,
  selectedValue,
  isKeyboard,
  hideTypedValue=false
}) => {
  let [resultAmount, setResultAmount] = useState("");
  let [isSymbolKeyboard, setSymbolKeyboard] = useState(false);
  let [isUppercaseKeyboard, setUppercaseKeyboard] = useState(false);

  // set selected value if was set before
  useEffect(() => {
    if (selectedValue) setResultAmount(selectedValue.toString());

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setResultAmount(selectedValue.toString());

    // eslint-disable-next-line
  }, [selectedValue]);

  const clear = () => {
    setResultAmount("");
  };
  const onButtonPress = event => {
    const pressedButton = event.target.innerHTML;
    if (pressedButton !== "OK") {
      switch (pressedButton) {
        case ".?!&amp;":
          setSymbolKeyboard(true);
          break;
        case "ABC":
          setSymbolKeyboard(false);
          break;
        case "↑":
          setUppercaseKeyboard(true);
          break;
        case "↓":
          setUppercaseKeyboard(false);
          break;
        case "←":
          resultAmount = resultAmount.slice(0, -1);
          setResultAmount(resultAmount);
          break;
        case "&lt;":
          resultAmount += "<";
          setResultAmount(resultAmount);
          break;
        case "&gt;":
          resultAmount += ">";
          setResultAmount(resultAmount);
          break;
        case "&amp;":
          resultAmount += "&";
          setResultAmount(resultAmount);
          break;
        case "space":
          resultAmount += " ";
          setResultAmount(resultAmount);
          break;

        default:
          resultAmount += pressedButton;
          setResultAmount(resultAmount);
      }
    } else {
      onAddAmountEventListener(
        resultAmount.indexOf(".") !== -1 || resultAmount === "" || isKeyboard
          ? resultAmount
          : resultAmount
        // : resultAmount.concat(".00")
      );
      onResult(event);
      clear();
    }
  };

  return (
    <main className={`calculator ${isKeyboard ? "isKeyboard" : ""} ${hideTypedValue ? "hideValue" : ""}`}>
      <section className="calculator-screen">
        <ResultScreen hideTypedValue={hideTypedValue} result={resultAmount} />
      </section>
      {isKeyboard ? (
        isSymbolKeyboard ? (
          <KeyboardSymbols onButtonPress={onButtonPress} />
        ) : isUppercaseKeyboard ? (
          <KeyboardUppercase onButtonPress={onButtonPress} />
        ) : (
          <Keyboard onButtonPress={onButtonPress} />
        )
      ) : (
        <Keypad onButtonPress={onButtonPress} />
      )}
    </main>
  );
};

export default Calculator;
