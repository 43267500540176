import React from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

function DateRangePicker({
  id,
  selected,
  disabled,
  onChange,
  onChangeRaw,
  onBlur,
  placeholderText,
  dateFormat,
  showTimeSelect,
  showTimeSelectOnly,
  timeFormat,
  customInput,
  context
}) {
  // DatePicker is a controlled component.
  // This means that you need to provide an input value
  // and an onChange handler that updates this value.
  const { t } = useTranslation();
  return (
    <DatePicker
      className="form-control"
      id={id}
      selected={selected}
      disabled={disabled}
      onChange={onChange}
      onChangeRaw={onChangeRaw}
      onBlur={onBlur}
      peekNextMonth={true}
      showMonthDropdown={true}
      showYearDropdown={true}
      dropdownMode="select"
      placeholderText={placeholderText}
      dateFormat={dateFormat}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      timeFormat={timeFormat}
      timeIntervals={10}
      timeCaption={t("time")}
      shouldCloseOnSelect={true}
      defaultValue={null}
      customInput={customInput}
    />
  );
}
export default DateRangePicker;
