import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useGlobalDataContext } from "../../../GlobalDataContext";
import cx from "clsx";

import { eraseCookie } from "./../../utils/CookieService";

import { removeTokenData } from "../../components/Authorization/TokenServiceStorage";
import { removeItemFromStorage } from "../../utils/LocalStorageService";

import { LOGOUT_URI } from "./../../utils/constants";
// eslint-disable-next-line
import { getCorrectXhrAPIdomain, getFloor } from "./../../utils/helpers";

import ScrambleEffectText from "../../components/ScrambleEffectText";
import { toastreact } from "../../components/ToastMessage";
import { toastMessageTypes } from "../../components/ToastMessage/constants";
const LoginMenu = () => {
  const { state, dispatch } = useGlobalDataContext();
  const { t, i18n } = useTranslation();
  const [stakeAmountScrambleEffect, setStakeAmountScrambleEffect] = useState(
    false
  );
  const [currentLang, setCurrentLang] = useState(
    Object.keys(i18n.options.resources).includes(i18n.language)
      ? i18n.language
      : "en"
  );
  const logout = () => {
    removeItemFromStorage("idsPlacedEvents", true);
    removeTokenData();
    removeItemFromStorage("gameIdentifier");
    eraseCookie("JSESSIONID", "/", window.location.origin);
    setTimeout(() => {
      window.location.href = LOGOUT_URI;
    }, 1000);
  };

  const showHeaderMenuEventListener = (event) => {
    dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload:
        event.currentTarget.getAttribute("data-aside-block") ===
        state.slideAsideBlockId
          ? null
          : event.currentTarget.getAttribute("data-aside-block"),
    });
  };
  const logoutEventListener = (event) => {
    if (state.partnerWalletActive) {
      // here we need to close iframe, in our case - redirect lotostar from rb iframe game to list of game page
      // window.top.location = window.top.location.origin;

      return;
    }
    if (event.currentTarget.classList.contains("inactive")) return;
    removeItemFromStorage("idsPlacedEvents", true);
    removeTokenData();
    removeItemFromStorage("gameIdentifier");
    eraseCookie("JSESSIONID", "/", window.location.origin);
    setTimeout(() => {
      window.location.href = LOGOUT_URI;
    }, 1000);
  };

  const terminalLogoutEventListener = (event) => {
    if (state.userData && state.userData.createWalletDTO.credits === 0) {
      logout();
    } else {
      const modalHref = event.currentTarget.getAttribute("data-modal");
      dispatch({
        type: "SLIDE_ASIDE_BLOCK",
        payload: null,
      });

      // dispatch event for showing modal window
      dispatch({
        type: "OPEN_MODAL_WINDOW",
        payload: modalHref ? modalHref : null,
      });
    }
  };

  // open modal
  const openModalEventListener = (event) => {
    const modalHref = event.currentTarget.getAttribute("data-modal");
    dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload: null,
    });

    // dispatch event for showing modal window
    dispatch({
      type: "OPEN_MODAL_WINDOW",
      payload: modalHref ? modalHref : null,
    });
  };

  const goToLoginPage = (event) => {
    logout();
  };

  const showSlideAsideBlockEventListener = (event) => {
    dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload:
        event.currentTarget.getAttribute("data-aside-block") ===
        state.slideAsideBlockId
          ? null
          : event.currentTarget.getAttribute("data-aside-block"),
    });
  };

  const changeLanguageEventListener = (e) => {
    if (i18n.language === e.currentTarget.value) return;
    i18n.changeLanguage(e.currentTarget.value);
  };

  React.useEffect(() => {
    const currLang = Object.keys(i18n.options.resources).filter((lang) => {
      return i18n.language === lang;
    })[0];
    setCurrentLang(currLang);
    // eslint-disable-next-line
  }, [i18n.language]);

  // set stake amount scramble effect when credits are changed
  React.useEffect(() => {
    if (state.userData && state.userData.createWalletDTO.credits <= 0) return;
    setStakeAmountScrambleEffect(true);
    setTimeout(() => {
      setStakeAmountScrambleEffect(false);
    }, 900);
    // eslint-disable-next-line
  }, [state.userData && state.userData.createWalletDTO.credits]);

  // logout user if account is disabled
  React.useEffect(() => {
    if (state.userData && !state.userData.enabled) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: "Your account is closed!",
      });
      logout();
    }
    // eslint-disable-next-line
  }, [state.userData && state.userData.enabled]);

 
  return (
    <>
      <ul className="login-menu row-list fl-cntr-end flex-align-end hide-for-mobile">
        <div className="credits-block ">
          {!state.partnerWalletActive && !state.isDemoGameVersion && (
            <li
              data-modal="voucher"
              onClick={openModalEventListener}
              className="login-menu-item add-credits align-center"
            >
              <span>{t("header.loginMenu.addCredits")}</span>
              <div className={`credits-text-flash-line`} />
            </li>
          )}
          {state.isDemoGameVersion && (
            <li
              data-modal="voucher"
              onClick={goToLoginPage}
              className="login-menu-item add-credits align-center"
            >
              <span>{t("header.loginMenu.placeRealBets")}</span>
              <div className={`credits-text-flash-line`} />
            </li>
          )}
          <li
            onClick={showSlideAsideBlockEventListener}
            data-aside-block="creditHistory"
            className="login-menu-item credits align-center"
            style={{
              cursor: state.partnerWalletActive ? "default" : "pointer",
            }}
          >
            {t("header.loginMenu.credits")}:{" "}
            {/*show user credits only if user already saved his creds*/}
            {state.userData &&
              state.userData.createWalletDTO.enabled &&
              !stakeAmountScrambleEffect && (
                <span>
                  {getFloor(state.userData.createWalletDTO.credits, 100)}{" "}
                  {state.gameSettings.currency}
                </span>
              )}
            {state.userData &&
              state.userData.createWalletDTO.enabled &&
              stakeAmountScrambleEffect && (
                <span>
                  <ScrambleEffectText
                    value={getFloor(
                      state.userData.createWalletDTO.credits,
                      100
                    )}
                  />{" "}
                  {state.gameSettings.currency}
                </span>
              )}
          </li>
        </div>
        {state.isWebGameVersion ? (
          <>
            {!state.partnerWalletActive && (
              <>
                {!state.isDemoGameVersion && (
                  <li className="login-menu-item username align-center">
                    <span></span>
                    <div className="name-block ">
                      <p className={"name hello"}>
                        {t("header.loginMenu.hello")},
                      </p>{" "}
                      <p className={"name"}>
                        {state.userData && state.userData.userName}
                      </p>
                    </div>
                  </li>
                )}
                <li
                  className="login-menu-item logout align-center"
                  onClick={logoutEventListener}
                >
                  <span></span>
                  {t("header.loginMenu.logout")}
                </li>
              </>
            )}

            <li
              className="login-menu-item menu align-center"
              onClick={showHeaderMenuEventListener}
              data-aside-block="headerSlideMenu"
            >
              <span></span>
              {t("header.loginMenu.menu")}
            </li>
            {}
          </>
        ) : (
          <>
            <li
              className="login-menu-item withdraw align-center btn btn-warning"
              data-modal="withdrawMoney"
              onClick={openModalEventListener}
            >
              <span>{t("header.loginMenu.withdrawMoney")}</span>
              <div className={`button-flash-line`} />
            </li>
            <li
              className={cx("login-menu-item logout align-center", {
                // inactive: !(
                //   state.userData && state.userData.createWalletDTO.credits === 0
                // )
              })}
              data-modal="logoutWithdrawMoney"
              onClick={terminalLogoutEventListener}
            >
              <span></span>
              {t("header.loginMenu.logout")}
            </li>
          </>
        )}
      </ul>
      <aside
        className={cx("header-slide-menu", {
          active: state.slideAsideBlockId === "headerSlideMenu",
          [`preloader-layer`]: state.eventsOdds.length === 0,
        })}
      >
        <span onClick={showHeaderMenuEventListener} className="close-btn">
          {t("header.loginMenu.close")}
        </span>

        {!state.partnerWalletActive && !state.isDemoGameVersion && (
          <>
            {state.isWebGameVersion && (
              <div
                data-modal="voucher"
                onClick={openModalEventListener}
                className="header-slide-menu-item"
              >
                {t("header.loginMenu.addCredits")}
              </div>
            )}
            <div
              className="header-slide-menu-item withdraw-money"
              data-modal="withdrawMoney"
              onClick={openModalEventListener}
            >
              {t("header.loginMenu.withdrawMoney")}
            </div>
            <div
              className="header-slide-menu-item withdraw-code"
              onClick={showSlideAsideBlockEventListener}
              data-aside-block="withdrawHistory"
            >
              {t("header.loginMenu.withdrawHistory")}
            </div>

            {state.selfData.withdrawMoneyCode && (
              <div className="header-slide-menu-item">
                {t("header.loginMenu.withdrawMoneyCode")}:{"  "}{" "}
                <strong>{state.selfData.withdrawMoneyCode}</strong>
              </div>
            )}

            {state.isWebGameVersion && (
              <>
                <div
                  className="header-slide-menu-item border-dashed border-top update-password"
                  data-modal="updateUserPassword"
                  onClick={openModalEventListener}
                >
                  {t("header.loginMenu.changePassword")}
                </div>
                <div
                  onClick={logoutEventListener}
                  className="header-slide-menu-item hide-for-desktop"
                >
                  {t("header.loginMenu.logout")}
                </div>
              </>
            )}

            {state.isWebGameVersion && (
              <>
                <div
                  className="header-slide-menu-item border-dashed border-top close-account"
                  data-modal="closeUserAccount"
                  onClick={openModalEventListener}
                >
                  {t("header.loginMenu.closeMyAccount")}
                </div>
              </>
            )}
          </>
        )}

        <div className="header-slide-menu-item language">
          <span>{t("header.loginMenu.changeLang")}</span>
          <select
            placeholder={t("header.loginMenu.changeLang")}
            onChange={changeLanguageEventListener}
            defaultValue={currentLang}
            className={"select-lang"}
          >
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="ru">Русский</option>
            <option value="yi">Yiddish</option>
          </select>
        </div>
      </aside>
      {/*backdrop for side slide menu*/}
      <div
        onClick={showHeaderMenuEventListener}
        data-aside-block="headerSlideMenu"
        className={cx("modal-backdrop aside-menu-backdrop", {
          active: state.slideAsideBlockId === "headerSlideMenu",
        })}
      ></div>
    </>
  );
};

export default LoginMenu;
