import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ticketStates } from "./../../../utils/constants";

import { useGlobalDataContext } from "../../../../GlobalDataContext";

const BasketContent = ({ results }) => {
  const { state } = useGlobalDataContext();
  const { t } = useTranslation();
  return (
    <>
      {results.map((event, i) => {
        let betTip = event.tip;
        if (event.tip.indexOf("{i18n(ticket.tip.rb_") !== -1)
          betTip = betTip.replace("{i18n(ticket.tip.rb_", "");
        if (event.tip.indexOf("{i18n(racingbet.sidebet.") !== -1)
          betTip = betTip.replace("{i18n(racingbet.sidebet.", "");
        if (event.tip.indexOf("6)}") !== -1) betTip = betTip.replace("6)}", "");
        if (event.tip.indexOf("8)}") !== -1) betTip = betTip.replace("8)}", "");
        betTip = betTip.replace(")} ", " ").slice(0, -4);

        return (
          <Fragment key={i}>
            <div className="bet-content">
              <div className="flex-row bet-content-row">
                <div className="flex-col-11 font-light mb-5 uppercase">
                  {betTip

                    .replace("FIRST", t("betlist.first"))
                    .replace("PLACEBET", t("betlist.placebet"))
                    .replace("LOW", t("betlist.low"))
                    .replace("HIGH", t("betlist.high"))
                    .replace("PERFECTA", t("betlist.perfecta"))
                    .replace("ODD", t("betlist.odd"))
                    .replace("EVEN", t("betlist.even"))
                    .replace("TRIFECTA", t("betlist.trifecta"))
                    .replace("QUINELLA", t("betlist.quinella"))}
                </div>
                <div className="flex-col-10 align-right font-light">
                  {event.odds}
                </div>
              </div>
              <div className="flex-row bet-content-row">
                <div className="flex-col-11 font-light mb-5">
                  {t("betlist.stake")}
                </div>
                <div className="flex-col-10 align-right font-light">
                  {event.stake} {state.gameSettings.currency}
                </div>
              </div>

              {event.state && (
                <div className="flex-row bet-content-row">
                  <div className="flex-col-11 font-bold mb-5">
                    {event.state === ticketStates.NOVICE
                      ? t("betlist.pending")
                      : event.state === ticketStates.WINNING
                      ? t("betlist.won")
                      : t("betlist.lost")}
                  </div>
                  {event.state !== ticketStates.NOVICE && (
                    <div className="flex-col-10 align-right font-bold">
                      {event.state === ticketStates.LOSING
                        ? event.stake
                        : event.win !== null
                        ? event.win
                        : 0}{" "}
                      {state.gameSettings.currency}
                    </div>
                  )}
                </div>
              )}

              {event.state === ticketStates.WINNING &&
                event.tax !== null &&
                event.tax > 0 && (
                  <div className="flex-row bet-content-row">
                    <div className="flex-col-11 font-bold mb-5">
                      {t("betlist.tax")}
                    </div>

                    <div className="flex-col-10 align-right font-bold">
                      {event.tax}
                    </div>
                  </div>
                )}
                
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default BasketContent;
