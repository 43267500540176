// save new object in LocalStorage
export function setItemInStorage(key, value, saveToSessionStorage) {
  try {
    const serializedObj = JSON.stringify(value);
    saveToSessionStorage ? sessionStorage.setItem(key, serializedObj) : localStorage.setItem(key, serializedObj);
  } catch (err) {
    console.log(`Looks like ${err}`);
  }
}

// get object from LocalStorage
export function getItemFromStorage(key, getFromSessionStorage) {
  try {
    const serializedObj = getFromSessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key);
    if (serializedObj === null) {
      return undefined;
    }
    return JSON.parse(serializedObj);
  } catch (err) {
    return undefined;
  }
}

// remove object from LocalStorage
export function removeItemFromStorage(key, removeFromSessionStorage) {
  try {
    const serializedObj = removeFromSessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key);
    if (serializedObj === null) {
      console.log(`There's no ${key}`);
    }
    removeFromSessionStorage ? sessionStorage.removeItem(key) : localStorage.removeItem(key);
  } catch (err) {
    console.log(`Looks like ${err}`);
  }
}

