import React from "react";
import { useTranslation } from "react-i18next";
import "./../../scss/RotationWindow/index.scss";
import cx from "clsx";
import rotate from "../../assets/images/rotate/rotate.gif";

function RotationWindow({ isLandscape, isLightRotationWindow, showInParent, rotateToTheRight }) {
  const { t } = useTranslation();
  return (
    <div
      className={cx("rotation-window-container", {
        hideWindow: !isLandscape,
        transparentAbsoluteWindow: isLightRotationWindow,
        [`show-in-parent`]: showInParent
      })}
    >
      <img className={cx("rotation-image",{
          toTheRight: rotateToTheRight
      })} alt={"Portrait-orientation"} src={rotate} />
      <h1>{t("rotationMsg")}</h1>
    </div>
  );
}
export default RotationWindow;
