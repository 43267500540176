import React, { useState } from "react";
import cn from "clsx";

import { useTranslation } from "react-i18next";
import { inputsNames } from "../../utils/constants";
import { isMobile } from "../../utils/helpers";
import isSuccessValidation from "./../../utils/validationFormField";

import ReactTooltip from "react-tooltip";

import { Modal, ModalHeader, ModalMainContent } from "./../Modal/Modal";
import Calculator from "../Calculator/Calculator";
import ActionButton from "../ActionButton";
import { toastreact } from "../ToastMessage";
import { toastMessageTypes } from "../ToastMessage/constants";

const UserCredsSetUpModal = ({
  userData,
  showModal,
  onSaveUserCreds,
  isWebGameVersion,
  onFocuseUsernameInput,
  onFocuseEmailInput,
  // saveCredsError,
  isEmailExists,
  isUserNameExists
}) => {
  const { t } = useTranslation();
  // Validation error messages
  const errorMessages = {
    emailExists: t("modalWindows.userCredsModal.emailExist"),
    userExists: t("modalWindows.userCredsModal.userExist"),
    // [inputsNames.FULLNAME]: "Please type correct Full Name!",
    // [inputsNames.PHYSICALADDRESS]: "Please type correct Physical Address!",
    // [inputsNames.PHONENUMBER]:
    //   "Please type correct Mobile/Contact Number! Example of correct values: +27832762842 or 27832762842 or 0832762842",
    [inputsNames.LOGIN]: t("modalWindows.userCredsModal.correctName"),
    [inputsNames.EMAIL]: t("modalWindows.userCredsModal.correctMail"),
    [inputsNames.PINCODE]: t("modalWindows.userCredsModal.correctPin"),
    [inputsNames.PINCODECONFIRM]: t("modalWindows.userCredsModal.confirmPin"),
    [inputsNames.IDNUMBER]: t("modalWindows.userCredsModal.correctID")
  };
  const [userLogin, setUserLogin] = useState(
    userData && userData.userName !== null ? userData.userName : ""
  ); // required
  // const [userFullName, setUserFullName] = useState(
  //   userData && userData.userFullName !== null ? userData.userFullName : ""
  // ); // required
  // const [physicalAddress, setPhysicalAddress] = useState(
  //   userData && userData.physicalAddress !== null
  //     ? userData.physicalAddress
  //     : ""
  // ); // required
  // const [phoneNumber, setPhoneNumber] = useState(
  //   userData && userData.phoneNumber !== null ? userData.phoneNumber : ""
  // ); // required
  const [userPin, setUserPin] = useState(
    userData && userData.pinCode !== null ? userData.pinCode : ""
  ); // required
  const [userPinConfirm, setUserPinConfirm] = useState(
    userData && userData.pinCode !== null ? userData.pinCode : ""
  ); // required
  const [userMail, setUserMail] = useState(
    userData && userData.email !== null ? userData.email : ""
  ); // required
  const [idNumber, setIdNumber] = useState(
    userData && userData.idNumber !== null ? userData.idNumber : ""
  ); // required

  const [isAllowSaveBtn, setIsAllowSaveBtn] = useState(false);
  const [showVirtualKeyboard, toggleVirtualKeyboard] = useState(false);
  const [focusedInputName, setFocusedInputName] = useState(null);
  const [validErrors, setValidError] = useState({});
  const [validSuccess, setValidSuccess] = useState({
    // [inputsNames.FULLNAME]: false,
    // [inputsNames.PHYSICALADDRESS]: false,
    // [inputsNames.PHONENUMBER]: false,
    [inputsNames.LOGIN]: false,
    [inputsNames.EMAIL]: false,
    // [inputsNames.IDNUMBER]: false,
    [inputsNames.PINCODE]: false,
    [inputsNames.PINCODECONFIRM]: false
  });
  // submit btn will be allowed only if pins are confirmed and login is valid
  const validateForm = () => {
    setIsAllowSaveBtn(
      validErrors[inputsNames.LOGIN] === false &&
        validErrors[inputsNames.PINCODE] === false &&
        // validErrors[inputsNames.IDNUMBER] === false &&
        userPin === userPinConfirm &&
        validErrors[inputsNames.EMAIL] === false
      // &&
      // validErrors[inputsNames.FULLNAME] === false &&
      // validErrors[inputsNames.PHYSICALADDRESS] === false
      // &&
      // validErrors[inputsNames.PHONENUMBER] === false
    );
  };

  // show virtual keyboard
  const onShowVirtualKeyboardEventListener = event => {
    setFocusedInputName(event.currentTarget.id);
    toggleVirtualKeyboard(true);
  };
  const onFocuseEventHandler = event => {
    if (event.currentTarget.name === inputsNames.LOGIN)
      onFocuseUsernameInput(false);
    if (event.currentTarget.name === inputsNames.EMAIL)
      onFocuseEmailInput(false);
    setFocusedInputName(event.currentTarget.name);
  };

  // set value of focusedInputName input
  const validateInputValue = value => {
    // set the value for its input
    let validationResult = null; // true if valid value
    // LOGIN
    if (focusedInputName === inputsNames.LOGIN) {
      validationResult = isSuccessValidation[inputsNames.LOGIN](value);

      // set validation error
      setValidError({
        ...validErrors,
        [inputsNames.LOGIN]: !validationResult
      });
      // set success validation
      setValidSuccess({
        ...validSuccess,
        [inputsNames.LOGIN]: validationResult
      });

      setUserLogin(value.trim());

      if (!isSuccessValidation[inputsNames.LOGIN](value)) {
        if (isMobile.any()) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: errorMessages[inputsNames.LOGIN]
          });
        }
      }
    }
    // FULLNAME
    // if (focusedInputName === inputsNames.FULLNAME) {
    //   validationResult = isSuccessValidation[inputsNames.FULLNAME](value);
    //
    //   // set validation error
    //   setValidError({
    //     ...validErrors,
    //     [inputsNames.FULLNAME]: !validationResult
    //   });
    //   // set success validation
    //   setValidSuccess({
    //     ...validSuccess,
    //     [inputsNames.FULLNAME]: validationResult
    //   });
    //
    //   setUserFullName(value.trim());
    //
    //   if (!isSuccessValidation[inputsNames.FULLNAME](value)) {
    //     if (isMobile.any()) {
    //       toastreact({
    //         type: toastMessageTypes.ERROR,
    //         title: errorMessages[inputsNames.FULLNAME]
    //       });
    //     }
    //   }
    // }
    // PHYSICALADDRESS
    // if (focusedInputName === inputsNames.PHYSICALADDRESS) {
    //   validationResult = isSuccessValidation[inputsNames.PHYSICALADDRESS](
    //     value
    //   );
    //
    //   // set validation error
    //   setValidError({
    //     ...validErrors,
    //     [inputsNames.PHYSICALADDRESS]: !validationResult
    //   });
    //   // set success validation
    //   setValidSuccess({
    //     ...validSuccess,
    //     [inputsNames.PHYSICALADDRESS]: validationResult
    //   });
    //
    //   setPhysicalAddress(value.trim());
    //
    //   if (!isSuccessValidation[inputsNames.PHYSICALADDRESS](value)) {
    //     if (isMobile.any()) {
    //       toastreact({
    //         type: toastMessageTypes.ERROR,
    //         title: errorMessages[inputsNames.PHYSICALADDRESS]
    //       });
    //     }
    //   }
    // }
    // PHONENUMBER
    // if (focusedInputName === inputsNames.PHONENUMBER) {
    //   validationResult = isSuccessValidation[inputsNames.PHONENUMBER](value);
    //
    //   // set validation error
    //   setValidError({
    //     ...validErrors,
    //     [inputsNames.PHONENUMBER]: !validationResult
    //   });
    //   // set success validation
    //   setValidSuccess({
    //     ...validSuccess,
    //     [inputsNames.PHONENUMBER]: validationResult
    //   });
    //
    //   setPhoneNumber(value.trim());
    //
    //   if (!isSuccessValidation[inputsNames.PHONENUMBER](value)) {
    //     if (isMobile.any()) {
    //       toastreact({
    //         type: toastMessageTypes.ERROR,
    //         title: errorMessages[inputsNames.PHONENUMBER]
    //       });
    //     }
    //   }
    // }
    // IDNUMBER
    if (focusedInputName === inputsNames.IDNUMBER) {
      validationResult = isSuccessValidation[inputsNames.IDNUMBER](value);

      // set validation error
      setValidError({
        ...validErrors,
        [inputsNames.IDNUMBER]: !validationResult
      });
      // set success validation
      setValidSuccess({
        ...validSuccess,
        [inputsNames.IDNUMBER]: validationResult
      });

      setIdNumber(value.trim());

      if (!isSuccessValidation[inputsNames.IDNUMBER](value)) {
        if (isMobile.any()) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: errorMessages[inputsNames.IDNUMBER]
          });
        }
      }
    }
    //EMAIL
    if (focusedInputName === inputsNames.EMAIL) {
      validationResult = isSuccessValidation[inputsNames.EMAIL](value);

      // set validation error
      setValidError({
        ...validErrors,
        [inputsNames.EMAIL]: !validationResult
      });
      // set success validation
      setValidSuccess({
        ...validSuccess,
        [inputsNames.EMAIL]: validationResult
      });
      setUserMail(value.trim());
      if (!isSuccessValidation[inputsNames.EMAIL](value)) {
        if (isMobile.any()) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: errorMessages[inputsNames.EMAIL]
          });
        }
      }
    }
    // PINCODE
    if (focusedInputName === inputsNames.PINCODE) {
      validationResult = isSuccessValidation[inputsNames.PINCODE](value);
      // set validation error
      const validErrors_ = {
        ...validErrors,
        [inputsNames.PINCODE]: !validationResult
      };
      setValidError(validErrors_);
      // set success validation
      const validSuccess_ = {
        ...validSuccess,
        [inputsNames.PINCODE]: validationResult
      };
      setValidSuccess(validSuccess_);

      setValidError({
        ...validErrors_,
        [inputsNames.PINCODECONFIRM]: !(value.trim() === userPinConfirm.trim())
      });
      setValidSuccess({
        ...validSuccess_,
        [inputsNames.PINCODECONFIRM]: value.trim() === userPinConfirm.trim()
      });
      setUserPin(value.trim());
      if (!validationResult) {
        if (isMobile.any()) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: errorMessages[inputsNames.PINCODE]
          });
        }
      }
    }
    // PINCODECONFIRM
    if (focusedInputName === inputsNames.PINCODECONFIRM) {
      // set validation error
      setValidError({
        ...validErrors,
        [inputsNames.PINCODECONFIRM]: !(value.trim() === userPin.trim())
      });
      // set success validation
      setValidSuccess({
        ...validSuccess,
        [inputsNames.PINCODECONFIRM]: value.trim() === userPin.trim()
      });
      setUserPinConfirm(value.trim());
      if (!(value.trim() === userPin.trim())) {
        if (isMobile.any()) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: errorMessages[inputsNames.PINCODECONFIRM]
          });
        }
      }
    }
  };

  // check if need validate input value by oncnange event
  const isStartValidateOnchangeEvent = target => {
    const inputName = target.name;
    let countOfValidationSuccess = 0;
    const validSuccessLength = Object.keys(validSuccess).length;
    if (validSuccess[inputName] === false) {
      for (const validationName of Object.keys(validSuccess)) {
        if (
          validationName !== inputName &&
          validSuccess[validationName] === true
        )
          countOfValidationSuccess++;
      }
    }
    return countOfValidationSuccess === validSuccessLength - 1;
  };

  // execute function for save user's creds
  const onSubmitEventHandler = () => {
    if (!isAllowSaveBtn) return;
    onSaveUserCreds({
      id: userData.id,
      userName: userLogin,
      email: userMail,
      password: userPin,
      contactNumber: '1111',
      fullName: 'fullName',
      physicalAddress: 'physicalAddres',
      // idNumber: null
    });
  };

  // change input value for desktop version of webGameVersion app
  const onChangeEventListener = e => {
    const value =
      // e.currentTarget.name === inputsNames.PHYSICALADDRESS ||
      // e.currentTarget.name === inputsNames.FULLNAME
      //   ? e.currentTarget.value
      //   :
      e.currentTarget.value.trim();
    if (
      e.currentTarget.name !== inputsNames.PINCODECONFIRM &&
      isStartValidateOnchangeEvent(e.currentTarget)
    )
      validateInputValue(value);
    switch (focusedInputName) {
      case inputsNames.LOGIN:
        setUserLogin(value);

        break;
      // case inputsNames.FULLNAME:
      //   setUserFullName(value);

      //   break;
      // case inputsNames.PHYSICALADDRESS:
      //   setPhysicalAddress(value);
      //
      //   break;
      // case inputsNames.PHONENUMBER:
      //   setPhoneNumber(value);

      // break;
      case inputsNames.IDNUMBER:
        setIdNumber(value);

        break;
      case inputsNames.EMAIL:
        setUserMail(value);

        break;
      case inputsNames.PINCODE:
        setUserPin(value);

        break;
      case inputsNames.PINCODECONFIRM:
        validateInputValue(value);
        break;

      default:
        break;
    }
  };

  // validate input's value after focusout event
  const onFocusOutEventListener = e => {
    validateInputValue(e.currentTarget.value);
  };

  // validate form after creds changed
  React.useEffect(() => {
    if (
      !userLogin &&
      !userPin &&
      !userPinConfirm &&
      !userMail
      // &&
      // !userFullName &&
      // !physicalAddress
      //   &&
      // !phoneNumber
    )
      return;
    validateForm();
    ReactTooltip.rebuild();

    // eslint-disable-next-line
  }, [
    userLogin,
    userPin,
    userPinConfirm,
    userMail
    // userFullName,
    // physicalAddress,
    // phoneNumber
  ]);

  return (
    <Modal showModal={showModal} id={"saveUserCreds"}>
      <ModalHeader>
        <h3>{t("modalWindows.userCredsModal.title")}:</h3>
      </ModalHeader>

      <ModalMainContent>
        {showVirtualKeyboard ? (
          <Calculator
            onAddAmountEventListener={validateInputValue}
            onResult={() => {
              toggleVirtualKeyboard(false);
            }}
            isKeyboard={true}
            selectedValue={
              focusedInputName === inputsNames.LOGIN
                ? userLogin
                : focusedInputName === inputsNames.PINCODE
                ? userPin
                : focusedInputName === inputsNames.PINCODECONFIRM
                ? userPinConfirm
                : focusedInputName === inputsNames.EMAIL
                ? userMail
                : focusedInputName === inputsNames.IDNUMBER
                ? idNumber
                : // : focusedInputName === inputsNames.FULLNAME
                  // ? userFullName
                  // : focusedInputName === inputsNames.PHYSICALADDRESS
                  // ? physicalAddress
                  ""
            }
            hideTypedValue={
              focusedInputName === inputsNames.PINCODE ||
              focusedInputName === inputsNames.PINCODECONFIRM
            }
          />
        ) : (
          <div className="input-wrap centered-block align-center">
            <div className="flex-row flex-align-baseline align-left form-group">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  tabIndex={"1"}
                  type="text"
                  onFocus={onFocuseEventHandler}
                  name={inputsNames.LOGIN}
                  id={inputsNames.LOGIN}
                  value={userLogin == null ? "" : userLogin}
                  onChange={e => {
                    if (!isMobile.any()) onChangeEventListener(e);
                  }}
                  onBlur={e => {
                    if (!isMobile.any()) onFocusOutEventListener(e);
                  }}
                  onClick={e => {
                    if (isMobile.any()) onShowVirtualKeyboardEventListener(e);
                  }}
                  readOnly={isMobile.any()}
                  className={cn("with-placeholder", {
                    error: validErrors[inputsNames.LOGIN] || isUserNameExists,
                    success:
                      validSuccess[inputsNames.LOGIN] && !isUserNameExists
                  })}
                  placeholder={`${t("modalWindows.userCredsModal.name")} *`}
                />
                <label className="" htmlFor={inputsNames.LOGIN}>
                  {t("modalWindows.userCredsModal.name")} *
                </label>
                <span className={"valid-icon"}></span>
                <span
                  className={cn(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        !isMobile.any() &&
                        (validErrors[inputsNames.LOGIN] || isUserNameExists)
                    }
                  )}
                >
                  {isUserNameExists
                    ? errorMessages.userExists
                    : errorMessages[inputsNames.LOGIN]}
                </span>
              </div>
            </div>

            {/*<div className="flex-row flex-align-baseline align-left form-group">*/}
            {/*  <div className="flex-col-21 with-input-placeholder">*/}
            {/*    <input*/}
            {/*      tabIndex={"2"}*/}
            {/*      type="text"*/}
            {/*      onFocus={onFocuseEventHandler}*/}
            {/*      name={inputsNames.FULLNAME}*/}
            {/*      id={inputsNames.FULLNAME}*/}
            {/*      value={userFullName == null ? "" : userFullName}*/}
            {/*      onChange={e => {*/}
            {/*        if (!isMobile.any()) onChangeEventListener(e);*/}
            {/*      }}*/}
            {/*      onBlur={e => {*/}
            {/*        if (!isMobile.any()) onFocusOutEventListener(e);*/}
            {/*      }}*/}
            {/*      onClick={e => {*/}
            {/*        if (isMobile.any()) onShowVirtualKeyboardEventListener(e);*/}
            {/*      }}*/}
            {/*      readOnly={isMobile.any()}*/}
            {/*      className={cn("with-placeholder", {*/}
            {/*        error: validErrors[inputsNames.FULLNAME],*/}
            {/*        success: validSuccess[inputsNames.FULLNAME]*/}
            {/*      })}*/}
            {/*      placeholder="Full Name *"*/}
            {/*    />*/}
            {/*    <label className="" htmlFor={inputsNames.FULLNAME}>*/}
            {/*      Full Name **/}
            {/*    </label>*/}
            {/*    <span className={"valid-icon"}></span>*/}
            {/*    <span*/}
            {/*      className={cn(*/}
            {/*        "custom-danger-tooltip type-error place-right",*/}
            {/*        {*/}
            {/*          show: !isMobile.any() && validErrors[inputsNames.FULLNAME]*/}
            {/*        }*/}
            {/*      )}*/}
            {/*    >*/}
            {/*      {errorMessages[inputsNames.FULLNAME]}*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="flex-row flex-align-baseline align-left form-group">*/}
            {/*  <div className="flex-col-21 with-input-placeholder">*/}
            {/*    <input*/}
            {/*      tabIndex={"3"}*/}
            {/*      type="text"*/}
            {/*      onFocus={onFocuseEventHandler}*/}
            {/*      name={inputsNames.PHYSICALADDRESS}*/}
            {/*      id={inputsNames.PHYSICALADDRESS}*/}
            {/*      value={physicalAddress == null ? "" : physicalAddress}*/}
            {/*      onChange={e => {*/}
            {/*        if (!isMobile.any()) onChangeEventListener(e);*/}
            {/*      }}*/}
            {/*      onBlur={e => {*/}
            {/*        if (!isMobile.any()) onFocusOutEventListener(e);*/}
            {/*      }}*/}
            {/*      onClick={e => {*/}
            {/*        if (isMobile.any()) onShowVirtualKeyboardEventListener(e);*/}
            {/*      }}*/}
            {/*      readOnly={isMobile.any()}*/}
            {/*      className={cn("with-placeholder", {*/}
            {/*        error: validErrors[inputsNames.PHYSICALADDRESS],*/}
            {/*        success: validSuccess[inputsNames.PHYSICALADDRESS]*/}
            {/*      })}*/}
            {/*      placeholder="Physical Address *"*/}
            {/*    />*/}
            {/*    <label className="" htmlFor={inputsNames.PHYSICALADDRESS}>*/}
            {/*      Physical Address **/}
            {/*    </label>*/}
            {/*    <span className={"valid-icon"}></span>*/}
            {/*    <span*/}
            {/*      className={cn(*/}
            {/*        "custom-danger-tooltip type-error place-right",*/}
            {/*        {*/}
            {/*          show:*/}
            {/*            !isMobile.any() &&*/}
            {/*            validErrors[inputsNames.PHYSICALADDRESS]*/}
            {/*        }*/}
            {/*      )}*/}
            {/*    >*/}
            {/*      {errorMessages[inputsNames.PHYSICALADDRESS]}*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="flex-row flex-align-baseline align-left form-group">*/}
            {/*  <div className="flex-col-21 with-input-placeholder">*/}
            {/*    <input*/}
            {/*      tabIndex={"4"}*/}
            {/*      type="text"*/}
            {/*      onFocus={onFocuseEventHandler}*/}
            {/*      name={inputsNames.PHONENUMBER}*/}
            {/*      id={inputsNames.PHONENUMBER}*/}
            {/*      value={phoneNumber == null ? "" : phoneNumber}*/}
            {/*      onChange={e => {*/}
            {/*        if (!isMobile.any()) onChangeEventListener(e);*/}
            {/*      }}*/}
            {/*      onBlur={e => {*/}
            {/*        if (!isMobile.any()) onFocusOutEventListener(e);*/}
            {/*      }}*/}
            {/*      onClick={e => {*/}
            {/*        if (isMobile.any()) onShowVirtualKeyboardEventListener(e);*/}
            {/*      }}*/}
            {/*      readOnly={isMobile.any()}*/}
            {/*      className={cn("with-placeholder", {*/}
            {/*        error: validErrors[inputsNames.PHONENUMBER],*/}
            {/*        success: validSuccess[inputsNames.PHONENUMBER]*/}
            {/*      })}*/}
            {/*      placeholder="Mobile/Contact Number *"*/}
            {/*    />*/}
            {/*    <label className="" htmlFor={inputsNames.PHONENUMBER}>*/}
            {/*      Mobile/Contact Number **/}
            {/*    </label>*/}
            {/*    <span className={"valid-icon"}></span>*/}
            {/*    <span*/}
            {/*      className={cn(*/}
            {/*        "custom-danger-tooltip type-error place-right",*/}
            {/*        {*/}
            {/*          show:*/}
            {/*            !isMobile.any() && validErrors[inputsNames.PHONENUMBER]*/}
            {/*        }*/}
            {/*      )}*/}
            {/*    >*/}
            {/*      {errorMessages[inputsNames.PHONENUMBER]}*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="flex-row flex-align-baseline align-left form-group hidden" >*/}
            {/*  <div className="flex-col-21 with-input-placeholder">*/}
            {/*    <input*/}
            {/*        tabIndex={"2"}*/}
            {/*        type="text"*/}
            {/*        onFocus={onFocuseEventHandler}*/}
            {/*        name={inputsNames.IDNUMBER}*/}
            {/*        id={inputsNames.IDNUMBER}*/}
            {/*        value={idNumber == null ? "" : idNumber}*/}
            {/*        onChange={e => {*/}
            {/*          if (!isMobile.any()) onChangeEventListener(e);*/}
            {/*        }}*/}
            {/*        onBlur={e => {*/}
            {/*          if (!isMobile.any()) onFocusOutEventListener(e);*/}
            {/*        }}*/}
            {/*        onClick={e => {*/}
            {/*          if (isMobile.any()) onShowVirtualKeyboardEventListener(e);*/}
            {/*        }}*/}
            {/*        readOnly={isMobile.any()}*/}
            {/*        className={cn("with-placeholder", {*/}
            {/*          error: validErrors[inputsNames.IDNUMBER],*/}
            {/*          success:*/}
            {/*              validSuccess[inputsNames.IDNUMBER]*/}
            {/*        })}*/}
            {/*        placeholder="Passport ID *"*/}
            {/*    />*/}
            {/*    <label className="" htmlFor={inputsNames.IDNUMBER}>*/}
            {/*      Passport ID **/}
            {/*    </label>*/}
            {/*    <span className={"valid-icon"}></span>*/}
            {/*    <span*/}
            {/*        className={cn(*/}
            {/*            "custom-danger-tooltip type-error place-right",*/}
            {/*            {*/}
            {/*              show:*/}
            {/*                  !isMobile.any() &&*/}
            {/*                  validErrors[inputsNames.IDNUMBER]*/}
            {/*            }*/}
            {/*        )}*/}
            {/*    >*/}
            {/*      {errorMessages[inputsNames.IDNUMBER]}*/}

            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="flex-row flex-align-baseline align-left form-group">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  tabIndex={"5"}
                  type="text"
                  value={userMail}
                  onChange={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onChangeEventListener(e);
                  }}
                  onBlur={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onFocusOutEventListener(e);
                  }}
                  onFocus={onFocuseEventHandler}
                  onClick={e => {
                    if (isMobile.any()) onShowVirtualKeyboardEventListener(e);
                  }}
                  name={inputsNames.EMAIL}
                  id={inputsNames.EMAIL}
                  readOnly={isMobile.any()}
                  className={cn({
                    error: validErrors[inputsNames.EMAIL] || isEmailExists,
                    success: validSuccess[inputsNames.EMAIL] && !isEmailExists,
                    [`with-placeholder`]: true
                  })}
                  placeholder={`${t("modalWindows.userCredsModal.mail")} *`}
                />
                <label className="" htmlFor={inputsNames.EMAIL}>
                  {t("modalWindows.userCredsModal.mail")} *
                </label>
                <span className={"valid-icon"}></span>

                <span
                  className={cn(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        !isMobile.any() &&
                        (validErrors[inputsNames.EMAIL] || isEmailExists)
                    }
                  )}
                >
                  {isEmailExists
                    ? errorMessages.emailExists
                    : errorMessages[inputsNames.EMAIL]}
                </span>
              </div>
            </div>

            <div className="flex-row flex-align-baseline align-left form-group">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  tabIndex={"6"}
                  type="password"
                  value={userPin}
                  onFocus={onFocuseEventHandler}
                  onChange={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onChangeEventListener(e);
                  }}
                  onBlur={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onFocusOutEventListener(e);
                  }}
                  onClick={e => {
                    if (isMobile.any()) onShowVirtualKeyboardEventListener(e);
                  }}
                  name={inputsNames.PINCODE}
                  id={inputsNames.PINCODE}
                  readOnly={isMobile.any()}
                  className={cn({
                    error: validErrors[inputsNames.PINCODE],
                    success: validSuccess[inputsNames.PINCODE],
                    [`with-placeholder`]: true
                  })}
                  data-val={userPin}
                  placeholder={`${t("modalWindows.userCredsModal.psw")} *`}
                />
                <label className="" htmlFor={inputsNames.PINCODE}>
                  {t("modalWindows.userCredsModal.psw")} *
                </label>
                <span className={"valid-icon"}></span>

                <span
                  className={cn(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show: !isMobile.any() && validErrors[inputsNames.PINCODE]
                    }
                  )}
                >
                  {errorMessages[inputsNames.PINCODE]}
                </span>
              </div>
            </div>

            <div className="flex-row flex-align-baseline align-left form-group">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  tabIndex={"7"}
                  type="password"
                  value={userPinConfirm}
                  onFocus={onFocuseEventHandler}
                  onChange={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onChangeEventListener(e);
                  }}
                  onBlur={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onFocusOutEventListener(e);
                  }}
                  onClick={e => {
                    if (isMobile.any()) onShowVirtualKeyboardEventListener(e);
                  }}
                  name={inputsNames.PINCODECONFIRM}
                  id={inputsNames.PINCODECONFIRM}
                  readOnly={isMobile.any()}
                  className={cn({
                    error: validErrors[inputsNames.PINCODECONFIRM],
                    success: validSuccess[inputsNames.PINCODECONFIRM],
                    [`with-placeholder`]: true
                  })}
                  data-val={userPinConfirm}
                  placeholder={`${t(
                    "modalWindows.userCredsModal.pswConfirm"
                  )} *`}
                />
                <label className="" htmlFor={inputsNames.PINCODECONFIRM}>
                  {t("modalWindows.userCredsModal.pswConfirm")} *
                </label>
                <span className={"valid-icon"}></span>

                <span
                  className={cn(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        !isMobile.any() &&
                        validErrors[inputsNames.PINCODECONFIRM]
                    }
                  )}
                >
                  {errorMessages[inputsNames.PINCODECONFIRM]}
                </span>
              </div>
            </div>

            <ActionButton
              tooltip={
                !isAllowSaveBtn ? t("modalWindows.userCredsModal.fillData") : ""
              }
              onclick={onSubmitEventHandler}
              classesName={cn("btn btn-warning", {
                disable: !isAllowSaveBtn
              })}
              isBlink={true}
              isWarning={true}
            >
              {t("modalWindows.userCredsModal.save")}
            </ActionButton>
          </div>
        )}
      </ModalMainContent>
    </Modal>
  );
};

export default UserCredsSetUpModal;
