import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Accordion from "./../../components/Accordion/Accordion";
import CreditsContent from "./CreditsContent";

import { Scrollbars } from "react-custom-scrollbars";
import { getObjectForAccordionsOpenState } from "./../../components/Accordion/helpers";

import { transactionTypes } from "./../../utils/constants";

function CreditsList({ creditsHistory, currency, portalKey }) {
  const { t } = useTranslation();
  // set the height of credits list content after page render
  useEffect(() => {
    setAutoHeightMaxScrollbar(
      document.querySelector(".basket").offsetHeight * 0.9
    );
  }, []);

  const onGetCreditsHistoryEventListener = item => {
    const raceIndex = item.currentTarget.getAttribute("data-index");

    const openEventsCopy = { ...openEvents };
    openEventsCopy[raceIndex] = !openEventsCopy[raceIndex];
    setOpenEvents(openEventsCopy);
  };

  // state for saving open state of each accordion
  const [openEvents, setOpenEvents] = useState(
    getObjectForAccordionsOpenState(creditsHistory)
  );

  const [autoHeightMaxScrollBar, setAutoHeightMaxScrollbar] = useState(1000);

  const getProceedCreditAmount = item => {
    let proceedCredit = item.proceedCredits;
    if (item.transactionType === transactionTypes["INITIAL_BALANCE"])
      proceedCredit = item.blockedCredits;
    return proceedCredit;
  };
  const getCreditAmount = item => {
    let credit = item.credits;
    if (item.transactionType === transactionTypes["INITIAL_BALANCE"])
      credit = item.blockedCredits;
    return credit;
  };

  const getTransactionType = transactionType => {
    return transactionType === transactionTypes["DEPOSIT_VOUCHER"] ||
      transactionType === transactionTypes["INITIAL_BALANCE"]
      ? t("creditHistory.payIn")
      : t("creditHistory.payOut");
  };

  const getFormatedDate = createdTimestamp => {
    const longTime = new Date(new Date(`${createdTimestamp}`).getTime());
    return `${longTime.getDate()}/${longTime.getMonth() +
      1}/${longTime.getFullYear()}`;
  };

  const getTimeFromDate = createdTimestamp => {
    const longTime = new Date(new Date(`${createdTimestamp}`).getTime());
    return `${longTime.getHours()}:${longTime.getMinutes()}:${longTime.getSeconds()}`;
  };
  return (
    <Scrollbars autoHeight autoHeightMin={autoHeightMaxScrollBar}>
      {creditsHistory.map((item, i) => {
        return (
          <Fragment key={i}>
            <Accordion
              isOpen={openEvents[i]}
              onToggle={onGetCreditsHistoryEventListener}
              heading={getTransactionType(item.transactionType)}
              subheading={`${getProceedCreditAmount(item)} ${currency}`}
              secondaryHeading={getFormatedDate(item.createdTimestamp)}
              //  secondaryHeading={`${new Date(
              //    item.operationDate
              // ).getDate()}/${new Date(item.operationDate).getMonth() +
              //   1}/${new Date(item.operationDate).getFullYear()}`}
              dataIndex={i}
            />
            <div
              className="collapse-css-transition"
              aria-hidden={openEvents[i] ? "false" : "true"}
              style={{
                overflow: "hidden",
                height: openEvents[i] ? "100%" : 0,
                visibility: openEvents[i] ? null : "hidden"
              }}
            >
              <CreditsContent
                currency={currency}
                proceedCreditAmount={getProceedCreditAmount(item)}
                creditAmount={getCreditAmount(item)}
                transactionType={getTransactionType(item.transactionType)}
                item={item}
                time={getTimeFromDate(item.createdTimestamp)}
                portalKey={portalKey}
              />
            </div>
          </Fragment>
        );
      })}
    </Scrollbars>
  );
}

export default CreditsList;
