import React from "react";
import KeypadRow from "./KeypadRow";
import KeypadButton from "./KeypadButton";

const Keypad = props => (
  <section className="keypad">
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>7</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>8</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>9</KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>4</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>5</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>6</KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>1</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>2</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>3</KeypadButton>
    </KeypadRow>
    <KeypadRow>
      <KeypadButton onButtonPress={props.onButtonPress}>.</KeypadButton>
      <KeypadButton onButtonPress={props.onButtonPress}>0</KeypadButton>
      <KeypadButton type="dark" onButtonPress={props.onButtonPress}>
        ←
      </KeypadButton>
    </KeypadRow>

    <KeypadRow>
      <KeypadButton type="space" onButtonPress={props.onButtonPress}>
        space
      </KeypadButton>
    </KeypadRow>

    <KeypadRow>
      <KeypadButton type="large" onButtonPress={props.onButtonPress}>
        OK
      </KeypadButton>
    </KeypadRow>
  </section>
);

export default Keypad;
