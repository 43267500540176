import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import languageEN from './locales/en/translate.json'
import languageRU from './locales/ru/translate.json';
import languageES from './locales/es/translate.json';
import languageYI from './locales/yi/translate.json';

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: languageEN,
        ru: languageRU,
        es: languageES,
        yi: languageYI
      },
      /* default language when load the website in browser */
      // lng: "en",
      /* When react i18next not finding any language to as default in borwser */
      fallbackLng: "en",
      /* debugger For Development environment */
      debug: false,
      ns: ["translations"],
      defaultNS: "translations",
      keySeparator: ".",
      interpolation: {
        escapeValue: false,
        formatSeparator: ","
      },
      react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
      },
      supportedLngs: ['ru', 'en', 'es', 'yi']
    })

export default i18n;