import React, { useState } from "react";
import cn from "clsx";

import { gameTypes } from "./../../../utils/constants";

import GameList from "../GameList";

const ActiveGameLogo = ({onChangeGameEventListener, raceType}) => {

  const [dropGameList, toggleDropGameList] = useState(false);

  const showGameListEventListener = () => {
    setTimeout(()=>{
      toggleDropGameList(!dropGameList);
    }, 300)

  };
  return (
    <div onClick={showGameListEventListener} className="active-game-logo">
      <div

        className={cn("active-game-logo-active", {
          [`no-active-game-logo`]: !raceType,
          [`dog-6`]: raceType ? raceType.includes(gameTypes.DOG_6) : false,
          [`dog-8`]: raceType ? raceType.includes(gameTypes.DOG_8) : false,
          [`horse-6`]: raceType ? raceType.includes(gameTypes.HORSE_6) : false,
          [`horse-8`]: raceType ? raceType.includes(gameTypes.HORSE_8) : false,
          open: dropGameList
        })}
      ></div>
      {dropGameList && <GameList onChangeGame={onChangeGameEventListener} />}
    </div>
  );
};

export default ActiveGameLogo;
