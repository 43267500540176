import React from "react";

import {
  Modal,
  ModalHeader,
  ModalCloseBtn,
  ModalMainContent
} from "./../Modal/Modal";
import Calculator from "../Calculator/Calculator";

const VirtualKeyboard = ({
  showModal,
  openModalEventListener,
  onSetStakeAmountCalculatorListener,
  selectedValue
}) => {
  return (
    <Modal showModal={showModal} id={"virtualKeyboard"}
           closeModalEventListener={openModalEventListener}>
      <ModalCloseBtn closeModalEventListener={openModalEventListener} />

      <ModalHeader>
        <h3 className="modal-header">Tap your amount</h3>
      </ModalHeader>
      <ModalMainContent>
        <Calculator
          onAddAmountEventListener={onSetStakeAmountCalculatorListener}
          onResult={openModalEventListener}
          selectedValue={selectedValue}
        />
      </ModalMainContent>
    </Modal>
  );
};

export default VirtualKeyboard;
