import React from "react";
import { useTranslation } from "react-i18next";

import { Scrollbars } from "react-custom-scrollbars";

import {
  betTypesForBetsContainer,
  lifecycleStates,
  EVENT_COUNT_FOR_BET_TABLE,
  rowsForTriffecta
} from "./../../utils/constants";
import { getRowTitles } from "./helpers";

import MainBetOddsTable from "./oddsTables/MainBetOddsTable";
import ExactaBetOddsTable from "./oddsTables/ExactaBetOddsTable";
import TriffectaBetOddsTable from "./oddsTables/TriffectaBetOddsTable";
import QuinellaBetOddsTable from "./oddsTables/QuinellaBetOddsTable";
import TwoWayBetOddsTable from "./oddsTables/TwoWayBetOddsTable";

import CurrentBetHistoryContent from "../History/CurrentBetHistoryContent";
import Countdown from "../../components/Countdown/Countdown";
import Accordion from "../../components/Accordion/Accordion";

import { getDataForBetsTable } from "./helpers";
import PreLoader from "../../components/PreLoader/PreLoader";

const CurrentBetTable = ({
  eventsForBeting,
  betsObject,
  betType,
  raceType,
  eventResult,
  lifecyclesConfig,
  videoComplete,
  videoSizeStatus,
  openEvents,
  autoHeightMaxScrollBar,
  addBetsInBetSlip,
  onGetEventHistoryEventListener,
  lifecycleData,
  openFirstAccordion
}) => {
  const { i18n, t } = useTranslation();
  const getBetTableHeight = () => {
    return videoSizeStatus
      ? videoSizeStatus.tabContainer.height
      : autoHeightMaxScrollBar;
  };

  // function for calculate timestamp for end of current event
  const getTimestampStartCurrentEvent = (
    raceType,
    timestampStartByEventState,
    eventState
  ) => {
    let timestampStart = timestampStartByEventState;
    const lifecycleForCurrentRaceType = lifecyclesConfig[raceType];
    switch (eventState) {
      case lifecycleStates.BET_LOCKED:
        timestampStart =
          timestampStartByEventState - lifecycleForCurrentRaceType.betTime;
        break;
      case lifecycleStates.RACE:
        timestampStart =
          timestampStartByEventState -
          (lifecycleForCurrentRaceType.betTime +
            lifecycleForCurrentRaceType.betLockedTime);
        break;

      case lifecycleStates.DELAY:
        timestampStart =
          timestampStartByEventState -
          (lifecycleForCurrentRaceType.betTime +
            lifecycleForCurrentRaceType.betLockedTime +
            lifecycleForCurrentRaceType.raceTime);
        break;
      default:
        timestampStart = timestampStartByEventState;
        break;
    }
    return timestampStart;
  };

  const getEventDuration = (eventStatesDuration, raceType) => {
    let eventDuration = 0;
    if (!eventStatesDuration || !raceType) return eventDuration;
    //eslint-disable-next-line
    for (const [state, duration] of Object.entries(
      eventStatesDuration[raceType]
    )) {
      if (duration !== raceType) eventDuration = eventDuration + duration;
    }
    return eventDuration;
  };

  const handleScrollUpdate = () => {
    // if (!isCustomScroll)
    //   scrollbar.scrollTop(document.querySelector(".tabs-content").offsetHeight);
  };
  const handleManualScroll = () => {
    // setCustomScroll(true);
  };

  const getOpenCollapseHeight = () => {
    let collapseHeight = raceType.indexOf("8") !== -1 ? "23rem" : "18rem";
    if (betType === betTypesForBetsContainer.TRIFECTA) collapseHeight = "17rem";
    if (betType === betTypesForBetsContainer["2WAYBETS"])
      collapseHeight = "12rem";
    return collapseHeight;
  };

  // open first accordion always after racy type canged on status BET
  React.useEffect(()=>{
    if (eventsForBeting[0].timestamp && eventsForBeting[0].state === lifecycleStates.BET) openFirstAccordion(eventsForBeting[0].raceNumber)
    // eslint-disable-next-line
  }, [eventsForBeting])

  return (
    <div
      style={{
        // height: getBetTableHeight(),
        overflow: "hidden"
      }}
    >
      <Scrollbars
        className="bets-scrollbar"
        autoHeight
        autoHeightMin={getBetTableHeight()}
        onUpdate={e => handleScrollUpdate(e)}
        onScrollStart={e => handleManualScroll(e)}
      >
        {eventsForBeting.map((singleEvent, i) => {
          if (i >= EVENT_COUNT_FOR_BET_TABLE) return null;
          const oddsForSingleEvent =
            singleEvent.state === lifecycleStates.BET
              ? getDataForBetsTable(
                  eventsForBeting,
                  betType,
                  rowsForTriffecta,
                  getRowTitles(raceType)
                )[singleEvent.raceNumber][betType]
              : {};
          const currentEventHistory =
            (eventResult && singleEvent.state === lifecycleStates.DELAY) ||
            (eventResult &&
              singleEvent.state === lifecycleStates.RACE &&
              videoComplete)
              ? eventResult
              : null;

          let timeArangeForCountdown = 0;
          if (i === 0) {
            timeArangeForCountdown =
              lifecycleData && lifecycleData.timestamp > 0
                ? lifecycleData.timestamp +
                  lifecyclesConfig[raceType].betTime -
                  Date.now()
                : lifecyclesConfig[raceType].betTime;
          }

          if (i > 0){
            timeArangeForCountdown =
              lifecycleData && lifecycleData.timestamp > 0
                ? getTimestampStartCurrentEvent(
                    raceType,
                    lifecycleData.timestamp,
                    eventsForBeting[0].state
                  ) +
                  i * getEventDuration(lifecyclesConfig, raceType) -
                  Date.now() +
                  lifecyclesConfig[raceType].betTime
                : lifecyclesConfig[raceType].betTime * (i + 1) +
                  lifecyclesConfig[raceType].betTime;
          }
          const progressBarTimer =
            (!singleEvent.timestamp && i === 0) || timeArangeForCountdown < 0
              ? null
              : singleEvent.state === lifecycleStates.BET
              ? timeArangeForCountdown
              : null;

          // max timer countdown for the list(timer for item with i=3)
          let maxTimeArrangeInList = 0;
          // width of filler block with width animation
          let widthForAnimationFiller = 0;

          if (lifecyclesConfig[raceType]) {
            if (i === 0) {
              maxTimeArrangeInList = lifecyclesConfig[raceType].betTime;
              widthForAnimationFiller = progressBarTimer
                ? (timeArangeForCountdown * 100) / maxTimeArrangeInList
                : 0;
            }
          }

          function getOpenedStateForOddsTable() {
            let isOpen = false;
            if (singleEvent.state === lifecycleStates.DELAY || (singleEvent.state === lifecycleStates.RACE && videoComplete)) {
              isOpen = openEvents[currentEventHistory]
            } else {
              isOpen = openEvents[singleEvent.raceNumber]
            } 
            
            return isOpen;
          }

          const isOpenOddsTable = getOpenedStateForOddsTable();

          return (
            i < EVENT_COUNT_FOR_BET_TABLE && (
              <div
                key={i}
                className={
                  ((!singleEvent.timestamp && i === 0) ||
                    timeArangeForCountdown < 0) &&
                  singleEvent.state === lifecycleStates.BET
                    ? "show-preloader event-odds-item"
                    : "event-odds-item"
                }
              >
                {singleEvent.state === lifecycleStates.BET &&
                !singleEvent.timestamp &&
                i === 0 ? (
                  <PreLoader
                    showInParent={true}
                    showLoadingText={false}
                    isLoading={true}
                    isLightPreLoader={true}
                  />
                ) : singleEvent.state === lifecycleStates.BET && i > 0 ? (
                  <Countdown timeInMs={timeArangeForCountdown} />
                ) : singleEvent.state === lifecycleStates.BET &&
                  singleEvent.timestamp &&
                  i === 0 ? (
                  <div
                    id={`firstEventCountdownId`}
                    className={`countdown-container firstEventCountdown`}
                    data-timestamp={
                      lifecycleData.timestamp +
                      lifecyclesConfig[raceType].betTime
                    }
                  >
                    {""}
                  </div>
                ) : null}
                <Accordion
                  isOpen={isOpenOddsTable}
                  onToggle={onGetEventHistoryEventListener}
                  heading={`${t("event")} ${singleEvent.raceNumber}`}
                  secondaryHeading={
                    singleEvent.state === lifecycleStates.BET_LOCKED
                      ? t("bets.betsClosed")
                      : singleEvent.state === lifecycleStates.DELAY ||
                        (singleEvent.state === lifecycleStates.RACE &&
                          videoComplete)
                      ? t("bets.raceFinished")
                      : ""
                  }
                  delay={
                    singleEvent.state === lifecycleStates.DELAY ||
                    (singleEvent.state === lifecycleStates.RACE &&
                      videoComplete)
                  }
                  locked={singleEvent.state === lifecycleStates.BET_LOCKED}
                  alarm={
                    singleEvent.state === lifecycleStates.RACE && !videoComplete
                  }
                  dataStatus={singleEvent.state}
                  dataIndex={singleEvent.raceNumber}
                  progressBarTimer={i === 0 ? progressBarTimer : null}
                  progressBarWidth={i === 0 ? widthForAnimationFiller : null}
                />
                {((singleEvent.state === lifecycleStates.RACE &&
                  videoComplete) ||
                  singleEvent.state === lifecycleStates.DELAY) &&
                  currentEventHistory && (
                    <div
                      className="collapse-css-transition"
                      aria-hidden={"false"}
                      style={{
                        overflow: "hidden",
                        height: 250
                      }}
                    >
                      <CurrentBetHistoryContent results={currentEventHistory} />
                    </div>
                  )}
                {singleEvent.state !== lifecycleStates.DELAY && (
                  <div
                    className="collapse-css-transition"
                    aria-hidden={
                      openEvents[singleEvent.raceNumber] &&
                      singleEvent.state !== lifecycleStates.BET_LOCKED &&
                      singleEvent.state !== lifecycleStates.RACE
                        ? "false"
                        : "true"
                    }
                    style={{
                      overflow: "hidden",
                      height:
                        openEvents[singleEvent.raceNumber] &&
                        singleEvent.state !== lifecycleStates.BET_LOCKED &&
                        singleEvent.state !== lifecycleStates.RACE
                          ? getOpenCollapseHeight()
                          : 0,
                      paddingBottom:
                        openEvents[singleEvent.raceNumber] &&
                        singleEvent.state !== lifecycleStates.BET_LOCKED &&
                        singleEvent.state !== lifecycleStates.RACE
                          ? "2rem"
                          : 0,
                      visibility:
                        openEvents[singleEvent.raceNumber] &&
                        singleEvent.state !== lifecycleStates.BET_LOCKED &&
                        singleEvent.state !== lifecycleStates.RACE
                          ? null
                          : "hidden"
                    }}
                  >
                    {/*render table by active bet type for each event*/}
                    {oddsForSingleEvent &&
                      singleEvent.state === lifecycleStates.BET &&
                      betType === betTypesForBetsContainer.MAIN && (
                        <MainBetOddsTable
                          eventId={singleEvent.raceNumber}
                          onAddBetEventListener={addBetsInBetSlip}
                          columnTitles={[t("bets.winner"), t("bets.infirst3")]}
                          rowTitles={getRowTitles(raceType)}
                          oddsData={oddsForSingleEvent}
                          betsForEvent={
                            betsObject[singleEvent.raceNumber]
                              ? betsObject[singleEvent.raceNumber]
                              : {}
                          }
                          raceType={raceType}
                        />
                      )}
                    {oddsForSingleEvent &&
                      singleEvent.state === lifecycleStates.BET &&
                      betType === betTypesForBetsContainer.EXACTA && (
                        <ExactaBetOddsTable
                          eventId={singleEvent.raceNumber}
                          onAddBetEventListener={addBetsInBetSlip}
                          columnTitles={getRowTitles(raceType)}
                          rowTitles={getRowTitles(raceType)}
                          oddsData={oddsForSingleEvent.exacta}
                          betsForEvent={
                            betsObject[singleEvent.raceNumber]
                              ? betsObject[singleEvent.raceNumber]
                              : {}
                          }
                          raceType={raceType}
                          language={i18n.language}
                        />
                      )}
                    {oddsForSingleEvent &&
                      singleEvent.state === lifecycleStates.BET &&
                      betType === betTypesForBetsContainer.TRIFECTA && (
                        <TriffectaBetOddsTable
                          eventId={singleEvent.raceNumber}
                          onAddBetEventListener={addBetsInBetSlip}
                          columnTitles={getRowTitles(raceType)}
                          rowTitles={[t("bets.first"), t("bets.second"), t("bets.third")]}
                          oddsData={oddsForSingleEvent}
                        />
                      )}
                    {oddsForSingleEvent &&
                      singleEvent.state === lifecycleStates.BET &&
                      betType === betTypesForBetsContainer.QUINELLA && (
                        <QuinellaBetOddsTable
                          eventId={singleEvent.raceNumber}
                          onAddBetEventListener={addBetsInBetSlip}
                          columnTitles={getRowTitles(raceType)}
                          rowTitles={getRowTitles(raceType)}
                          oddsData={oddsForSingleEvent.quinella}
                          betsForEvent={
                            betsObject[singleEvent.raceNumber]
                              ? betsObject[singleEvent.raceNumber]
                              : {}
                          }
                          raceType={raceType}
                          language={i18n.language}
                        />
                      )}
                    {oddsForSingleEvent &&
                      singleEvent.state === lifecycleStates.BET &&
                      betType === betTypesForBetsContainer["2WAYBETS"] && (
                        <TwoWayBetOddsTable
                          eventId={singleEvent.raceNumber}
                          onAddBetEventListener={addBetsInBetSlip}
                          columnTitlesFirst={[t("bets.even"), t("bets.odd")]}
                          columnTitlesSecond={[t("bets.over"), t("bets.under")]}
                          oddsData={oddsForSingleEvent}
                          betsForEvent={
                            betsObject[singleEvent.raceNumber]
                              ? betsObject[singleEvent.raceNumber]
                              : {}
                          }
                          raceType={raceType}
                        />
                      )}
                  </div>
                )}
              </div>
            )
          );
        })}
      </Scrollbars>
    </div>
  );
};

export default CurrentBetTable;
