import React, { useEffect, useState } from "react";
import { useGlobalDataContext } from "../../../GlobalDataContext";

const TestVideo = ({ videLink }) => {
  const { state } = useGlobalDataContext();
  // eslint-disable-next-line
  const playerId = "mohiogaming";
  // eslint-disable-next-line
  const [playerWidth, setPlayerWidth] = useState(null);

  useEffect(() => {
      console.log('load video container')
    //   remove();
      load();
    
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (window.jwplayer) {
      window.jwplayer(playerId).setMute();
    }
    // eslint-disable-next-line
  }, []);

  // video complete event listener
  const onVideoCompleteEventListener = event => {
    if (window.jwplayer) {
    //   remove();
    console.log('onVideoComplete event listener')
    }
  };
//   const remove = () => {
//     if (window.jwplayer) {
//       window.jwplayer(playerId).remove();
//     }
//   };

  const load = () => {
    if (window.jwplayer) {
      window.jwplayer.key = "SbLw/rTJkBYP133hol2o5FCBHkE8QOge8TIZmotvFmc=";
      // console.log("videLink.replace({VIDEO_NAME}, state.videoName)", videLink.replace("{VIDEO_NAME}", state.videoName))
    
        let config = {
          width: "100%",
          aspectratio: "16:9",
          autostart: "viewable",
          autoPause: {
            "viewability": false,
          },
          start: 0,
          end: 240,
          seekRange: { start: 0, end: 240 },
          cast: {
            appid: "00000000"
          },
          controls: false,
          displaydescription: false,
          displaytitle: false,
          key: "SbLw/rTJkBYP133hol2o5FCBHkE8QOge8TIZmotvFmc=",
          mute: state.video.videoMute,
          ph: 3,
          pid: "kGWxh33Q",
          playbackRateControls: false,
          // playlist: [
          //   {
          sources: [
            {
              file: "https://video01.mohiogaming.com///vod/rb6h_043.smil/playlist.m3u8"
            }
          ],
          // }
          // ]
          preload: "auto",
          repeat: false,
          stagevideo: false,
          // autostart: "viewable",
          // autoplay: true,
          // flashplayer: "./../../../app/jwplayer/jwplayer.flash.swf",
            // preload: "metadata",
          // stretching: "fill"
          // stretching: "uniform"
         
        };
  
        if (window.jwplayer) {
          window
            .jwplayer(playerId)
            .setup(Object.assign(config, config))
            .seek(5)
            .on("error", e => {
              console.log("Failed to load video", e);
            })
            .on("setupError", e => {
              console.log("Failed to load video player", e);
            });
  
          window.jwplayer(playerId).onComplete(onVideoCompleteEventListener);
        }
      
      
    }
  };
  return (
    <div
      className="player-container background-video-fadein"
      style={{
        width: "100%",
        height: "auto !important",
        maxHeight: "auto !important",
        maxWidth: playerWidth,
        margin: "0 auto"
      }}
    >
      <div id={playerId}></div>
    </div>
  );
};

export default TestVideo;
