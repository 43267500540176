import React from "react";
import Scramble from "react-scramble";

const ScrambleEffectText = ({ value }) => {
  return (
    <Scramble
      style={{
          display: "inline-block",
          width: "4rem"
      }}
      autoStart
      text={value.toString()}
      steps={[
        {
          roll: 15,
          action: "+",
          type: "all"
        },
        {
          action: "-",
          type: "forward"
        }
      ]}
    />
  );
};

export default ScrambleEffectText;
