import React, { useEffect, useState } from "react";
import cx from "clsx";

import api from "./app/utils/api";
import { isPasswordExpired } from "./app/utils/helpers";
import {
  identifiers,
  statuses,
  DAYS_BEFORE_PASSWORD_EXPIRED,
} from "./app/utils/constants";

import { useGlobalDataContext } from "./GlobalDataContext";
import {
  setItemInStorage,
  getItemFromStorage,
} from "./app/utils/LocalStorageService";
import "./index.scss";
import EventHistory from "./app/containers/History";
import Header from "./app/containers/Header";
import VideoContainer from "./app/containers/Video";
import Bets from "./app/containers/Bets";
import Basket from "./app/containers/Basket";

import { ToastReact } from "./app/components/ToastMessage/index";
import ReactTooltipWrapper from "./app/components/Tooltip";

import { useTranslation } from "react-i18next";

import PreLoader from "./app/components/PreLoader/PreLoader";
import CreditsHistory from "./app/containers/Credits";

import ModalWindows from "./app/components/ModalWindows";
import WithdrawHistory from "./app/containers/Withdraw";

import {
  getActiveBetsObject,
  getSortedFutureEvents,
} from "./app/containers/Bets/helpers";
import { saveTokenData } from "./app/components/Authorization/TokenServiceStorage";
import { ChangeGame } from "./app/mixins/ChangeGame";

function GameScreen() {
  const { state, dispatch } = useGlobalDataContext();
  const { i18n } = useTranslation();
  const [showLoading, toggleLoading] = useState(true);
  const [expiredRaceIds, setExpiredRaceIds] = useState([]);
  const [startGame, setStartGame] = useState(false);

  useEffect(() => {
    // if platformId paremeter is exists in url than make reqeuest with user_token parameter to get access token for api
    const getParamsPairs = window.location.search
      .replace("?", "")
      .split("&")
      .map((paramPair) => {
        const param = paramPair.split("=");
        return [param[0], param[1]];
      });

    const demoPlay = [...getParamsPairs].filter((oneParamArr) => {
      return oneParamArr[0] === "mode";
    });
    const demoToken = [...getParamsPairs].filter((oneParamArr) => {
      return oneParamArr[0] === "token";
    });

    const isLanguage = [...getParamsPairs].filter((oneParamArr) => {
      return oneParamArr[0] === "language";
    });

    const platformIdArr = [...getParamsPairs].filter((oneParamArr) => {
      return oneParamArr[0] === "platformId";
    });

    let gameIdArr = [...getParamsPairs].filter((oneParamArr) => {
      return oneParamArr[0] === "gameID";
    });
    gameIdArr = Array.from(new Set(gameIdArr));

    const platformId = platformIdArr.length > 0 ? platformIdArr[0][1] : null;

    const allLanguages = ["ru", "es", "yi", "en"];

    if (isLanguage.length > 0)
      if (allLanguages.includes(isLanguage[0][1].toString())) {
        i18n.changeLanguage(isLanguage[0][1].toString());
      }

    if (demoPlay.length > 0)
      if (demoPlay[0][1] === "demo") {
        let token = {
          acess_token: demoToken[0][1],
          refresh_token: demoToken[0][1],
          scope: "read write",
          expires_in: "88888",
          token_type: "bearer",
        };
        dispatch({
          type: "SET_DEMO_GAME_VERSION",
          payload: true,
        });
        saveTokenData(token);
      }

    if (gameIdArr.length > 0) {
      dispatch({
        type: "SET_GAMES_SETTINGS",
        payload: gameIdArr,
      });
      dispatch({
        type: "SET_RBI",
        payload: true,
      });
    }

    if (platformId && getParamsPairs[0][1] !== undefined) {
      console.log(
        "platformId params was detected in query params of iframe window"
      );
      dispatch({
        type: "SET_PARENT_WALLET_USING",
        payload: true,
      });

      let queryStringForRequest = "";
      [...getParamsPairs].forEach((oneParamArr) => {
        if (oneParamArr[0] === "platformId") return;
        queryStringForRequest += `&${oneParamArr.join("=")}`;
      });

      try {
        console.log(
          `trying to get access token from iframe window with platformId = ${platformId} param`
        );
        api
          .getAccessTokenForIframe({
            queryStringForRequest: queryStringForRequest,
            platformId: platformId,
          })
          .then((tokenDataForIframe) => {
            // test token data
            // const tokend = {
            //   access_token: "03cac7bd-6b3f-4cb9-9877-963a74d538ba",
            //   token_type: "bearer",
            //   refresh_token: "7a766548-5adb-4720-8997-6c134e7cf738",
            //   expires_in: "99999",
            //   scope: "read write",
            // };

            if (tokenDataForIframe.error) {
              // show the modal window with error message - need to refresh the parent page  to refresh the value of token in iframe url
              console.log(
                "show the modal window with error message - need to refresh the parent page  to refresh the value of token in iframe url"
              );
              toggleModal("iframeTokenDataErrorModal");
            } else {
              saveTokenData(tokenDataForIframe);
              fetchCurrentUserData();
            }
          });
      } catch (e) {
        console.log("error in get iframe token data request");
      }
    } else {
      fetchCurrentUserData();
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // upload new future event only if one event array by one race type has length < 4
    if (
      Object.keys(state.futureEvents).length === 0 ||
      Object.keys(state.futureEvents).length !== Object.keys(identifiers).length
    )
      return;
    uploadNewFutureEvents();
    // eslint-disable-next-line
  }, [state.futureEvents]);

  useEffect(() => {
    //show jackpot winner modal window if user won jackpot(this data recieved by websocket connection)
    if (state.jackpotStatus && state.jackpotStatus.fallen)
      toggleModal("jackpotWinner");
    // eslint-disable-next-line
  }, [state.jackpotStatus]);

  useEffect(() => {
    // show super jackpot winner modal window if user won jackpot(this data recieved by websocket connection)
    if (state.superJackpotStatus && state.superJackpotStatus.fallen)
      toggleModal("jackpotWinner");
    // eslint-disable-next-line
  }, [state.superJackpotStatus]);

  useEffect(() => {
    const gameListing = state.rbi === true ? state.rbiGames : state.allGames;

    if (startGame && gameListing.length) {
      if (gameListing.length === 1) {
        onChangeGameEventListenerInHeader(gameListing[0]);
      } else {
        toggleModal("gameSelectModal");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startGame, state.rbiGames, state.allGames]);

  // dispatch event for showing modal window
  const toggleModal = (modalName) => {
    dispatch({
      type: "OPEN_MODAL_WINDOW",
      payload: modalName,
    });
  };

  // upload new future events (if at least one array of future event for one of race type < LIMIT_FEVENT_COUNT_FOR_UPLOAD)
  const uploadNewFutureEvents = async () => {
    const objOfLength = {}; // object of length values of future events by each type
    for (const type of Object.keys(identifiers)) {
      objOfLength[type] = state.futureEvents[type].length;
    }
    const LIMIT_FEVENT_COUNT_FOR_UPLOAD = 4;
    let startFetchFutureEvent = false;
    // upload future events if at least one array of future events has length < 4
    for (const value of Object.values(objOfLength)) {
      if (value > 0 && value < LIMIT_FEVENT_COUNT_FOR_UPLOAD) {
        startFetchFutureEvent = true;
      }
    }

    if (startFetchFutureEvent) {
      await fetchFutureEvents();
    }
  };

  // fetch future events
  const fetchFutureEvents = async () => {
    const futureEvents = await api.getFutureEvents();
    if (futureEvents.errorMsg) {
      toggleModal(null);
      if (futureEvents.status === statuses.internalError)
        toggleModal("internalErrorModal");
      dispatch({
        type: "SAVE_FUTURE_EVENTS",
        payload: state.futureEvents,
      });
    } else {
      dispatch({
        type: "SAVE_FUTURE_EVENTS",
        payload: getSortedFutureEvents(futureEvents),
      });
    }
  };

  // fetch duration values for each state of event lifecycle  for each race types
  const fetchLifecyclesConfig = async () => {
    const lifecyclesConfig = await api.getLifecyclesConfig();
    if (lifecyclesConfig.errorMsg) {
      toggleModal(null);
      if (lifecyclesConfig.status === statuses.internalError)
        toggleModal("internalErrorModal");

      dispatch({
        type: "SAVE_LIFECYCLE_CONFIG",
        payload: {},
      });
    } else {
      const lifecycleConfigObj = {};
      for (const lfconfigbyracetype of lifecyclesConfig) {
        lifecycleConfigObj[lfconfigbyracetype.raceType] = lfconfigbyracetype;
      }
      dispatch({
        type: "SAVE_LIFECYCLE_CONFIG",
        payload: lifecycleConfigObj,
      });
    }
  };

  // let api know who is logged in current session
  const fetchCurrentUserData = async () => {
    try {
      const resJson = await api.getCurrentUserData();
      if (resJson.errorMsg) {
        toggleModal(null);
        if (resJson.status === statuses.internalError)
          toggleModal("internalErrorModal");
      } else {
        dispatch({
          type: "SET_CURRENT_USER_DATA",
          payload: resJson,
        });

        await fetchFutureEvents();
        await fetchLifecyclesConfig();

        if (!state.raceType) {
          const isCredsSaved = resJson.createWalletDTO.enabled === true;
          // if user have already save his creds than show game selected modal
          if (
            (state.isWebGameVersion && isCredsSaved) ||
            !state.isWebGameVersion ||
            state.isDemoGameVersion
          ) {
            const appSettings = await api.getAppsSettings();

            if (appSettings.errorMsg) {
              toggleModal(null);
              if (appSettings.status === statuses.internalError)
                toggleModal("internalErrorModal");
            } else {
              dispatch({
                type: "SET_APP_SETTINGS",
                payload: appSettings,
              });

              if (!state.isWebGameVersion && !isCredsSaved) {
                const enableWallet = await api.enableWalletForUser(resJson.id);

                toggleLoading(true);

                if (!enableWallet.errorMsg) {
                  const userDataTerminal = await api.getCurrentUserData();
                  if (!resJson.errorMsg) {
                    dispatch({
                      type: "SET_CURRENT_USER_DATA",
                      payload: userDataTerminal,
                    });
                  }
                }
              } // send request for user data update

              toggleLoading(false);
              if (appSettings.enabledGames.length === 1) {
                onChangeGameEventListenerInHeader(appSettings.enabledGames[0]);
              } else {
                // show change expired password popup only if password expired or before expiring left 5 days
                // show this popup only once per day
                if (
                  state.isWebGameVersion &&
                  (isPasswordExpired(resJson.daysToPasswordExpiring)
                    .isExpired ||
                    isPasswordExpired(resJson.daysToPasswordExpiring)
                      .daysToExpired < DAYS_BEFORE_PASSWORD_EXPIRED) &&
                  new Date(
                    getItemFromStorage("dateOfSkipChangeExpiredPsw")
                  ).getDate() !== new Date().getDate()
                ) {
                  toggleModal("updateUserPassword");
                } else {
                  setStartGame(true);
                  console.log("run front");
                }
              }
            }
          }

          // show user data modal only for webGameVersion and only if user still do not save theirs creds
          if (state.isWebGameVersion && !isCredsSaved) {
            toggleLoading(false);
            toggleModal("saveUserCreds");
          }
        }
      }
    } catch (error) {
      console.log("User data fetching was failed!");
    }
  };

  // change game event listener (in game's list in header mobile and desktop version)
  const onChangeGameEventListenerInHeader = (newGameIdentifier) => {
    let allRaceTypeData = getItemFromStorage("allRaceTypeData", true);

    ChangeGame(
      newGameIdentifier,
      state,
      dispatch,
      allRaceTypeData,
      getActiveBetsObject,
      toggleModal,
      setExpiredRaceIds,
      setItemInStorage
    );

    /**
     * save bets from betslip by current raceType
     * */
    let allRaceTypeData_ = { ...allRaceTypeData };
    if (allRaceTypeData) {
      if (allRaceTypeData[state.raceType]) {
        allRaceTypeData_[state.raceType].betsObject = { ...state.betsObject };
      } else {
        allRaceTypeData_[state.raceType] = {};
        allRaceTypeData_[state.raceType].betsObject = { ...state.betsObject };
      }
    } else {
      allRaceTypeData_[state.raceType] = {};
      allRaceTypeData_[state.raceType].betsObject = { ...state.betsObject };
    }

    setItemInStorage("allRaceTypeData", allRaceTypeData_, true);

    // // show modal window with info about deleted bets by not available events
    // if (activeBetsObject.expiredRaceIds.length > 0) {
    //   setExpiredRaceIds(activeBetsObject.expiredRaceIds);
    //   toggleModal("showInfoExpiredEvents");
    // }
    //
    // const betsObject = activeBetsObject.betsObject;
    //
    // dispatch({
    //   type: "CHANGE_GAME",
    //   payload: {
    //     raceType: newGameIdentifier,
    //     lifecycleData: lifecycleData,
    //     currentLifecycleState: currentLifecycleState,
    //     videoName: videoName,
    //     timeStampVideo: timeStampVideo,
    //     videoProgressLine: videoProgressLine,
    //     eventsOdds: eventsOdds,
    //     eventHistory: eventHistory,
    //     betsObject: betsObject
    //   }
    // });

    // setItemInStorage("gameIdentifier", newGameIdentifier);
  };

  const updateUsersCredits = async () => {
    const resJson = await api.getCurrentUserData();

    if (resJson.errorMsg) {
      if (resJson.status === statuses.internalError)
        toggleModal("internalErrorModal");
    } else {
      dispatch({
        type: "SET_CURRENT_USER_DATA",
        payload: resJson,
      });
    }
  };

  return (
    <>
      <div className="game-screen-model">
        <Header onChangeGameEventListener={onChangeGameEventListenerInHeader} />
        <div className="body fli-1 fl-cntr pos-rel">
          <div
            className={cx("fli-3 event-history-aside fl-cntr-left-col", {
              [`show-aside`]: state.slideAsideBlockId === "eventHistory",
              [`preloader-layer`]: state.eventsOdds.length === 0,
            })}
          >
            <EventHistory />
          </div>
          <main className="fli-6 fl-cntr-col">
            <VideoContainer />
            <Bets />
          </main>
          <div
            className={cx("fli-4 basket-aside fl-cntr-right-col", {
              [`show-aside`]:
                state.slideAsideBlockId === "betHistory" ||
                state.slideAsideBlockId === "betSlip",
              [`bet-slip-mobile`]: state.slideAsideBlockId === "betSlip",
              [`bet-history-mobile`]: state.slideAsideBlockId === "betHistory",
              [`preloader-layer`]: state.eventsOdds.length === 0,
            })}
          >
            <Basket />
          </div>
          <div
            className={cx("credits-aside fl-cntr-right-col", {
              [`show-aside`]: state.slideAsideBlockId === "creditHistory",
              [`credits-history-mobile`]:
                state.slideAsideBlockId === "creditHistory",
              [`preloader-layer`]: state.eventsOdds.length === 0,
            })}
          >
            <CreditsHistory toggleModal={toggleModal} />
          </div>
          <div
            className={cx("withdraw-aside fl-cntr-right-col", {
              [`show-aside`]: state.slideAsideBlockId === "withdrawHistory",
              [`withdraw-history-mobile`]:
                state.slideAsideBlockId === "withdrawHistory",
              [`preloader-layer`]: state.eventsOdds.length === 0,
            })}
          >
            <WithdrawHistory />
          </div>
        </div>
      </div>
      {/*container for toast messages*/}
      <ToastReact />
      {/*tooltip component*/}
      <ReactTooltipWrapper />

      <PreLoader isLoading={showLoading} />
      {/*modal windows with theirs event listeners functions*/}
      <ModalWindows
        fetchCurrentUserData={updateUsersCredits}
        setExpiredRaceIds={setExpiredRaceIds}
        expiredRaceIds={expiredRaceIds}
        toggleLoading={toggleLoading}
        toggleModal={toggleModal}
      />
    </>
  );
}

export default GameScreen;
