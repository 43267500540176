import React, { useEffect } from "react";
import { useGlobalDataContext } from "../../../GlobalDataContext";

import SecondScreen from "./../../components/SecondScreen";

const SecondScreenVideo = () => {
  const { state, dispatch } = useGlobalDataContext();
  // eslint-disable-next-line
  const playerId = "mohiogaming";

  const getSendingDataForSecondScreen = () => {
    const videoConfig = {
      width: "100%",
      aspectratio: "16:9",
      autostart: "viewable",
      // autoplay: true,
      start: 0,
      end: 240,
      seekRange: { start: 0, end: 240 },
      cast: {
        appid: "00000000"
      },
      controls: false,
      displaydescription: false,
      displaytitle: false,
      flashplayer: "./../../../app/jwplayer/jwplayer.flash.swf",
      key: "SbLw/rTJkBYP133hol2o5FCBHkE8QOge8TIZmotvFmc=",
      mute: state.video.videoMute,
      ph: 3,
      pid: "kGWxh33Q",
      playbackRateControls: false,
      sources: [
        {
          file: state.videoUrl.replace("{VIDEO_NAME}", state.videoName)
        }
      ],
      preload: "auto",
      repeat: false,
      stagevideo: false
    };

    const sendData = {
      playerId: playerId,
      videoConfig: state.videoName ? videoConfig : {},
      lifecycleState: state.currentLifecycleState,
      raceType: state.raceType,
      seekStartTime:
        state.timeStampVideo !== 0 &&
        Number(new Date().getTime()) > state.timeStampVideo
          ? (Number(new Date().getTime()) - state.timeStampVideo) / 1000
          : 0
    };
    return sendData;
  };

  useEffect(() => {
    const secondScreenObj = new SecondScreen({
      secondScreenUrl: `/secondScreen/secondScreen.html`
    });
    secondScreenObj.getAvailability();
    if (!state.isWebGameVersion) {
      dispatch({
        type: "SET_SECOND_SCREEN_INSTANCE",
        payload: secondScreenObj
      });
      // setTimeout(() => {
      //   console.log("time out state.secondScreenInstance", state.secondScreenInstance);
      //   if (
      //     secondScreenObj &&
      //     secondScreenObj.supportSecondScreen &&
      //     secondScreenObj.presentationConnection
      //   )
      //     secondScreenObj.sendMessage(getSendingDataForSecondScreen());
      // }, 5000);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      state.secondScreenInstance &&
      state.secondScreenInstance.supportSecondScreen &&
      state.secondScreenInstance.presentationConnection
    )
      state.secondScreenInstance.sendMessage(getSendingDataForSecondScreen());
    // eslint-disable-next-line
  }, [state.raceType, state.currentLifecycleState, state.videoUrl]);

  return <div></div>;
};

export default SecondScreenVideo;
