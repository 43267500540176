import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalMainContent,
  ModalCloseBtn
} from "./../Modal/Modal";
import ActionButton from "../ActionButton";

import Calculator from "../Calculator/Calculator";
import ReactTooltip from "react-tooltip";
import { isMobile, getFloor } from "../../utils/helpers";

import { toastreact } from "../ToastMessage";
import { toastMessageTypes } from "../ToastMessage/constants";
import { inputsNames } from "../../utils/constants";

const WithdrawMoneyModal = ({
  showModal,
  openModalEventListener,
  withdrawMoneyCode,
  isWebGameVersion,
  creditsBalance,
  withdrawMoneyEventListener,
  currency,
  threshold
}) => {
  const { t } = useTranslation();

  const [showVirtualKeyboard, toggleVirtualKeyboard] = useState(false);
  const [withdrawMoneyValue, setWithdrawMoneyValue] = useState(
    isWebGameVersion ? creditsBalance : -1
  );

  const errorMessages = {
    [inputsNames.WITHDRAWMONEY]: t(
      "modalWindows.logoutWithdrawModal.typeAmount"
    )
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [withdrawMoneyValue]);

  // update value of withdraw money input
  useEffect(() => {
    if (creditsBalance > 0)
      if (threshold > 0 && !isWebGameVersion) {
        setWithdrawMoneyValue(
          getFloor(
            creditsBalance >= threshold ? threshold - 1 : creditsBalance,
            100
          )
        );
      }

    if (isWebGameVersion) setWithdrawMoneyValue(getFloor(creditsBalance, 100));
    // eslint-disable-next-line
  }, [creditsBalance, threshold]);

  const onChangeEventListener = e => {
    const valueWithdrawMoney = e.currentTarget ? e.currentTarget.value : e;
    if (isMobile.any() && valueWithdrawMoney > creditsBalance) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: errorMessages[inputsNames.WITHDRAWMONEY].replace(
          "%d%",
          `${creditsBalance}`
        )
      });
    }
    // disable type non number in input but allow empty value ('')
    const reg = new RegExp("^\\d+$");
    let formatedValue = valueWithdrawMoney.replace(",", ".");
    if (formatedValue.length > 0) {
      setWithdrawMoneyValue(
        reg.test(formatedValue.replace(".", ""))
          ? getFloor(formatedValue, 100)
          : getFloor(withdrawMoneyValue, 100)
      );
    } else {
      setWithdrawMoneyValue(getFloor(formatedValue, 100));
    }
  };

  // withdraw money request
  const onWithdrawMoneyEventListener = event => {
    event.preventDefault();
    if (event.currentTarget.classList.contains("disable")) return;
    withdrawMoneyEventListener(withdrawMoneyValue);
  };
  const onCloseModal = e => {
    openModalEventListener(e);
    setWithdrawMoneyValue(getFloor(creditsBalance, 100));

    toggleVirtualKeyboard(false);
  };

  return (
    <Modal
      showModal={showModal}
      id={"withdrawMoney"}
      customClass={`withdraw-money`}
      closeModalEventListener={onCloseModal}
    >
      <ModalCloseBtn closeModalEventListener={onCloseModal} />

      <ModalHeader>
        <h3>
          {t("modalWindows.withdrawMoneyModal.title")}:
          <p
            style={{
              marginTop: "4px",
              fontSize: "1rem",
              fontWeight: "400",
              color: "#e29f01"
            }}
          >
            {creditsBalance >= threshold &&
              !isWebGameVersion &&
              t("modalWindows.withdrawMoneyModal.warningMsg")
                .replace("%threshold%", threshold - 1)
                .replace("%currency%", currency)}
          </p>
          <p style={{ marginTop: "4px", fontSize: "1rem", fontWeight: "400" }}>
            {t("modalWindows.withdrawMoneyModal.availableAmount")} {getFloor(creditsBalance, 100)} {currency}
          </p>
        </h3>
      </ModalHeader>

      <ModalMainContent>
        {showVirtualKeyboard ? (
          <Calculator
            onAddAmountEventListener={onChangeEventListener}
            onResult={() => {
              toggleVirtualKeyboard(false);
            }}
            selectedValue={withdrawMoneyValue}
            isKeyboard={false}
          />
        ) : withdrawMoneyCode ? (
          <div className="centered-block align-center">
            <hr />
            {t("modalWindows.logoutWithdrawModal.showCode")}:
            <hr />
            <h2>
              <strong>{withdrawMoneyCode}</strong>
            </h2>
          </div>
        ) : (
          <div className="input-wrap centered-block align-center">
            <div className="flex-row flex-align-baseline align-left form-group ">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  onClick={e => {
                    if (isMobile.any()) toggleVirtualKeyboard(true);
                  }}
                  value={`${withdrawMoneyValue}`}
                  onChange={onChangeEventListener}
                  type="text"
                  placeholder={t("modalWindows.logoutWithdrawModal.moneyAmountPlacehold")}
                  name="withdrawMoneyAmount"
                  id="withdrawMoneyAmount"
                  // readOnly={isMobile.any() || !isWebGameVersion}
                  readOnly={isMobile.any()}
                  className={cx("with-placeholder", {
                    error: withdrawMoneyValue > creditsBalance,
                    success:
                      withdrawMoneyValue <= creditsBalance &&
                      withdrawMoneyValue > 0
                  })}
                />
                <label htmlFor="withdrawMoneyAmount">
                  {t("modalWindows.logoutWithdrawModal.moneyAmountPlacehold")}
                </label>
                <span className={"valid-icon"}></span>
                <span
                  className={cx(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        !isMobile.any() && withdrawMoneyValue > creditsBalance
                    }
                  )}
                >
                  {errorMessages[inputsNames.WITHDRAWMONEY].replace(
                    "%d%",
                    `${getFloor(creditsBalance, 100)}`
                  )}
                </span>
              </div>
            </div>

            <ActionButton
              tooltip={
                withdrawMoneyValue < 1 || withdrawMoneyValue > creditsBalance
                  ? t("modalWindows.logoutWithdrawModal.prefferedAmount")
                  : ""
              }
              onclick={onWithdrawMoneyEventListener}
              classesName={cx("btn btn-warning", {
                disable:
                  withdrawMoneyValue === 0 ||
                  withdrawMoneyValue > creditsBalance ||
                  (!isWebGameVersion && withdrawMoneyValue > threshold)
              })}
              isWarning={true}
            >
              {withdrawMoneyValue < 1 || withdrawMoneyValue > creditsBalance
                ? t("modalWindows.logoutWithdrawModal.typeCorrect")
                : `${t("modalWindows.logoutWithdrawModal.generateCodeFor")} ${getFloor(
                    withdrawMoneyValue,
                    100
                  )} ${currency}`}
            </ActionButton>
          </div>
        )}
      </ModalMainContent>
    </Modal>
  );
};

export default WithdrawMoneyModal;
