/** set videoSizeStatus property*/
/**
 *
 * @return {object} An action object with a type of SET_VIDEO_SIZE
 */
export function setVideoSize(videoSizeStatus) {
    return {
        payload: videoSizeStatus,
        type: "SET_VIDEO_SIZE"
    };
}