import cn from "clsx";
import {
  inputsNames,
  DAYS_BEFORE_PASSWORD_EXPIRED
} from "../../utils/constants";
import { isMobile } from "../../utils/helpers";
import { setItemInStorage } from "./../../utils/LocalStorageService";
import isSuccessValidation from "./../../utils/validationFormField";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import {
  Modal,
  ModalCloseBtn,
  ModalHeader,
  ModalMainContent
} from "./../Modal/Modal";
import Calculator from "../Calculator/Calculator";
import ActionButton from "../ActionButton";
import { toastreact } from "../ToastMessage";
import { toastMessageTypes } from "../ToastMessage/constants";

import UserEmailModalWindow from "./../ModalWindows/UserEmailModalWindow";
import UserEmailStatusModalWindow from "./../ModalWindows/UserEmailStatusModalWindow";

const UserPasswordUpdateModal = ({
  userData,
  showModal,
  openModalEventListener,
  onUpdateUserPassword,
  isWebGameVersion,
  isPasswordExpired = {}
}) => {
  const { t } = useTranslation();

  const [userPin, setUserPin] = useState(
    userData && userData.pinCode !== null ? userData.pinCode : ""
  ); // required
  const [userPinConfirm, setUserPinConfirm] = useState(
    userData && userData.pinCode !== null ? userData.pinCode : ""
  ); // required
  const [userOldPin, setUserOldPin] = useState(""); // required

  // set if current user's password is correct or not
  const [currentPswErrorMsg, setCurrentPswErrorMsg] = useState(null);
  const [newPswErrorMsg, setNewPswErrorMsg] = useState(null);

  const [isAllowSaveBtn, setIsAllowSaveBtn] = useState(false);
  const [showVirtualKeyboard, toggleVirtualKeyboard] = useState(false);
  const [focusedInputName, setFocusedInputName] = useState(null);
  const [validErrors, setValidError] = useState({});
  const [validSuccess, setValidSuccess] = useState({
    [inputsNames.OLDPASSWORD]: false,
    [inputsNames.USERPASSWORD]: false,
    [inputsNames.PASSWORDCONFIRM]: false
  });

  // show popup for recovery password email sending
  const [showMailPswRecoveryModal, setShowMailPswRecoveryModal] = useState(
    false
  );
  // show popup with success message after email was sended
  const [
    showMailPswRecoverySuccessModal,
    setShowMailPswRecoverySuccessModal
  ] = useState(false);

  // Validation error messages
  const errorMessages = {
    emailExists: t("modalWindows.userCredsModal.emailExist"),
    userExists: t("modalWindows.userCredsModal.userExist"),
    [inputsNames.LOGIN]: t("modalWindows.userCredsModal.correctName"),
    [inputsNames.EMAIL]: t("modalWindows.userCredsModal.correctMail"),
    [inputsNames.OLDPASSWORD]: t(
      "modalWindows.userCredsModal.correctPswLength"
    ),
    [inputsNames.USERPASSWORD]: t("modalWindows.userCredsModal.correctPin"),
    [inputsNames.PASSWORDCONFIRM]: t("modalWindows.userCredsModal.confirmPin"),
    [inputsNames.IDNUMBER]: t("modalWindows.userCredsModal.correctID")
  };

  // submit btn will be allowed only if pins are confirmed and login is valid
  const validateForm = () => {
    setIsAllowSaveBtn(
      validErrors[inputsNames.USERPASSWORD] === false &&
        userPin === userPinConfirm
    );
  };

  // show virtual keyboard
  const onShowVirtualKeyboardEventListener = event => {
    setCurrentPswErrorMsg(null);
    setNewPswErrorMsg(null);
    setFocusedInputName(event.currentTarget.id);
    toggleVirtualKeyboard(true);
  };
  const onFocuseEventHandler = event => {
    if (
      event.currentTarget.name === inputsNames.OLDPASSWORD &&
      typeof currentPswErrorMsg === "string"
    )
      setCurrentPswErrorMsg(null);
    setNewPswErrorMsg(null);
    if (
      event.currentTarget.name === inputsNames.OLDPASSWORD &&
      validErrors[inputsNames.OLDPASSWORD]
    )
      setValidError({
        ...validErrors,
        [inputsNames.OLDPASSWORD]: false
      });

    setFocusedInputName(event.currentTarget.name);
  };

  // set value of focusedInputName input
  const validateInputValue = value => {
    // set the value for its input
    let validationResult = null; // true if valid value
    // USERPASSWORD
    if (focusedInputName === inputsNames.USERPASSWORD) {
      validationResult = isSuccessValidation[inputsNames.USERPASSWORD](value);
      // set validation error
      const validErrors_ = {
        ...validErrors,
        [inputsNames.USERPASSWORD]: !validationResult
      };
      setValidError(validErrors_);
      // set success validation
      const validSuccess_ = {
        ...validSuccess,
        [inputsNames.USERPASSWORD]: validationResult
      };
      setValidSuccess(validSuccess_);

      setValidError({
        ...validErrors_,
        [inputsNames.PASSWORDCONFIRM]: !(value.trim() === userPinConfirm.trim())
      });
      setValidSuccess({
        ...validSuccess_,
        [inputsNames.PASSWORDCONFIRM]: value.trim() === userPinConfirm.trim()
      });
      setUserPin(value.trim());
      if (!validationResult) {
        if (isMobile.any()) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: errorMessages[inputsNames.USERPASSWORD]
          });
        }
      }
    }
    // OLDPASSWORD
    if (focusedInputName === inputsNames.OLDPASSWORD) {
      // set validation error
      setValidError({
        ...validErrors,
        [inputsNames.OLDPASSWORD]: value.trim().length < 8
      });
      // set success validation
      setValidSuccess({
        ...validSuccess,
        [inputsNames.OLDPASSWORD]: value.trim().length >= 8
      });
      setUserOldPin(value.trim());
      if (value.trim().length < 8) {
        if (isMobile.any()) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: errorMessages[inputsNames.OLDPASSWORD]
          });
        }
      }
    }
    // PASSWORDCONFIRM
    if (focusedInputName === inputsNames.PASSWORDCONFIRM) {
      // set validation error
      setValidError({
        ...validErrors,
        [inputsNames.PASSWORDCONFIRM]: !(value.trim() === userPin.trim())
      });
      // set success validation
      setValidSuccess({
        ...validSuccess,
        [inputsNames.PASSWORDCONFIRM]: value.trim() === userPin.trim()
      });
      setUserPinConfirm(value.trim());
      if (!(value.trim() === userPin.trim())) {
        if (isMobile.any()) {
          toastreact({
            type: toastMessageTypes.ERROR,
            title: errorMessages[inputsNames.PASSWORDCONFIRM]
          });
        }
      }
    }
  };

  // check if need validate input value by oncnange event
  const isStartValidateOnchangeEvent = target => {
    const inputName = target.name;
    let countOfValidationSuccess = 0;
    const validSuccessLength = Object.keys(validSuccess).length;
    if (validSuccess[inputName] === false) {
      for (const validationName of Object.keys(validSuccess)) {
        if (
          validationName !== inputName &&
          validSuccess[validationName] === true
        )
          countOfValidationSuccess++;
      }
    }
    return countOfValidationSuccess === validSuccessLength - 1;
  };

  // execute function for save user's creds
  const onSubmitEventHandler = () => {
    if (!isAllowSaveBtn) return;
    onUpdateUserPassword({
      id: userData.id,
      oldPassword: userOldPin,
      newPassword: userPin
    }).then(res => {
      if (!res) return;
      let res_;
      if (
        res === "New password was used recently" ||
        res === "New password is the same as the old one"
      ) {
        res_ = res.indexOf('recently') !== -1 ? t("modalWindows.pswUpdateModal.pswUsedRecently") : t("modalWindows.pswUpdateModal.pswrdsSame")
        setNewPswErrorMsg(res_);
      }

      if (res === "Old password is wrong"){
        res_ = t("modalWindows.pswUpdateModal.oldPswWrong");
        setCurrentPswErrorMsg(res_);

      }

      toastreact({
        type: toastMessageTypes.ERROR,
        title: res_
      });
    });
  };

  // change input value for desktop version of webGameVersion app
  const onChangeEventListener = e => {
    const value = e.currentTarget.value.trim();
    if (
      e.currentTarget.name !== inputsNames.PASSWORDCONFIRM &&
      isStartValidateOnchangeEvent(e.currentTarget)
    )
      validateInputValue(value);
    switch (focusedInputName) {
      case inputsNames.OLDPASSWORD:
        setUserOldPin(value);

        break;
      case inputsNames.USERPASSWORD:
        setUserPin(value);

        break;
      case inputsNames.PASSWORDCONFIRM:
        validateInputValue(value);
        break;

      default:
        break;
    }
  };

  // validate input's value after focusout event
  const onFocusOutEventListener = e => {
    validateInputValue(e.currentTarget.value);
  };

  // validate form after creds changed
  React.useEffect(() => {
    if (!userPin && !userPinConfirm) return;
    validateForm();
    ReactTooltip.rebuild();

    // eslint-disable-next-line
  }, [userPin, userPinConfirm]); //idNumber

  const onCloseModal = e => {
    openModalEventListener(e);
    toggleVirtualKeyboard(false);
    // store date of closing of change expired password popup in localStorage
    if (
      isPasswordExpired.daysToExpired > -1 &&
      isPasswordExpired.daysToExpired < DAYS_BEFORE_PASSWORD_EXPIRED
    ) {
      // save date in localStorage
      setItemInStorage("dateOfSkipChangeExpiredPsw", new Date());
    }
  };

  // open send mail for password recovery modal window
  const toggleMailPswRecoveryModal = () => {
    setShowMailPswRecoveryModal(!showMailPswRecoveryModal);
  };

  // close all opened submodals after email for password recovery was sended successfuly
  const onCloseSubmodals = () => {
    setShowMailPswRecoverySuccessModal(false);
    setShowMailPswRecoveryModal(false);
  };

  return (
    <Modal
      showModal={showModal}
      id={"updateUserPassword"}
      closeModalEventListener={onCloseModal}
      restrictCloseModalOnBackdrop={
        isPasswordExpired.isExpired ||
        isPasswordExpired.daysToExpired < DAYS_BEFORE_PASSWORD_EXPIRED
      }
    >
      {!isPasswordExpired.isExpired && (
        <ModalCloseBtn closeModalEventListener={onCloseModal} />
      )}

      {isPasswordExpired.daysToExpired < DAYS_BEFORE_PASSWORD_EXPIRED &&
        !isPasswordExpired.isExpired && (
          <span
            className="modal-close-btn"
            onClick={onCloseModal}
            data-modal={"gameSelectModal"}
          >
            {t("close")}
          </span>
        )}

      <ModalHeader>
        {isPasswordExpired.isExpired ? (
          <>
            <h3>{t("modalWindows.pswUpdateModal.title")}:</h3>
            <p>{t("modalWindows.pswUpdateModal.pswExpired")}</p>
          </>
        ) : isPasswordExpired.daysToExpired < DAYS_BEFORE_PASSWORD_EXPIRED ? (
          <>
            <h3>{t("modalWindows.pswUpdateModal.title")}:</h3>
            <p>
              {isPasswordExpired.daysToExpired === 0
                ? t("modalWindows.pswUpdateModal.todayExp")
                : t("modalWindows.pswUpdateModal.expInDays").replace(
                    "%d%",
                    isPasswordExpired.daysToExpired + 1
                  )}
              . {t("modalWindows.pswUpdateModal.canChangePsw")}
            </p>
          </>
        ) : (
          <h3>{t("modalWindows.pswUpdateModal.typeNew")}:</h3>
        )}
      </ModalHeader>

      <ModalMainContent>
        {showVirtualKeyboard ? (
          <Calculator
            onAddAmountEventListener={validateInputValue}
            onResult={() => {
              toggleVirtualKeyboard(false);
            }}
            isKeyboard={true}
            selectedValue={
              focusedInputName === inputsNames.USERPASSWORD
                ? userPin
                : focusedInputName === inputsNames.OLDPASSWORD
                ? userOldPin
                : focusedInputName === inputsNames.PASSWORDCONFIRM
                ? userPinConfirm
                : ""
            }
            hideTypedValue={true}
          />
        ) : (
          <div className="input-wrap centered-block align-center">
            <div className="flex-row flex-align-baseline align-left form-group">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  tabIndex={"1"}
                  type="password"
                  value={userOldPin == null ? "" : userOldPin}
                  onFocus={onFocuseEventHandler}
                  onChange={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onChangeEventListener(e);
                  }}
                  onBlur={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onFocusOutEventListener(e);
                  }}
                  onClick={e => {
                    if (isMobile.any()) onShowVirtualKeyboardEventListener(e);
                  }}
                  name={inputsNames.OLDPASSWORD}
                  id={inputsNames.OLDPASSWORD}
                  readOnly={isMobile.any()}
                  className={cn({
                    error:
                      validErrors[inputsNames.OLDPASSWORD] ||
                      typeof currentPswErrorMsg === "string",
                    success:
                      validSuccess[inputsNames.OLDPASSWORD] &&
                      currentPswErrorMsg === null,
                    [`with-placeholder`]: true
                  })}
                  data-val={userOldPin}
                  placeholder={t("modalWindows.pswUpdateModal.currPsw")}
                />
                <label className="" htmlFor={inputsNames.OLDPASSWORD}>
                  {t("modalWindows.pswUpdateModal.currPsw")}
                </label>
                <span className={"valid-icon"}></span>
                <span
                  className={cn(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        !isMobile.any() &&
                        (typeof currentPswErrorMsg === "string" ||
                          validErrors[inputsNames.OLDPASSWORD])
                    }
                  )}
                >
                  {typeof currentPswErrorMsg === "string"
                    ? currentPswErrorMsg
                    : errorMessages[inputsNames.OLDPASSWORD]}
                </span>
              </div>
            </div>
            <div className="flex-row flex-align-baseline align-left form-group">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  tabIndex={"2"}
                  type="password"
                  value={userPin == null ? "" : userPin}
                  onFocus={onFocuseEventHandler}
                  onChange={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onChangeEventListener(e);
                  }}
                  onBlur={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onFocusOutEventListener(e);
                  }}
                  onClick={e => {
                    if (isMobile.any()) onShowVirtualKeyboardEventListener(e);
                  }}
                  name={inputsNames.USERPASSWORD}
                  id={inputsNames.USERPASSWORD}
                  readOnly={isMobile.any()}
                  className={cn({
                    error:
                      validErrors[inputsNames.USERPASSWORD] ||
                      typeof newPswErrorMsg === "string",
                    success: validSuccess[inputsNames.USERPASSWORD],
                    [`with-placeholder`]: true
                  })}
                  data-val={userPin}
                  placeholder={t("modalWindows.pswUpdateModal.newPsw")}
                />
                <label className="" htmlFor={inputsNames.USERPASSWORD}>
                  {t("modalWindows.pswUpdateModal.newPsw")}
                </label>
                <span className={"valid-icon"}></span>

                <span
                  style={{ top: "-20px" }}
                  className={cn(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        !isMobile.any() &&
                        (validErrors[inputsNames.USERPASSWORD] ||
                          typeof newPswErrorMsg === "string")
                    }
                  )}
                >
                  {typeof newPswErrorMsg === "string"
                    ? newPswErrorMsg
                    : errorMessages[inputsNames.USERPASSWORD]}
                </span>
              </div>
            </div>

            <div className="flex-row flex-align-baseline align-left form-group">
              <div className="flex-col-21 with-input-placeholder">
                <input
                  tabIndex={"3"}
                  type="password"
                  value={userPinConfirm == null ? "" : userPinConfirm}
                  onFocus={onFocuseEventHandler}
                  onChange={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onChangeEventListener(e);
                  }}
                  onBlur={e => {
                    if (!isMobile.any() || isWebGameVersion)
                      onFocusOutEventListener(e);
                  }}
                  onClick={e => {
                    if (isMobile.any()) onShowVirtualKeyboardEventListener(e);
                  }}
                  name={inputsNames.PASSWORDCONFIRM}
                  id={inputsNames.PASSWORDCONFIRM}
                  readOnly={isMobile.any()}
                  className={cn({
                    error: validErrors[inputsNames.PASSWORDCONFIRM],
                    success: validSuccess[inputsNames.PASSWORDCONFIRM],
                    [`with-placeholder`]: true
                  })}
                  data-val={userPinConfirm}
                  placeholder={t("modalWindows.pswUpdateModal.confirmPsw")}
                />
                <label className="" htmlFor={inputsNames.PASSWORDCONFIRM}>
                  {t("modalWindows.pswUpdateModal.confirmPsw")}
                </label>
                <span className={"valid-icon"}></span>

                <span
                  className={cn(
                    "custom-danger-tooltip type-error place-right",
                    {
                      show:
                        !isMobile.any() &&
                        validErrors[inputsNames.PASSWORDCONFIRM]
                    }
                  )}
                >
                  {errorMessages[inputsNames.PASSWORDCONFIRM]}
                </span>
              </div>
            </div>

            <ActionButton
              tooltip={
                !isAllowSaveBtn ? "Please, fill all user data fields" : ""
              }
              onclick={onSubmitEventHandler}
              classesName={cn("btn btn-warning", {
                disable: !isAllowSaveBtn
              })}
              isBlink={true}
              isWarning={true}
            >
              {Object.keys(isPasswordExpired).length > 0
                ? t("modalWindows.pswUpdateModal.update")
                : t("modalWindows.pswUpdateModal.save")}
            </ActionButton>
            {Object.keys(isPasswordExpired).length > 0 && (
              <>
                <hr />
                {t("or")}
                <hr />
                <p>{t("modalWindows.pswUpdateModal.ifForgotPsw")}</p>
                <span
                  className={"font-bold linked underline hover"}
                  onClick={toggleMailPswRecoveryModal}
                >
                  {t("modalWindows.pswUpdateModal.getLink")}
                </span>
                {isPasswordExpired.daysToExpired > -1 &&
                  isPasswordExpired.daysToExpired <
                    DAYS_BEFORE_PASSWORD_EXPIRED && (
                    <>
                      <span
                        style={{ display: "block", marginTop: "20px" }}
                        className={" linked hover"}
                        onClick={onCloseModal}
                        data-modal={"gameSelectModal"}
                      >
                        {t("modalWindows.pswUpdateModal.remindTomorow")}
                      </span>
                    </>
                  )}
                {showMailPswRecoveryModal && isWebGameVersion && (
                  <UserEmailModalWindow
                    onCloseModal={toggleMailPswRecoveryModal}
                    showModal={"userEmailModalWindow"}
                    sendUserMailForPswRecoverySuccess={() =>
                      setShowMailPswRecoverySuccessModal(true)
                    }
                  />
                )}
                {showMailPswRecoverySuccessModal && isWebGameVersion && (
                  <UserEmailStatusModalWindow
                    onCloseModal={onCloseSubmodals}
                    showModal={"userEmailStatusModalWindow"}
                  />
                )}
              </>
            )}
          </div>
        )}
      </ModalMainContent>
    </Modal>
  );
};

export default UserPasswordUpdateModal;
