import React from "react";
import { useTranslation } from "react-i18next";
import cx from "clsx";

import { Modal, ModalMainContent, ModalHeader } from "./../Modal/Modal";
import ActionButton from "../ActionButton";

const UserEmailStatusModalWindow = ({ showModal, onCloseModal }) => {
  const { t } = useTranslation();
  return (
    <Modal
      showModal={showModal === "userEmailStatusModalWindow"}
      id={"userEmailStatusModalWindow"}
    >
      <ModalHeader>
        <h3>{t("modalWindows.emailStatusModal.title")}</h3>
      </ModalHeader>
      <ModalMainContent>
        <div className="input-wrap centered-block align-center">
          <div className="flex-row flex-align-baseline align-left form-group">
            {t("modalWindows.emailStatusModal.checkMail")}
          </div>

          <ActionButton
            onclick={onCloseModal}
            classesName={cx("btn btn-warning")}
            isWarning={true}
          >
            {t("OK")}
          </ActionButton>
        </div>
      </ModalMainContent>
    </Modal>
  );
};

export default UserEmailStatusModalWindow;
