import React from "react";

const ActionButton = ({
  children,
  onclick,
  classesName,
  buttonBlinkDisable,
  isWarning,
  tooltip = ""
}) => {
  const onClickEventListener = event => {
    if (event.currentTarget.classList.contains("inactive")) return;
    onclick(event);
  };

  return (
    <div
      data-tip={tooltip}
      onClick={onClickEventListener}
      className={
        !isWarning
          ? `action-button align-center ${classesName}`
          : `align-center ${classesName}`
      }
    >
      {children}
      <div className={!buttonBlinkDisable ? `button-flash-line` : ``} />
    </div>
  );
};

export default ActionButton;
