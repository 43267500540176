import {
  setItemInStorage,
  getItemFromStorage,
  removeItemFromStorage,
} from "./../../utils/LocalStorageService";

export const saveAccessTokenInLocalStorage = (accessToken) => {
  setItemInStorage("accessToken", accessToken);
};

export const saveRefreshTokenInLocalStorage = (refreshToken) => {
  setItemInStorage("refreshToken", refreshToken);
};

export const getAccessTokenInLocalStorage = () => {
  return getItemFromStorage("accessToken");
};

export const getRefreshTokenInLocalStorage = () => {
  return getItemFromStorage("refreshToken");
};

// save token data in storage
export const saveTokenData = (tokenData) => {
  const tokenDataWithExpiresInValue = { ...tokenData };

  tokenDataWithExpiresInValue.expires_in =
    Date.now() + tokenData.expires_in * 1000;

  setItemInStorage("tokenData", JSON.stringify(tokenDataWithExpiresInValue));
};
// get token data from storage
export const getTokenData = () => {
  return getItemFromStorage("tokenData");
};

// remove token data from storage
export const removeTokenData = () => {
  removeItemFromStorage("tokenData");
};
