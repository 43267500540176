import React from "react";
import { useTranslation } from "react-i18next";

const TicketInfo = ({
  shopAddress,
  ticketData,
  maxWinningPerTicket,
  currency
}) => {
  const { t } = useTranslation();
  return (
    <>
      {shopAddress ? (
          <div style={{marginBottom: '5px'}} className="flex-row bet-content-row">
            <div className="flex-col-8 font-light mb-5">
              {t("betlist.shopAddress")}:
            </div>
            <div className="flex-col-13 align-right font-light">
              {shopAddress.split("-//-").join(" ")}
            </div>
          </div>
      ) : null}


      <div className="flex-row bet-content-row">
        <div className="flex-col-11 font-light mb-5">{t("betlist.ticketNumber")}:</div>
        <div className="flex-col-10 align-right font-light">
          {ticketData.ticketNumber}
        </div>
      </div>

      <div className="flex-row bet-content-row">
        <div className="flex-col-11 font-light mb-5">{t("betlist.ticketID")}:</div>
        <div className="flex-col-10 align-right font-light">
          {ticketData.ticketId}
        </div>
      </div>

      <div className="flex-row bet-content-row">
        <div className="flex-col-11 font-light mb-5">{t("betlist.maxWinning")}:</div>
        <div className="flex-col-10 align-right font-light">
          {maxWinningPerTicket} {currency}
        </div>
      </div>

      {ticketData.shopInfo ? (
          <div style={{marginBottom: '5px'}} className="flex-row bet-content-row">
            <div className="flex-col-8 font-light mb-5">
               {t("betlist.additInfo")}:
            </div>
            <div className="flex-col-13 align-right font-light">
              {ticketData.shopInfo}
            </div>
          </div>
      ) : null}
    </>
  );
};

export default TicketInfo;
