import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import cx from "clsx";

import { Modal, ModalHeader, ModalMainContent } from "./../Modal/Modal";

const GameSelectModal = ({
  showModal,
  allGames,
  onChangeGameEventListener
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      showModal={showModal}
      id={"gameSelectModal"}
      customClass={`game-select-modal`}
    >
      <ModalHeader>
        <h3>{t("modalWindows.gameSelectModal.title")}</h3>
      </ModalHeader>
      <ModalMainContent>
        <div className="game-select-list-container">
          {(allGames.indexOf("DOG_6_WEB") !== -1 ||
            allGames.indexOf("DOG_6") !== -1 ||
            allGames.indexOf("DOG_8_WEB") !== -1 ||
            allGames.indexOf("DOG_8") !== -1) && (
            <div className="game-select-list-titles">{t("modalWindows.gameSelectModal.dogTitle")}</div>
          )}
          <div className="game-select-list-content">
            {allGames
              .filter(game => {
                return game.indexOf("DOG") !== -1;
              })
              .map((game, i) => {
                return (
                  <Fragment key={i}>
                    <div
                      className={cx(
                        "game-select-list-item",
                        {
                          DOG_6: game === "DOG_6" || game === "DOG_6_WEB"
                        },
                        {
                          DOG_8: game === "DOG_8" || game === "DOG_8_WEB"
                        }
                      )}
                      data-identifier={game}
                      onClick={onChangeGameEventListener}
                    />
                  </Fragment>
                );
              })}
          </div>
          {(allGames.indexOf("HORSE_6") !== -1 ||
            allGames.indexOf("HORSE_6_WEB") !== -1 ||
            allGames.indexOf("HORSE_8") !== -1 ||
            allGames.indexOf("HORSE_8_WEB") !== -1) && (
            <div className="game-select-list-titles">{t("modalWindows.gameSelectModal.horseTitle")}</div>
          )}
          <div className="game-select-list-content">
            {allGames
              .filter(game => {
                return game.indexOf("HORSE") !== -1;
              })
              .map((game, i) => {
                return (
                  <Fragment key={i}>
                    <div
                      className={cx(
                        "game-select-list-item",
                        {
                          HORSE_6: game === "HORSE_6" || game === "HORSE_6_WEB"
                        },
                        {
                          HORSE_8: game === "HORSE_8" || game === "HORSE_8_WEB"
                        }
                      )}
                      data-identifier={game}
                      onClick={onChangeGameEventListener}
                    />
                  </Fragment>
                );
              })}
          </div>
        </div>
      </ModalMainContent>
    </Modal>
  );
};

export default GameSelectModal;
