import React, { useEffect, useState } from "react";
import { useGlobalDataContext } from "../../../GlobalDataContext";
import { useTranslation } from "react-i18next";

import { getFloor } from "./../../utils/helpers";

const JackPotLogo = () => {
  const { state } = useGlobalDataContext();
  const { t } = useTranslation();

  const [jackpotShadow, makeJackpotShadow] = useState(false);
  const [superJackpotShadow, makeSuperJackpotShadow] = useState(false);
  const [outerEllipseMove, makeOuterEllipseMove] = useState(false);

  // jackpot and super jackpot shadow animation
  useEffect(() => {
    setTimeout(() => {
      makeSuperJackpotAnimate();
    }, 11000);
    // eslint-disable-next-line
  }, []);

  const makeJackpotAnimate = () => {
    makeJackpotShadow(true);
    const jackpotShadowTimeout = setTimeout(() => {
      makeJackpotShadow(false);
    }, 2000);
    const outerEllipseMoveTimeout = setTimeout(() => {
      makeOuterEllipseMove(true);
    }, 6000);
    const superJackpotShadowTimeout = setTimeout(() => {
      makeSuperJackpotAnimate();
    }, 12000);
    return () => {
      clearTimeout(jackpotShadowTimeout);
      clearTimeout(superJackpotShadowTimeout);
      clearTimeout(outerEllipseMoveTimeout);
    };
  };

  const makeSuperJackpotAnimate = () => {
    makeOuterEllipseMove(true);
    makeSuperJackpotShadow(true);
    const superJackpotShadowTimeout = setTimeout(() => {
      makeSuperJackpotShadow(false);
      makeOuterEllipseMove(false);
    }, 2000);
    const jackpotShadowTimeout = setTimeout(() => {
      makeJackpotAnimate();
    }, 2000);
    return () => {
      clearTimeout(jackpotShadowTimeout);
      clearTimeout(superJackpotShadowTimeout);
    };
  };

  return state.jackPot !== 0 && state.superJackPot !== 0 ? (
    <>
      <div className="logo-jp centered-block align-center hide-for-mobile">
        <div
          className={
            !outerEllipseMove
              ? "outer-ellipse"
              : "outer-ellipse outer-ellipse-move"
          }
        >
          <span
            className={
              !superJackpotShadow
                ? `super-jackpot `
                : `super-jackpot super-jackpot-label-text-shadow`
            }
          >
            {t("header.jackpot.superjackpot")}:{" "}
            {getFloor(state.superJackPot, 100)}
            {state.gameSettings.currency}
          </span>
        </div>
      </div>
      <div className="logo-jp centered-block">
        <span
          className={
            !jackpotShadow ? `jackpot` : `jackpot jackpot-label-text-shadow`
          }
        >
          {t("header.jackpot.jackpot")}: {getFloor(state.jackPot, 100)}
          {state.gameSettings.currency}
        </span>
      </div>
    </>
  ) : state.superJackPot !== 0 && state.jackPot === 0 ? (
    <div className="logo-jp centered-block align-center hide-for-mobile fullheight vertical-align-center">
      <div
        className={
          !outerEllipseMove
            ? "outer-ellipse"
            : "outer-ellipse outer-ellipse-move"
        }
      >
        <span
          className={
            !superJackpotShadow
              ? `super-jackpot`
              : `super-jackpot super-jackpot-label-text-shadow`
          }
        >
          {t("header.jackpot.superjackpot")}:{" "}
          {getFloor(state.superJackPot, 100)}
          {state.gameSettings.currency}
        </span>
      </div>
    </div>
  ) : state.jackPot !== 0 && state.superJackPot === 0 ? (
    <div className="logo-jp centered-block align-center hide-for-mobile fullheight vertical-align-center">
      <div
        className={
          !outerEllipseMove
            ? "outer-ellipse"
            : "outer-ellipse outer-ellipse-move"
        }
      >
        <span
          className={
            !jackpotShadow ? `jackpot` : `jackpot jackpot-label-text-shadow`
          }
        >
          {t("header.jackpot.jackpot")}: {getFloor(state.jackPot, 100)}
          {state.gameSettings.currency}
        </span>
      </div>
    </div>
  ) : null;
};

export default JackPotLogo;
