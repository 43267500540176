import React, { useEffect, useState } from "react";

import {
  getTokenData,
  saveTokenData,
} from "../../components/Authorization/TokenServiceStorage";
import api from "../../utils/api";
import history from "../../../history";
import PreLoader from "../../components/PreLoader/PreLoader";

const Callback = () => {
  const [showLoading, toggleLoading] = useState(null);
  // get authentification code from get params of url
  // https://rb.mohiogaming.com/calback_url/?code=cYVnxL&state=
  function getAuthCode() {
    let authCode;
    try {
      authCode = window.location.href.split("&")[0].split("=")[1];
    } catch (e) {
      authCode = "";
    }
    return authCode;
  }

  // access_token: "cd956f2f-ffda-45d9-ae1d-f6f8d50dcef6"
  // token_type: "bearer"
  // refresh_token: "3b502217-db55-4277-a4cb-1bc1f04f6069"
  // expires_in: 35300 * 1000 + Date.now()
  // scope: "read write"

  // get token data using authenticate code
  const getTokenByAuthCode = () => {
    if (getAuthCode()) toggleLoading(true);
    if (!getAuthCode()) return;
    try {
      api.getTokensData({ authCode: getAuthCode() }).then((tokenData) => {
        saveTokenData(tokenData);
        if (tokenData["expires_in"]) history.replace("/");
      });
    } catch (e) {
      console.log("something went wrong with getting TOKEN data");
    }
    if (getTokenData()) toggleLoading(false);
  };

  useEffect(() => {
    getTokenByAuthCode();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PreLoader isLoading={showLoading} />
    </>
  );
};

export default Callback;
