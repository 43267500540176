import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cn from "clsx";
import { betsTypeForBetRequest } from "../../../utils/constants";

const TriffectaBetOddsTable = ({
  oddsData,
  columnTitles,
  rowTitles,
  onAddBetEventListener,
  eventId
}) => {
  const { t } = useTranslation();
  // state for saving active cell of each column
  const [bettedFirst, setBettedFirst] = useState(""); // cell in first row in table
  const [bettedSecond, setBettedSecond] = useState(""); // cell in second row in table
  const [bettedThird, setBettedThird] = useState(""); // cell in third row in table
  const [winNumbers, setWinNumbers] = useState([]); // winner numbers on dog||horse
  const [trifectaCellCoords, settrifectaCellCoords] = useState([]); // coords of odd from quinella odd's table

  React.useEffect(() => {
    if (bettedFirst || bettedSecond || bettedThird) {
      setWinNumbers([
        Number(bettedFirst.split("||")[1]) + 1,
        Number(bettedSecond.split("||")[1]) + 1,
        Number(bettedThird.split("||")[1]) + 1
      ]);
      settrifectaCellCoords([
        bettedFirst.split("||")[1],
        bettedSecond.split("||")[1]
      ]);
    }
  }, [bettedFirst, bettedSecond, bettedThird]);

  // make bet (create bet in betslip container in basket)
  // in each column available to choose only one cell
  const onClickEventListener = event => {
    // const typeBet = event.currentTarget.getAttribute("data-type-bet");
    const coordsCell = event.currentTarget.getAttribute("data-coords-cell");
    const coordsCellInArray = coordsCell.split("||");
    if (coordsCellInArray[0] === "0")
      setBettedFirst(coordsCell === bettedFirst ? "" : coordsCell); // after clicking on active cell remove its active state

    if (coordsCellInArray[0] === "1")
      setBettedSecond(coordsCell === bettedSecond ? "" : coordsCell); // after clicking on active cell remove its active state

    if (coordsCellInArray[0] === "2")
      setBettedThird(coordsCell === bettedThird ? "" : coordsCell); // after clicking on active cell remove its active state

    if (
      coordsCell === bettedFirst ||
      coordsCell === bettedSecond ||
      coordsCell === bettedThird
    ) {
      setWinNumbers([]);
      settrifectaCellCoords([]);
    }
  };

  const clearStateForTrifecta = () => {
    setBettedFirst("");
    setBettedSecond("");
    setBettedThird("");
    setWinNumbers([]);
    settrifectaCellCoords([]);
  };

  // add triffecta bet in prepared bets
  const onClickApplyBtnEventListener = event => {
    if (!event.currentTarget.classList.contains("active")) return;
    // prepare all needed data for send bets to the server
    onAddBetEventListener(event);
    setTimeout(() => {
      clearStateForTrifecta();
    }, 300);
  };

  // reset checked cells
  const onClickResetBtnEventListener = event => {
    clearStateForTrifecta();
  };

  return (
    <div className="flex-row accordion-table-wrap">
      <table className="flex-col-21 bordered-table light-table table trifecta">
        <thead>
          <tr>
            {/*empty cell*/}
            <th className={`titleRow-class no-table-cell`}></th>

            {/*name type of bets*/}
            {columnTitles.map((title, j) => {
              return (
                <th className="no-table-cell" key={j}>
                  <span className={`event-win-item-${title}`}></span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rowTitles.map((titleRow, k) => {
            const secondIndexCellInFirstRow = bettedFirst.split("||")[1];
            const secondIndexCellInSecondRow = bettedSecond.split("||")[1];
            const secondIndexCellInthirdRow = bettedThird.split("||")[1];

            const activeCellCoordFromState =
              // eslint-disable-next-line
              k == 0 ? bettedFirst : k == 1 ? bettedSecond : bettedThird;
            return (
              <tr key={k}>
                {/* cell of column with number*/}
                <td className="no-table-cell">{titleRow}</td>
                {oddsData[k].map((cellOdd, j) => {
                  // row of cells with odds

                  return (
                    <td
                      className={cn({
                        // block all item in the same column with selected cell
                        disabled:
                          // eslint-disable-next-line
                          (secondIndexCellInFirstRow == j ||
                            // eslint-disable-next-line
                            secondIndexCellInSecondRow == j ||
                            // eslint-disable-next-line
                            secondIndexCellInthirdRow == j) &&
                            // eslint-disable-next-line
                          `${k}||${j}` != activeCellCoordFromState,
                        active:
                          bettedFirst.includes(`${k}||${j}`) ||
                          bettedSecond.includes(`${k}||${j}`) ||
                          bettedThird.includes(`${k}||${j}`)
                      })}
                      data-coords-cell={`${k}||${j}`}
                      onClick={onClickEventListener.bind(this)}
                      key={j}
                    >
                      {cellOdd === '1st' && t("bets.1st")}
                      {cellOdd === '2nd' && t("bets.2nd")}
                      {cellOdd === '3rd' && t("bets.3rd")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex-col-21">
        <ul className="row-list justify-around btns-block">
          <li
            className={cn({
              available: bettedFirst || bettedSecond || bettedThird
            })}
            onClick={onClickResetBtnEventListener}
          >
            {t("bets.reset")}
          </li>
          <li
            className={cn({
              active: bettedFirst && bettedSecond && bettedThird
            })}
            onClick={onClickApplyBtnEventListener}
            data-type-bet={betsTypeForBetRequest.trifecta}
            data-event-id={eventId}
            data-win-number={winNumbers}
            data-perfecta-coords={trifectaCellCoords}
          >
            {t("bets.apply")}
          </li>
        </ul>
      </div>

      <div className="flex-col-21">
        <hr />
        {winNumbers.length > 0 && (
          <ul className="row-list fl-cntr-center status-block">
            {winNumbers.map((winNumber, index) => {
              return (
                <li className={`event-win-item-${winNumber}`} key={index}></li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

TriffectaBetOddsTable.propTypes = {
  oddsData: PropTypes.array
};

export default TriffectaBetOddsTable;
