// left signsAfterComma digit after comma
import { statuses } from "./constants";
import { xhrAPIdomain } from "./xhrDomain";
// import { xhrAPIdomain } from "./xhrDomain";

export const getFloor = (originValue, signsAfterComma) => {
  let signsAfterComma_ = signsAfterComma;
  if (!signsAfterComma) signsAfterComma_ = 1;
  return Math.floor(originValue * signsAfterComma_) / signsAfterComma_;
};

export const redirectToAuthServer = (status) => {
  if (status === statuses.statusForRedirect) {
    // window.location.href = REDIRECT_AUTH_PAGE;
    window.location.href = "/login";
  }
};

export const isMobile = {
  Android: function() {
    return /Android/i.test(navigator.userAgent);
  },
  BlackBerry: function() {
    return /BlackBerry/i.test(navigator.userAgent);
  },
  iOS: function() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  },
  Opera: function() {
    return /Opera Mini/i.test(navigator.userAgent);
  },
  Windows: function() {
    return /IEMobile/i.test(navigator.userAgent);
  },
  any: function() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

// check if user's password was expired or not
export const isPasswordExpired = (expiredPasswordPeriodDays) => {
  return isNaN(expiredPasswordPeriodDays) && isPasswordExpired !== null
    ? {}
    : {
        isExpired: expiredPasswordPeriodDays < 0,
        daysToExpired: expiredPasswordPeriodDays,
      };
};

// get correct hostname for auth links
const getCorrectHostnameForAuthLinks = (hostname) => {
  // list of existing link of active service
  // const linkOfExistingHostsPrefix = ['terminalza', "terminalracingza", "terminal.", "terminalstage", "rbiframe", "rbiframestage"];
  let correctHostNameForAuthLink = `${xhrAPIdomain}`;
  if (hostname.indexOf("terminalza") !== -1)
    correctHostNameForAuthLink = "https://webza.mohiogaming.com";
  if (hostname.indexOf("terminalracingza") !== -1)
    correctHostNameForAuthLink = "https://racingza.mohiogaming.com";
  if (hostname.indexOf("terminal.") !== -1)
    correctHostNameForAuthLink = "https://web.mohiogaming.com";
  if (hostname.indexOf("terminalstage") !== -1)
    correctHostNameForAuthLink = "https://webstage.mohiogaming.com";
  if (hostname.indexOf("rbiframe") !== -1)
    correctHostNameForAuthLink = "https://web.mohiogaming.com";
  if (hostname.indexOf("rbiframestage") !== -1)
    correctHostNameForAuthLink = "https://webstage.mohiogaming.com";
  if (hostname.indexOf("demo") !== -1)
    correctHostNameForAuthLink = "https://demo.mohiogaming.com";
  return correctHostNameForAuthLink;
};

// check if redirect auth link needs to be modified
export const changeRedirectAuthLink = (host) => {
  return (
    host.indexOf("terminalza") !== -1 ||
    host.indexOf("terminalracingza") !== -1 ||
    host.indexOf("terminal.") !== -1 ||
    host.indexOf("terminalstage") !== -1 ||
    host.indexOf("rbiframe") !== -1 ||
    host.indexOf("rbiframestage") !== -1
  );
};

const AUTH_WEBVERSION_CLIENT = "client_3";
const AUTH_TERMINAL_CLIENT = `client_terminal_front`;
const AUTH_IFRAME_CLIENT = "client_4";
// const AUTH_DEMO_CLIENT = "clien_demo";

// get the new redirect auth server link
export const getRedirectAuthLink = (host) => {
  let authLink = `${getCorrectXhrAPIdomain(
    window.location.origin
  )}/auth/oauth/authorize?response_type=code&state=&client_id=${AUTH_WEBVERSION_CLIENT}&scope=&redirect_uri=${xhrAPIdomain}/calback_url/`;
  let authClientRedirect = AUTH_TERMINAL_CLIENT;

  if (host.indexOf("rbiframe") !== -1 || host.indexOf("rbiframestage") !== -1)
    authClientRedirect = AUTH_IFRAME_CLIENT;

  if (changeRedirectAuthLink(host))
    authLink = `${getCorrectXhrAPIdomain(
      window.location.origin
    )}/auth/oauth/authorize?response_type=code&state=&client_id=${authClientRedirect}&scope=&redirect_uri=${xhrAPIdomain}/calback_url/`;

  return authLink;
};

// get logout link
export const getLogoutLink = (host) => {
  let logoutLink = `${xhrAPIdomain}/auth/logout`;
  if (changeRedirectAuthLink(host))
    logoutLink = `${getCorrectHostnameForAuthLinks(host)}/auth/logout`;

  if (
    host.indexOf("terminalza") !== -1 ||
    host.indexOf("terminalracingza") !== -1 ||
    host.indexOf("terminal.") !== -1 ||
    host.indexOf("terminalstage") !== -1
  )
    logoutLink = `${logoutLink}?origin=terminal`;

  return logoutLink;
};

// get url for get token request
export const getTokenUrl = (host, authCode) => {
  let tokenUrl = `${getCorrectXhrAPIdomain(
    window.location.origin
  )}/auth/oauth/token?grant_type=authorization_code&code=${authCode}&redirect_uri=${xhrAPIdomain}/calback_url/&client_id=${AUTH_WEBVERSION_CLIENT}&client_secret=password`;

  let authClientToken = AUTH_TERMINAL_CLIENT;

  if (host.indexOf("rbiframe") !== -1 || host.indexOf("rbiframestage") !== -1)
    authClientToken = AUTH_IFRAME_CLIENT;

  if (changeRedirectAuthLink(host))
    tokenUrl = `${getCorrectXhrAPIdomain(
      window.location.origin
    )}/auth/oauth/token?grant_type=authorization_code&code=${authCode}&redirect_uri=${xhrAPIdomain}/calback_url/&client_id=${authClientToken}&client_secret=password`;

  // for development env
  if (process.env.NODE_ENV === "development")
    tokenUrl = `http://localhost:8081/auth/oauth/token?grant_type=authorization_code&code=${authCode}&redirect_uri=http://localhost:3000/calback_url/&client_id=client_local_front&client_secret=password`;

  if (host.indexOf("demo") !== -1) {
    fetch(`https://demo.mohiogaming.com/front/demo`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      tokenUrl = response.url;
    });
  }

  return tokenUrl;
};

// get correct hostname for url for api requests
export const getCorrectXhrAPIdomain = (host) => {
  return getCorrectHostnameForAuthLinks(host);
};

// get url for refresh access token using refresh token
export const getUrlForTokenRefresh = (host, refreshToken) => {
  let refreshTokenUrl = `${xhrAPIdomain}/auth/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${AUTH_WEBVERSION_CLIENT}&client_secret=password`;
  let authClientRefreshToken = AUTH_TERMINAL_CLIENT;

  if (host.indexOf("rbiframe") !== -1 || host.indexOf("rbiframestage") !== -1)
    authClientRefreshToken = AUTH_IFRAME_CLIENT;

  if (changeRedirectAuthLink(host))
    refreshTokenUrl = `${xhrAPIdomain}/auth/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${authClientRefreshToken}&client_secret=password`;

  // for development env
  if (process.env.NODE_ENV === "development")
    refreshTokenUrl = `${xhrAPIdomain}/auth/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}&client_id=client_local_front&client_secret=password`;

  return refreshTokenUrl;
};
