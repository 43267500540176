import React from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalHeader,
  ModalMainContent
} from "./../Modal/Modal";

const InternalErrorMessageModal = ({
  showModal
}) => {
  const { t } = useTranslation();
  return (
    <Modal showModal={showModal} id={"internalErrorModal"}>

      <ModalHeader>
        <h3 className={'danger'}>{t("modalWindows.internalError.title")}</h3>
      </ModalHeader>

      <ModalMainContent>
        <div className="input-wrap centered-block align-center">
          <p>
            {t("modalWindows.internalError.smthWrong")}
          </p>
          <p>
            {t("modalWindows.internalError.fixingError")}
          </p>
        </div>
      </ModalMainContent>
    </Modal>
  );
};

export default InternalErrorMessageModal;
