import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "clsx";
import "./../../scss/Video/index.scss";
import JWPlayer from "./JWPlayer";
import { setVideoSize } from "./../App/actions";
import { lifecycleStates } from "../../utils/constants";
import { useGlobalDataContext } from "../../../GlobalDataContext";
import { isMobile } from "./../../utils/helpers";
import PreLoader from "../../components/PreLoader/PreLoader";

import VideoCountdown from "./../../components/Countdown/VideoCountdown";

import api from "../../utils/api";
import { toastreact } from "../../components/ToastMessage";
import { toastMessageTypes } from "../../components/ToastMessage/constants";
import BetsLocked from "./../../components/BetsClosed";

const VideoContainer = () => {
  const { state, dispatch } = useGlobalDataContext();
  const { t } = useTranslation();
  const [autoHeightMax, setAutoHeightMax] = useState(400);
  let [showHalfScreen, setScreenMode] = useState(null);
  let [showFullscreen, setFullscreenMode] = useState(null);
  let [hideVideo, setVideoHidden] = useState(null);
  let [playVideo, setVideoPlayer] = useState(null);
  let [playerURL, setPlayerURL] = useState(null);

  useEffect(() => {
    if (state.allGames.length > 0) getVideoURL();
    // eslint-disable-next-line
  }, [state.raceType]);

  useEffect(() => {
    if (
      state.video.videoSizeStatus &&
      state.video.videoSizeStatus.videoContainer
    ) {
      if (state.video.videoSizeStatus.videoContainer.height === "100%") {
        setFullscreenMode(false);
        setVideoHidden(false);
        setScreenMode(false);
      } else setScreenMode(true);
    }
  }, [state.video.videoSizeStatus]);

  useEffect(() => {
    if (
      state.lifecycleData &&
      state.lifecycleData.state === lifecycleStates.BET_LOCKED
    ) {
      setVideoPlayer(false);
    
      dispatch({
        type: "SET_VIDEO_DATA",
        payload: {
          videoName: state.lifecycleData.videoName,
          timeStampVideo: state.timeStampVideo,
          videoProgressLine: state.videoProgressLine
        }
      });
    }
    if (
      state.lifecycleData &&
      state.lifecycleData.state === lifecycleStates.DELAY
    ) {
      closeVideoPlayer();
      if (isMobile.any()) onCloseVideoEventListener();
      dispatch({
        type: "SET_VIDEO_DATA",
        payload: {
          videoName: null,
          timeStampVideo: 0,
          videoProgressLine: 0
        }
      });
    }
    if (!isMobile.any()) {
      if (
        state.lifecycleData &&
        state.lifecycleData.state === lifecycleStates.RACE
      ) {
        setVideoPlayer(true);
      } else closeVideoPlayer();
    } else {
      startMobileVideoPlayer();
      closeVideoPlayer();
    }

    // eslint-disable-next-line
  }, [state.lifecycleData]);

  useEffect(() => {
    if (
      state.lifecycleData &&
      state.lifecycleData.state === lifecycleStates.RACE &&
      state.videoComplete
    ) {
      closeVideoPlayer();
      if (isMobile.any()) onCloseVideoEventListener();
      dispatch({
        type: "SET_VIDEO_DATA",
        payload: {
          videoName: null,
          timeStampVideo: 0,
          videoProgressLine: 0
        }
      });
    }
    // eslint-disable-next-line
  }, [state.videoComplete]);

  // set the height of video content after page render
  useEffect(() => {
    setAutoHeightMax(
      !showFullscreen
        ? "100%"
        : document.querySelector(".basket").offsetHeight * 0.5
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isMobile.any()) {
      if (
        state.lifecycleData &&
        state.lifecycleData.state === lifecycleStates.RACE
      ) {
        setVideoPlayer(true);
        setAutoHeightMax(
          !showFullscreen
            ? "100%"
            : document.querySelector(".player-container").offsetHeight
        );
      } else closeVideoPlayer();
    } else {
      closeVideoPlayer();
      onCloseVideoEventListener();
    } 

    if (
      state.lifecycleData &&
      state.lifecycleData.state === lifecycleStates.RACE
    ) dispatch({
        type: "SET_VIDEO_COMPLETE",
        payload: {
          raceType: state.raceType,
          videoComplete: false
        }
      });   

    // eslint-disable-next-line
  }, [state.raceType]);

  useEffect(() => {
    if (isMobile.any()) {
      startMobileVideoPlayer();
    }
    // eslint-disable-next-line
  }, [state.video.videoSizeStatus.tabContainer]);

  // change video mute event listener
  const onChangeVideoMuteEventListener = event => {
    dispatch({
      type: "SET_VIDEO_MUTE",
      payload: !state.video.videoMute
    });
  };

  const onCloseVideoEventListener = event => {
    setTimeout(() => {
      dispatch(
        setVideoSize({
          tabContainer: {
            height: isMobile.any()
              ? window.innerHeight * 0.65
              : window.innerHeight < 900
              ? window.innerHeight * 0.37
              : "29rem"
          },
          videoContainer: { height: 0 },
          startVideoOnMobile: false
        })
      );
      setVideoHidden(true);
    }, 100);
  };

  // change video screen event listener
  const onChangeVideoScreenEventListener = event => {
    setTimeout(() => {
      setFullscreenMode(false);
      setScreenMode(false);
      setVideoScreenHeight();
    }, 150);
  };

  const setVideoScreenHeight = () => {
    setTimeout(() => {
      dispatch(
        setVideoSize({
          tabContainer: {
            height: isMobile.any()
              ? window.innerHeight * 0.65
              : window.innerHeight < 900
              ? window.innerHeight * 0.37
              : "29rem"
          }
        })
      );
    }, 100);
  };

  const getVideoURL = async () => {
    try {
      const videoURL = await api.getVideoUrl();
      if (videoURL.errorMsg) {
        toastreact({
          type: toastMessageTypes.ERROR,
          title: "Something went wrong with video URL fetching"
        });
      } else {
        dispatch({
          type: "SET_VIDEO_URL",
          payload: videoURL
        });
        setPlayerURL(videoURL);
      }
    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: "Something went wrong with video URL fetching"
      });
    }
  };

  const startMobileVideoPlayer = () => {
    if (
      state.lifecycleData &&
      state.lifecycleData.state === lifecycleStates.RACE &&
      state.video.videoSizeStatus.tabContainer.height === 0
    ) {
      setFullscreenMode(true);

      setVideoPlayer(true);
    }
  };

  const closeVideoPlayer = () => {
    setVideoPlayer(false);
    // setFullscreenMode(false);
   
  };

  return (
    <div
      className={cx(
        "video-container",
        {
          active: showHalfScreen
        },
        {
          fullscreen: showFullscreen
        },
        {
          hidden:
            hideVideo ||
            (state.video.videoSizeStatus.videoContainer &&
              state.video.videoSizeStatus.videoContainer.height === 0)
        }
      )}
      style={{
        height: autoHeightMax
      }}
    >
      {state.lifecyclesConfig &&
        state.raceType &&
        state.lifecycleData &&
        state.lifecycleData.state === lifecycleStates.BET_LOCKED && (
          <BetsLocked
            parent={true}
            timeinms={
              state.lifecycleData.timestamp +
              state.lifecyclesConfig[state.raceType].betLockedTime
            }
          />
        )}

      {state.lifecycleData &&
        state.lifecycleData.state === lifecycleStates.RACE &&
        !state.videoComplete && (
          <div className="game-video-live-block">
            <div className="game-video-live" />
            <div className="game-video-title">
              {state.lifecycleData &&
                state.lifecycleData.result &&
                `${t("event")} ${state.lifecycleData.result.raceIndex}`}
            </div>
          </div>
        )}
      <div
        className={cx(
          "game-video-content",
          {
            active: showHalfScreen
          },
          {
            horses: state.raceType && state.raceType.indexOf("HORSE") !== -1
          },
          {
            backgroundSizeZero:
              state.lifecycleData &&
              state.lifecycleData.state === lifecycleStates.RACE &&
              !state.videoComplete
          }
        )}
      >
        {state.lifecycleData &&
          state.lifecyclesConfig &&
          state.lifecycleData.state === lifecycleStates.BET && (
            // race number with RACE label
            <div className="game-video-circular-progressbar">
              <span className="game-video-circular-progressbar-label">
                {t("event")} {state.lifecycleData.raceNumber}
              </span>

              {/* video countdown */}
              {state.lifecycleData &&
                state.lifecycleData.state === lifecycleStates.BET && (
                  <>
                    <div
                      id={`videoCountdownId`}
                      className={`countdown-container videoCountdown`}
                      data-timestamp={
                        state.lifecycleData.timestamp +
                        state.lifecyclesConfig[state.raceType].betTime
                      }
                    >
                      {""}
                    </div>
                    <VideoCountdown
                      lftimestamp={state.lifecycleData.timestamp}
                      betTime={state.lifecyclesConfig[state.raceType].betTime}
                    />
                  </>
                )}
            </div>
          )}

        {/* video player */}
        <JWPlayer
          videLink={playerURL}
        />

        {/* mutted button */}
        {playVideo && state.videoName !== null && !hideVideo && (
          <div
            className={cx("game-video-volume", {
              mute: state.video.videoMute
            })}
            onClick={onChangeVideoMuteEventListener}
          ></div>
        )}

        {/*Preloader on video launching*/}
        {state.lifecycleData &&
          state.lifecycleData.state === lifecycleStates.RACE &&
          !state.videoComplete && (
            <div className="background-preloader-fadeout">
              <PreLoader
                isLoading={true}
                isLightPreLoader={true}
                showLoadingText={false}
                isTransparentBackground={true}
              />
            </div>
          )}

        {/* button for miximizing videon container for desktop */}
        {!isMobile.any() && (
          <>
            <div
              className={cx(
                "game-video-fullscreen",
                {
                  exit: !showHalfScreen && !showFullscreen
                },
                {
                  fullscreen: showFullscreen
                }
              )}
              onClick={onChangeVideoScreenEventListener}
            ></div>
          </>
        )}
      </div>

      {/* close video button for mobile devices */}
      <div
        onClick={onCloseVideoEventListener}
        className={cx("game-video-close-button action-button align-center", {
          fullScreen: showFullscreen
        })}
      >
        {t("video.closeVideo")}
      </div>
    </div>
  );
};

export default VideoContainer;
