import {getTokenUrl, getCorrectXhrAPIdomain} from './helpers';
import { fetchWithAuth } from "./../components/Authorization/TokenServiceAsynch";

// eslint-disable-next-line
export default {
  // get token object for lotto star api access(using player_token parameter)
  getAccessTokenForIframe: (data) => {
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const urlForIframeRequest = `/auth/oauth/token?grant_type=platform_grant&client_id=client_local_front&client_secret=password&platformId=${data.platformId}${data.queryStringForRequest}`;

    console.log("data.queryStringForRequest", data.queryStringForRequest);

    return fetch(`${getCorrectXhrAPIdomain(window.location.origin)}${urlForIframeRequest}`, options)
      .then((res) => {
        return res;
      })
      .then((res) => {
        return res.json();
      });
  },
  getTokensData: (data) => {
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return fetch(getTokenUrl(window.location.origin, data.authCode), options)
      .then(res => {
        return res;
      })
      .then(res => {
        return res.json();
      });
  },

  /**
   * fetching future events for all race types
   * @todo Add swager link here
   * */
  getFutureEvents: () => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };

    return fetchWithAuth(`${getCorrectXhrAPIdomain(window.location.origin)}/api/future-races`, options).then(
      res => {
        return res;
      }
    );
  },
  /**
   * fetching values of duration for lifecycle states of all race types
   * @todo Add swager link here
   * */
  getLifecyclesConfig: () => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };

    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/api/game/settings/lifecycle-configuration`,
      options
    ).then(res => {
      return res;
    });
  },
  /**
   * fetching game's settings (available games, min max stake, ticket header etc...)
   * https://rb.mohiogaming.com/api/swagger-ui/index.html?configUrl=/api/v3/api-docs/swagger-config#/game-controller/gameSettings
   * */
  getAppsSettings: () => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };

    return fetchWithAuth(`${getCorrectXhrAPIdomain(window.location.origin)}/api/game/settings`, options).then(
      res => {
        return res;
      }
    );
  },
  /**
   * fetching data by jackpots
   * https://rb.mohiogaming.com/api/swagger-ui/index.html?configUrl=/api/v3/api-docs/swagger-config#/jackpot-controller/getJackpotConfiguration
   * */
  getJackPotData: () => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(`${getCorrectXhrAPIdomain(window.location.origin)}/api/jackpot/settings`, options).then(
      res => {
        return res;
      }
    );
  },
  /**
   * fetching current user data
   * */
  getCurrentUserData: () => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer dc52cab1-ded9-4b8c-b060-d4530ccc9df5`,
      },
    };
    return fetchWithAuth(`${getCorrectXhrAPIdomain(window.location.origin)}/auth/users/current`, options).then(
      res => {
        return res;
      }
    );
  },

  /**
   * activate user's wallet
   */
  enableWalletForUser: userid => {
    const options = {
      method: "put",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/auth/wallets/activate/${userid}`,
      options
    ).then(res => {
      return res;
    });
  },
  /**
   * fetching round video link
   * */
  getVideoUrl: () => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };

    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/api/racingbetInfo/video-url`,
      options
    ).then(res => {
      return res.url;
    });
  },
  /**
   * fetching event history
   * https://rb.mohiogaming.com/api/swagger-ui/index.html?configUrl=/api/v3/api-docs/swagger-config#/event-controller/getRoundResult
   * */
  getCurrentEventHistory: identifier => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/api/round-results/${identifier}`,
      options
    ).then(res => {
      return res;
    });
  },
  /**
   * fetching Bets history in basket component
   * https://rb.mohiogaming.com/api/swagger-ui/index.html?configUrl=/api/v3/api-docs/swagger-config#/betting-controller/tickets
   * */
  getCurrentBasket: ({size, page}) => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/api/betting/bet?size=${size}&page=${page}&sort=issueDate,DESC`,
      options
    ).then(res => {
      return res;
    });
  },
  // get credits history
  getCurrentCreditsHistory: userId => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/auth/wallets/history/${userId}`,
      options
    ).then(res => {
      return res;
    });
  },
  // get withdraw history
  getCurrentWithdrawHistory: () => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(`${getCorrectXhrAPIdomain(window.location.origin)}/auth/creditsCodes`, options).then(
      res => {
        return res;
      }
    );
  },
  /**
   * activate voucher for add credits to user's credits
   *
   * */
  activateVoucher: ({ voucherId, portalKey }) => {
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ code: voucherId, portalKey: portalKey })
    };

    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/auth/creditsCodes/apply`,
      options
    ).then(res => {
      return res;
    });
  },
  /**
   * generate withdraw code for cash out from user credit
   *
   * */
  generateWithdrawCode: ({ amount, portalKey }) => {
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ amount: amount, portalKey: portalKey })
    };

    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/auth/creditsCodes/byUser`,
      options
    ).then(res => {
      return res;
    });
  },
  /**
   * cancel ticket by its id 
   */
  cancelTicket: (ticketId) => {
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/api/betting/bet/storno/${ticketId}`,
      options
    ).then(res => {
      return res;
    });
  },
  /**
   * place Bets in basket(betslip) component
   * https://rb.mohiogaming.com/api/swagger-ui/index.html?configUrl=/api/v3/api-docs/swagger-config#/betting-controller/placeBet
   * */
  placeBet: (betsObject, eventsId) => {
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(betsObject)
    };
    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/api/betting/bet/${eventsId}`,
      options
    ).then(res => {
      // @response res.json() = {additionalInformation: ""
      // betInformation: [{id: 2, stake: 1, tip: "{i18n(ticket.tip.rb_FIRST)} (1)-//-", odds: 5.96, win: 5.96}]
      // expireDate: 1594901956303
      // gameName: "RACING BET (DOG 6)"
      // gameNumber: "8725"
      // issueDate: 1587125956303
      // jackpotCode: null
      // state: "NOVICE"
      // ticketId: 11049
      // ticketNumber: null
      // totalStake: 1}
      return res;
    });
  },
  /** update User data
   * */
  updateUserData: userData => {
    const options = {
      method: "put",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(userData)
    };
    return fetchWithAuth(`${getCorrectXhrAPIdomain(window.location.origin)}/auth/users/register`, options).then(
      res => {
        return res;
      }
    );
  },
  /** update User password
   * */
  updateUserPassword: userData => {
    const options = {
      method: "put",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(userData)
    };
    return fetchWithAuth(`${getCorrectXhrAPIdomain(window.location.origin)}/auth/users/password`, options).then(
      res => {
        return res;
      }
    );
  },
  /** close User account
   * */
  closeUserAccount: userId => {
    const options = {
      method: "put",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(
        `${getCorrectXhrAPIdomain(window.location.origin)}/auth/users/disable/${userId}`,
        options
    ).then(res => {
      return res;
    });
  },
  /**
   * check if email is exists
   */
  checkExistsUserMail: userMail => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/auth/users/check?email=${userMail}`,
      options
    ).then(res => {
      return res;
    });
  },
  /**
   * check if Login is exists
   */
  checkExistsUserLogin: userLogin => {
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    };
    return fetchWithAuth(
      `${getCorrectXhrAPIdomain(window.location.origin)}/auth/users/check?username=${userLogin}`,
      options
    ).then(res => {
      return res;
    });
  }
};
