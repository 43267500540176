import React from "react";
import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalHeader,
  ModalMainContent,
  ModalCloseBtn
} from "./../Modal/Modal";
import ActionButton from "../ActionButton";

const UserAccountCloseModal = ({
  showModal,
  openModalEventListener,
  onCloseUserAccount,
}) => {
  const onCloseModal = e => {
    openModalEventListener(e);
  };
  const { t } = useTranslation();
  return (
    <Modal
      showModal={showModal}
      id={"closeUserAccount"}
      customClass={`withdraw-money`}
      closeModalEventListener={onCloseModal}
    >
      <ModalCloseBtn closeModalEventListener={onCloseModal} />

      <ModalHeader>
        <h3>
          {t("modalWindows.closeUserModal.title")}
          <p style={{ marginTop: "4px", fontSize: "1rem", fontWeight: "400" }}>
            {t("modalWindows.closeUserModal.areYouSureMsg")}
          </p>
        </h3>
      </ModalHeader>

      <ModalMainContent>
        <div className="input-wrap centered-block align-center">
          <ActionButton
            onclick={onCloseUserAccount}
            classesName="btn btn-warning"
            isWarning={true}
          >
            {t("modalWindows.closeUserModal.confirm")}
          </ActionButton>
        </div>
      </ModalMainContent>
    </Modal>
  );
};

export default UserAccountCloseModal;
