import React, { Fragment } from "react";
import { useGlobalDataContext } from "../../../GlobalDataContext";
import {useTranslation} from "react-i18next";

const WithdrawContent = ({ withdraws }) => {
  const { state } = useGlobalDataContext();
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="bet-content-divider" />
      <div className="bet-content">
        <div className="flex-row bet-content-row">
          <div className="flex-col-11 font-light mb-5">
            {t("withdrawContent.withdrawCode")}
          </div>
          <div className="flex-col-10 align-right font-light">
            {withdraws.id.code}
          </div>
        </div>
        <div className="flex-row bet-content-row">
          <div className="flex-col-11 font-light mb-5">{t("withdrawContent.status")}</div>
          <div className="flex-col-10 align-right font-light">
            {t(withdraws.state)}
          </div>
        </div>
        <div className="flex-row bet-content-row">
          <div className="flex-col-11 font-light mb-5">{t("withdrawContent.amount")}</div>
          <div className="flex-col-10 align-right font-light">
            {withdraws.amount} {state.gameSettings.currency}
          </div>
        </div>
        <div className="flex-row bet-content-row">
          <div className="flex-col-11 font-light mb-5">{t("withdrawContent.date")}</div>
          <div className="flex-col-10 align-right font-light">
            {`${new Date(withdraws.createdTimestamp).getDate()}/${new Date(
              withdraws.createdTimestamp
            ).getMonth() + 1}/${new Date(
              withdraws.createdTimestamp
            ).getFullYear()} ${(
              "0" + new Date(withdraws.createdTimestamp).getHours()
            ).slice(-2)}:${(
              "0" + new Date(withdraws.createdTimestamp).getMinutes()
            ).slice(-2)}:${(
              "0" + new Date(withdraws.createdTimestamp).getSeconds()
            ).slice(-2)}`}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WithdrawContent;
