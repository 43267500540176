import React from "react";
import { useTranslation } from "react-i18next";

import "./../../scss/BetsClosed/index.scss";


const BetsLocked = ({ parent, timeinms }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`bets-closed-container ${parent ? "show-in-parent" : ""}`}
      >
        <h1>{t("raceisstarting")}</h1>
        {/*<h1>RACE IS STARTING...</h1>*/}
        <div
          id={"betsClosedId"}
          className="countdown-label"
          data-timestamp={timeinms}
        >
          {""}
        </div>
      </div>
    </>
  );
};

export default BetsLocked;
