import React, { useEffect } from "react";
import WithdrawList from "./WithdrawList";
import { useGlobalDataContext } from "./../../../GlobalDataContext";
import api from "./../../utils/api";
import { useTranslation } from "react-i18next";

import "./../../scss/Withdraw/index.scss";
import { toastreact } from "../../components/ToastMessage";
import { toastMessageTypes } from "../../components/ToastMessage/constants";

const WithdrawHistory = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalDataContext();

  const getWithdrawHistory = async () => {
    try {
      const withdrawHistory = await api.getCurrentWithdrawHistory();

      if (withdrawHistory.errorMsg) {
        toastreact({
          type: toastMessageTypes.ERROR,
          title: "Something went wrong with withdraw history fetching"
        });
      } else {
        dispatch({
          type: "SET_WITHDRAW_HISTORY",
          payload: withdrawHistory
        });
      }

    } catch (error) {
      toastreact({
        type: toastMessageTypes.ERROR,
        title: "Something went wrong with withdraw history fetching"
      });
    }
  };

  // close withdraw history
  const hideSlideAsideBlockEventListener = event => {
    dispatch({
      type: "SLIDE_ASIDE_BLOCK",
      payload: null
    });
  };

  useEffect(() => {
    if (state.slideAsideBlockId === 'withdrawHistory') {
      getWithdrawHistory();
    };
    // eslint-disable-next-line
  }, [state.slideAsideBlockId]);

  return (
    <div className="withdraw-history-container">
      <h2 className="top-title">{t('withdrawContent.withdrawHistoryTitle')}</h2>
      <span
        onClick={hideSlideAsideBlockEventListener}
        className="modal-close-btn"
      >
        {t("close")}
      </span>
      {state.withdrawHistory.length > 0 && (
        <WithdrawList withdrawHistory={state.withdrawHistory} />
      )}
    </div>
  );
};

export default WithdrawHistory;
