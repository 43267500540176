import React from "react";
import { useTranslation } from "react-i18next";

import cn from "clsx";
import { Modal, ModalHeader, ModalMainContent } from "./../Modal/Modal";
import ActionButton from "../ActionButton";
import { removeItemFromStorage } from "../../utils/LocalStorageService";
import { removeTokenData } from "../Authorization/TokenServiceStorage";
import { eraseCookie } from "../../utils/CookieService";
import { LOGOUT_URI } from "../../utils/constants";

const ShowWithdrawMoneyCodeModal = ({
  showModal,
  withdrawMoneyCode,
  closeWithdrawMoneyCodeModal,
  isWebGameVersion,
  cashierAddress,
  credits
}) => {
  const { t } = useTranslation();
  const [showWithdrawCodeAgain, setShowWithdrawCodeAgain] = React.useState(
    false
  );
  const onClickEventListener = () => {
    setTimeout(() => {
      setShowWithdrawCodeAgain(true);
    }, 500);
  };

  const onClickCloseBtn = e => {
    if (showWithdrawCodeAgain && !isWebGameVersion && Number(credits) < 1) {

      setTimeout(() => {
        removeItemFromStorage("idsPlacedEvents", true);
        removeTokenData();
        removeItemFromStorage("gameIdentifier");
        eraseCookie("JSESSIONID", "/", window.location.origin);
        window.location.href = LOGOUT_URI;
      }, 1000);
    }
    setShowWithdrawCodeAgain(false);
    closeWithdrawMoneyCodeModal(e);
  };
  return (
    <Modal
      showModal={showModal}
      id={"showWithdrawMoneyCode"}
      customClass={cn(" withdraw-money", {
        [`fade-in-custom`]: showWithdrawCodeAgain
      })}
      closeModalEventListener={onClickCloseBtn}
      restrictCloseModalOnBackdrop={true}
    >
      {showWithdrawCodeAgain ? (
        <ModalHeader>
          <h3>{t("modalWindows.showWithdrawCodeModal.title")}</h3>
        </ModalHeader>
      ) : (
        <ModalHeader>
          <h3>{t("modalWindows.showWithdrawCodeModal.codeGenerated")}:</h3>
        </ModalHeader>
      )}

      <ModalMainContent>
        {showWithdrawCodeAgain ? (
          <div
            className={cn("centered-block align-center", {
              // [`fade-in-custom`]: showWithdrawCodeAgain
            })}
          >
            <hr />
            <h2
              className={cn({
                [`warning-blink`]: showWithdrawCodeAgain
              })}
            >
              <strong>{withdrawMoneyCode}</strong>
            </h2>
            <hr />
            {cashierAddress !== "" && (
              <span className="cashier-address small-text ">
                {t("modalWindows.showWithdrawCodeModal.provideBetShow")}: <br />
                <strong className="warning">
                  {cashierAddress
                    ? cashierAddress
                        .split("-//-")
                        .join(" ")
                        .split("-//--//-")
                        .join(" ")
                    : ""}
                </strong>
              </span>
            )}
          </div>
        ) : (
          <div className="centered-block align-center">
            <hr />
            {t("modalWindows.showWithdrawCodeModal.getMoney")}:
            <hr />
            <h2
              className={cn({
                [`warning-blink`]: showWithdrawCodeAgain
              })}
            >
              <strong>{withdrawMoneyCode}</strong>
            </h2>
            <hr />
            {cashierAddress !== "" && (
              <span className="cashier-address small-text ">
                {t("modalWindows.showWithdrawCodeModal.provideBetShow")}:{" "}
                <br />
                <strong className="warning">
                  {cashierAddress
                    ? cashierAddress
                        .split("-//-")
                        .join(" ")
                        .split("-//--//-")
                        .join(" ")
                    : ""}
                </strong>
              </span>
            )}
          </div>
        )}

        <br />
        <div className="input-wrap centered-block align-center">
          <ActionButton
            onclick={
              showWithdrawCodeAgain ? onClickCloseBtn : onClickEventListener
            }
            classesName={"btn btn-warning"}
            isWarning={true}
          >
            {showWithdrawCodeAgain ? t("modalWindows.showWithdrawCodeModal.yesConfirm") : t("OK")}
          </ActionButton>
        </div>
      </ModalMainContent>
    </Modal>
  );
};

export default ShowWithdrawMoneyCodeModal;
