import React, { useEffect, useState } from "react";
import { useGlobalDataContext } from "../../../GlobalDataContext";
import { useTranslation } from "react-i18next";

const AdditionalInformation = () => {
  const { state } = useGlobalDataContext();
  const { t } = useTranslation();
  const [infoValueShadow, makeInfoValueShadow] = useState(false);
  const [infoLabelShadow, makeInfoLabelShadow] = useState(false);

  // additional info label and info value shadow animation
  useEffect(() => {
    setTimeout(() => {
      makeInfoLabelAnimate();
    }, 11000);
    // eslint-disable-next-line
  }, []);

  const makeInfoValueAnimate = () => {
    makeInfoValueShadow(true);
    const infoValueTimeout = setTimeout(() => {
      makeInfoValueShadow(false);
    }, 2000);
    const infoLabelShadowTimeout = setTimeout(() => {
      makeInfoLabelAnimate();
    }, 12000);
    return () => {
      clearTimeout(infoValueTimeout);
      clearTimeout(infoLabelShadowTimeout);
    };
  };

  const makeInfoLabelAnimate = () => {
    makeInfoLabelShadow(true);
    const infoLabelShadowTimeout = setTimeout(() => {
      makeInfoLabelShadow(false);
    }, 2000);
    const infoValueTimeout = setTimeout(() => {
      makeInfoValueAnimate();
    }, 2000);
    return () => {
      clearTimeout(infoValueTimeout);
      clearTimeout(infoLabelShadowTimeout);
    };
  };
  return (
    state.gameSettings &&
    state.gameSettings.addressHeader !== "" &&
    // eslint-disable-next-line
    state.jackPot == 0 &&
    // eslint-disable-next-line
    state.superJackPot == 0 && (
      <>
        <div className="logo centered-block align-center hide-for-mobile">
          <div
            className={
              !infoLabelShadow
                ? `address-label`
                : `address-label super-jackpot-label-text-shadow`
            }
          >
            {t("header.withdrawVoucherProvide")}:
          </div>
        </div>
        <div className="logo centered-block address-label-block">
          <span
            className={
              !infoValueShadow
                ? `address-text`
                : `address-text super-jackpot-label-text-shadow`
            }
          >
            {state.gameSettings && state.gameSettings.addressHeader
              ? state.gameSettings.addressHeader.split("-//-").join(" ")
              : ""}
          </span>
        </div>
      </>
    )
  );
};

export default AdditionalInformation;
