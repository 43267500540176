import React from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalHeader,
  ModalMainContent
} from "./../Modal/Modal";

const IframeTokenDataErrorModal = ({
                                     showModal
                                   }) => {
  const { t } = useTranslation();
  return (
      <Modal showModal={showModal} id={"iframeTokenDataErrorModal"}>

        <ModalHeader>
          <h3 className={'danger'}>{t("modalWindows.iframeTokenError.title")}</h3>
        </ModalHeader>

        <ModalMainContent>
          <div className="input-wrap centered-block align-center">
            <p>
              {t("modalWindows.iframeTokenError.refreshPageError")}
            </p>

          </div>
        </ModalMainContent>
      </Modal>
  );
};

export default IframeTokenDataErrorModal;
