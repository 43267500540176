import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cn from "clsx";
import { betsTypeForBetRequest } from "../../../utils/constants";

const TwoWayBetOddsTable = ({
  oddsData,
  columnTitlesFirst,
  columnTitlesSecond,
  onAddBetEventListener,
  eventId,
  betsForEvent,
  raceType
}) => {
  // state for saving active cell of each column
  const [evenOddBet, setEvenOddBet] = useState(
    betsForEvent[betsTypeForBetRequest.even]
      ? "0"
      : betsForEvent[betsTypeForBetRequest.odd]
      ? "1"
      : ""
  ); // EvenBet (%win%) OddBet (%win%)
  const [overUnderBet, setOverUnderBet] = useState(
    betsForEvent[betsTypeForBetRequest.over]
      ? "0"
      : betsForEvent[betsTypeForBetRequest.under]
      ? "1"
      : ""
  ); // OverBet (%win%) UnderBet (%win%)
  const secondTableData = Object.values(oddsData).slice(2);

  useEffect(() => {
    if (Object.keys(betsForEvent).length === 0) {
      setEvenOddBet("");
      setOverUnderBet("");
    }

    setEvenOddBet(
      betsForEvent[betsTypeForBetRequest.even]
        ? "0"
        : betsForEvent[betsTypeForBetRequest.odd]
        ? "1"
        : ""
    );

    setOverUnderBet(
      betsForEvent[betsTypeForBetRequest.over]
        ? "0"
        : betsForEvent[betsTypeForBetRequest.under]
        ? "1"
        : ""
    );
  }, [betsForEvent]);

  // set the active cell in odds table after game changed
  useEffect(() => {
    if (
      betsForEvent[betsTypeForBetRequest.even] ||
      betsForEvent[betsTypeForBetRequest.odd]
    ) {
      setEvenOddBet(
        betsForEvent[betsTypeForBetRequest.even]
          ? "0"
          : betsForEvent[betsTypeForBetRequest.odd]
          ? "1"
          : ""
      );
    }

    if (
      betsForEvent[betsTypeForBetRequest.over] ||
      betsForEvent[betsTypeForBetRequest.under]
    )
      setOverUnderBet(
        betsForEvent[betsTypeForBetRequest.over]
          ? "0"
          : betsForEvent[betsTypeForBetRequest.under]
          ? "1"
          : ""
      );

    // eslint-disable-next-line
  }, [raceType]);

  // make bet (create bet in betslip container in basket)
  // in each column available to choose only one cell
  const onClickEventListener = event => {
    const typeBet = event.currentTarget.getAttribute("data-type-bet");
    const coordsCell = event.currentTarget.getAttribute("data-coords-cell");
    if (typeBet === betsTypeForBetRequest.even)
      setEvenOddBet(coordsCell === evenOddBet ? "" : coordsCell); // after clicking on active cell remove its active state
    if (typeBet === betsTypeForBetRequest.odd)
      setEvenOddBet(coordsCell === evenOddBet ? "" : coordsCell); // after clicking on active cell remove its active state
    if (typeBet === betsTypeForBetRequest.over)
      setOverUnderBet(coordsCell === overUnderBet ? "" : coordsCell); // after clicking on active cell remove its active state
    if (typeBet === betsTypeForBetRequest.under)
      setOverUnderBet(coordsCell === overUnderBet ? "" : coordsCell); // after clicking on active cell remove its active state
    // prepare all needed data for send bets to the server
    onAddBetEventListener(event);
  };
  return (
    <div className="flex-row accordion-table-wrap">
      <table className="flex-col-21 bordered-table light-table table two-way-bets">
        <thead>
          <tr>
            {/*name type of bets*/}
            {columnTitlesFirst.map((title, j) => {
              return (
                <th className="no-table-cell" key={j}>
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* cell of column */}
            <td
              className={cn("event-item", {
                active: evenOddBet.includes(`0`)
              })}
              data-type-bet={`Even (%win%)`}
              data-coords-cell={`0`}
              data-odd-amount={oddsData["even"]}
              data-win-number={oddsData["even"]}
              data-event-id={eventId}
              onClick={onClickEventListener.bind(this)}
              key={0}
            >
              <span className={"event-item"} />
              {oddsData["even"]}
            </td>

            <td
              className={cn("event-item", {
                active: evenOddBet.includes(`1`)
              })}
              data-type-bet={`Odd (%win%)`}
              data-coords-cell={`1`}
              data-odd-amount={oddsData["odd"]}
              data-win-number={oddsData["odd"]}
              data-event-id={eventId}
              onClick={onClickEventListener.bind(this)}
              key={1}
            >
              <span className={"event-item"} />
              {oddsData["odd"]}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="flex-col-21 bordered-table light-table table two-way-bets">
        <thead>
          <tr>
            {/*name type of bets*/}
            {columnTitlesSecond.map((title, j) => {
              return (
                <th className="no-table-cell" key={j}>
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* cell of column */}
            {secondTableData.map((cellOdd, j) => {
              const typeBet = j % 2 !== 0 ? `Under (%win%)` : `Over (%win%)`;
              // row of cells with odds
              return (
                <td
                  className={cn("event-item", {
                    active:
                      (j % 2 !== 0 && overUnderBet.includes(`${j}`)) ||
                      (j % 2 === 0 && overUnderBet.includes(`${j}`))
                  })}
                  data-type-bet={`${typeBet}`}
                  data-coords-cell={`${j}`}
                  data-odd-amount={cellOdd}
                  data-win-number={cellOdd}
                  data-event-id={eventId}
                  onClick={onClickEventListener.bind(this)}
                  key={j}
                >
                  {cellOdd}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TwoWayBetOddsTable.propTypes = {
  oddsData: PropTypes.object
};

export default TwoWayBetOddsTable;
