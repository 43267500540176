import React from "react";


function Heading({ code, currency, amount }) {

  return (
      <>
        <span style={{display: 'block'}} className={'warning'}>{code}</span> {amount} {currency}
      </>
  );
}

export default Heading;
