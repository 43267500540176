import { getLogoutLink, getRedirectAuthLink } from "./helpers";

// length of voucher code for add credits
export const VOUCHER_CODE_LENGTH = 6;

// amount of days before password will expired when show warning message about password expired
export const DAYS_BEFORE_PASSWORD_EXPIRED = 5;

// count of items in current bet table list for make bet
export const EVENT_COUNT_FOR_BET_TABLE = 4;

// delay before user's logout in terminal version
export const AUTOLOGOUT_TIMER = 300000;

// delay before user's logout in terminal version
export const TICKET_COUNT_FOR_LOADING = 20;

// row name for triffecta odds table
export const rowsForTriffecta = ["first", "second", "third"];
// identifiers for each game
export const identifiers = {
  DOG_6: "DOG_6",
  DOG_8: "DOG_8",
  HORSE_6: "HORSE_6",
  HORSE_8: "HORSE_8",
  DOG_6_WEB: "DOG_6_WEB",
  DOG_8_WEB: "DOG_8_WEB",
  HORSE_6_WEB: "HORSE_6_WEB",
  HORSE_8_WEB: "HORSE_8_WEB"
};
// transaction type of credits history
export const transactionTypes = {
  WITHDRAW_VOUCHER: "WITHDRAW_VOUCHER",
  [`ADD CREDITS`]: `ADD CREDITS`,
  [`SUB CREDITS`]: "SUB CREDITS",
  INITIAL_BALANCE: "INITIAL_BALANCE",
  DEPOSIT_VOUCHER: "DEPOSIT_VOUCHER"
};

// link for authenticated server redirect
const REDIRECT_AUTH_PAGE_DEV = `https://web.mohiogaming.com/auth/oauth/authorize?response_type=code&state=&client_id=client_local_front&scope=&redirect_uri=http://localhost:3000/calback_url/`;
const REDIRECT_AUTH_PAGE_PROD = getRedirectAuthLink(window.location.origin);

export const REDIRECT_AUTH_PAGE =
  process.env.NODE_ENV === "production"
    ? REDIRECT_AUTH_PAGE_PROD
    : REDIRECT_AUTH_PAGE_DEV;

// ling for logout page of authentification server redirect
const LOGOUT_URI_DEV = `https://web.mohiogaming.com/auth/logout`;
const LOGOUT_URI_PROD = getLogoutLink(window.location.origin);

export const LOGOUT_URI =
  process.env.NODE_ENV === "production" ? LOGOUT_URI_PROD : LOGOUT_URI_DEV;

export const broadcastTypes = {
  LIFECYCLE_STATUS: "LIFECYCLE_STATUS",
  JACKPOT_STATUS: "JACKPOT_STATES"
};

// titles of games
export const gameTitles = {
  DOGS: "GREYHOUND RACING",
  HORSES: "HORSE RACING"
};

// type of games
export const gameTypes = {
  DOG_6: "DOG_6",
  DOG_8: "DOG_8",
  HORSE_6: "HORSE_6",
  HORSE_8: "HORSE_8",
  DOG_6_WEB: "DOG_6_WEB",
  DOG_8_WEB: "DOG_8_WEB",
  HORSE_6_WEB: "HORSE_6_WEB",
  HORSE_8_WEB: "HORSE_8_WEB"
};

// racing type titles for betslip
export const racingTypes = {
  DOG_6: "DOG RACING 6",
  DOG_8: "DOG RACING 8",
  HORSE_6: "HORSE RACING 6",
  HORSE_8: "HORSE RACING 8",
  DOG_6_WEB: "DOG RACING 6",
  DOG_8_WEB: "DOG RACING 8",
  HORSE_6_WEB: "HORSE RACING 6",
  HORSE_8_WEB: "HORSE RACING 8"
};

// lifecycle statuses
export const lifecycleStates = {
  BET: "BET",
  BET_LOCKED: "BET_LOCKED",
  RACE: "RACE",
  DELAY: "DELAY"
};

// type of bets
export const betTypes = {
  FIRST: "First",
  SECOND: "Second",
  THIRD: "Third",
  WIN: "WIN",
  IN_FIRST_2: "IN FIRST 2",
  IN_FIRST_3: "IN FIRST 3",
  EXACTA: "EXACTA",
  QUINELLA: "QUINELLA",
  TRIFECTA: "TRIFECTA",
  EVEN_ODD: "EVEN / ODD",
  OVER_UNDER: "OVER / UNDER"
};
// type of bets
export const betTypesForBetsContainer = {
  MAIN: "MAIN",
  EXACTA: "EXACTA",
  TRIFECTA: "TRIFECTA",
  QUINELLA: "QUINELLA",
  "2WAYBETS": "2WAYBETS"
};

// types of bet(values are equals data-type-bet attribute of odds cell)
export const betsTypeForBetRequest = {
  winBet: "WinBet (%win%)",
  inFirst3: "In First 3 (%win%)",
  quinella: "Quinella (%win%)",
  perfecta: "Perfecta (%win%)", // exacta
  trifecta: "Trifecta (%win%)",
  over: "Over (%win%)",
  under: "Under (%win%)",
  even: "Even (%win%)",
  odd: "Odd (%win%)"
};

// bets tip for show notification
export const betsTipForNotification = {
  EVEN: "Even",
  ODD: "Odd",
  LOW: "Under",
  HIGH: "Over",
  QUINELLA: "Quinella",
  TRIFECTA: "Trifecta",
  PERFECTA: "Exacta",
  FIRST: "Winner",
  PLACEBET: "In First 3"
};

// modes of stake in betslip component of basket
export const stakeModes = {
  STAKE_PER_BET_MODE: "stakePerBet",
  SPLIT_STAKE_MODE: "splitStake",
  CUSTOM_STAKE_MODE: "customStake"
};

// alias for Stake bet in Betslip container
export const incrementsForStake = {
  1: "+1",
  5: "+5",
  10: "+10",
  25: "+25",
  50: "+50",
  [`Max`]: "Max"
};

export const withdrawsStates = {ACTIVE: 'ACTIVE', CASHED_OUT : 'CASHED_OUT', APPLIED: 'APPLIED'};

// pre-loader loading text
export const loadingText = {
  loading: "LOADING..."
};

// rotation warning text
export const rotationText = {
  rotationMessage: "Please rotate your device"
};

// info message types
export const toastMessageTypes = {
  WARN: "WARN",
  INFO: "INFO",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

// form field names
export const inputsNames = {
  // FULLNAME: 'full_name',
  // PHYSICALADDRESS: 'physical_number',
  // PHONENUMBER: 'phone_number',
  LOGIN: "login",
  PINCODE: "pincode",
  EMAIL: "email",
  PINCODECONFIRM: "pincodeConfirm",
  USERNAME: "username",
  PASSWORD: "password",
  WITHDRAWMONEY: "withdrawMoneyAmount",
  VOUCHERCODE: "voucher_code",
  IDNUMBER: "idNumber",
  USERPASSWORD: "password",
  OLDPASSWORD: "oldPassword",
  PASSWORDCONFIRM: "passwordConfirm"
};

// ticket states
export const ticketStates = {
  NOVICE: "NOVICE",
  WINNING: "WINNING",
  LOSING: "LOSING",
  STORNO: "STORNO"
};

// bet statuses
export const betStatuses = {
  NOVICE: "PENDING",
  WON: "WON",
  LOST: "LOST",
  STORNO: "STORNO"
};

export const getTicketStatesOptionsFromConst = () => {
  return [
    { value: "NOVICE", label: "PENDING", isFixed: true },
    { value: "WINNING", label: "WINNING" },
    { value: "LOSING", label: "LOSING" },
    { value: "STORNO", label: "STORNO" }
  ]
}
export const ticketStatesOptions = [
  { value: "NOVICE", label: "PENDING", isFixed: true },
  { value: "WINNING", label: "WINNING" },
  { value: "LOSING", label: "LOSING" },
  { value: "STORNO", label: "STORNO" }
];

export const statuses = {
  statusForRedirect: 401,
  statusOk: 200,
  statusOkNoContent: 204,
  clientError: 400,
  notFound: 404,
  internalError: 500
};
