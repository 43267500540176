import React from "react";
import { useTranslation } from "react-i18next";
import "./../../scss/History/index.scss";

const CurrentBetHistoryContent = ({ results }) => {
  const { t } = useTranslation();
  return (
    <div className="event-history-content">
      <div className="event-container">
        <div className="event-first-three-item">
          <span className={`bet-event-win-single-item-${results.first}`} />
          <span className="event-first-three-item-name">
            {t("history.first")}
          </span>
        </div>
        <div className="event-first-three-item">
          <span className={`bet-event-win-single-item-${results.second}`} />
          <span className="event-first-three-item-name">
            {t("history.second")}
          </span>
        </div>
        <div className="event-first-three-item">
          <span className={`bet-event-win-single-item-${results.third}`} />
          <span className="event-first-three-item-name">
            {t("history.third")}
          </span>
        </div>
      </div>
      <div className="event-container">
        <div className="event-item-1">
          <div className="event-sub-item">
            <span className="event-item-name">{t("history.win")}</span>
            <span className={`event-win-item-${results.first}`} />
            <span className="event-win-coefficient">{results.winner}</span>
          </div>
        </div>
        <div className="event-item-2">
          <span className="event-item-name">{t("history.inFirst3")}</span>
          <div className="event-sub-container">
            <div className="event-sub-item">
              <span className={`bet-event-win-three-items-${results.first}`} />
              <span className="event-win-coefficient">
                {results.placeOddsFirst}
              </span>
            </div>
            <div className="event-sub-item">
              <span className={`bet-event-win-three-items-${results.second}`} />
              <span className="event-win-coefficient">
                {results.placeOddsSecond}
              </span>
            </div>
            <div className="event-sub-item">
              <span className={`bet-event-win-three-items-${results.third}`} />
              <span className="event-win-coefficient">
                {results.placeOddsThird}
              </span>
            </div>
          </div>
        </div>

        <div className="event-item-1">
          <span className="event-item-name">{t("history.exacta")}</span>
          <div className="event-duo-container">
            <div className="event-sub-item">
              <span className={`bet-event-win-duo-item-${results.first}`} />
            </div>
            <div className="event-sub-item">
              <span className={`bet-event-win-duo-item-${results.second}`} />
            </div>
          </div>
          <span className="event-win-coefficient">{results.exacta}</span>
        </div>
        <div className="event-item-1">
          <span className="event-item-name">{t("history.quinella")}</span>
          <div className="event-duo-container">
            <div className="event-sub-item">
              <span className={`bet-event-win-duo-item-${results.second}`} />
            </div>
            <div className="event-sub-item">
              <span className={`bet-event-win-duo-item-${results.first}`} />
            </div>
          </div>
          <span className="event-win-coefficient">{results.quinella}</span>
        </div>
        <div className="event-item-1">
          <span className="event-item-name">{t("history.trifecta")}</span>
          <div className="event-trio-container">
            <div className="event-sub-item">
              <span className={`bet-event-win-trio-item-${results.first}`} />
            </div>
            <div className="event-sub-item">
              <span className={`bet-event-win-trio-item-${results.second}`} />
            </div>
            <div className="event-sub-item">
              <span className={`bet-event-win-trio-item-${results.third}`} />
            </div>
          </div>
          <span className="event-win-coefficient">{results.trifecta}</span>
        </div>
      </div>
      <div className="event-container">
        <div className="event-item-2">
          <span className="event-item-name">{t("history.evenodd")}</span>
          <span className="event-win-odds">
            {results.oddOdds !== null ? t("history.odd") : t("history.even")}
          </span>
          <span className="event-win-coefficient">
            {results.oddOdds !== null ? results.oddOdds : results.evenOdds}
          </span>
        </div>
        <div className="event-item-2">
          <span className="event-item-name">{t("history.overunder")}</span>
          <span className="event-win-odds">
            {results.highOdds !== null ? t("history.over") : t("history.under")}
          </span>
          <span className="event-win-coefficient">
            {results.highOdds !== null ? results.highOdds : results.lowerOdds}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CurrentBetHistoryContent;
