import { getFloor } from "../../utils/helpers";
import { betsTypeForBetRequest } from "../../utils/constants";

export const getNewStakeAmountValueIncrement = (
  stakeAmount,
  incrementForStake
) => {
  const stakeAmountWithDot = String(stakeAmount).replace(",", ".");
  // const stakeAmountWithoutCurrency = stakeAmountWithDot;
  // const commaInAmount =
  //   stakeAmountWithoutCurrency.indexOf(".") !== -1 ? "." : "";
  // const singsAfterComma = stakeAmountWithoutCurrency.split(commaInAmount)[1]
  //   ? stakeAmountWithoutCurrency.split(commaInAmount)[1]
  //   : "00";
  // const NumberBeforeComma = stakeAmountWithoutCurrency.split(commaInAmount)[0]
  //   ? stakeAmountWithoutCurrency.split(commaInAmount)[0]
  //   : "0";

  return `${Number(stakeAmountWithDot) + Number(incrementForStake)}`;
};

export const getNewStakeAmountValue = (stakeAmount, currency) => {
  // const stakeAmountWithDot = stakeAmount.replace(",", ".");
  // // const stakeAmountWithoutCurrency = stakeAmountWithDot.split(" ")[1];
  // const stakeAmountWithoutCurrency = stakeAmountWithDot;
  return `${stakeAmount.replace(",", ".")}`;
};

// remove bets by one
export const getNewBets = (betsObject, eventId, betType) => {
  let betsObjectCopy = Object.assign({}, { ...betsObject });

  if (betsObjectCopy[eventId] && betsObjectCopy[eventId][betType]) {
    let returnedObj = { [eventId]: {} };

    Object.keys(betsObjectCopy[eventId]).map(tip => {
      if (tip !== betType) {
        returnedObj[eventId][tip] = betsObjectCopy[eventId][tip];
      }
      return tip;
    });
    betsObjectCopy[eventId] = returnedObj[eventId];
    return betsObjectCopy;
  }
  // return betsObject;
};

// remove all bets by one event
export const getNewBetsEventDelete = (eventsForBetting, eventId) => {
  const eventsForBettingCopy = { ...eventsForBetting };

  if (eventsForBettingCopy[eventId]) {
    delete eventsForBettingCopy[eventId];
  }

  return eventsForBettingCopy;
};

// set the same stake amount for all bets
export const getBetsWithSameStake = (bets, stakeAmount) => {
  const betsCopy = { ...bets };
  const returnedBets = {};
  Object.keys(betsCopy).map((eventId, i) => {
    returnedBets[eventId] = {};
    const eventObj = betsCopy[eventId];
    let betsByEvent = {};
    Object.keys(eventObj).map(betTip => {
      let dataByOneBet = {};
      // data for one bet
      Object.keys(eventObj[betTip]).map(betProperty => {
        if (betProperty === "stake") {
          dataByOneBet["stake"] = stakeAmount;
        } else if (betProperty === "win") {
          dataByOneBet["win"] =
            (stakeAmount * 100 * (dataByOneBet["odds"] * 100)) / 10000;
        } else {
          dataByOneBet[betProperty] = eventObj[betTip][betProperty];
        }
        return betProperty;
      });
      betsByEvent[betTip] = dataByOneBet;
      return betTip;
    });
    returnedBets[eventId] = betsByEvent;
    return eventId;
  });
  return returnedBets;
};
// change stake for one bet in particular bet type and return new event's bets object
export const getBetsWithOneNewStake = (
  betsObject,
  betStake,
  eventId,
  betType
) => {
  const betsCopy = { ...betsObject };
  const returnedBets = {};
  Object.keys(betsCopy).map((eventId, i) => {
    returnedBets[eventId] = {};
    const eventObj = betsCopy[eventId];
    let betsByEvent = {};
    Object.keys(eventObj).map(betTip => {
      let dataByOneBet = {};
      // data for one bet
      Object.keys(eventObj[betTip]).map(betProperty => {
        dataByOneBet[betProperty] = eventObj[betTip][betProperty];
        return betProperty;
      });
      betsByEvent[betTip] = dataByOneBet;
      return betTip;
    });
    returnedBets[eventId] = betsByEvent;
    return eventId;
  });
  returnedBets[eventId][betType].stake = betStake;
  returnedBets[eventId][betType].win =
    (betStake * 100 * (returnedBets[eventId][betType].odds * 100)) / 10000;
  return returnedBets;
};

export const getBetsWithSplitStake = (bets, stakeAmount, betsCount) => {
  const splitedStakeAmount = betsCount > 0 ? stakeAmount / betsCount : 1;
  const betsCopy = { ...bets };
  const returnedBets = {};
  Object.keys(betsCopy).map((eventId, i) => {
    returnedBets[eventId] = {};
    const eventObj = betsCopy[eventId];
    let betsByEvent = {};
    Object.keys(eventObj).map(betTip => {
      let dataByOneBet = {};
      // data for one bet
      Object.keys(eventObj[betTip]).map(betProperty => {
        if (betProperty === "stake") {
          dataByOneBet["stake"] = splitedStakeAmount;
        } else if (betProperty === "win") {
          dataByOneBet["win"] =
            (splitedStakeAmount * 100 * (dataByOneBet["odds"] * 100)) / 10000;
        } else {
          dataByOneBet[betProperty] = eventObj[betTip][betProperty];
        }
        return betProperty;
      });
      betsByEvent[betTip] = dataByOneBet;
      return betTip;
    });
    returnedBets[eventId] = betsByEvent;
    return eventId;
  });
  return returnedBets;
};

// get summary data from events object with bets
export const getSumDataFromBetsObject = betsObject => {
  let tempTotalStake = 0;
  let tempMaxWinning = 0;
  let betsCount = 0;
  let tempMaxWinningPerTicket = [];
  // set total stake for betslip
  Object.keys(betsObject).map((eventId, i) => {
    const eventObj = betsObject[eventId];
    let maxWinPerTicket = 0;
    Object.keys(eventObj).map(betTip => {
      // eslint-disable-next-line
      if (!eventObj[betTip]) return;
      const stakeFormated = Number(
        String(eventObj[betTip].stake).replace(",", ".")
      );

      const odds = Number(String(eventObj[betTip].odds).replace(",", "."));

      tempTotalStake = stakeFormated + tempTotalStake;
      tempMaxWinning = tempMaxWinning + stakeFormated * odds;
      betsCount = betsCount + 1;
      maxWinPerTicket =
        maxWinPerTicket +
        Number(eventObj[betTip].stake) * Number(eventObj[betTip].odds);

      return betTip;
    });

    tempMaxWinningPerTicket.push(maxWinPerTicket);

    return eventId;
  });

  return {
    tempTotalStake: getFloor(tempTotalStake, 10000),
    tempMaxWinning: tempMaxWinning,
    betsCount: betsCount,
    maxWinningPerTicket: tempMaxWinningPerTicket
  };
};

// get win number from tip property
export const getBetWinNumbers = tip => {
  const start = tip.indexOf("(") !== -1 ? tip.indexOf("(") : 0;
  const end = tip.indexOf(")") !== -1 ? tip.indexOf(")") : 0;
  const betWinNumbers = tip.slice(start + 1, end);

  return betWinNumbers;
};
// get bet type name from tip property
export const getBetTipName = tip => {
  const start = tip.indexOf("(") !== -1 ? tip.indexOf("(") : 0;
  const betTipName = tip.slice(0, start).trim();
  return betTipName === betsTypeForBetRequest.winBet.slice(0, start).trim()
    ? "Winner"
    : betTipName;
};

// check if total stake amount bigger than user's credits
export const checkIfCreditsValueIsLow = (
  totalStakeForLowCredits,
  walletAmount
) => {
  const isCreditsLow = totalStakeForLowCredits > walletAmount;
  return isCreditsLow;
};

// get min stake amount value
export const getMinStakeAmount = minStake => {
  return `${minStake}`;
};

export const checkIfEmptyBetsObject = betsObject => {
  let isEmptyBetsObject = true;
  for (const betsByEvent of Object.keys(betsObject)) {
    if (Object.keys(betsByEvent).length > 0) {
      isEmptyBetsObject = false;
      return isEmptyBetsObject;
    }
  }
  return isEmptyBetsObject;
};
