import React from "react";
import { useTranslation } from "react-i18next";

import cn from "clsx";

import {
  Modal,
  ModalHeader,
  ModalMainContent,
  ModalCloseBtn
} from "./../Modal/Modal";
import ActionButton from "../ActionButton";

const DeleteBetsBetslipModal = ({
  showModal,
  openModalEventListener,
  expiredRaceIds
}) => {
  const { t } = useTranslation();
  return (
    <Modal showModal={showModal} id={"saveUserCreds"}>
      <ModalCloseBtn closeModalEventListener={openModalEventListener} />

      <ModalHeader>
        <h3>{t("modalWindows.deleteBetModal.title")}</h3>
      </ModalHeader>

      <ModalMainContent>
        <div className="input-wrap centered-block align-center">
          {expiredRaceIds.length > 1 && (
            <p>
              {t("modalWindows.deleteBetModal.expiredBetsMsg")}{" "}
              {expiredRaceIds.join(", ")}, {t("modalWindows.deleteBetModal.expiredBetsMsgRemoved")}
            </p>
          )}
          {expiredRaceIds.length === 1 && (
            <p>
              {t("modalWindows.deleteBetModal.expiredBetMsg")}{" "}
              {expiredRaceIds.join(", ")}, {t("modalWindows.deleteBetModal.expiredBetsMsgRemoved")}
            </p>
          )}
          {expiredRaceIds.length === 0 && (
            <p>
              {t("modalWindows.deleteBetModal.expiredBetslipMsg")}
            </p>
          )}
          <ActionButton
            onclick={openModalEventListener}
            classesName={cn("btn btn-warning")}
            isWarning={true}
          >
            {t("OK")}
          </ActionButton>
        </div>
      </ModalMainContent>
    </Modal>
  );
};

export default DeleteBetsBetslipModal;
