import React, { useState, Fragment, useEffect } from "react";

import Accordion from "./../../components/Accordion/Accordion";
import WithdrawContent from "./WithdrawContent";
import { Scrollbars } from "react-custom-scrollbars";
import { getObjectForAccordionsOpenState } from "./../../components/Accordion/helpers";
import { useGlobalDataContext } from "../../../GlobalDataContext";
import Heading from "./Heading";

function WithdrawList({ withdrawHistory }) {
  const { state } = useGlobalDataContext();
  // set the height of withdraw list content after page render
  useEffect(() => {
    setAutoHeightMaxScrollbar(
      document.querySelector(".basket").offsetHeight * 0.9
    );
  }, []);

  const onGetWithdrawHistoryEventListener = event => {
    const raceIndex = event.currentTarget.getAttribute("data-index");

    const openEventsCopy = { ...openEvents };
    openEventsCopy[raceIndex] = !openEventsCopy[raceIndex];
    setOpenEvents(openEventsCopy);
  };

  // state for saving open state of each accordion
  const [openEvents, setOpenEvents] = useState(
    getObjectForAccordionsOpenState(withdrawHistory)
  );

  const [autoHeightMaxScrollBar, setAutoHeightMaxScrollbar] = useState(1000);

  return (
    <Scrollbars autoHeight autoHeightMin={autoHeightMaxScrollBar}>
      {withdrawHistory.map((event, i) => {
        const heading =
            <Heading currency={state.gameSettings.currency} amount={event.amount} code={event.id.code}/>;

        return (
          <Fragment key={i}>
            <Accordion
              isOpen={openEvents[event.id.code]}
              onToggle={onGetWithdrawHistoryEventListener}
              heading={heading}
              secondaryHeading={`${new Date(
                event.createdTimestamp
              ).getDate()}/${new Date(event.createdTimestamp).getMonth() +
                1}/${new Date(event.createdTimestamp).getFullYear()}`}
              dataIndex={event.id.code}
            />
            <div
              className="collapse-css-transition"
              aria-hidden={openEvents[event.id.code] ? "false" : "true"}
              style={{
                overflow: "hidden",
                height: openEvents[event.id.code] ? "100%" : 0,
                visibility: openEvents[event.id.code] ? null : "hidden"
              }}
            >
              <WithdrawContent withdraws={event} />
            </div>
          </Fragment>
        );
      })}
    </Scrollbars>
  );
}

export default WithdrawList;
