import React from "react";

import { REDIRECT_AUTH_PAGE } from "./../../utils/constants";

import PreLoader from "../PreLoader/PreLoader";

export const Login = ({ login }) => {
  // start image changing in on left slider
  React.useEffect(() => {
    setTimeout(()=> {
      window.location.href = REDIRECT_AUTH_PAGE;
    }, 500)
    // eslint-disable-next-line
  }, []);

  return <PreLoader isLoading={true} />;
};

export default Login;
