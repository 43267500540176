import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "clsx";
import { betsTypeForBetRequest } from "../../../utils/constants";

const ExactaBetOddsTable = ({
  oddsData,
  columnTitles,
  rowTitles,
  onAddBetEventListener,
  eventId,
  betsForEvent,
  raceType,
  language
}) => {
  const [exactaBet, setExactaBet] = useState(
    betsForEvent[betsTypeForBetRequest.perfecta]
      ? `${betsForEvent[betsTypeForBetRequest.perfecta].first -
          1}||${betsForEvent[betsTypeForBetRequest.perfecta].second - 1}`
      : ""
  ); // state for saving active cell

  useEffect(() => {
    if (Object.keys(betsForEvent).length === 0) {
      setExactaBet("");
    }

    setExactaBet(
      betsForEvent[betsTypeForBetRequest.perfecta]
        ? `${betsForEvent[betsTypeForBetRequest.perfecta].first -
            1}||${betsForEvent[betsTypeForBetRequest.perfecta].second - 1}`
        : ""
    );
  }, [betsForEvent]);

  // set the active cell in odds table after game changed
  useEffect(() => {
    if (betsForEvent[betsTypeForBetRequest.perfecta]) {
      setExactaBet(
        `${betsForEvent[betsTypeForBetRequest.perfecta].first -
          1}||${betsForEvent[betsTypeForBetRequest.perfecta].second - 1}`
      );
    }

    // eslint-disable-next-line
  }, [raceType]);

  // make bet (create bet in betslip container in basket)
  // in each column available to choose only one cell
  const onClickEventListener = event => {
    // const typeBet = event.currentTarget.getAttribute("data-type-bet");
    const coordsCell = event.currentTarget.getAttribute("data-coords-cell");
    setExactaBet(coordsCell === exactaBet ? "" : coordsCell);
    const coordsCellInArray = coordsCell.split("||");
    // prepare all needed data for send bets to the server
    if (coordsCellInArray[0] !== coordsCellInArray[1])
      onAddBetEventListener(event);
  };

  // const getOddsDataForMobileVersion = oddsData => {
  //   for (let i = 0; i < oddsData.length; i++) {
  //     for (let j = 0; j < oddsData.length; j++) {
  //       if (i !== j) return oddsData[i][j]
  //     }
  //   }
  // };
  return (
    <div className="flex-row accordion-table-wrap">
      <table className="flex-col-21 bordered-table light-table table exacta">
        <thead>
          <tr>
            {/*empty cell*/}
            <th
              className={`titleRow-class no-table-cell first-second-label ${language} `}
            ></th>

            {/*name type of bets*/}
            {columnTitles.map((title, j) => {
              return (
                <th className="no-table-cell" key={j}>
                  <span className={`event-win-item-${title}`}></span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rowTitles.map((titleRow, k) => {
            return (
              <tr key={k}>
                {/* cell of column with number*/}
                <td className="no-table-cell">
                  <span className={`event-win-item-${titleRow}`}></span>
                </td>
                {oddsData[k].map((cellOdd, j) => {
                  // row of cells with odds
                  return (
                    <td
                      className={cn(
                        {
                          active: exactaBet.includes(`${k}||${j}`) && k !== j
                        },
                        {
                          unavailable: k === j
                        }
                      )}
                      data-type-bet={betsTypeForBetRequest.perfecta}
                      data-coords-cell={`${k}||${j}`}
                      data-odd-amount={cellOdd}
                      data-win-number={`${k + 1}||${j + 1}`}
                      data-event-id={eventId}
                      onClick={onClickEventListener.bind(this)}
                      key={j}
                    >
                      {cellOdd}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

ExactaBetOddsTable.propTypes = {
  oddsData: PropTypes.array
};

export default ExactaBetOddsTable;
